import { useUploadContext } from "@context/CTUpload/UploadContext";
import { D3DrawerContext } from "@provider/D3DrawerContext";

import { Button, Progress, Tooltip } from "antd";
import { useContext } from "react";
import ListView from "./CTUploadManager/ListView";

function UploadManager() {
  const { fileList } = useUploadContext();
  const { openDrawer } = useContext(D3DrawerContext);
  return fileList.length > 0 ? (
    <Tooltip placement="left" title="Gerenciamento de Upload">
      <Button type="link" onClick={() => openDrawer(<ListView />, "right", 800, "Gerenciamento de Upload")}>
        <Progress
          size={28}
          type="circle"
          percent={
            fileList.length > 0
              ? Math.round((fileList.filter((file) => file.progress === 100).length / fileList.length) * 100)
              : 0
          }
          steps={{ count: 10, gap: 8 }}
          trailColor="rgba(0, 0, 0, 0.06)"
          strokeWidth={10}
        />
      </Button>
    </Tooltip>
  ) : null;
}

export default UploadManager;
