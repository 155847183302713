import { create } from "zustand";
import {
  CompanyUserListItemType,
  CreateCompanyUserType,
  ItemListReportsType,
  ListCompanyUserType,
  LockOrUnlockPayloadType,
  SearchCompanyUserType,
  SelectPermissionsGroupItemType,
  SimpleListPermissionsGroupType,
  SimpleListReportsType,
  UpdateCompanyUserType,
  ViewCompanyUserType,
} from "./types";

import showMessage from "@utils/showMessage";

import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";

type CompanyUserStoreState = {
  apiResult: ListCompanyUserType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchCompanyUserType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchCompanyUserType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadCompanyUsers: () => Promise<Success<ListCompanyUserType> | BadRequest | StandardError>;
  getCompanyUser: (cuid: string) => Promise<Success<ViewCompanyUserType> | StandardError>;
  getPermissionsGroupForSelect: () => Promise<SelectPermissionsGroupItemType[]>;
  createCompanyUser: (
    companyUser: CreateCompanyUserType
  ) => Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError>;
  updateCompanyUser: (cuid: string, companyUser: UpdateCompanyUserType) => Promise<boolean>;
  loadingUpdate: boolean;
  lockCompanyUser: (cuids: string[]) => Promise<void>;
  unLockCompanyUser: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: CompanyUserListItemType[]) => void;
  generatePassword: (userCuid: string) => Promise<void>;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;

  simpleListReports: () => Promise<void>;
  loadingReport: boolean;
  reports: ItemListReportsType[];
};

const defaultSort = {
  sort_by: "usr_name",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  usr_name: { op: "lk", type: "string" },
  usr_email: { op: "lk", type: "string" },
  usr_username: { op: "lk", type: "string" },
  usr_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    usr_name: "",
    usr_email: "",
    usr_username: "",
    usr_active: "on",
  } as SearchCompanyUserType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
  loadingUpdate: false,
  loadingReport: false,
  reports: [],
};

export const useCompanyUsersStore = create<CompanyUserStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchCompanyUserType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadCompanyUsers();
  },
  onMultiSelectedRowChange: (selectedRows: CompanyUserListItemType[]): void =>
    IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({
      filter: {
        usr_name: "",
        usr_email: "",
        usr_username: "",
        usr_active: "on",
      },
    });
    get().loadCompanyUsers();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadCompanyUsers();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["name", "usr_name"],
        ["email", "usr_email"],
        ["username", "usr_username"],
        ["active", "usr_active"],
      ]),
      set,
      get().loadCompanyUsers
    ),
  loadCompanyUsers: async (): Promise<Success<ListCompanyUserType> | BadRequest | StandardError> => {
    set({ loading: true });

    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    return await new ClientHttp().get<Success<ListCompanyUserType>, BadRequest | StandardError>(
      `/api/v1/company/users?${qs}`,
      (result: Success<ListCompanyUserType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadCompanyUsers();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getCompanyUser: async (cuid: string): Promise<Success<ViewCompanyUserType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCompanyUserType>, StandardError>(`/api/v1/company/users/${cuid}`);
  },
  getPermissionsGroupForSelect: async (): Promise<SelectPermissionsGroupItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListPermissionsGroupType>,
      StandardError,
      SelectPermissionsGroupItemType[]
    >(
      "/api/v1/company/permissions-groups/simple-list",
      (result: Success<SimpleListPermissionsGroupType>): SelectPermissionsGroupItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.name,
            active: item.active,
          } as SelectPermissionsGroupItemType;
        });
      },
      (error: StandardError): SelectPermissionsGroupItemType[] => {
        showMessage(error);
        return [] as SelectPermissionsGroupItemType[];
      }
    );
  },
  createCompanyUser: async (
    companyUser: CreateCompanyUserType
  ): Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateCompanyUserType,
      Success<{ data: { cuid: string } }>,
      BadRequest | StandardError
    >("/api/v1/company/users", companyUser, (result: Success<{ data: { cuid: string } }>) => {
      get().loadCompanyUsers();
    });
  },
  updateCompanyUser: async (cuid: string, companyUser: UpdateCompanyUserType): Promise<boolean> => {
    set({ loadingUpdate: true });
    const result = await new ClientHttp().put<UpdateCompanyUserType, Success<void>, BadRequest | StandardError>(
      `/api/v1/company/users/${cuid}`,
      companyUser,
      (result: Success<void>) => {
        showMessage(result, "Usuário atualizado com sucesso.");
        get().loadCompanyUsers();
        set({ loadingUpdate: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdate: false });
      }
    );
    return result.status === "success";
  },
  lockCompanyUser: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/users/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCompanyUsers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockCompanyUser: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/company/users/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCompanyUsers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  generatePassword: async (userCuid: string): Promise<void> => {
    await new ClientHttp().patch<null, Success<void>, BadRequest | StandardError>(
      `/api/v1/company/users/${userCuid}/generate-password`,
      null,
      (result: Success<void>) => {
        showMessage(result, "Nova senha enviada com sucesso.");
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  simpleListReports: async (): Promise<void> => {
    set({ loadingReport: true });
    await new ClientHttp().get<Success<SimpleListReportsType>, BadRequest | StandardError>(
      "/api/v1/reports/contexts/CPN/simple-list",
      (result: Success<SimpleListReportsType>): void => {
        set({
          reports: result.body.data,
          loadingReport: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        set({ loadingReport: false });
        showMessage(error);
      }
    );
  },
}));
