import extractOptions from "@utils/extractOptions";
import { Form, Input, Select } from "antd";
import { useState } from "react";

type ItemEMAProps = {
  options: string;
  name: number;
  search_option: string;
};

function ItemEMA({ name, options, search_option = "", ...props }: ItemEMAProps) {
  const [option, setOption] = useState<string>(search_option);
  return (
    <div className="flex w-full gap-3 ">
      <Form.Item className="w-40 p-0 m-0" {...props} name={[name, "search_option"]}>
        <Select
          className="min-w-40"
          onChange={(e) => setOption(e)}
          placeholder="Opções de busca"
          options={extractOptions(options)}
        />
      </Form.Item>

      {option === "MV" ? (
        <Form.Item {...props} className="w-full p-0 m-0" name={[name, "values"]}>
          <Select allowClear open={false} mode="tags" suffixIcon={<></>} />
        </Form.Item>
      ) : (
        <Form.Item
          {...props}
          className="w-full p-0 m-0"
          name={[name, "value"]}
          rules={[{ type: "email", message: "E-mail inválido." }]}
        >
          <Input />
        </Form.Item>
      )}
    </div>
  );
}

export default ItemEMA;
