import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Table, Tag, Transfer } from "antd";
import { useContext, useEffect } from "react";
import { useCustomerGroupsStore } from "../store";
import { DataSourceType } from "../types";

import SpinLoading from "@components/shared/SpinLoading";
import { TransferDirection } from "antd/es/transfer";
import { TransferKey } from "antd/es/transfer/interface";

const columns = [
  {
    dataIndex: "id",
    title: "Código",
  },
  {
    dataIndex: "fantasy_name",
    title: "Name Fantasia",
  },
  {
    dataIndex: "name",
    title: "Razão Social",
  },
  {
    dataIndex: "active",
    title: "Status",
    render: (active: boolean) => (
      <Tag color={active ? "success" : "error"}>{active ? "Desbloqueado" : "Bloqueado"}</Tag>
    ),
  },
];

type TransferCustomerProps = {
  cuid: string;
};

function TransferCustomerForm({ cuid }: TransferCustomerProps) {
  const {
    viewCustomerGroup,
    loadingView,
    simpleListSystemCustomer,
    loadingCustomers,
    updateGroup,
    loadingUpdateGroup,
    dataSource,
    targetKeys,
    payload,
    onChangeTransfer,
  } = useCustomerGroupsStore();

  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await Promise.all([viewCustomerGroup(cuid), simpleListSystemCustomer()]);
    })();
  }, [cuid]);

  console.log(payload.cuids_to_add?.length === 0 && payload.cuids_to_remove?.length === 0);

  return loadingView || loadingCustomers ? (
    <SpinLoading />
  ) : (
    <div className="flex flex-col w-full h-full gap-3 p-3">
      <Transfer
        showSearch
        rowKey={(record: DataSourceType) => record.cuid}
        dataSource={dataSource}
        targetKeys={targetKeys}
        onChange={(targetKeys: TransferKey[], direction: TransferDirection, moveKeys: TransferKey[]) => {
          onChangeTransfer(moveKeys, direction);
        }}
        showSelectAll={false}
        titles={[
          <div className="flex items-center justify-center">
            <Tag className="flex items-center justify-start w-32 h-6 gap-1 px-2 select-none" color="processing">
              Clientes da Empresa
            </Tag>
          </div>,
          <div className="flex items-center justify-center">
            <Tag className="flex items-center justify-start w-32 h-6 gap-1 px-2" color="processing">
              Clientes do Grupo
            </Tag>
          </div>,
        ]}
        operations={["Adicionar", "Remover"]}
        filterOption={(inputValue, option) =>
          option.id.toString().toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
          option.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
          option.fantasy_name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
      >
        {({ direction, filteredItems, disabled, onItemSelectAll, onItemSelect, selectedKeys }) => {
          return (
            <Table
              rowKey="cuid"
              rowSelection={{
                preserveSelectedRowKeys: true,
                type: "checkbox",
                selectedRowKeys: selectedKeys,
                onSelect: (record, selected) => {
                  onItemSelect(record.cuid, selected);
                },
                onSelectAll: (selected, _, changeRows) => {
                  const changedKeys = changeRows.map((r) => r.cuid);
                  onItemSelectAll(changedKeys, selected);
                },
              }}
              columns={columns}
              dataSource={filteredItems}
              size="small"
            />
          );
        }}
      </Transfer>
      <div className="flex justify-end gap-3 min-h-10">
        <Button type="default" disabled={loadingUpdateGroup} danger onClick={() => onClose()}>
          Cancelar
        </Button>
        <D3Can action="create" resource="customer_group">
          <Button
            disabled={payload.cuids_to_add?.length === 0 && payload.cuids_to_remove?.length === 0}
            loading={loadingUpdateGroup}
            type="primary"
            onClick={async () => {
              const isUpdate = await updateGroup(cuid);
              if (isUpdate) {
                onClose();
              }
            }}
          >
            Atualizar
          </Button>
        </D3Can>
      </div>
    </div>
  );
}

export default TransferCustomerForm;
