import { Button, ButtonProps, Modal, ModalProps, Spin } from "antd";
import { ReactNode } from "react";

type CTNotificationProps = {
  type?: "info" | "success" | "error" | "warn" | "warning" | "confirm"; // usar confirm quando tiver promise que no button aparece loading.
  title: ReactNode;
  content: ReactNode;
  duration?: number;
  withoutFooter?: boolean;
  showCustom?: boolean;
  customButtonProps?: ButtonProps;
  customText?: string;
  onCustom?: (...args: any[]) => any;
  doNotClose?: boolean;
  cancelShow?: boolean;
  wait?: boolean;
} & ModalProps;

let activeModal: { destroy: () => void } | null = null;

export const ctNotification = ({
  type = "success",
  title,
  content,
  duration = 3,
  withoutFooter = false,
  showCustom = false,
  onCustom,
  customText,
  customButtonProps,
  doNotClose = false,
  cancelShow = true,
  wait = false,
  ...props
}: CTNotificationProps) => {
  if (activeModal) {
    activeModal.destroy();
  }

  const modal = Modal[type];
  let isOkLoading = false;

  const instance = modal({
    ...props,
    title,
    content: <span className="text-base">{content}</span>,
    centered: true,
    className: "select-none",
    closable: true,
    maskClosable: true,
    footer: () =>
      wait ? (
        <div className="flex items-center justify-center w-full py-4">
          <Spin />
        </div>
      ) : !withoutFooter ? (
        <div className="flex items-center justify-end gap-1">
          {showCustom && (
            <Button onClick={onCustom} {...customButtonProps}>
              {customText}
            </Button>
          )}
          {cancelShow && (
            <Button
              {...props.cancelButtonProps}
              onClick={async (e) => {
                if (props.onCancel) {
                  await props.onCancel(e as React.MouseEvent<HTMLButtonElement>);
                }
                instance.destroy();
              }}
              className="cancel-button"
            >
              {props.cancelText}
            </Button>
          )}
          <Button
            {...props.okButtonProps}
            type="primary"
            loading={isOkLoading}
            onClick={async (e) => {
              if (props.onOk) {
                const result = props.onOk(e as React.MouseEvent<HTMLButtonElement>);
                if (type === "confirm") {
                  try {
                    isOkLoading = true;
                    instance.update({
                      okButtonProps: { ...props.okButtonProps, loading: true },
                    });
                    await result;
                  } finally {
                    isOkLoading = false;
                    instance.update({
                      okButtonProps: { ...props.okButtonProps, loading: false },
                    });
                  }
                } else {
                  await result;
                }
              }
              instance.destroy();
            }}
          >
            {props.okText}
          </Button>
        </div>
      ) : null,
  });

  activeModal = instance;

  if (!doNotClose) {
    setTimeout(() => {
      instance.destroy();
      if (activeModal === instance) {
        activeModal = null;
      }
    }, duration * 1000);
  }
};
