import useAuthStore from "@provider/AuthProvider";
import { Checkbox, Spin } from "antd";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AutoSizer, GridCellProps, Grid, List } from "react-virtualized";
import ItemGrid from "./components/ItemGrid";
import SkeletonFile from "./components/SkeletonFile";
import useNavigateStore from "./store";
import UploadFileUnDraw from "./components/UploadFileUnDraw";
import { ListRowProps, RenderedRows } from "react-virtualized/dist/es/List";
import ItemList from "./components/ItemList";
import useSearchStore from "./components/search/store";

function NavigateBody() {
  const { cuid } = useParams<{ cuid: string }>();

  const FSRootCuid = useAuthStore().getUserInfo().FSRoot.Cuid;

  const navigate = useNavigate();

  const { loadContent, loading, content, resetState, viewType, isScroll, onCheckAllChange, selected, isFilter } =
    useNavigateStore();

  useEffect(() => {
    if (!cuid) {
      navigate(`/app/customer/spaces/work/${FSRootCuid}`);
    } else if (isFilter) {
      return;
    } else if (content.cuid) {
      if (content.breadcrumbs.length > 0) {
        if (FSRootCuid === content.breadcrumbs[0].cuid) {
          (async () => {
            await loadContent(cuid);
          })();
        } else {
          navigate(`/app/customer/spaces/work/${FSRootCuid}`);
        }
      }
    } else {
      (async () => {
        await loadContent(cuid);
      })();
    }
    return () => {
      resetState();
    };
  }, [cuid, isFilter, FSRootCuid]);

  const loadMoreItems = useCallback(async () => {
    if (loading) return;
    if (isFilter) {
      await useSearchStore.getState().loadContentWithFilter();
    } else if (cuid) {
      await loadContent(cuid);
    }
  }, [loadContent, loading, isFilter]);

  const cellRenderer = ({ columnIndex, key, rowIndex, style, parent }: GridCellProps) => {
    const { columnCount } = parent.props;
    const index = rowIndex * columnCount + columnIndex;
    const item = content.children[index];
    return item ? (
      <div key={key} style={style}>
        <ItemGrid
          key={`item-${item.cuid}`}
          cuid={item.cuid}
          name={item.name}
          createdUserName={item.created_user_name}
          type={item.type}
          isPhysical={item.is_physical}
          fileUploadCompleted={item.file_upload_completed}
          icon={item.extension.icon}
        />
      </div>
    ) : content.children.length < content.total_children ? (
      <div key={key} style={style}>
        <SkeletonFile key={`skeleton-${index}`} />
      </div>
    ) : (
      <div style={style} key={`clear-${index}`}></div>
    );
  };

  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    const item = content.children[index];
    return item ? (
      <div key={key} style={style}>
        <ItemList
          key={`item-${item.cuid}`}
          cuid={item.cuid}
          name={item.name}
          createdUserName={item.created_user_name}
          type={item.type}
          isPhysical={item.is_physical}
          fileUploadCompleted={item.file_upload_completed}
          icon={item.extension.icon}
        />
      </div>
    ) : content.children.length < content.total_children ? (
      <div key={key} style={style}>
        <SkeletonFile key={`skeleton-${index}`} />
      </div>
    ) : (
      <div style={style} key={`clear-${index}`}></div>
    );
  };

  return (
    <>
      {viewType === "list" && (
        <div
          className={`grid grid-cols-9 w-full bg-white divide-x-[1px] font-semibold  border-b-[1px] z-50 shadow-b border-gray-200 min-h-12 ${
            isScroll ? "pr-[10px]" : ""
          }`}
        >
          <div className="flex items-center col-end-1 px-2 min-h-12 min-w-9 max-w-9">
            <Checkbox
              indeterminate={selected.indeterminate}
              disabled={content.children.length === 0}
              checked={selected.checkAll}
              onChange={onCheckAllChange}
            />
          </div>
          <div className="flex items-center justify-start col-span-3 px-2 min-h-12 0">Descrição</div>
          <div className="flex items-center justify-start col-span-1 px-2 min-h-1">Tipo</div>
          <div className="flex items-center justify-start col-span-1 px-2 min-h-12">Natureza</div>
          <div className="flex items-center justify-start col-span-2 px-2 min-h-12">Autor</div>
          <div className="flex items-center justify-start col-span-1 px-2 min-h-12">Status Upload</div>
          <div className="flex items-center justify-center col-span-1 px-2 min-h-12">Ações</div>
        </div>
      )}

      {content.children.length < 1 && loading === false ? (
        <div className="flex items-center justify-center w-full grow">
          <UploadFileUnDraw />
        </div>
      ) : content.children.length < 1 ? (
        <div className="flex items-center justify-center h-full grow">
          <Spin />
        </div>
      ) : (
        <div className="flex w-full h-full grow">
          <AutoSizer>
            {({ height, width }) => {
              const columns = Math.floor(width / 260);
              const rowCount = Math.ceil(content.children.length / columns);
              return viewType === "list" ? (
                <List
                  className="custom-scroll"
                  height={height}
                  onRowsRendered={(info: RenderedRows) => {
                    const hasScroll =
                      info.overscanStartIndex > 0
                        ? info.overscanStopIndex >= info.stopIndex
                        : info.overscanStopIndex > info.stopIndex;
                    useNavigateStore.setState({ isScroll: hasScroll });
                  }}
                  rowCount={content.children.length}
                  rowHeight={49}
                  rowRenderer={rowRenderer}
                  width={width}
                  onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
                    if (content.children.length < content.total_children) {
                      if (!loading && scrollTop + clientHeight >= scrollHeight) {
                        loadMoreItems();
                      }
                    }
                  }}
                />
              ) : (
                <>
                  <Grid
                    className="custom-scroll"
                    cellRenderer={cellRenderer}
                    columnCount={columns}
                    columnWidth={260}
                    height={height}
                    rowCount={rowCount}
                    rowHeight={210}
                    width={width}
                    onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
                      if (content.children.length < content.total_children) {
                        if (!loading && scrollTop + clientHeight >= scrollHeight) {
                          loadMoreItems();
                        }
                      }
                    }}
                  />
                </>
              );
            }}
          </AutoSizer>
        </div>
      )}
    </>
  );
}

export default NavigateBody;
