import { Button, Col, Form, Input, Row, Segmented, Space, Spin } from "antd";
import { useCGRCustomersStore } from "../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateCGRCustomerForm } from "./UpdateCGRCustomerForm";
import { D3Date } from "@utils/date";
import { ViewCGRCustomerType } from "../types";
import showMessage from "@utils/showMessage";
import { PatternFormat } from "react-number-format";
import D3Can from "@components/shared/D3Can";

type ViewCGRCustomerFormProps = {
  cuid: string;
};

export const ViewCGRCustomerForm = ({ cuid }: ViewCGRCustomerFormProps) => {
  const { getCGRCustomer } = useCGRCustomersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewCGRCustomerType>();

  useEffect(() => {
    (async () => {
      const result = await getCGRCustomer(cuid);
      if (result.status === "success") {
        const viewCGRCustomer = result.body as ViewCGRCustomerType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewCGRCustomer.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewCGRCustomer.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getCGRCustomer]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
          <Form.Item<ViewCGRCustomerType> label="Nome Fantasia" name="fantasy_name">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCGRCustomerType> label="Razão Social" name="name">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCGRCustomerType> label="Empresa" name={["company", "fantasy_name"]}>
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCGRCustomerType> label="Responsável pelo contrato" name="contract_manager">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCGRCustomerType> name="active" label="Status">
            <Segmented
              disabled
              options={[
                { label: "Desbloqueado", value: true },
                { label: "Bloqueado", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewCGRCustomerType> label="Pessoa" name="document_type">
            <Segmented
              disabled
              options={[
                { label: "Jurídica", value: "CNPJ" },
                { label: "Física", value: "CPF" },
              ]}
            />
          </Form.Item>
          {form.getFieldValue("document_type") === "CPF" ? (
            <Form.Item<ViewCGRCustomerType> label="CPF" name="document">
              <PatternFormat format="###.###.###-##" customInput={Input} disabled />
            </Form.Item>
          ) : (
            <Row gutter={10}>
              <Col>
                <Form.Item<ViewCGRCustomerType> label="CNPJ" className="w-[294px]" name="document">
                  <PatternFormat disabled format="##.###.###/####-##" customInput={Input} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item<ViewCGRCustomerType>
                  label="Inscrição Estadual"
                  className="w-[294px]"
                  name="state_inscription"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item<ViewCGRCustomerType> label="Criado em" name="created_at">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCGRCustomerType> label="Atualizado em" name="updated_at">
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={false} type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer">
                  <Button
                    disabled={false}
                    type="primary"
                    onClick={() => openDrawer(<UpdateCGRCustomerForm cuid={cuid} />, "right", 646, "Editar Cliente")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
