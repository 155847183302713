import { useContext, useEffect, useState } from "react";

import { Button, Form, Space, Spin, Tabs } from "antd";

import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useDocumentTypesStore } from "../../../store";
import { UpdateDocumentTypeType, ViewDocumentTypeType } from "../../../types";
import UpdateData from "../UpdateData";
import UpdateIndex from "../UpdateIndex";

type TabUpdatePropsType = {
  cuid: string;
};

export const TabUpdate = ({ cuid }: TabUpdatePropsType) => {
  const { updateDocumentType, getActiveTab, setActiveTab, payload, getDocumentType, setIndexadores, resetStateDrawer } =
    useDocumentTypesStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateDocumentTypeType>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await getDocumentType(cuid);
      if (result.status === "success") {
        const viewDocumentType = result.body as ViewDocumentTypeType;
        form.setFieldsValue(viewDocumentType);
        setIndexadores(
          viewDocumentType.document_type_indexes.map((item: any) => {
            return {
              default_search_option: item.default_search_option,
              end_interval_required: item.end_interval_required,
              is_allowed_copy: item.is_allowed_copy,
              is_global_index: item.is_global_index,
              label: item.label,
              list_of_key_and_value: item.list_of_key_and_value.map((kv: any) => ({
                key: kv.key,
                value: kv.value,
              })),
              order: item.order,
              required: item.required,
              search_key: item.search_key,
              size: item.size,
              id: item.id,
              start_interval_required: item.start_interval_required,
              system_index_type_id: item.system_index_type.id,
              temporality_date: item.temporality_date,
              default_input: {
                can_set_end_interval_required: item.system_index_type.can_set_end_interval_required,
                can_set_required: item.system_index_type.can_set_required,
                can_set_global: item.system_index_type.can_set_global,
                can_set_list_of_key_and_value: item.system_index_type.can_set_list_of_key_and_value,
                can_set_search_key: item.system_index_type.can_set_search_key,
                can_set_size: item.system_index_type.can_set_size,
                can_set_start_interval_required: item.system_index_type.can_set_start_interval_required,
                can_set_temporality_date: item.system_index_type.can_set_temporality_date,
                id: item.system_index_type.id,
                key: item.system_index_type.key,
                label: item.system_index_type.label,
                mask: item.system_index_type.mask,
                max_size: item.system_index_type.max_size,
                regex: item.system_index_type.regex,
                search_options: item.system_index_type.search_options,
                default_search_option: item.default_search_option,
                table: item.system_index_type.table,
              },
            };
          })
        );
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
    return () => {
      resetStateDrawer();
    };
  }, [cuid, form, getDocumentType]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payloads: UpdateDocumentTypeType) => {
            setLoading(true);
            if (payloads.temporality_permanent_guard) {
              payloads.temporality_year = 0;
              payloads.is_temporality_mandatory = false;
            }
            payloads.document_type_indexes = payload;
            const result = await updateDocumentType(cuid, payloads);
            if (result.status === "success") {
              showMessage(result, "Tipo de Documento cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }

            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            tabPosition="top"
            defaultActiveKey="1"
            activeKey={getActiveTab()}
            onTabClick={(tab) => {
              setActiveTab(tab);
              if (tab === "1") {
                setSize(600);
              } else {
                setSize(1500);
              }
            }}
            className="flex h-full overflow-hidden select-none"
            items={[
              {
                key: "1",
                label: (
                  <Button className="flex items-center" type="link" icon={<Icon icon="ri:profile-line" />} size="small">
                    Dados
                  </Button>
                ),
                children: <UpdateData />,
              },
              {
                key: "2",
                label: (
                  <Button
                    className="flex items-center"
                    type="link"
                    icon={<Icon icon="dashicons:index-card" />}
                    size="small"
                  >
                    Indexadores
                  </Button>
                ),
                children: <UpdateIndex />,
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_document_type">
                  <Button type="primary" htmlType="submit">
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
