import { green, red } from "@ant-design/colors";
import D3Can from "@components/shared/D3Can";
import D3Status from "@components/shared/D3Status";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { Button, Progress, Result, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateShelfForm } from "./components/CreateShelfForm";
import { UpdateShelfForm } from "./components/UpdateShelfForm";
import { ViewShelfForm } from "./components/ViewShelfForm";
import { useShelvesStore } from "./store";
import { RowShelfType } from "./types";

const CustomerShelvesView: React.FC = () => {
  let { storageCuid } = useParams();
  const navigate = useNavigate();
  const { openDrawer } = useContext(D3DrawerContext);
  const { setStorageCuid, apiResult, loading, loadShelves, lockShelf, unLockShelf } = useShelvesStore();

  useEffect(() => {
    (async () => {
      if (storageCuid) {
        setStorageCuid(storageCuid);
        await loadShelves();
      }
    })();
  }, [loadShelves, storageCuid, setStorageCuid]);

  return storageCuid === undefined ? (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary">Back Home</Button>}
    />
  ) : (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<RowShelfType>
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data as RowShelfType[]}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Button
                    className="flex items-center justify-center"
                    icon={<Icon height={18} icon="ion:arrow-back-outline" />}
                    type="dashed"
                    onClick={() => navigate(-1)}
                  >
                    Voltar
                  </Button>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Estantes</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="customer_storage">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateShelfForm />, "right", 600, "Cadastrar Estante")}
                    >
                      Nova Estante
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
      >
        <Table.Column<RowShelfType> key="id" title="Código" dataIndex="id" />
        <Table.Column<RowShelfType> key="abbreviation" title="Estante" dataIndex="abbreviation" />

        <Table.Column<RowShelfType> key="number_of_positions" title="Nº de posições" dataIndex="number_of_positions" />
        <Table.Column<RowShelfType>
          key="number_of_repositories"
          title="Nº de repositórios"
          dataIndex="number_of_repositories"
        />
        <Table.Column<RowShelfType>
          defaultSortOrder="ascend"
          title="Ocupação"
          render={(value, shelf, index) => {
            const percent = Number(((shelf.number_of_repositories / shelf.number_of_positions) * 100).toPrecision(2));

            return (
              <Progress
                steps={10}
                size={[8, 20]}
                percent={percent}
                strokeColor={[green[6], green[5], green[4], green[3], green[2], red[2], red[3], red[4], red[4], red[6]]}
                status="normal"
              />
            );
          }}
        />
        <Table.Column<RowShelfType>
          key="updated_at"
          title="Última atualização"
          render={(value, shelf) => D3DateHourSorter(shelf.updated_at)}
        />

        <Table.Column<RowShelfType>
          key="active"
          title="Status"
          dataIndex="active"
          render={(value) => <D3Status status={value} />}
        />
        <Table.Column<RowShelfType>
          key="action"
          title="Ações"
          width={170}
          render={(value, shelf) => {
            return (
              <>
                <Space>
                  <D3Can action="view" resource="customer_storage">
                    <Tooltip title="Vão Prateleira" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="ri:merge-cells-vertical"
                            height={20}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() => navigate(`/app/customer/storages/${storageCuid}/shelves/${shelf.cuid}`)}
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="update" resource="customer_storage">
                    <Tooltip title="Editar" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<UpdateShelfForm cuid={shelf.cuid} />, "right", 600, "Editar Estante")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="customer_storage">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<ViewShelfForm cuid={shelf.cuid} />, "right", 600, "Visualizar Estante")
                        }
                      />
                    </Tooltip>
                  </D3Can>

                  <ToggleLockAction
                    rules="customer_storage.lock"
                    description="Confirma bloqueio?"
                    title="Atenção!"
                    isVisible={shelf.active}
                    tooltip="Bloquear"
                    paramsToAction={[shelf.cuid]}
                    action={async () => {
                      await lockShelf(shelf.cuid);
                    }}
                  />

                  <ToggleLockAction
                    rules="customer_storage.unlock"
                    title="Atenção!"
                    description="Confirma desbloqueio?"
                    isVisible={!shelf.active}
                    tooltip="Desbloquear"
                    paramsToAction={[shelf.cuid]}
                    action={async () => {
                      await unLockShelf(shelf.cuid);
                    }}
                  />
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default CustomerShelvesView;
