import { Col, Divider, Form, Input, Row, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useProtocolOrdersStore } from "../store";

import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import showMessage from "@utils/showMessage";
import { ViewProtocolOrderType } from "../types";
import { StatusOrders } from "./statusOrders";

type ViewProtocolOrderFormProps = {
  cuid: string;
};
const { TextArea } = Input;

export const ViewProtocolOrderForm = ({ cuid }: ViewProtocolOrderFormProps) => {
  const { getProtocolOrder } = useProtocolOrdersStore();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewProtocolOrderType>();

  useEffect(() => {
    (async () => {
      const result = await getProtocolOrder(cuid);
      if (result.status === "success") {
        const viewProtocolOrder = result.body as ViewProtocolOrderType;
        form.setFieldsValue({
          ...viewProtocolOrder,
          canceled_at: D3DateHourSorter(viewProtocolOrder.canceled_at),
          created_at: D3DateHourSorter(viewProtocolOrder.created_at),
          printed_at: D3DateHourSorter(viewProtocolOrder.printed_at),
          served_at: D3DateHourSorter(viewProtocolOrder.served_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getProtocolOrder]);

  function StatusOrder() {
    const statusObj = StatusOrders.find((item) => item.status === form.getFieldValue("status"));
    if (statusObj) {
      return (
        <Tag
          className="flex items-center gap-2 py-[1px] max-w-max"
          color={statusObj.color}
          icon={<Icon icon={statusObj.icon} />}
        >
          {statusObj.label}
        </Tag>
      );
    }

    return null;
  }

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off">
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item<ViewProtocolOrderType> name="id" label="Número">
                <Input
                  disabled={false}
                  readOnly
                  addonAfter={
                    <Icon
                      onClick={() =>
                        D3Copy({
                          text: form.getFieldValue("id"),
                          success: "Número copiado!",
                        })
                      }
                      className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                      icon="fluent:copy-32-filled"
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<ViewProtocolOrderType> name="customer_name" label="Cliente">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item<ViewProtocolOrderType> name="created_by" label="Criado por">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item<ViewProtocolOrderType> name="created_at" label="Data da criação">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item<ViewProtocolOrderType> name="requester_user" label="Solicitado por">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<ViewProtocolOrderType> name="requester_department" label="Departamento">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item<ViewProtocolOrderType> label="Status">{StatusOrder()}</Form.Item>
          <Form.Item<ViewProtocolOrderType> name="content" label="Conteúdo">
            <TextArea autoSize={{ minRows: 5 }} disabled />
          </Form.Item>

          {form.getFieldValue("printed") && (
            <>
              <Divider plain>Dados da impressão</Divider>
              <Row gutter={20}>
                <Col span={12}>
                  {" "}
                  <Form.Item<ViewProtocolOrderType> name="printed_by" label="Impresso por">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item<ViewProtocolOrderType> name="printed_at" label="Data da impressão">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {form.getFieldValue("served") && (
            <>
              <Divider plain>Dados do atendimento</Divider>
              <Row gutter={20}>
                <Col span={12}>
                  {" "}
                  <Form.Item<ViewProtocolOrderType> name="served_by" label="Atendido por">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item<ViewProtocolOrderType> name="served_at" label="Data do atendimento">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {form.getFieldValue("canceled") && (
            <>
              <Divider plain>Dados do cancelamento</Divider>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item<ViewProtocolOrderType> name="canceled_by" label="Cancelado por">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item<ViewProtocolOrderType> name="canceled_at" label="Data do cancelamento">
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item<ViewProtocolOrderType> name="cancel_reason" label="Motivo do cancelamento">
                <TextArea autoSize={{ minRows: 3 }} disabled />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </>
  );
};
