import { Button, Form, Input, Segmented, Select, Space } from "antd";
import { useRepositoryTypesStore } from "../store";
import { SearchRepositoryTypeType } from "../types";
import { useEffect } from "react";

type SearchRepositoryTypeFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchRepositoryTypeForm = ({
  onCallBackFilter,
  onCallBackResetFilter,
}: SearchRepositoryTypeFormProps) => {
  const [form] = Form.useForm<SearchRepositoryTypeType>();
  const { filter, setFilter } = useRepositoryTypesStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchRepositoryTypeType> name="rt_description" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item<SearchRepositoryTypeType> name="rt_mode" label="Tipo">
          <Select
            options={[
              { value: "REP", label: "Repositório" },
              { value: "HAF", label: "Pasta Suspensa" },
            ]}
          />
        </Form.Item>
        <Form.Item<SearchRepositoryTypeType> name="rt_active" label="Status">
          <Segmented
            value={filter.rt_active}
            options={[
              { value: "on", label: "Desbloqueados" },
              { value: "off", label: "Bloqueados" },
              { value: "all", label: "Todos" },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
