import { Button, Form, Input, Progress, Row, Segmented, Space, Spin } from "antd";
import { useShelvesStore } from "../store";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateShelfForm } from "./UpdateShelfForm";
import { red, green } from "@ant-design/colors";
import { D3Date } from "@utils/date";
import { ViewShelfType } from "../types";
import D3Can from "@components/shared/D3Can";

type ViewShelfFormProps = {
  cuid: string;
};

export const ViewShelfForm = ({ cuid }: ViewShelfFormProps) => {
  const { loadView, view, loadingView } = useShelvesStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ViewShelfType>();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await loadView(cuid);
    })();
  }, [cuid, form, loadView]);

  useEffect(() => {
    form.setFieldsValue({
      ...view,
      created_at: D3Date.FormatValueApiToPtBR(view.created_at),
      updated_at: D3Date.FormatValueApiToPtBR(view.updated_at),
    });
  }, [form, view]);

  const totalCells = view.number_of_rows * view.number_of_columns;
  const percent = Number(((view.number_of_repositories / view.number_of_positions) * 100).toPrecision(2));

  return (
    <>
      <Spin spinning={loadingView}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
          <Form.Item<ViewShelfType> name="abbreviation" label="Estante">
            <Input />
          </Form.Item>
          <Row className="flex items-center justify-between">
            <Form.Item<ViewShelfType> className="w-[170px]" name="number_of_columns" label="Nº de Vãos">
              <Input />
            </Form.Item>
            <Form.Item<ViewShelfType> className="w-[170px]" name="number_of_rows" label="Nº de Prateleiras">
              <Input />
            </Form.Item>
            <Form.Item<ViewShelfType> className="w-[170px]" label="Vãos x Prateleiras">
              <Input value={totalCells} />
            </Form.Item>
          </Row>
          <Row className="flex items-center justify-between">
            <Form.Item<ViewShelfType> className="w-[170px]" name="number_of_positions" label="Nº de Posições">
              <Input />
            </Form.Item>
            <Form.Item<ViewShelfType> className="w-[170px]" name="number_of_repositories" label="Nº de Repositórios">
              <Input />
            </Form.Item>
            <Form.Item label="Ocupação" className="w-[170px]">
              <Progress
                steps={10}
                size={[8, 20]}
                percent={percent}
                strokeColor={[green[6], green[5], green[4], green[3], green[2], red[2], red[3], red[4], red[4], red[6]]}
                status="normal"
              />
            </Form.Item>
          </Row>
          <Form.Item<ViewShelfType> name="active" label="Status">
            <Segmented
              disabled
              options={[
                { label: "Desbloqueado", value: true },
                { label: "Bloqueado", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewShelfType> name="created_at" label="Criado em">
            <Input />
          </Form.Item>
          <Form.Item<ViewShelfType> name="updated_at" label="Atualizado em">
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={false} type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="company_storage">
                  <Button
                    disabled={false}
                    type="primary"
                    onClick={() => openDrawer(<UpdateShelfForm cuid={cuid} />, "right", 600, "Editar Estante")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
