import { Icon } from "@iconify/react";
import { Button, ButtonProps, Form } from "antd";
import { MaskedInput } from "antd-mask-input";
import { NamePath } from "antd/es/form/interface";

type FormItemCEPProps = {
  required?: boolean;
  name: NamePath;
  buttonProps?: ButtonProps;
};

const CEP = (props: any) => {
  return <MaskedInput {...props} mask="00000-000" className="rounded-r-none max-w-72" value={props.value || ""} />;
};

function FormItemCEP({ required = false, name, buttonProps }: FormItemCEPProps) {
  return (
    <div className="flex">
      <Form.Item
        className="px-0 mx-0 w-72"
        label="CEP"
        name={name}
        rules={[
          { required: required, message: "Por favor, insira o CEP." },
          { min: 8, message: "O CEP deve conter no mínimo 8 caracteres." },
        ]}
        getValueProps={(value) => ({ value: value || "" })}
        normalize={(value) => value.replace(/[^\d]/g, "")}
      >
        <CEP />
      </Form.Item>
      <Button
        icon={<Icon height={16} icon="tabler:search" />}
        type="primary"
        className="rounded-l-none mt-[31px] min-w-12"
        {...buttonProps}
      />
    </div>
  );
}

export default FormItemCEP;
