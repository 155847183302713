export type ColumnType = {
  key: string;
  description: string;
  radio?: boolean;
  name?: string;
};

export const Data: ColumnType[] = [
  {
    key: "1",
    description: "Processar OCR ?",
    radio: false,
    name: "is_ocr_processed",
  },
  {
    key: "2",
    description: "Digitalizável ?",
    radio: true,
    name: "is_scannable",
  },
  {
    key: "3",
    description: "Guarda permanente ?",
    radio: false,
    name: "temporality_permanent_guard",
  },
  {
    key: "4",
    description: "Temporalidade (anos)",
    name: "temporality_year",
  },
  {
    key: "5",
    description: "Obrigatório no documento ?",
    radio: false,
    name: "is_temporality_mandatory",
  },
  {
    key: "6",
    description: "Frequência de atualização",
    name: "update_frequency_period",
  },
  {
    key: "7",
    description: "Frequência de atualização tipo",
    name: "update_frequency_type",
  },
  {
    key: "8",
    description: "A descrição é obrigatória na ordem de serviço para localização do documento ?",
    radio: false,
    name: "service_order_requires_description_for_document_location",
  },
];
