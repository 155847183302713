import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { diff } from "deep-diff";
import { create } from "zustand";
import { EditApiResultType, ListType, PriceSettingType, SavePriceSettingsType } from "./types";
import { ctNotification } from "@components/shared/CTNotification";

type UseCustomerGroupOperationStoreType = {
  apiResult: ListType;
  oldApiResult: ListType;
  loading: boolean;
  loadingSave: boolean;
  alteredOperations: string[];
  loadItemsList: () => Promise<Success<ListType> | BadRequest | StandardError>;
  savePriceSettings: () => Promise<Success<void> | BadRequest | StandardError | void>;
  editApiResult: (objs: EditApiResultType[]) => void;
  payloadGenerator: (alteredOperations: string[]) => SavePriceSettingsType | void;
  resetState: () => void;
};

const defaultState = {
  loading: false,
  loadingSave: false,
  apiResult: {
    data: [],
  },
  oldApiResult: {
    data: [],
  },
  alteredOperations: [],
};

export const useCustomerGroupOperationStore = create<UseCustomerGroupOperationStoreType>((set, get) => ({
  ...defaultState,

  resetState: () => set(defaultState),

  loadItemsList: async (): Promise<Success<ListType> | BadRequest | StandardError> => {
    set({ loading: true });
    return await new ClientHttp().get<Success<ListType>, BadRequest | StandardError>(
      "/api/v1/customer-group/services/operations",
      (result: Success<ListType>) => {
        const { status, body } = result;
        if (status !== "success") {
          set({
            apiResult: body,
            loading: false,
          });
          get().loadItemsList();
        } else {
          set({
            apiResult: body,
            oldApiResult: body,
            loading: false,
          });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  editApiResult: (objs: EditApiResultType[]) => {
    set({
      apiResult: {
        data: get().apiResult.data.map((item) => {
          const objsToUpdate = objs.filter((obj) => obj.operation_cuid === item.operation_cuid);
          if (objsToUpdate.length > 0) {
            let updatedItem = { ...item };
            objsToUpdate.forEach((objToUpdate) => {
              if (objToUpdate.field in updatedItem) {
                updatedItem = { ...updatedItem, [objToUpdate.field]: objToUpdate.value };
              }
            });
            return updatedItem;
          }
          return item;
        }),
      },
    });

    const oldData = get().oldApiResult.data;
    const newData = get().apiResult.data;
    const listCuids = get().alteredOperations;
    newData.forEach((newItem, index) => {
      const differences = diff(oldData[index], newItem);
      if (differences && !listCuids.includes(newItem.operation_cuid)) {
        set({
          alteredOperations: [...listCuids, newItem.operation_cuid],
        });
      } else if (!differences && listCuids.includes(newItem.operation_cuid)) {
        set({
          alteredOperations: listCuids.filter((item) => item !== newItem.operation_cuid),
        });
      }
    });
  },

  payloadGenerator: (alteredOperations: string[]): SavePriceSettingsType | void => {
    const data = get().apiResult.data;
    let hasValidationError = false;

    const payloads = alteredOperations.map((operationCuid) => {
      const rowData = data.find((item) => item.operation_cuid === operationCuid);

      if (!rowData) {
        hasValidationError = true;
        return null;
      }

      const { apply_default_price, price } = rowData;
      if (apply_default_price === false && (price === null || price === undefined)) {
        hasValidationError = true;
        return null;
      }

      return {
        operation_cuid: rowData.operation_cuid,
        apply_default_price: rowData.apply_default_price,
        price: rowData.price,
      };
    });

    if (hasValidationError) {
      set({
        loadingSave: false,
      });
      ctNotification({
        title: "Atenção !",
        content: "Por favor, preencha os campos destacados em vermelho.",
        type: "error",
        duration: 5,
      });
    }

    const result = payloads.filter((payload): payload is PriceSettingType => payload !== null);

    return {
      operations: result,
    };
  },

  savePriceSettings: async (): Promise<Success<void> | BadRequest | StandardError | void> => {
    set({ loadingSave: true });
    const alteredOperations = get().alteredOperations;
    const payload = get().payloadGenerator(alteredOperations);
    if (payload && payload.operations.length > 0) {
      await new ClientHttp().post<SavePriceSettingsType, Success<void>, BadRequest | StandardError>(
        `/api/v1/customer-group/services/operations/price-setup`,
        payload,
        (result: Success<void>) => {
          showMessage(result, `${alteredOperations.length > 1 ? "Alterações salvas" : "Alteração salva"} com sucesso.`);
          set({
            alteredOperations: [],
            loadingSave: false,
          });
        },
        (error: StandardError | BadRequest) => {
          showMessage(error);
          set({ loadingSave: false });
        }
      );
    }
  },
}));
