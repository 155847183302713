import { useContext, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { useContactStore } from "./store";
import { ItemListContactType, MutationContactType } from "./type";
import { Icon } from "@iconify/react";
import SpinLoading from "@components/shared/SpinLoading";
import FormItemPhoneNumber from "@components/shared/FormItemPhoneNumber";
import StatusSelect from "@components/shared/StatusSelect";
import { ctNotification } from "@components/shared/CTNotification";

type UpdateContactFormProps = {
  contact: ItemListContactType;
};

export const UpdateContactForm = ({ contact }: UpdateContactFormProps) => {
  const {
    updateContact,
    loadingUpdated,
    simpleListContactTypes,
    listContactTypes,
    loadingContactTypes,
    simpleListUsersCustomer,
    listUsersCustomer,
    loadingUsersCustomer,
  } = useContactStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<MutationContactType>();

  useEffect(() => {
    (async () => {
      await Promise.all([simpleListContactTypes(), simpleListUsersCustomer()]);
      form.setFieldsValue({
        contact_type_id: contact.contact_type.id,
        description: contact.description,
        email: contact.email,
        extension: contact.extension,
        land_line: contact.landline,
        name: contact.name,
        phone: contact.phone,
        user_cuid: contact.user ? contact.user.cuid : "",
      });
    })();
  }, [simpleListUsersCustomer]);

  return loadingUsersCustomer || loadingContactTypes ? (
    <SpinLoading />
  ) : (
    <Form
      className="select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: MutationContactType) => {
        if (!(payload.land_line || payload.phone || payload.email)) {
          ctNotification({
            title: "Atenção !",
            content: "Por favor, preencha pelo menos um dos seguintes campos: e-mail, telefone fixo ou celular.",
            type: "error",
            duration: 6,
          });
        } else {
          const isCreated = await updateContact(contact.cuid, payload);
          if (isCreated) {
            onClose();
          }
        }
      }}
      autoComplete="off"
    >
      <Form.Item<MutationContactType>
        name="description"
        label="Descrição"
        rules={[{ required: true, message: "Por favor, insira a descrição." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<MutationContactType>
        name="name"
        label="Nome"
        rules={[{ required: true, message: "Por favor, insira o nome." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<MutationContactType>
        label="Tipo de contato"
        name="contact_type_id"
        rules={[{ required: true, message: "Por favor, selecione o tipo de contato." }]}
      >
        <Select
          listItemHeight={10}
          listHeight={250}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {listContactTypes.map(({ description, id, profile }) => (
            <Select.Option key={id} value={id} dataFilter={description} dataLabel={description}>
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{description}</span>
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item<MutationContactType> label="Usuário" name="user_cuid">
        <Select
          listItemHeight={10}
          listHeight={250}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {listUsersCustomer.map(({ active, cuid, login, name }) => (
            <Select.Option key={cuid} value={cuid} dataFilter={`${name} ${login}`} dataLabel={`${name}`}>
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{name}</span>
                  <span className="text-[10px] truncate">{login}</span>
                </div>
                <StatusSelect status={active} />
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<MutationContactType> name="email" label="Email">
        <Input />
      </Form.Item>

      <Row gutter={20}>
        <Col>
          <FormItemPhoneNumber type="landline" label="Telefone fixo" name="land_line" />
        </Col>
        <Col>
          <Form.Item<MutationContactType> dependencies={["land_line"]} name="extension" label="Ramal">
            <Input className="w-32" />
          </Form.Item>
        </Col>
      </Row>

      <FormItemPhoneNumber type="phone" label="Celular" name="phone" />

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingUpdated} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="update" resource="customer_contact">
              <Button loading={loadingUpdated} type="primary" htmlType="submit">
                Atualizar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
