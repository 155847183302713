import { Form, Input } from "antd";

type ItemTXTProps = {
  name: number;
  required: boolean;
  label: string;
};

function ItemTXT({ name, required, label, ...props }: ItemTXTProps) {
  return (
    <Form.Item
      {...props}
      name={[name, "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: "Obrigatório",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
}

export default ItemTXT;
