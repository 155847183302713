import { Col, Divider, Form, Input, InputNumber, Row, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSpecialOrdersStore } from "../store";

import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import showMessage from "@utils/showMessage";
import { ShowTag, StatusOrders, TypeOfRequest } from "@views/orders/Utils";
import { ViewSpecialOrderType } from "../types";

type ViewSpecialOrderFormProps = {
  cuid: string;
};
const { TextArea } = Input;

export const ViewSpecialOrderForm = ({ cuid }: ViewSpecialOrderFormProps) => {
  const { getSpecialOrder } = useSpecialOrdersStore();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewSpecialOrderType>();

  useEffect(() => {
    (async () => {
      const result = await getSpecialOrder(cuid);
      if (result.status === "success") {
        const viewSpecialOrder = result.body as ViewSpecialOrderType;
        const datesTransformed = {
          canceled_at: D3DateHourSorter(viewSpecialOrder.canceled_at),
          created_at: D3DateHourSorter(viewSpecialOrder.created_at),
          printed_at: D3DateHourSorter(viewSpecialOrder.printed_at),
          served_at: D3DateHourSorter(viewSpecialOrder.served_at),
        };

        form.setFieldsValue({
          ...viewSpecialOrder,
          ...datesTransformed,
        });

        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getSpecialOrder]);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none form-view" layout="vertical" form={form} autoComplete="off">
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> label="Número" name="id">
                          <Input
                            disabled={false}
                            readOnly
                            addonAfter={
                              <Icon
                                onClick={() =>
                                  D3Copy({
                                    text: form.getFieldValue("id"),
                                    success: "Número copiado!",
                                  })
                                }
                                className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                                icon="fluent:copy-32-filled"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> name="customer_name" label="Cliente">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> name="created_by" label="Criado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> name="created_at" label="Data da criação">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> name="requester_user" label="Requisitado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> name="requester_department" label="Departamento">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> name="special_service_description" label="Serviço">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewSpecialOrderType> label="Status">
                          {ShowTag(StatusOrders, form.getFieldValue("status"))}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item<ViewSpecialOrderType> name="quantity" label="Quantidade">
                      <InputNumber disabled />
                    </Form.Item>
                    <Form.Item<ViewSpecialOrderType> name="observation" label="Observação">
                      <TextArea autoSize={{ minRows: 2 }} disabled />
                    </Form.Item>

                    {form.getFieldValue("printed") && (
                      <>
                        <Divider plain>Dados da impressão</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewSpecialOrderType> name="printed_by" label="Impresso por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewSpecialOrderType> name="printed_at" label="Data da impressão">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("served") && (
                      <>
                        <Divider plain>Dados do atendimento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewSpecialOrderType> name="served_by" label="Atendido por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewSpecialOrderType> name="served_at" label="Data do atendimento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("canceled") && (
                      <>
                        <Divider plain>Dados do cancelamento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewSpecialOrderType> name="canceled_by" label="Cancelado por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewSpecialOrderType> name="canceled_at" label="Data do cancelamento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item<ViewSpecialOrderType> name="cancel_reason" label="Motivo do cancelamento">
                          <TextArea autoSize={{ minRows: 3 }} disabled />
                        </Form.Item>
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </Form>
      </Spin>
    </>
  );
};
