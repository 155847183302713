import { Icon } from "@iconify/react";
import { ListOfKeyAndValueType } from "../../../types";

import { Form, Select } from "antd";

type ItemSELProps = {
  name: number;
  values: ListOfKeyAndValueType[];
  label: string;
  required: boolean;
};

function ItemSEL({ name, values, label, required, ...props }: ItemSELProps) {
  return (
    <Form.Item
      {...props}
      name={[name, "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: "Obrigatório",
        },
      ]}
    >
      <Select
        allowClear
        showSearch
        optionFilterProp="children"
        removeIcon={
          <Icon
            icon="iconamoon:close-duotone"
            className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
          />
        }
        menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
        className="w-full truncate select-none"
        options={values.map((option) => {
          return {
            value: option.key,
            label: option.value,
          };
        })}
      />
    </Form.Item>
  );
}

export default ItemSEL;
