import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import { DefaultPricesType, ResultRequestType, UpdateDefaultPricesType } from "./types";

type useDefaultPricesStoreType = {
  getDefaultPrices: () => Promise<void>;
  updateDefaultPrices: (payload: UpdateDefaultPricesType) => Promise<boolean>;
  priceDefault: DefaultPricesType;
  loading: boolean;
};

export const useDefaultPricesStore = create<useDefaultPricesStoreType>((set, get) => ({
  priceDefault: {} as DefaultPricesType,
  loading: false,
  getDefaultPrices: async (): Promise<void> => {
    set({ loading: true });
    await new ClientHttp().get<Success<DefaultPricesType>, StandardError>(
      `/api/v1/customer/table-prices`,
      (result: Success<DefaultPricesType>) => {
        set({
          priceDefault: result.body,
          loading: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },
  updateDefaultPrices: async (payload: UpdateDefaultPricesType): Promise<boolean> => {
    const result = await new ClientHttp().put<
      UpdateDefaultPricesType,
      Success<ResultRequestType>,
      BadRequest | StandardError
    >(
      "/api/v1/customer/table-prices",
      payload,
      (result: Success<ResultRequestType>) => {
        showMessage(result, "Preços atualizado com sucesso. ");
        get().getDefaultPrices();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },
}));
