enum SizeUnits {
  Bytes = "Bytes",
  KB = "KB",
  MB = "MB",
  GB = "GB",
  TB = "TB",
  PB = "PB",
  EB = "EB",
  ZB = "ZB",
  YB = "YB",
}

export default function formatBytes(bytes: number): string {
  if (bytes === 0) return `0 Bytes`;

  const k = 1000;
  const sizes: SizeUnits[] = [
    SizeUnits.Bytes,
    SizeUnits.KB,
    SizeUnits.MB,
    SizeUnits.GB,
    SizeUnits.TB,
    SizeUnits.PB,
    SizeUnits.EB,
    SizeUnits.ZB,
    SizeUnits.YB,
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const preciseBytes = bytes / Math.pow(k, i);
  const value = parseFloat(preciseBytes.toFixed(2));

  return `${value} ${sizes[i]}`;
}
