import { create } from "zustand";
import {
  ListAdminsType,
  MutationAdminType,
  SelectCompanyUserItemType,
  SimpleListCompanyUserType,
  ViewCustomerGroupType,
} from "./types";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";

type CustomerGroupStoreState = {
  apiResultAdmins: ListAdminsType;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  getActiveTab: () => string;
  getCustomerGroup: () => Promise<Success<ViewCustomerGroupType> | StandardError>;
  loadAdmins: () => Promise<void>;
  getCompanyUsersForSelect: () => Promise<SelectCompanyUserItemType[]>;
  createAdmin: (payload: MutationAdminType) => Promise<Success<null> | BadRequest | StandardError>;
  deleteAdmin: (cuid: string) => Promise<Success<void> | StandardError>;
  resetState: () => void;
};

const defaultState = {
  apiResultAdmins: {
    total: 0,
    data: [],
  },
  apiResultNoPayments: {
    data: [],
  },
  activeTab: "1",
};

export const useCustomerGroupStore = create<CustomerGroupStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setActiveTab: (tab: string) => {
    set({ activeTab: tab });
  },
  getActiveTab: () => {
    return get().activeTab;
  },
  getCustomerGroup: async (): Promise<Success<ViewCustomerGroupType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCustomerGroupType>, StandardError>(`/api/v1/customer-group`);
  },

  loadAdmins: async (): Promise<void> => {
    await new ClientHttp().get<Success<ListAdminsType>, BadRequest | StandardError>(
      `/api/v1/customer-group/admins`,
      (result: Success<ListAdminsType>) => {
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          set({
            apiResultAdmins: body,
          });
          get().loadAdmins();
        } else {
          set({ apiResultAdmins: body });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  getCompanyUsersForSelect: async (): Promise<SelectCompanyUserItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCompanyUserType>,
      StandardError,
      SelectCompanyUserItemType[]
    >(
      "/api/v1/customer-group/users/simple-list",
      (result: Success<SimpleListCompanyUserType>): SelectCompanyUserItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.name,
            active: item.active,
            username: item.username,
          } as SelectCompanyUserItemType;
        });
      },
      (error: StandardError): SelectCompanyUserItemType[] => {
        showMessage(error);
        return [] as SelectCompanyUserItemType[];
      }
    );
  },

  createAdmin: async (payload: MutationAdminType): Promise<Success<null> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<MutationAdminType, Success<null>, BadRequest | StandardError>(
      `/api/v1/customer-group/admins`,
      payload,
      (result: Success<null>) => {
        get().loadAdmins();
      }
    );
  },

  deleteAdmin: async (cuid: string): Promise<Success<void> | StandardError> => {
    return await new ClientHttp().delete<Success<void>, StandardError>(
      `/api/v1/customer-group/admins/${cuid}`,
      (result: Success<void>) => {
        showMessage(result, "Administrador removido com sucesso.");
        get().loadAdmins();
      },
      (result: StandardError) => {
        showMessage(result);
      }
    );
  },
}));
