import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Copy from "@utils/D3Copy";
import D3RepositorySituation from "@utils/D3RepositorySituation";
import { D3Date } from "@utils/date";
import showMessage from "@utils/showMessage";
import { Button, Form, Input, InputNumber, Segmented, Space, Spin, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { useRepositoriesStore } from "../store";
import { RepositorySealViewType, ViewRepositoryType } from "../types";
import { UpdateRepositoryForm } from "./UpdateRepositoryForm";

type ViewRepositoryFormProps = {
  cuid: string;
};

export const ViewRepositoryForm = ({ cuid }: ViewRepositoryFormProps) => {
  const { getRepository } = useRepositoriesStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewRepositoryType>();

  useEffect(() => {
    (async () => {
      const result = await getRepository(cuid);
      if (result.status === "success") {
        const viewRepository = result.body as ViewRepositoryType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewRepository.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewRepository.updated_at),
          status: D3RepositorySituation(viewRepository.status),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getRepository]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Form.Item<ViewRepositoryType> name="repository_physical_tag_id" label="Código da etiqueta">
                          <Input
                            disabled={false}
                            readOnly
                            addonAfter={
                              <Icon
                                onClick={() =>
                                  D3Copy({
                                    text: form.getFieldValue("repository_physical_tag_id"),
                                    success: "Código copiado!",
                                  })
                                }
                                className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                                icon="fluent:copy-32-filled"
                              />
                            }
                          />
                        </Form.Item>

                        <Form.Item<ViewRepositoryType> name="status" label="Situação">
                          <Input />
                        </Form.Item>

                        <Form.Item<ViewRepositoryType>
                          name={["repository_type", "description"]}
                          label="Tipo de repositório"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item<ViewRepositoryType> name="global_visibility" label="Visibilidade global">
                          <Segmented
                            options={[
                              { label: "Sim", value: true },
                              { label: "Não", value: false },
                            ]}
                            disabled
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item<ViewRepositoryType> name="origin" label="Origem">
                          <Input />
                        </Form.Item>
                        <Form.Item<ViewRepositoryType> label="ID da posição">
                          <Input
                            value={
                              form.getFieldValue("position") === null
                                ? "Não armazenada"
                                : form.getFieldValue(["position", "id"])
                            }
                          />
                        </Form.Item>
                        <Form.Item<ViewRepositoryType>
                          name={["billable_department", "description"]}
                          label="Departamento"
                        >
                          <Input />
                        </Form.Item>

                        <div className="grid grid-cols-2 gap-4">
                          <Form.Item<ViewRepositoryType> name="has_seals" label="Possui lacre">
                            <Segmented
                              disabled
                              options={[
                                { label: "Sim", value: true },
                                { label: "Não", value: false },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item<ViewRepositoryType>
                            hidden={!form.getFieldValue("has_seals")}
                            name="number_of_seals"
                            label="Qtd. de lacres"
                          >
                            <InputNumber />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <Form.Item<ViewRepositoryType> name={["created_by", "name"]} label="Criado por">
                        <Input />
                      </Form.Item>
                      <Form.Item<ViewRepositoryType> name="created_at" label="Criado em">
                        <Input />
                      </Form.Item>

                      <Form.Item<ViewRepositoryType> name={["updated_by", "name"]} label="Atualizado por">
                        <Input />
                      </Form.Item>
                      <Form.Item<ViewRepositoryType> name="updated_at" label="Atualizado em">
                        <Input />
                      </Form.Item>
                    </div>
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span
                    className={`flex items-center justify-center gap-2 ${
                      form.getFieldValue("has_seals") ? "" : "hidden"
                    }`}
                  >
                    <Icon icon="mdi:box-lock" className="w-[20px] h-[20px]" />
                    Lacres
                  </span>
                ),
                children: (
                  <>
                    {form.getFieldValue("repository_seals")?.map((item: RepositorySealViewType) => {
                      return (
                        <Form.Item key={item.number} label={`${item.number}º lacre `}>
                          <Input disabled value={item.value} />
                        </Form.Item>
                      );
                    })}
                  </>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={false} type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_repository">
                  <Button
                    disabled={false}
                    type="primary"
                    onClick={() => openDrawer(<UpdateRepositoryForm cuid={cuid} />, "right", 600, "Editar Repositório")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
