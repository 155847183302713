import { useContext, useMemo } from "react";
import { CellView } from "../types";
import { Icon } from "@iconify/react";

import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ViewCellForm } from "./ViewCellForm";
import { Tooltip } from "antd";

type CellProps = {
  row: number;
  column: number;
  info: CellView;
  clickFnc: (item: CellView) => void;
};

const Cell: React.FC<CellProps> = ({ row, column, info, clickFnc }) => {
  const { openDrawer } = useContext(D3DrawerContext);

  function getBorderStyle(info: any) {
    if (info.checked) {
      return "border-dashed border-blue-700";
    }
    return "border-solid";
  }

  function getBackgroundColor(info: any) {
    if (info.active) {
      if (info.repository_type) {
        return "bg-green-100";
      }
      if (info.repository_type?.cuid === undefined) {
        return "bg-blue-100";
      }
    } else {
      if (info.repository_type?.cuid === undefined) {
        return "bg-yellow-100";
      }
      if (info.repository_type?.cuid !== undefined) {
        return "bg-red-100";
      }
    }
    return "";
  }

  const className = `flex flex-col border-2 p-1  min-w-[90px] ${getBorderStyle(info)} ${getBackgroundColor(info)}`;

  const tableCell = useMemo(() => {
    return (
      <>
        <div className={className} onClick={() => clickFnc(info)}>
          <div className="flex justify-between">
            <div className="flex justify-start">
              <Tooltip title="Visualizar Vão / Prateleira" trigger={["click", "hover"]}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    openDrawer(<ViewCellForm cuid={info.cuid} />, "right", 700, "Vizualizar Vão / Prateleira");
                  }}
                >
                  <Icon
                    icon="mdi:box-search"
                    className={`cursor-pointer  text-blue-500 ${info.repository_type?.cuid === undefined && "hidden"}`}
                  />
                </button>
              </Tooltip>
            </div>
            <div className="flex justify-end">
              <Tooltip
                title={info.active ? "Vão/Prateleira Desbloqueado" : "Vão/Prateleira Bloqueado"}
                trigger={["click", "hover"]}
              >
                <span>
                  <Icon
                    onClick={(e) => e.stopPropagation()}
                    className={`${info.active ? "text-green-500" : "text-red-500"} cursor-pointer`}
                    icon={info.active ? "basil:unlock-solid" : "basil:lock-solid"}
                  />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="flex justify-center p-1 text-gray-700">{`V${column}.P${row}`}</div>
          <div className="flex justify-between">
            <div
              className="flex items-end cursor-pointer justify-start text-[11px] text-gray-700"
              onClick={(e) => e.stopPropagation()}
            >
              <Tooltip title={`Qtd. de repositórios: ${info.number_of_repositories}`} trigger={["click", "hover"]}>
                <span> R: {info.number_of_repositories}</span>
              </Tooltip>
            </div>
            <div
              className="flex items-end justify-end text-[11px] text-gray-700 cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              <Tooltip title={`Qtd. de posições: ${info.number_of_positions}`} trigger={["click", "hover"]}>
                <span> P: {info.number_of_positions}</span>
              </Tooltip>
            </div>
          </div>
        </div>
      </>
    );
  }, [info.checked, info.number_of_positions, info.repository_type?.cuid, info.active]);
  return tableCell;
};

export default Cell;
