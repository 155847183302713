import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Col, ColorPicker, Row, Switch, Table, Tag } from "antd";
import { useContext, useEffect } from "react";
import ListOperations from "../operations/ListOperations";
import "../style.css";
import { useCustomerMaterialStore } from "./store";
import { ColumnsType, ListItemType } from "./types";
import D3Can from "@components/shared/D3Can";

function ListServices() {
	const { serviceUpdate, alteredMaterials, loadItemsList, apiResult, loading, loadingSave, editApiResult, resetState } = useCustomerMaterialStore();
	const { openDrawer } = useContext(D3DrawerContext);

	useEffect(() => {
		(async () => {
			await loadItemsList();
		})();

		return () => {
			resetState();
		};
	}, [loadItemsList]);

	const columns: ColumnsType[] = [
		{
			title: "Ativo?",
			dataIndex: "active",
			key: "active",
			align: "center" as const,
			width: 100,
			render: (_: any, record: ListItemType) => (
				<Switch
					disabled={!record.is_allowed_active_or_inactive}
					checkedChildren={
						record.is_allowed_active_or_inactive ? "Sim" : <Icon icon="material-symbols:lock" height={14} className="mt-1 ml-2 text-white" />
					}
					unCheckedChildren={
						record.is_allowed_active_or_inactive ? "Não" : <Icon icon="material-symbols:lock" height={14} className="mt-2 mr-2 text-gray-500" />
					}
					defaultChecked={record.active}
					onChange={(isActive) => {
						editApiResult([
							{
								service_cuid: record.service_cuid,
								field: "active",
								value: isActive,
							},
						]);
					}}
				/>
			),
		},
		{
			title: "Descrição",
			dataIndex: "description",
			key: "description",
		},
		{
			title: "Solicitável por ordem de serviço?",
			dataIndex: "is_requestable_in_service_order",
			key: "is_requestable_in_service_order",
			render: (_: any, record: ListItemType) => {
				const status = record.is_requestable_in_service_order ? 0 : 1;
				return (
					<Tag
						className="flex items-center justify-start gap-1 max-w-max"
						icon={<Icon height={8} icon={["fa:check", "fa:close"][status]} />}
						color={["success", "error"][status]}
					>
						{["Sim", "Não"][status]}
					</Tag>
				);
			},
		},
	];

	return (
		<div className="flex flex-col w-full h-full pb-1 overflow-hidden select-none new-table">
			<Table
				rowClassName={(record) => (alteredMaterials.includes(record.service_cuid) ? "change-row" : "")}
				size="small"
				dataSource={apiResult.data}
				columns={columns as ColumnsType[]}
				pagination={false}
				bordered
				loading={loading}
				sticky
				rowKey={(record) => record.service_cuid}
				title={() => (
					<>
						<Row className="w-full">
							<Col span={8} className="flex items-center justify-start">
								<span className="text-sm font-semibold text-gray-600">Configurações de serviços que podem ser solicitados pelo cliente.</span>
							</Col>
							<Col span={8} className="text-center">
								<span className="text-xl font-semibold text-gray-600">Configurações de Serviços</span>
							</Col>
							<Col span={8} className="flex items-center justify-end">
								<Button
									type="primary"
									size="middle"
									icon={<Icon icon="carbon:settings-services" height={20} width={20} />}
									onClick={() => openDrawer(<ListOperations />, "right", 1100, "Configurações de Operações")}
								>
									Configurar Operações
								</Button>
							</Col>
						</Row>
					</>
				)}
				footer={() => (
					<>
						<Row className="w-full">
							<Col span={8} className="flex items-center justify-start gap-4">
								<div className="flex items-center justify-center gap-2">
									<ColorPicker value="#dbeafe" size="small" disabled /> Serviços alterado
								</div>
							</Col>
							<Col span={8} className="text-center"></Col>
							<Col span={8} className="flex items-center justify-end">
								<D3Can action="update" resource="customer_group">
									<Button
										loading={loadingSave}
										disabled={alteredMaterials.length === 0}
										type="primary"
										size="middle"
										icon={<Icon icon="ant-design:save-filled" height={20} width={20} />}
										onClick={() => serviceUpdate()}
									>
										Salvar {alteredMaterials.length > 1 ? "alterações" : "alteração"}
									</Button>
								</D3Can>
							</Col>
						</Row>
					</>
				)}
			/>
		</div>
	);
}

export default ListServices;
