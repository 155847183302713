import { Col, Divider, Form, Input, Row, Spin, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useMaterialOrdersStore } from "../store";

import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import showMessage from "@utils/showMessage";

import { AddressType, ViewMaterialOrderType, ViewMaterialType } from "../types";
import { ShowTag, StatusOrders, TypeOfRequest } from "../utils";

type ViewMaterialOrderFormProps = {
  cuid: string;
};
const { TextArea } = Input;

export const ViewMaterialOrderForm = ({ cuid }: ViewMaterialOrderFormProps) => {
  const { getMaterialOrder } = useMaterialOrdersStore();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewMaterialOrderType>();

  useEffect(() => {
    (async () => {
      const result = await getMaterialOrder(cuid);
      if (result.status === "success") {
        const viewMaterialOrder = result.body as ViewMaterialOrderType;
        const datesTransformed = {
          canceled_at: D3DateHourSorter(viewMaterialOrder.canceled_at),
          created_at: D3DateHourSorter(viewMaterialOrder.created_at),
          printed_at: D3DateHourSorter(viewMaterialOrder.printed_at),
          completed_at: D3DateHourSorter(viewMaterialOrder.completed_at),
          ready_at: D3DateHourSorter(viewMaterialOrder.ready_at),
        };
        const requestedMaterialsTransformed = viewMaterialOrder.requested_materials.map((item, index) => ({
          ...item,
          key: `${index}-${item.material_description}`,
        }));
        form.setFieldsValue({
          ...viewMaterialOrder,
          ...datesTransformed,
          requested_materials: requestedMaterialsTransformed,
        });

        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getMaterialOrder]);

  const customerAddress: AddressType = form.getFieldValue("customer_address") || null;
  const companyAddress: AddressType = form.getFieldValue("pickup_address") || null;

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none form-view" layout="vertical" form={form} autoComplete="off">
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item label="Número" name="id">
                          <Input
                            disabled={false}
                            readOnly
                            addonAfter={
                              <Icon
                                onClick={() =>
                                  D3Copy({
                                    text: form.getFieldValue("id"),
                                    success: "Número copiado!",
                                  })
                                }
                                className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                                icon="fluent:copy-32-filled"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewMaterialOrderType> name="customer_name" label="Cliente">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewMaterialOrderType> name="created_by" label="Criado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewMaterialOrderType> name="created_at" label="Data da criação">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewMaterialOrderType> name="requester_user" label="Solicitado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="requester_department" label="Departamento">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item label="Status">{ShowTag(StatusOrders, form.getFieldValue("status"))}</Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Tipo de requisição">
                          {ShowTag(TypeOfRequest, form.getFieldValue("request_type"))}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item<ViewMaterialOrderType>
                      hidden={companyAddress === null}
                      label="Endereço de retirada de materiais"
                    >
                      <TextArea
                        value={
                          companyAddress
                            ? `${companyAddress.street_address}, ${companyAddress.street_number}${
                                companyAddress.complement ? ` - ${companyAddress.complement}` : ""
                              }, ${companyAddress.neighborhood}, ${companyAddress.city},  ${
                                companyAddress.state
                              } - CEP ${companyAddress.zip_code}`
                            : "requisição sem transporte"
                        }
                        autoSize
                        disabled
                      />
                    </Form.Item>

                    <Form.Item<ViewMaterialOrderType> label="Endereço de entrega" hidden={customerAddress === null}>
                      <TextArea
                        value={
                          customerAddress
                            ? `${customerAddress.street_address}, ${customerAddress.street_number}${
                                customerAddress.complement ? ` - ${customerAddress.complement}` : ""
                              }, ${customerAddress.neighborhood}, ${customerAddress.city},  ${
                                customerAddress.state
                              } - CEP ${customerAddress.zip_code}`
                            : "requisição sem transporte"
                        }
                        autoSize
                        disabled
                      />
                    </Form.Item>

                    <Form.Item<ViewMaterialOrderType> name="observation" label="Observação">
                      <TextArea autoSize={{ minRows: 2 }} disabled />
                    </Form.Item>

                    {form.getFieldValue("printed") && (
                      <>
                        <Divider plain>Dados da impressão</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="printed_by" label="Impresso por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="printed_at" label="Data da impressão">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("ready") && (
                      <>
                        <Divider plain>Dados de preparação</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="ready_by" label="Preparado por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="ready_at" label="Data do atendimento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("completed") && (
                      <>
                        <Divider plain>Dados do atendimento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="completed_by" label="Atendido por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="completed_at" label="Data do atendimento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("canceled") && (
                      <>
                        <Divider plain>Dados do cancelamento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="canceled_by" label="Cancelado por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewMaterialOrderType> name="canceled_at" label="Data do cancelamento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item<ViewMaterialOrderType> name="cancel_reason" label="Motivo do cancelamento">
                          <TextArea autoSize={{ minRows: 3 }} disabled />
                        </Form.Item>
                      </>
                    )}
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Materiais
                  </span>
                ),
                children: (
                  <Table
                    className="mt-2"
                    key="material_description"
                    size="small"
                    bordered
                    pagination={false}
                    dataSource={form.getFieldValue("requested_materials")}
                  >
                    <Table.Column<ViewMaterialType>
                      showSorterTooltip={false}
                      defaultSortOrder="ascend"
                      key="material_description"
                      title="Material"
                      dataIndex="material_description"
                    />

                    <Table.Column<ViewMaterialType>
                      showSorterTooltip={false}
                      defaultSortOrder="ascend"
                      key="quantity"
                      title="Quantidade"
                      dataIndex="quantity"
                    />
                  </Table>
                ),
              },
            ]}
          />
        </Form>
      </Spin>
    </>
  );
};
