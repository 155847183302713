import { Button, Form, Input, InputNumber, Segmented, Space, Spin } from "antd";
import { useOperationsStore } from "../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateOperationForm } from "./UpdateOperationForm";
import { CurrencyInput } from "react-currency-mask";
import { D3Date } from "@utils/date";
import { ViewOperationType } from "../types";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";

type ViewOperationFormProps = {
  cuid: string;
};

export const ViewOperationForm = ({ cuid }: ViewOperationFormProps) => {
  const { getOperation } = useOperationsStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm<ViewOperationType>();

  useEffect(() => {
    (async () => {
      const result = await getOperation(cuid);
      if (result.status === "success") {
        const viewMaterial = result.body as ViewOperationType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewMaterial.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewMaterial.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getOperation]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off">
          <Form.Item<ViewOperationType> name="description" label="Descrição">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewOperationType> name="price" label="Preço">
            <CurrencyInput
              value={form.getFieldValue("price")}
              locale="pt-BR"
              currency="BRL"
              onChangeValue={() => {}}
              InputElement={<InputNumber className="min-w-max" disabled />}
            />
          </Form.Item>
          <Form.Item<ViewOperationType> name="active" label="Status">
            <Segmented
              options={[
                { label: "Desbloqueado", value: true },
                { label: "Bloqueado", value: false },
              ]}
              disabled
            />
          </Form.Item>
          <Form.Item<ViewOperationType> name="created_at" label="Criado em">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewOperationType> name="updated_at" label="Atualizado em">
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="system_operation">
                  <Button
                    type="primary"
                    onClick={() => openDrawer(<UpdateOperationForm cuid={cuid} />, "right", 600, "Editar Operação")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
