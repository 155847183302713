import { Icon } from "@iconify/react";
import { Button, Form, Input, Segmented, Select } from "antd";
import { PrePayloadType } from "../types";
import { useDocumentBatchStore } from "../store";
import StatusSelect from "@components/shared/StatusSelect";
import { useEffect } from "react";

function FormValidation() {
  const {
    isValid,
    documentTypes,
    loadingValidate,
    loadingDocumentTypes,
    validateRepository,
    simpleListDocumentTypeCustomer,
  } = useDocumentBatchStore();

  useEffect(() => {
    (async () => {
      await simpleListDocumentTypeCustomer();
    })();
  }, []);

  return (
    <Form
      key="1"
      hidden={isValid}
      layout="vertical"
      className="w-[400px] select-none"
      onFinish={async (payload: PrePayloadType) => {
        const { document_type_cuid, ...rest }: PrePayloadType = payload;
        const isValid = await validateRepository(rest, payload.document_type_cuid);
        if (isValid) {
          useDocumentBatchStore.setState({
            prePayload: {
              ...payload,
            },
            isValid,
            documentTypeSelected: documentTypes.find((doc) => doc.cuid === document_type_cuid),
          });
        }
      }}
    >
      <Form.Item<PrePayloadType>
        initialValue={false}
        name="can_insert_in_stored_repository"
        label="Inserção em repositório armazenado?"
      >
        <Segmented
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          size="small"
          className="select-none"
        />
      </Form.Item>
      <Form.Item<PrePayloadType>
        name="repository_physical_tag_id"
        label="Etiqueta do repositório"
        rules={[
          {
            required: true,
            message: "Por favor, insira o código do repositório.",
          },
          {
            min: 12,
            max: 12,
            message: "O código do repositório deve incluir uma sequência de 12 dígitos numéricos.",
          },
          {
            validator: (_, value) => {
              if (value && value !== "") {
                return /^\d+$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(new Error("O código do repositório deve ser composta exclusivamente por números."));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input minLength={12} maxLength={12} showCount />
      </Form.Item>
      <Form.Item<PrePayloadType>
        name="document_type_cuid"
        label="Tipo de Documento"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o tipo de documento.",
          },
        ]}
      >
        <Select
          loading={loadingDocumentTypes}
          listItemHeight={10}
          allowClear
          disabled={loadingDocumentTypes || documentTypes.length === 0}
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {documentTypes.map(({ active, cuid, description, temporality_permanent_guard, temporality_year }) => (
            <Select.Option
              disabled={!active}
              key={cuid}
              value={cuid}
              dataFilter={`${description}`}
              dataLabel={`${description} - ${
                temporality_permanent_guard === true
                  ? "Tempo indeterminado"
                  : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
              }`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${description} - ${
                    temporality_permanent_guard === true
                      ? "Tempo indeterminado"
                      : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
                  }`}</span>
                </div>
                <StatusSelect status={active} />
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button loading={loadingValidate} block type="primary" htmlType="submit">
          Iniciar cadastramento em lote
        </Button>
      </Form.Item>
    </Form>
  );
}

export default FormValidation;
