import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { D3Money } from "@utils/money";
import { Badge, Button, Pagination, Popconfirm, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useInvoiceStore } from "./store";
import { ItemListInvoiceType } from "./types";
import { CreateInvoiceForm } from "./components/CreateInvoiceForm";
import { SearchInvoiceForm } from "./components/SearchInvoiceForm";
const InvoicesView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    selectedRows,
    setPagination,
    setSort,
    loadInvoices,
    resetFilter,
    resetState,
  } = useInvoiceStore();
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    (async () => {
      await loadInvoices();
    })();
    return () => resetState();
  }, [loadInvoices]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow">
      <Table<ItemListInvoiceType>
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar notas fiscais"
                    content={
                      <SearchInvoiceForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Notas Fiscais</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="billing">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateInvoiceForm />, "right", 600, "Cadastrar Nota Fiscal")}
                    >
                      Cadastrar Nota Fiscal
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
        onChange={(a, b, sorter: any) => {
          if (sorter) {
            setSort({
              sort_by: sorter.field,
              sort_direction: sorter.order,
            });
          }
        }}
        footer={() => {
          return (
            <>
              <div className="flex gap-4">
                <D3Can action="lock" resource="billing">
                  <Badge count={selectedRows.cuids.length > 0 ? selectedRows.cuids.length : undefined}>
                    <Popconfirm
                      disabled={!(selectedRows.cuids.length > 0)}
                      title={`Atenção!`}
                      description={`Confirma exclusão de  ${selectedRows.cuids.length} ${
                        selectedRows.cuids.length > 1 ? "eventos" : "evento"
                      }?`}
                      onConfirm={() => {}}
                      okText="Sim"
                      cancelText="Não"
                      placement="top"
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={!(selectedRows.cuids.length > 0)}
                        size="middle"
                        icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
                      >
                        Bloquear
                      </Button>
                    </Popconfirm>
                  </Badge>
                </D3Can>
              </div>

              <Space>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                  }
                  showSizeChanger={true}
                  current={pagination.page}
                  onChange={setPagination}
                  pageSize={pagination.pageSize}
                  total={apiResult.total}
                />
              </Space>
            </>
          );
        }}
      >
        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="id"
          title="Código"
          dataIndex="id"
        />

        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="number"
          title="Número"
          dataIndex="number"
        />
        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="invoice_id"
          title="Nota Fiscal"
          dataIndex={["customer", "fantasy_name"]}
        />
        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="start_date"
          title="Período"
          render={(record) => {
            return `${dayjs(record.start_date).format("DD/MM/YYYY")} à ${dayjs(record.end_date).format("DD/MM/YYYY")}`;
          }}
        />
        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="release_date"
          title="Emissão"
          render={(record) => {
            return `${dayjs(record.release_date).format("DD/MM/YYYY")}`;
          }}
        />
        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="due_date"
          title="Vencimento"
          render={(record) => {
            return `${dayjs(record.due_date).format("DD/MM/YYYY")}`;
          }}
        />
        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="value"
          title="Valor total"
          render={(record) => D3Money.FormatValueApiToPtBR(record.value)}
        />
        <Table.Column<ItemListInvoiceType>
          showSorterTooltip={false}
          key="active"
          title="Status"
          dataIndex="active"
          render={(value, record) => {
            return (
              <Space>
                {record.printed && (
                  <Tooltip trigger={["click", "hover"]} title="Ordem impressa">
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:print-duotone"
                          className="text-blue-400 transition-all duration-100 ease-in hover:scale-110 hover:text-blue-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />

        {/*    <Table.Column<ItemListInvoiceType>
          key="action"
          title="Ações"
          width={130}
          render={(value, record) => {
            return (
              <>
                <Space>
                  <D3Can action="update" resource="billing">
                    <Tooltip
                      title={record.invoice_id ? "Impossível editar, evento já faturado" : "Editar"}
                     trigger={["click", "hover"]}
                    >
                      <Button
                        disabled={record.invoice_id ? true : false}
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<UpdateInvoiceForm cuid={record.cuid} />, "right", 600, "Editar Nota Fiscal")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="company_billing">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<ViewInvoiceForm cuid={record.cuid} />, "right", 700, "Visualizar Nota Fiscal")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="delete" resource="billing">
                    <Popconfirm
                      title="Atenção!"
                      description="Confirmar exclusão do evento?"
                      onConfirm={() => deleteInvoice(record.cuid)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Tooltip
                        title={record.invoice_id ? "Impossível excluir, evento já faturado" : "Excluir"}
                       trigger={["click", "hover"]}
                      >
                        <Button
                          disabled={record.invoice_id ? true : false}
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="material-symbols:delete"
                              height={18}
                              width={18}
                              className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </D3Can>
                </Space>
              </>
            );
          }}
        /> */}
      </Table>
    </div>
  );
};

export default InvoicesView;
