import { Button, Tabs, Space, Spin } from "antd";
import { useCompanyUsersStore } from "../../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateCompanyUserForm } from "../UpdateCompanyUserForm";
import { ViewCompanyUserType } from "../../types";
import showMessage from "@utils/showMessage";
import { Icon } from "@iconify/react";
import TabForm from "./TabData";
import TabUsers from "./TabUsers";
import TabPermissions from "./TabPermissions";
import D3Can from "@components/shared/D3Can";
import TabReports from "./TabReports";

type ViewCompanyUserFormProps = {
  cuid: string;
};

export const ViewCompanyUserForm = ({ cuid }: ViewCompanyUserFormProps) => {
  const { getCompanyUser } = useCompanyUsersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState<string>("1");

  const [companyUser, setCompanyUser] = useState<ViewCompanyUserType | undefined>();

  useEffect(() => {
    (async () => {
      const result = await getCompanyUser(cuid);
      if (result.status === "success") {
        const viewCompanyUser = result.body as ViewCompanyUserType;
        setCompanyUser(viewCompanyUser);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, getCompanyUser]);

  const { onClose } = useContext(D3DrawerContext);

  return (
    <>
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey="1"
          className="select-none"
          onTabClick={(tab) => setTab(tab)}
          items={[
            {
              key: "1",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                  Dados
                </span>
              ),
              children: <TabForm data={companyUser} />,
            },
            {
              key: "2",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="fa:users" className="w-4 h-4" />
                  Grupos
                </span>
              ),
              children: <TabUsers data={companyUser} />,
            },
            {
              key: "3",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                  Permissões
                </span>
              ),
              children: tab === "3" && <TabPermissions data={companyUser} />,
            },
            {
              key: "4",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="mdi:file-report-outline" className="w-[18px] h-[18px]" />
                  Relatórios
                </span>
              ),
              children: tab === "4" && <TabReports reports={companyUser?.reports ?? []} />,
            },
          ]}
        />
        <div className="m-2 text-right">
          <Space size="small">
            <Button type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="update" resource="company_user">
              <Button
                type="primary"
                onClick={() =>
                  openDrawer(<UpdateCompanyUserForm cuid={cuid} />, "right", 800, "Editar Usuário Empresa")
                }
              >
                Editar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Spin>
    </>
  );
};
