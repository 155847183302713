export const channelsNotification = [
  {
    label: "Email",
    value: "E",
  },
  {
    label: "Sistema",
    value: "S",
  },
];

export const systemOrderTypes = [
  {
    label: "Ordem de Serviço",
    value: "SVC",
  },
  {
    label: "Ordem de Protocolo",
    value: "PTC",
  },
  {
    label: "Ordem de Realocação",
    value: "REL",
  },
  {
    label: "Ordem de Material",
    value: "MAT",
  },
  {
    label: "Ordem de Implantação",
    value: "DPL",
  },
  {
    label: "Ordem Especial",
    value: "SPE",
  },
];
