import { Form, Input } from "antd";

type ItemEMAProps = {
  name: number;
  required: boolean;
  label: string;
};

function ItemEMA({ name, required, label, ...props }: ItemEMAProps) {
  return (
    <Form.Item
      {...props}
      name={[name, "value"]}
      rules={[
        { type: "email", message: "Por favor, insira um endereço de e-mail válido!" },
        {
          required: required,
          message: "Obrigatório",
        },
      ]}
      label={label}
    >
      <Input />
    </Form.Item>
  );
}

export default ItemEMA;
