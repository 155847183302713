import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import TabAddress from "./components/tabs/TabAddress";
import TabAdmins from "./components/tabs/TabAdmins";
import TabData from "./components/tabs/TabData";
import TabIncident from "./components/tabs/TabIncident";
import { useCustomerStore } from "./store";
import TabPrices from "./components/tabs/TabPrices";
import TabNotifications from "./components/tabs/TabNotifications";
import TabContracts from "./components/tabs/TabContracts";
import TabContacts from "./components/tabs/TabContacts";
import TabNotes from "./components/tabs/TabNotes";
import { AbilityContext } from "@context/CASLContext";
import { useAbility } from "@casl/react";
import { useEffect } from "react";

function CustomerView() {
  const { activeTab, setActiveTab } = useCustomerStore();
  const ability = useAbility(AbilityContext);
  const usePermission = (action: string, resource: string, multiple?: string[]) => {
    if (multiple) {
      return multiple.some((rule) => {
        const [res, act] = rule.split(".");
        return ability.can(act, res);
      });
    }
    return ability.can(action, resource);
  };

  interface KeyRules {
    [key: string]: string[];
  }

  const keyRules: KeyRules = {
    "1": ["customer.details", "customer.view"],
    "2": ["customer_contact.view"],
    "3": ["customer_note.view"],
    "4": ["customer_address.view"],
    "5": ["customer.details"],
    "6": ["customer_admin.view"],
    "7": ["customer_contract.view"],
    "8": ["customer_notification.view"],
    "9": ["customer_non_payment.view"],
  };

  const getAllowedKey = () => {
    for (const [key, rules] of Object.entries(keyRules)) {
      for (const rule of rules) {
        const [resource, action] = rule.split(".");
        if (ability.can(action, resource)) {
          return key;
        }
      }
    }
    return "";
  };

  useEffect(() => {
    (() => {
      setActiveTab(getAllowedKey());
    })();
  }, []);

  return (
    <div className="flex flex-col h-full p-1 mb-1 mr-1 overflow-hidden bg-white rounded shadow-md">
      <Tabs
        tabPosition="left"
        activeKey={activeTab}
        onTabClick={(tab) => {
          setActiveTab(tab);
        }}
        className="flex h-full overflow-hidden select-none"
        items={[
          {
            disabled: !usePermission("", "", ["customer.details", "customer.view"]),
            key: "1",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ri:profile-line" />
                Dados
              </span>
            ),
            children: <TabData />,
          },
          {
            disabled: !usePermission("view", "customer_contact"),
            key: "2",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:contact-phone-rounded" />
                Contatos
              </span>
            ),
            children: <TabContacts />,
          },
          {
            disabled: !usePermission("view", "customer_note"),
            key: "3",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:contact-phone-rounded" />
                Notas de observações
              </span>
            ),
            children: <TabNotes />,
          },
          {
            disabled: !usePermission("view", "customer_address"),
            key: "4",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mdi:address-marker" />
                Endereços
              </span>
            ),
            children: <TabAddress />,
          },
          {
            disabled: !usePermission("details", "customer"),
            key: "5",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:price-change" />
                Preço
              </span>
            ),
            children: <TabPrices />,
          },
          {
            disabled: !usePermission("view", "customer_admin"),
            key: "6",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="clarity:group-solid" />
                Administradores
              </span>
            ),
            children: <TabAdmins />,
          },
          {
            disabled: !usePermission("view", "customer_contract"),
            key: "7",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon height={16} icon="ri:contract-fill" />
                Contratos
              </span>
            ),
            children: <TabContracts />,
          },
          {
            disabled: !usePermission("view", "customer_notification"),
            key: "8",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mingcute:notification-fill" />
                Notificações
              </span>
            ),
            children: <TabNotifications />,
          },
          {
            disabled: !usePermission("view", "customer_non_payment"),
            key: "9",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ic:round-money-off" width={18} height={18} />
                Registros
              </span>
            ),
            children: <TabIncident />,
          },
        ]}
      />
    </div>
  );
}

export default CustomerView;
