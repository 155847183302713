import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  AddressItemType,
  CancelOrderType,
  CancelType,
  CreateDeploymentOrderType,
  ItemSimpleListCellsType,
  ItemSimpleListCustomerType,
  ItemSimpleListDepartmentType,
  ItemSimpleListRepositoryType,
  ItemSimpleListShelvesType,
  ItemSimpleListStorageType,
  ListDeploymentOrderType,
  PrintResendType,
  PrintType,
  RepositoryListType,
  ResendType,
  ResultRequestType,
  SearchDeploymentOrderType,
  SendDataGetRepository,
  ServedType,
  SimpleListAddressType,
  SimpleListCellsType,
  SimpleListCustomerType,
  SimpleListDepartmentType,
  SimpleListRepositoryType,
  SimpleListShelvesType,
  SimpleListStorageType,
  ViewDeploymentOrderType,
} from "./types";

type DeploymentOrderStoreState = {
  apiResult: ListDeploymentOrderType;

  filter: SearchDeploymentOrderType;
  pagination: { page: number; pageSize: number };
  loading: boolean;
  setFilter: (filter: SearchDeploymentOrderType) => void;
  setPagination: (page: number, pageSize: number) => void;
  loadDeploymentOrders: () => Promise<void>;
  getDeploymentOrder: (cuid: string) => Promise<Success<ViewDeploymentOrderType> | StandardError>;
  getUrl: (orderCuid: string) => Promise<Success<PrintType> | StandardError>;
  createDeploymentOrder: (deploymentOrder: CreateDeploymentOrderType) => Promise<boolean>;
  loadingCreated: boolean;

  cancelDeploymentOrder: (orderCuid: string, payload: CancelOrderType) => Promise<boolean>;
  loadingCancel: boolean;

  simpleListRepositoryType: () => Promise<ItemSimpleListRepositoryType[]>;
  simpleListDepartment: () => Promise<ItemSimpleListDepartmentType[]>;
  simpleListAddress: () => Promise<AddressItemType[]>;
  getRepositories: (payload: SendDataGetRepository) => Promise<void>;
  payloadRepositories: SendDataGetRepository;
  filterRepositories: {
    rep_repository_physical_tag_id: string;
  };
  resetFilter: () => void;
  resetState: () => void;
  resetRepositories: () => void;
  resetStateCreate: () => void;
  apiRepositories: RepositoryListType;
  apiRepositoriesLoading: boolean;
  repositoriesPagination: { page: number; pageSize: number };
  setRepositoriesPagination: (page: number, pageSize: number) => void;
  selectedRows: number[];
  simpleListStorage: () => Promise<ItemSimpleListStorageType[]>;
  simpleListShelves: (storageCuid: string) => Promise<ItemSimpleListShelvesType[]>;
  simpleListCells: (storageCuid: string, shelfCuid: string, orderCuid: string) => Promise<ItemSimpleListCellsType[]>;
  savedDeploymentOrder: (orderCuid: string, payload: ServedType) => Promise<boolean>;
  loadingServe: boolean;

  simpleListDepartmentsByCuidCustomer: (customerCuid: string) => Promise<ItemSimpleListDepartmentType[]>;

  simpleListDepartmentsByCustomerCuidCustomerGroup: (customerCuid: string) => Promise<ItemSimpleListDepartmentType[]>;

  urlResendOrder: string;
  resendDeploymentOrderByCuid: (orderCuid: string) => Promise<void>;

  printResendOrderDeploymentByCuid: (orderCuid: string) => Promise<void>;
  urlPrintResendOrder: string;

  simpleListCustomer: () => Promise<void>;
  loadingCustomer: boolean;
  listCustomer: ItemSimpleListCustomerType[];

  simpleListDepartmentByCustomerCuid: (customerCuid: string) => Promise<void>;
  loadingDepartments: boolean;
  listDepartments: ItemSimpleListDepartmentType[];
};

const deploymentOrdersFilter: FilterDefinition = {
  dep_cuid: { op: "eq", type: "string" },
  do_id: { op: "eq", type: "string" },
  do_request_type: { op: "eq", type: "string" },
  do_status: { op: "eq", type: "string" },
  r_repository_physical_tag_id: { op: "eq", type: "string" },
  uc_name: { op: "lk", type: "string" },
  c_cuid: { op: "eq", type: "string" },
  do_has_order_resent: { op: "eq", type: "active" },
};

const defaultState = {
  loadingServe: false,
  apiResult: {
    total: 0,
    data: [],
  },
  loading: false,
  filter: {
    c_cuid: "",
    dep_cuid: "",
    do_id: "",
    do_request_type: "",
    do_status: "WTG",
    r_repository_physical_tag_id: "",
    uc_name: "",
    do_has_order_resent: "all",
  } as SearchDeploymentOrderType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  urlResendOrder: "",
  urlPrintResendOrder: "",

  loadingCustomer: false,
  listCustomer: [],

  loadingDepartments: false,
  listDepartments: [],

  loadingCancel: false,
};

const defaultStateCreate = {
  apiRepositories: {
    data: [],
    total: 0,
  },
  repositoriesPagination: {
    page: 1,
    pageSize: 10,
  },
  apiRepositoriesLoading: false,
  selectedRows: [],
  payloadRepositories: {
    department_cuid: "",
    repository_type_cuid: "",
  },
  filterRepositories: {
    rep_repository_physical_tag_id: "",
  },
  loadingCreated: false,
};

export const useDeploymentOrdersStore = create<DeploymentOrderStoreState>((set, get) => ({
  ...defaultState,
  ...defaultStateCreate,
  resetState: () => set(defaultState),
  resetRepositories: () => {
    set({
      apiRepositoriesLoading: true,
      apiRepositories: { data: [], total: 0 },
      selectedRows: [],
    });
    set({
      apiRepositoriesLoading: false,
    });
  },
  resetStateCreate: () => set(defaultStateCreate),

  setFilter: (filter: SearchDeploymentOrderType) => {
    set({
      filter,
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadDeploymentOrders();
  },
  resetFilter: () => {
    set({ filter: { ...defaultState.filter, do_status: "" } });
    get().loadDeploymentOrders();
  },

  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadDeploymentOrders();
  },

  loadDeploymentOrders: async (): Promise<void> => {
    set({ loading: true });

    let qs = D3QSGenerator(get().filter, deploymentOrdersFilter, get().pagination);

    await new ClientHttp().get<Success<ListDeploymentOrderType>, BadRequest | StandardError>(
      `/api/v1/orders/deployments?${qs}`,
      (result: Success<ListDeploymentOrderType>) => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  getDeploymentOrder: async (cuid: string): Promise<Success<ViewDeploymentOrderType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewDeploymentOrderType>, StandardError>(
      `/api/v1/orders/deployments/${cuid}`
    );
  },

  createDeploymentOrder: async (deploymentOrder: CreateDeploymentOrderType): Promise<boolean> => {
    set({ loadingCreated: true });
    const result = await new ClientHttp().post<CreateDeploymentOrderType, Success<void>, BadRequest | StandardError>(
      "/api/v1/orders/deployments",
      deploymentOrder,
      (result: Success<void>) => {
        showMessage(result, "Ordem de implantação cadastrada com sucesso.");
        get().loadDeploymentOrders();
        set({ loadingCreated: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCreated: false });
      }
    );
    return result.status === "success";
  },
  cancelDeploymentOrder: async (orderCuid: string, payload: CancelOrderType): Promise<boolean> => {
    set({ loadingCancel: true });
    const result = await new ClientHttp().patch<CancelType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/deployments/${orderCuid}/cancel`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Ordem cancelada com sucesso.");
        set({ loadingCancel: false });
        get().loadDeploymentOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCancel: false });
      }
    );
    return result.status === "success";
  },
  simpleListDepartment: async (): Promise<ItemSimpleListDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemSimpleListDepartmentType[]
    >(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): ItemSimpleListDepartmentType[] => {
        return result.body.data as ItemSimpleListDepartmentType[];
      },
      (error: StandardError): ItemSimpleListDepartmentType[] => {
        showMessage(error);
        return [] as ItemSimpleListDepartmentType[];
      }
    );
  },

  simpleListAddress: async (): Promise<AddressItemType[]> => {
    return await new ClientHttp().getItensForSelect<Success<SimpleListAddressType>, StandardError, AddressItemType[]>(
      `/api/v1/customer/addresses/simple-list`,
      (result: Success<SimpleListAddressType>): AddressItemType[] => {
        return result.body.data as AddressItemType[];
      },
      (error: StandardError): AddressItemType[] => {
        showMessage(error);
        return [] as AddressItemType[];
      }
    );
  },
  simpleListRepositoryType: async (): Promise<ItemSimpleListRepositoryType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListRepositoryType>,
      StandardError,
      ItemSimpleListRepositoryType[]
    >(
      `/api/v1/customer/repository-types/simple-list`,
      (result: Success<SimpleListRepositoryType>): ItemSimpleListRepositoryType[] => {
        return result.body.data as ItemSimpleListRepositoryType[];
      },
      (error: StandardError): ItemSimpleListRepositoryType[] => {
        showMessage(error);
        return [] as ItemSimpleListRepositoryType[];
      }
    );
  },

  getRepositories: async (payload: SendDataGetRepository): Promise<void> => {
    set({ apiRepositoriesLoading: true, payloadRepositories: payload });
    const isFilter = get().filterRepositories.rep_repository_physical_tag_id;
    const queryString = isFilter ? `&q=rep_repository_physical_tag_id:lk=${isFilter}` : undefined;
    const { page, pageSize } = get().repositoriesPagination;
    await new ClientHttp().post<SendDataGetRepository, Success<RepositoryListType>, BadRequest | StandardError>(
      `/api/v1/orders/deployments/repositories?page=${page}&per_page=${pageSize}${queryString ? queryString : ""}`,
      payload,
      (result: Success<RepositoryListType>): void => {
        const { body } = result;
        set({
          apiRepositories: body as RepositoryListType,
          apiRepositoriesLoading: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ apiRepositoriesLoading: false });
      }
    );
  },

  setRepositoriesPagination: (page: number, pageSize: number) => {
    const currentPagination = get().repositoriesPagination;
    const pageSizeChanged = pageSize !== currentPagination.pageSize;
    if (pageSizeChanged) {
      page = 1;
    }
    if (page !== currentPagination.page || pageSizeChanged) {
      set({ repositoriesPagination: { page, pageSize } });
      get().getRepositories(get().payloadRepositories);
    }
  },

  getUrl: async (orderCuid: string): Promise<Success<PrintType> | StandardError> => {
    return await new ClientHttp().get<Success<PrintType>, StandardError>(
      `/api/v1/orders/deployments/${orderCuid}/print`,
      (result: Success<PrintType>): PrintType => {
        return result.body;
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListStorage: async (): Promise<ItemSimpleListStorageType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListStorageType>,
      StandardError,
      ItemSimpleListStorageType[]
    >(
      "/api/v1/system/storages/simple-list",
      (result: Success<SimpleListStorageType>): ItemSimpleListStorageType[] => {
        return result.body.data as ItemSimpleListStorageType[];
      },
      (error: StandardError): ItemSimpleListStorageType[] => {
        showMessage(error);
        return [] as ItemSimpleListStorageType[];
      }
    );
  },
  simpleListShelves: async (storageCuid: string): Promise<ItemSimpleListShelvesType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListShelvesType>,
      StandardError,
      ItemSimpleListShelvesType[]
    >(
      `/api/v1/system/storages/${storageCuid}/shelves/simple-list`,
      (result: Success<SimpleListShelvesType>): ItemSimpleListShelvesType[] => {
        return result.body.data as ItemSimpleListShelvesType[];
      },
      (error: StandardError): ItemSimpleListShelvesType[] => {
        showMessage(error);
        return [] as ItemSimpleListShelvesType[];
      }
    );
  },
  simpleListCells: async (
    storageCuid: string,
    shelfCuid: string,
    orderCuid: string
  ): Promise<ItemSimpleListCellsType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCellsType>,
      StandardError,
      ItemSimpleListCellsType[]
    >(
      `/api/v1/system/storages/${storageCuid}/shelves/${shelfCuid}/cells/simple-list?deploymentOrderCuid=${orderCuid}`,
      (result: Success<SimpleListCellsType>): ItemSimpleListCellsType[] => {
        return result.body.data as ItemSimpleListCellsType[];
      },
      (error: StandardError): ItemSimpleListCellsType[] => {
        showMessage(error);
        return [] as ItemSimpleListCellsType[];
      }
    );
  },
  savedDeploymentOrder: async (orderCuid: string, payload: ServedType): Promise<boolean> => {
    set({ loadingServe: true });
    const result = await new ClientHttp().patch<ServedType, Success<ResultRequestType>, BadRequest | StandardError>(
      `/api/v1/orders/deployments/${orderCuid}/serve`,
      payload,
      (result: Success<ResultRequestType>) => {
        showMessage(result, "Ordem baixada com sucesso.");
        get().loadDeploymentOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
    set({ loadingServe: false });
    return result.status === "success";
  },

  /*   simpleListCustomer: async (): Promise<ItemSimpleListCustomerType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCustomerType>,
      StandardError,
      ItemSimpleListCustomerType[]
    >(
      "/api/v1/company/customers/simple-list",
      (result: Success<SimpleListCustomerType>): ItemSimpleListCustomerType[] => {
        return result.body.data as ItemSimpleListCustomerType[];
      },
      (error: StandardError): ItemSimpleListCustomerType[] => {
        showMessage(error);
        return [] as ItemSimpleListCustomerType[];
      }
    );
  },
 */
  simpleListDepartmentsByCuidCustomer: async (customerCuid: string): Promise<ItemSimpleListDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemSimpleListDepartmentType[]
    >(
      `/api/v1/company/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): ItemSimpleListDepartmentType[] => {
        return result.body.data as ItemSimpleListDepartmentType[];
      },
      (error: StandardError): ItemSimpleListDepartmentType[] => {
        showMessage(error);
        return [] as ItemSimpleListDepartmentType[];
      }
    );
  },

  simpleListDepartmentsByCustomerCuidCustomerGroup: async (
    customerCuid: string
  ): Promise<ItemSimpleListDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemSimpleListDepartmentType[]
    >(
      `/api/v1/customer-group/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): ItemSimpleListDepartmentType[] => {
        return result.body.data as ItemSimpleListDepartmentType[];
      },
      (error: StandardError): ItemSimpleListDepartmentType[] => {
        showMessage(error);
        return [] as ItemSimpleListDepartmentType[];
      }
    );
  },
  resendDeploymentOrderByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().post<undefined, Success<ResendType>, BadRequest | StandardError>(
      `/api/v1/orders/deployments/${orderCuid}/resend`,
      undefined,
      (result: Success<ResendType>): void => {
        set({
          urlResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  printResendOrderDeploymentByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().get<Success<PrintResendType>, StandardError>(
      `/api/v1/orders/deployments/${orderCuid}/print-resend`,
      (result: Success<PrintResendType>): void => {
        set({
          urlPrintResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },

  simpleListCustomer: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/system/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomer: false });
  },
  simpleListDepartmentByCustomerCuid: async (customerCuid: string): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      `/api/v1/system/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): void => {
        set({ listDepartments: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingDepartments: false });
  },
}));
