import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { SetSort, SortType } from "@utils/table/SetSort";
import { create } from "zustand";
import {
  CreateInvoiceType,
  ItemCustomerSystemType,
  ListInvoiceType,
  SearchInvoiceType,
  SimpleListCustomerSystemType,
} from "./types";

type InvoiceStoreType = {
  selectedRows: { cuids: string[] };

  loadInvoices: () => Promise<void>;
  loading: boolean;
  apiResult: ListInvoiceType;

  setFilter: (filter: SearchInvoiceType) => void;
  filter: SearchInvoiceType;
  resetFilter: () => void;

  pagination: { page: number; pageSize: number };
  setPagination: (page: number, pageSize: number) => void;

  sort: { sort_by: string; sort_direction: string };
  setSort: (newSort: SortType) => void;

  /*   loadView: (invoiceCuid: string) => Promise<void>;
  loadingView: boolean;
  view: ViewInvoiceType; */

  createInvoice: (payload: CreateInvoiceType) => Promise<boolean>;
  loadingCreate: boolean;

  /*   updateInvoice: (invoiceCuid: string, payload: UpdateInvoiceType) => Promise<boolean>;
  loadingUpdate: boolean;

  deleteInvoice: (invoiceCuid: string) => Promise<boolean>;
  loadingDelete: boolean; */

  resetState: () => void;

  simpleListCustomersSystem: ({active}: {active?: boolean}) => Promise<void>;
  loadingListCustomer: boolean;
  listCustomer: ItemCustomerSystemType[];

  /*  simpleListDepartmentsByCustomerCuid: (customerCuid: string) => Promise<void>;
  loadingListDepartments: boolean;
  listDepartments: ItemDepartmentsCustomerCuid[]; */
};

const defaultSort = {
  sort_by: "invoice_id",
  sort_direction: "desc",
};

const operationsFilter: FilterDefinition = {
  invoice_id: { op: "eq", type: "string" },
  invoice_number: { op: "eq", type: "string" },
  customer_cuid: { op: "eq", type: "string" },
  customer_fantasy_name: { op: "lk", type: "string" },
  invoice_release_date: { op: "eq", type: "string" },
  invoice_due_date: { op: "eq", type: "string" },
  invoice_start_date: { op: "eq", type: "string" },
  invoice_end_date: { op: "eq", type: "string" },
  invoice_printed: { op: "eq", type: "active" },
  invoice_canceled: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
  },
  filter: {
    invoice_id: "",
    invoice_number: "",
    customer_cuid: "",
    customer_fantasy_name: "",
    invoice_release_date: "",
    invoice_due_date: "",
    invoice_start_date: "",
    invoice_end_date: "",
    invoice_printed: "all",
    invoice_canceled: "all",
  } as SearchInvoiceType,

  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,

  loadingCreate: false,

  loadingListCustomer: false,
  listCustomer: [],

  /*  loadingView: false,
  view: {} as ViewInvoiceType,
  loadingUpdate: false,
  loadingDelete: false,
 
  loadingListDepartments: false,
  listDepartments: [], */
};
export const useInvoiceStore = create<InvoiceStoreType>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchInvoiceType) => {
    set({
      filter,
      selectedRows: { cuids: [] },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadInvoices();
  },
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [] } });
  },
  resetFilter: () => {
    set({ filter: defaultState.filter });
    get().loadInvoices();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadInvoices();
  },
  setSort: (newSort: SortType) =>
    SetSort(newSort, get().sort, defaultSort, new Map<string, string>([]), set, get().loadInvoices),

  loadInvoices: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);
    await new ClientHttp().get<Success<ListInvoiceType>, BadRequest | StandardError>(
      `/api/v1/company/billing/invoices?${qs}`,
      (result: Success<ListInvoiceType>): void => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },
  createInvoice: async (payload: CreateInvoiceType): Promise<boolean> => {
    set({ loadingCreate: true });
    const result = await new ClientHttp().post<CreateInvoiceType, Success<void>, BadRequest | StandardError>(
      "/api/v1/company/billing/invoices",
      payload,
      (result: Success<void>): void => {
        showMessage(result, "Nota fiscal cadastrada com sucesso.");
        set({ loadingCreate: false });
        get().loadInvoices();
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingCreate: false });
      }
    );
    return result.status === "success";
  },
  simpleListCustomersSystem: async ({active}: {active?: boolean}): Promise<void> => {
    set({ loadingListCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerSystemType>, BadRequest | StandardError, void>(
      `/api/v1/company/customers/simple-list${active ? "?active=true" : ""}`,
      (result: Success<SimpleListCustomerSystemType>): void => {
        set({
          listCustomer: result.body.data,
          loadingListCustomer: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingListCustomer: false });
      }
    );
  },
}));
