import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import TabAddress from "./components/tabs/TabAddress";
import TabAdmins from "./components/tabs/TabAdmins";
import TabData from "./components/tabs/TabData";
import TabPrices from "./components/tabs/TabPrices";
import { useCompanyStore } from "./store";
import TabNotifications from "./components/tabs/TabNotifications";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@context/CASLContext";

const CompanyView: React.FC = () => {
  const { getActiveTab, setActiveTab } = useCompanyStore();

  const usePermission = (action: string, resource: string) => {
    const ability = useAbility(AbilityContext);
    return ability.can(action, resource);
  };
  return (
    <div className="h-full p-4 mb-1 mr-1 bg-white rounded shadow-md">
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        activeKey={getActiveTab()}
        onTabClick={(tab) => {
          setActiveTab(tab);
        }}
        className="h-full select-none"
        items={[
          {
            disabled: !usePermission("details", "company"),
            key: "1",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ri:profile-line" />
                Dados
              </span>
            ),
            children: <TabData />,
          },
          {
            disabled: !usePermission("view", "company_address"),
            key: "2",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mdi:address-marker" />
                Endereços
              </span>
            ),
            children: <TabAddress />,
          },
          {            
            disabled: !usePermission("details", "company"),
            key: "3",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:price-change" />
                Preço
              </span>
            ),
            children: <TabPrices />,
          },
          {
            disabled: !usePermission("view", "company_admin"),
            key: "4",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="clarity:group-solid" />
                Administradores
              </span>
            ),
            children: <TabAdmins />,
          },
          {
            disabled: !usePermission("view", "company_notification"),
            key: "5",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="mingcute:notification-fill" />
                Notificações
              </span>
            ),
            children: <TabNotifications />,
          },
        ]}
      />
    </div>
  );
};

export default CompanyView;
