import { Form, Segmented } from "antd";

type ItemBOOProps = {
  name: number;
  label: string;
};

function ItemBOO({ name, label, ...props }: ItemBOOProps) {
  return (
    <Form.Item
      {...props}
      name={[name, "value"]}
      getValueProps={(value: string) => {
        return { value: value !== undefined ? value : "" };
      }}
      normalize={(value: string) => {
        if (value === "true" || value === "false" || value === "") {
          return value;
        }
        return "";
      }}
      label={label}
    >
      <Segmented
        options={[
          { label: "Sim", value: "true" },
          { label: "Não", value: "false" },
        ]}
        className="select-none"
      />
    </Form.Item>
  );
}

export default ItemBOO;
