import { Form, InputNumber } from "antd";
type ItemNMIProps = {
  name: number;
  label: string;
  requiredStart: boolean;
  requiredEnd: boolean;
};
function ItemNMI({ name, label, requiredEnd, requiredStart, ...props }: ItemNMIProps) {
  return (
    <Form.Item className="p-0 m-0" label={label}>
      <div className="flex w-full gap-3 ">
        <Form.Item
          {...props}
          name={[name, "start_value"]}
          getValueProps={(value: number) => {
            return { value: value !== undefined ? value.toString() : "" };
          }}
          normalize={(value: string) => {
            const numericValue = Number(value);
            return !isNaN(numericValue) ? numericValue.toString() : value;
          }}
          rules={[
            {
              required: requiredStart,
              message: "Obrigatório",
            },
          ]}
        >
          <InputNumber placeholder="Valor inicial" type="number" className="w-32" />
        </Form.Item>
        <div className="flex items-center h-8 ">ao</div>
        <Form.Item
          {...props}
          name={[name, "end_value"]}
          getValueProps={(value: number) => {
            return { value: value !== undefined ? value.toString() : "" };
          }}
          normalize={(value: string) => {
            const numericValue = Number(value);
            return !isNaN(numericValue) ? numericValue.toString() : value;
          }}
          rules={[
            {
              required: requiredEnd,
              message: "Obrigatório",
            },
          ]}
        >
          <InputNumber placeholder="Valor final" type="number" className="w-32" />
        </Form.Item>
      </div>
    </Form.Item>
  );
}

export default ItemNMI;
