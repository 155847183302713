import { useEffect } from "react";
import { Button, Form, Input, Segmented, Space } from "antd";
import { SearchCustomerGroupUserType } from "../types";
import { useCustomerGroupUsersStore } from "../store";

type SearchCustomerGroupUserFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchCustomerGroupUserForm = ({
  onCallBackFilter,
  onCallBackResetFilter,
}: SearchCustomerGroupUserFormProps) => {
  const [form] = Form.useForm<SearchCustomerGroupUserType>();
  const { filter, setFilter } = useCustomerGroupUsersStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);
  function hasSpaces(value: string) {
    return /\s/.test(value);
  }
  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchCustomerGroupUserType> name="user_name" label="Nome">
          <Input />
        </Form.Item>
        <Form.Item<SearchCustomerGroupUserType>
          label="Usuário"
          name="user_username"
          rules={[
            {
              validator: (_, value) =>
                hasSpaces(value) ? Promise.reject(new Error("O usuário não pode conter espaços.")) : Promise.resolve(),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<SearchCustomerGroupUserType> name="user_email" label="E-mail">
          <Input />
        </Form.Item>
        <Form.Item<SearchCustomerGroupUserType> name="user_active" label="Status">
          <Segmented
            value={filter.user_active}
            options={[
              { value: "off", label: "Bloqueados" },
              { value: "on", label: "Desbloqueados" },
              { value: "all", label: "Todos" },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
