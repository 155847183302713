import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

enum DocType {
  bmp = "image/bmp",
  csv = "text/csv",
  odt = "application/vnd.oasis.opendocument.text",
  doc = "application/msword",
  docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  gif = "image/gif",
  htm = "text/htm",
  html = "text/html",
  jpg = "image/jpg",
  jpeg = "image/jpeg",
  pdf = "application/pdf",
  png = "image/png",
  ppt = "application/vnd.ms-powerpoint",
  pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  tif = "image/tiff", // key estava value, troquei q por algum motivo está salvando como .tif
  txt = "text/plain",
  xls = "application/vnd.ms-excel",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  mp4 = "video/mp4",
  webp = "image/webp",
}

type RenderFileProps = {
  uri: string;
  fileType: string;
};

function RenderFile({ fileType, uri }: RenderFileProps) {
  return (
    <DocViewer
      language="pt"
      className="doc-view"
      documents={[{ uri, fileType: DocType[fileType as keyof typeof DocType] }]}
      pluginRenderers={DocViewerRenderers}
      config={{
        header: {
          disableFileName: true,
          disableHeader: true,
        },
      }}
    />
  );
}

export default RenderFile;
