import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Col, DatePicker, Form, Row, Space, Spin } from "antd";
import dayjs from "dayjs"
import { useContext, useEffect } from "react";
import { CreateInvoiceType } from "../types";
import { useInvoiceStore } from "../store";
import Selector from "@components/shared/Selector";

export const CreateInvoiceForm = () => {
  const { createInvoice, loadingCreate, simpleListCustomersSystem, listCustomer, loadingListCustomer } =
    useInvoiceStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateInvoiceType>();

  useEffect(() => {
    (async () => {
      await simpleListCustomersSystem({ active: true });
    })();
  }, [simpleListCustomersSystem]);

  const normalize = (value: any) => {
    return dayjs(value as Date).format("YYYY-MM-DD");
  };
  const getValueProps = (value: any) => {
    return { value: value ? dayjs(value) : "" };
  };

  const startDate = Form.useWatch("start_date", form);
  const releaseDate = Form.useWatch("release_date", form);

  return (
    <Spin spinning={loadingListCustomer}>
      <Form
        className="select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: CreateInvoiceType) => {
          const isCreated = await createInvoice(payload);
          if (isCreated) {
            onClose();
          }
        }}
        autoComplete="off"
      >
        <Form.Item<CreateInvoiceType>
          name="customer_cuids"
          label="Clientes"
          rules={[{ required: true, message: "Por favor, selecione um ou mais clientes." }]}
        >
          <Selector
            fieldMap={{
              status: "active",
              value: "cuid",
              label: "fantasy_name",
              description: "name",
              dataLabel: ["fantasy_name"],
              dataFilter: ["fantasy_name", "name", "id", "document"],
              disabled: true,
            }}
            mode="multiple"
            options={listCustomer}
            loading={loadingListCustomer}
            disabled={listCustomer.length === 0 || loadingListCustomer}
          />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="start_date"
              label="Data inicial"
              className="w-56"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data inicial.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="end_date"
              label="Data final"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data final.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker disabled={!startDate} minDate={dayjs(startDate)} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="release_date"
              label="Data de emissão"
              className="w-56"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data de emissão.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item<CreateInvoiceType>
              name="due_date"
              label="Data de vencimento"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira data de vencimento.",
                },
              ]}
              normalize={normalize}
              getValueProps={getValueProps}
            >
              <DatePicker disabled={!releaseDate} minDate={dayjs(releaseDate)} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="create" resource="billing">
                <Button loading={loadingCreate} type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
