import React from "react";
import { DatePicker, Form, Input, InputNumber, Segmented, Select } from "antd";
import { ItemParamsReportType } from "../types";
import dayjs from "dayjs";

interface InputGeneratorProps {
  input: ItemParamsReportType;
}

const renderFormItem = (
  id: number,
  order: number,
  label: string,
  required: boolean,
  message: string,
  component: React.ReactNode,
  getValueProps?: (value: any) => Record<string, unknown>,
  normalize?: (value: any) => any
) => (
  <>
    <Form.Item hidden initialValue={id} name={["params", order - 1, "id"]}>
      <InputNumber value={id} />
    </Form.Item>
    <Form.Item
      name={["params", order - 1, "values"]}
      label={label}
      rules={[{ required, message }]}
      getValueProps={getValueProps}
      normalize={normalize}
    >
      {component}
    </Form.Item>
  </>
);

const InputGenerator: React.FC<InputGeneratorProps> = ({ input: { id, label, multiple, order, required, type } }) => {
  const selectComponent = (
    <Select open={false} suffixIcon={null} className="w-full" mode="tags" tokenSeparators={[",", " ", "\t"]} />
  );

  switch (type) {
    case "C":
      return renderFormItem(
        id,
        order,
        label,
        required,
        "Por favor, preencha o carácter.",
        multiple ? selectComponent : <Input />,
        multiple ? undefined : (value) => ({ value: value ? String(value[0]) : "" }),
        multiple ? undefined : (value) => (value ? [String(value)] : "")
      );

    case "I":
      return renderFormItem(
        id,
        order,
        label,
        required,
        "Por favor, preencha o inteiro.",
        multiple ? selectComponent : <InputNumber />,
        multiple ? undefined : (value) => ({ value: value ? String(value[0]) : 0 }),
        multiple ? undefined : (value) => (value ? [String(value)] : 0)
      );

    case "F":
      return renderFormItem(
        id,
        order,
        label,
        required,
        "Por favor, preencha o decimal.",
        multiple ? selectComponent : <InputNumber />,
        multiple ? undefined : (value) => ({ value: value ? String(value[0]) : 0 }),
        multiple ? undefined : (value) => (value ? [String(value)] : 0)
      );

    case "D":
      return renderFormItem(
        id,
        order,
        label,
        required,
        multiple ? "Por favor, selecione as datas." : "Por favor, selecione a data.",
        <DatePicker
          placeholder={multiple ? "Selecione as datas" : "Selecione a data"}
          format="DD/MM/YYYY"
          multiple={multiple}
        />,
        (value) => ({
          value: multiple ? (value ? value.map((date: any) => dayjs(date)) : []) : value ? dayjs(value) : undefined,
        }),
        (value) =>
          multiple
            ? value
              ? value.map((date: any) => dayjs(date).format("YYYY-MM-DD"))
              : undefined
            : value
            ? dayjs(value).format("YYYY-MM-DD")
            : ""
      );

    case "B": {
      const options = [
        { label: "Sim", value: "true" },
        { label: "Não", value: "false" },
      ];
      if (required === false) {
        options.push({
          label: "Todos",
          value: "",
        });
      }

      const initialValue = required ? "false" : "";

      return renderFormItem(
        id,
        order,
        label,
        required,
        "Por favor, escolha uma opção.",
        <Segmented defaultValue={initialValue} options={options} />,
        (value) => ({ value: value ? value[0] : "" }),
        (value) => (value ? [value] : [""])
      );
    }
    default:
      return null;
  }
};

export default InputGenerator;
