import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import {
  ChangedFilterType,
  FilterResponseType,
  GlobalIndexersType,
  GlobalIndexValueType,
  IndexItemType,
  ItemDepartmentType,
  ItemDocumentTypeType,
  ItemExtensionsType,
  ItemGlobalIndexersType,
  ItemRepositoryTypeType,
  ListIndexesType,
  MarkerItemType,
  SearchType,
  SimpleListDepartmentType,
  SimpleListDocumentTypeType,
  SimpleListExtensionsType,
  SimpleListMarkersType,
  SimpleListRepositoryTypeType,
} from "./types";
import useNavigateStore from "../../store";
import { Diff, diff } from "deep-diff";
import mergePartialObject from "@utils/mergePartialObject";

type SearchStoreType = {
  filter: SearchType;
  setFilter: (filter: SearchType) => void;
  resetFilter: () => void;
  listIndexers: IndexItemType[];
  simpleListDocumentType: () => Promise<void>;
  loadingDocumentsType: boolean;
  documentsType: ItemDocumentTypeType[];
  getListIndexesByDocumentTypeCuid: (documentTypeCuid: string) => Promise<void>;
  loadingIndexes: boolean;
  simpleListDepartmentCustomer: () => Promise<void>;
  departments: ItemDepartmentType[];
  loadContentWithFilter: () => Promise<void>;
  simpleListMarkers: () => Promise<void>;
  markers: MarkerItemType[];
  simpleListExtensions: () => Promise<void>;
  extensions: ItemExtensionsType[];
  simpleListRepositoryType: () => Promise<void>;
  repositoryTypes: ItemRepositoryTypeType[];
  resetPartFilter: (partName: keyof SearchType) => void;
  diffFilter: (path?: (keyof SearchType | string) | undefined, values?: any | undefined) => boolean;
  listDocumentTypeIndexersGlobal: () => Promise<void>;
  listGlobalIndexers: ItemGlobalIndexersType[];
  loadingGlobalIndexers: boolean;
  changedFilter: ChangedFilterType;
  setChangedFilter: (filter: keyof ChangedFilterType, boo: boolean) => void;
};

export const defaultState = {
  filter: {
    config: {
      pagination: {
        page: 1,
        per_page: 50,
      },
      sort: {
        by: "f_id",
        direction: "desc",
      },
    },
    filter_document: {
      created_at: {
        end_date: "",
        start_date: "",
        option: "",
      },
      departments_cuids: [],
      document_physical_tag_id: [],
      document_range: {
        end_document_physical_tag_id: "",
        start_document_physical_tag_id: "",
      },
      expected_purge_date: {
        end_date: "",
        start_date: "",
        option: "",
      },
      extensions_ids: [],
      name: "",
      purged_at: {
        end_date: "",
        start_date: "",
        option: "",
      },
      status: [],
    },
    filter_document_type: {
      document_type_cuid: "",
      indexes_values: [],
    },
    filter_marker: {
      markers_cuids: [],
    },
    filter_repository: {
      created_at: {
        end_date: "",
        start_date: "",
        option: "",
      },
      origin: "",
      position: "",
      repository_physical_tag_id: [],
      repository_range: {
        end_repository_physical_tag_id: "",
        start_repository_physical_tag_id: "",
      },
      repository_types_cuids: [],
    },
    filter_global_index: {
      global_indexes_values: [] as GlobalIndexValueType[],
    },
  },
  listIndexers: [],
  documentsType: [],
  loadingDocumentsType: false,
  loadingIndexes: false,
  departments: [],
  extensions: [],
  markers: [],
  repositoryTypes: [],
  listGlobalIndexers: [],
  loadingGlobalIndexers: false,
  changedFilter: {
    config: false,
    filter_document: false,
    filter_document_type: false,
    filter_marker: false,
    filter_repository: false,
    filter_global_index: false,
  },
};

const useSearchStore = create<SearchStoreType>((set, get) => ({
  ...defaultState,
  setFilter: (newFilter: Partial<SearchType>) => {
    const oldFilter = get().filter;
    const filter = mergePartialObject(oldFilter, newFilter);
    set({
      filter: {
        ...filter,
        config: {
          ...filter.config,
          pagination: {
            per_page: 50,
            page: 1,
          },
        },
      },
    });
    get().loadContentWithFilter();
  },
  resetFilter: () => {
    set({ filter: defaultState.filter });
  },
  loadContentWithFilter: async (): Promise<void> => {
    await new ClientHttp().post<SearchType, Success<FilterResponseType>, BadRequest | StandardError>(
      "/api/v1/customer/fs/search",
      get().filter,
      (result: Success<FilterResponseType>): void => {
        const content = useNavigateStore.getState().content;
        useNavigateStore.setState({
          content: {
            ...content,
            total_children: result.body.total,
            children:
              get().filter.config.pagination.page > 1 ? [...content.children, ...result.body.data] : result.body.data,
            breadcrumbs: [content.breadcrumbs[0]],
          },
        });
        const filter = get().filter;
        set({
          filter: {
            ...filter,
            config: {
              ...filter.config,
              pagination: {
                ...filter.config.pagination,
                page: filter.config.pagination.page + 1,
              },
            },
          },
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListDocumentType: async (): Promise<void> => {
    set({ loadingDocumentsType: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDocumentTypeType>, StandardError, void>(
      "/api/v1/customer/document-types/simple-list",
      (result: Success<SimpleListDocumentTypeType>): void => {
        set({
          documentsType: result.body.data,
          loadingDocumentsType: false,
        });
      },
      (error: StandardError): void => {
        set({ loadingDocumentsType: false });
        showMessage(error);
      }
    );
  },
  getListIndexesByDocumentTypeCuid: async (documentTypeCuid: string): Promise<void> => {
    set({ loadingIndexes: true });
    await new ClientHttp().getItensForSelect<Success<ListIndexesType>, StandardError, void>(
      `/api/v1/customer/document-types/${documentTypeCuid}/document-type-indexes`,
      (result: Success<ListIndexesType>): void => {
        set({
          listIndexers: result.body.data as IndexItemType[],
          loadingIndexes: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          loadingIndexes: false,
        });
      }
    );
  },
  simpleListDepartmentCustomer: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): void => {
        set({
          departments: result.body.data,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListMarkers: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListMarkersType>, StandardError, void>(
      "/api/v1/customer/markers/simple-list",
      (result: Success<SimpleListMarkersType>): void => {
        set({ markers: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListExtensions: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListExtensionsType>, StandardError, void>(
      "/api/v1/system/extensions/simple-list",
      (result: Success<SimpleListExtensionsType>): void => {
        set({ extensions: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListRepositoryType: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListRepositoryTypeType>, StandardError, void>(
      "/api/v1/customer/repository-types/simple-list",
      (result: Success<SimpleListRepositoryTypeType>): void => {
        set({ repositoryTypes: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },

  resetPartFilter: (partName: keyof SearchType) => {
    const oldFilter = defaultState.filter;
    const filter = get().filter;
    if (oldFilter[partName] && filter[partName]) {
      set((state) => {
        return {
          filter: {
            ...state.filter,
            [partName]: oldFilter[partName],
          },
          changedFilter: {
            ...state.changedFilter,
            [partName]: false,
          },
        };
      });
    }
  },

  diffFilter: (path: keyof SearchType | string = "", values: any | undefined): boolean => {
    const getNestedValue = (obj: any, path: string) => {
      return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    };
    const filterOld = defaultState.filter;
    const filter = values ? values : get().filter;
    const oldFilter = path ? getNestedValue(filterOld, path as string) : filterOld;
    const newFilter = path ? getNestedValue(filter, path as string) : filter;
    const differences = diff(oldFilter, newFilter);
    return differences ? (differences as Diff<any, any>[]).length > 0 : false;
  },
  listDocumentTypeIndexersGlobal: async (): Promise<void> => {
    set({ loadingGlobalIndexers: true });
    await new ClientHttp().getItensForSelect<Success<GlobalIndexersType>, StandardError, void>(
      "/api/v1/customer/document-types/document-type-indexes/global",
      (result: Success<GlobalIndexersType>): void => {
        set({
          listGlobalIndexers: result.body.data,
          loadingGlobalIndexers: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          loadingGlobalIndexers: false,
        });
      }
    );
  },
  setChangedFilter: (filter: keyof ChangedFilterType, boo: boolean) => {
    set((state) => ({
      changedFilter: {
        ...state.changedFilter,
        [filter]: boo,
      },
    }));
  },
}));

export default useSearchStore;
