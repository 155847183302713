import { Button, Drawer, Form, Space, Tooltip, Upload } from "antd";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UploadOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import D3PDFView from "@components/shared/D3PDF/D3PDFView";
import { useSpecialOrdersStore } from "../store";
import { ctNotification } from "@components/shared/CTNotification";

type SendSignedSpecialOrderFormProps = {
  orderCuid: string;
  fileName: string;
  isUploadOrder: boolean;
};

export const SendSignedSpecialOrderForm = ({ orderCuid, fileName, isUploadOrder }: SendSignedSpecialOrderFormProps) => {
  const {
    urlResendOrder,
    resendSpecialOrderByCuid,
    loadSpecialOrders,
    printResendOrderSpecialByCuid,
    urlPrintResendOrder,
  } = useSpecialOrdersStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]); // Adicionado estado para lista de arquivos

  useEffect(() => {
    return () => {
      if (!isUploadOrder) {
        loadSpecialOrders();
      }
    };
  }, []);

  return (
    <>
      <Form className="select-none" form={form} layout="vertical" autoComplete="off">
        <Form.Item label="Visualizar ordem enviada">
          <Tooltip
            title={`${!isUploadOrder && !uploadSuccess ? "Nenhum upload da ordem assinada foi realizado." : ""}`}
            trigger={["click", "hover"]}
          >
            <Button
              disabled={!isUploadOrder && !uploadSuccess}
              type="link"
              onClick={async () => {
                await printResendOrderSpecialByCuid(orderCuid);
                setOpen(true);
                setSize(1000);
              }}
              icon={
                <Icon
                  icon="vscode-icons:file-type-pdf2"
                  height={30}
                  width={30}
                  className="text-blue-400 transition-all duration-100 ease-in drop-shadow hover:text-blue-500"
                />
              }
            />
          </Tooltip>
        </Form.Item>
        <Form.Item name="anexo" label="Anexo" rules={[{ required: true, message: "Por favor, anexe a ordem." }]}>
          <Upload
            accept=".pdf"
            action={urlResendOrder}
            listType="picture"
            fileList={fileList} // Configura a lista de arquivos
            showUploadList={{
              showRemoveIcon: false,
              showPreviewIcon: false,
              showDownloadIcon: false,
            }}
            iconRender={() => (
              <div className="flex items-center justify-center h-full drop-shadow ">
                <Icon height={40} icon="vscode-icons:file-type-pdf2" />
              </div>
            )}
            maxCount={1}
            method="PUT"
            beforeUpload={async (file) => {
              const isPdf = file.type === "application/pdf";
              const limitFileSize = file.size / 1024 / 1024 < 50;
              if (!isPdf) {
                ctNotification({
                  title: "Atenção !",
                  content: "Somente arquivos PDF são permitidos para upload.",
                  type: "error",
                });
                return Upload.LIST_IGNORE;
              }
              if (!limitFileSize) {
                ctNotification({ title: "Atenção !", content: "O arquivo deve ser menor que 50MB.", type: "error" });
                return Upload.LIST_IGNORE;
              }
              await resendSpecialOrderByCuid(orderCuid);
              return true;
            }}
            onChange={async (info) => {
              setFileList(info.fileList); // Atualiza a lista de arquivos
              if (info.file.status === "done") {
                await printResendOrderSpecialByCuid(orderCuid);
                setUploadSuccess(true);
                ctNotification({ title: "Sucesso !", content: "O upload do arquivo foi concluído.", type: "success" });
              } else if (info.file.status === "error") {
                ctNotification({
                  title: "Atenção !",
                  content: "Ocorreu um erro ao fazer o upload do arquivo.",
                  type: "error",
                });
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Selecione a ordem especial</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Fechar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
      <Drawer
        title={fileName}
        open={open}
        width={900}
        onClose={() => {
          setOpen(false);
          setSize(600);
        }}
      >
        <D3PDFView url={urlPrintResendOrder} fileName={fileName} />
      </Drawer>
    </>
  );
};
