import { ReactElement, useMemo } from "react";
import { useAbility } from "@casl/react";
import { Button, Result } from "antd";

import { useD3SelectEntityStore } from "../Layout/D3HeaderBar/components/store";
import useAuthStore from "@provider/AuthProvider";
import { AbilityContext } from "@context/CASLContext";

type D3ProtectPageProps = {
  targetPage: ReactElement;
  isRequiredCustomerGroup: boolean;
  isRequiredCustomer: boolean;
  resource?: string;
  action?: string;
  rules?: string[];
};

const D3ProtectPage = ({
  targetPage,
  isRequiredCustomerGroup,
  isRequiredCustomer,
  resource,
  action,
  rules,
}: D3ProtectPageProps) => {
  const ability = useAbility(AbilityContext);

  const userInfo = useAuthStore.getState().getUserInfo();

  const { setIsOpen } = useD3SelectEntityStore();

  const isAllowed = useMemo(() => {
    if (rules) {
      return rules.some((rule) => {
        const [res, act] = rule.split(".");
        return ability.can(act, res);
      });
    } else if (resource && action) {
      return ability.can(action, resource);
    } else {
      return false;
    }
  }, [rules, resource, action, ability]);

  if (isRequiredCustomerGroup && (userInfo?.CustomerGroupCuid === undefined || userInfo?.CustomerGroupCuid === "")) {
    return (
      <div className="flex flex-col items-center justify-center h-full mb-1 mr-1 bg-white rounded shadow-md">
        <Result
          status="404"
          title="Selecione um cliente que pertença a um grupo de clientes"
          subTitle="O acesso a tela solicitada só é permitida com grupo de clientes selecionado."
          extra={
            <Button type="primary" onClick={() => setIsOpen(true)}>
              Selecionar grupo de clientes
            </Button>
          }
        />
      </div>
    );
  }

  if (isRequiredCustomer && (userInfo?.CustomerCuid === undefined || userInfo?.CustomerCuid === "")) {
    return (
      <div className="flex flex-col items-center justify-center h-full mb-1 mr-1 bg-white rounded shadow-md">
        <Result
          status="404"
          title="Selecione um cliente"
          subTitle="O acesso a tela solicitada só é permitida com cliente selecionado."
          extra={
            <Button type="primary" onClick={() => setIsOpen(true)}>
              Selecionar cliente
            </Button>
          }
        />
      </div>
    );
  }

  if (isAllowed) {
    return targetPage;
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-full mb-1 mr-1 bg-white rounded shadow-md">
        <Result
          className="h-full"
          status="403"
          title="Acesso negado"
          subTitle="Você não tem permissão para acessar esta tela."
        />
      </div>
    );
  }
};

export default D3ProtectPage;
