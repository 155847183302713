import { Key, useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Badge, Button, Descriptions, Pagination, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import dayjs from "dayjs";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3RepositorySituation from "@utils/D3RepositorySituation";
import { SearchPhysicalDocumentForm } from "./components/SearchTabs";
import { defaultState, usePhysicalDocumentsStore } from "./store";
import { PhysicalDocumentItemType, RowPhysicalDocumentType } from "./types";
import convertIndexerValue from "./components/criterion/convertIndexerValue";
import { useNavigate } from "react-router-dom";
import { ViewStructuredDocument } from "../work/components/structured-document/view/ViewStructuredDocument";
import EditStructuredDocumentForm from "../work/components/structured-document/edit/EditStructuredDocumenForm";
import EditIndexersForm from "../work/components/structured-document/edit-indexers";
import ViewSelected from "./components/ViewSelected";
import GenerateWorkOrder from "./components/GenerateWorkOrder";
import D3Can from "@components/shared/D3Can";

const PhysicalDocumentsView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    loadPhysicalDocument,
    loading,
    filter,
    resetState,
    resetFilter,
    setPagination,
    selectedRows,
    documentsSelected,
  } = usePhysicalDocumentsStore();
  const navigate = useNavigate();
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

  const toggleExpandAll = () => {
    if (expandedRowKeys.length !== apiResult.data.length) {
      setExpandedRowKeys(apiResult.data.map((item) => item.fs_document_id));
    } else {
      setExpandedRowKeys([]);
    }
  };

  const toggleExpand = (key: Key, expand: boolean) => {
    if (expand) {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== key));
    } else {
      setExpandedRowKeys([key, ...expandedRowKeys]);
    }
  };

  useEffect(() => {
    (async () => {
      await loadPhysicalDocument();
    })();
    return () => resetState();
  }, [loadPhysicalDocument]);

  useEffect(() => {
    if (filter.filter_document.show_index === false) {
      setExpandedRowKeys([]);
    }
  }, [filter.filter_document.show_index]);

  return (
    <div className="relative flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow">
      <Table<RowPhysicalDocumentType>
        size="small"
        rowKey="fs_document_id"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full ">
                <div className="flex items-center justify-start w-1/3 ">
                  <Badge
                    dot={Object.values(usePhysicalDocumentsStore.getState().changedFilter).some(
                      (value) => value === true
                    )}
                  >
                    <Button
                      onClick={() =>
                        openDrawer(
                          <SearchPhysicalDocumentForm
                            onCallBackResetFilter={() => {
                              resetFilter();
                            }}
                          />,
                          "left",
                          600,
                          "Parâmetros da Pesquisa"
                        )
                      }
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Badge>
                </div>
                <div className="flex items-center justify-center w-1/3 ">
                  <span className="text-xl font-semibold text-gray-600">Documentos Físico</span>
                </div>
                <div className="flex items-center justify-end w-1/3 gap-5"></div>
              </div>
            </>
          );
        }}
        rowSelection={{
          preserveSelectedRowKeys: true,
          fixed: true,
          hideSelectAll: selectedRows.length === 0,
          type: "checkbox",
          selectedRowKeys: selectedRows.map((doc) => doc.fs_document_id),
          getCheckboxProps: (record: RowPhysicalDocumentType) => {
            return {
              disabled:
                ["PUR", "MIG", "REQ"].includes(record.fs_document_status) || selectedRows.length > 0
                  ? record.fs_document_status !== filter.filter_rule.status ||
                    filter.filter_rule.department_cuid !== record.billable_department.cuid
                  : false,
            };
          },
          onChange: (selectedRowKeys: Key[], selectedRows: PhysicalDocumentItemType[]) => {
            usePhysicalDocumentsStore.setState({
              selectedRows: selectedRows,
            });
            if (selectedRowKeys.length === 1) {
              const preRule = apiResult.data.find((doc) => doc.fs_document_id === selectedRowKeys[0]);
              if (preRule) {
                usePhysicalDocumentsStore.setState((state) => ({
                  filter: {
                    ...state.filter,
                    filter_rule: {
                      ...state.filter.filter_rule,
                      department_cuid: preRule.billable_department.cuid || state.filter.filter_rule.department_cuid,
                      status: preRule.fs_document_status || state.filter.filter_rule.status,
                    },
                  },
                }));
              }
            }
          },
        }}
        footer={() => (
          <div className="flex items-center justify-between w-full gap-4 ">
            <div className="flex gap-5">
              <Button
                disabled={selectedRows.length === 0}
                icon={<Icon icon="fluent:task-list-add-20-filled" height={18} width={18} />}
                type="dashed"
                onClick={() =>
                  usePhysicalDocumentsStore.setState((state) => ({
                    documentsSelected: state.selectedRows, // posso deixa somente com selectedRows em vez de criar mais uma state
                    payload: {
                      ...state.payload,
                      doc_status: filter.filter_rule.status,
                      department_cuid: filter.filter_rule.department_cuid,
                      fs_documents: state.selectedRows.map((doc) => {
                        return {
                          fs_document_physical_tag_id: doc.fs_document_physical_tag_id,
                          description: "",
                          required_description:
                            doc.document_type_service_order_requires_description_for_document_location,
                          quantity: 1,
                        };
                      }),
                    },
                  }))
                }
              >
                Adicionar ({selectedRows.length})
              </Button>

              <Button
                disabled={documentsSelected.length === 0}
                icon={<Icon icon="ph:list-checks-bold" height={18} width={18} />}
                type="primary"
                onClick={() => openDrawer(<ViewSelected />, "right", 800, "Visualizar Documentos Selecionados")}
              >
                Visualizar ({String(documentsSelected.length)})
              </Button>

              <Button
                disabled={documentsSelected.length === 0}
                icon={<Icon icon="octicon:multi-select-16" height={16} width={16} />}
                type="primary"
                onClick={() => openDrawer(<GenerateWorkOrder />, "right", 800, "Gerador de Ordem de Serviço")}
              >
                Gerar Ordem
              </Button>

              <Button
                disabled={documentsSelected.length === 0}
                icon={<Icon icon="material-symbols:delete" height={18} width={18} />}
                type="dashed"
                danger
                onClick={async () => {
                  usePhysicalDocumentsStore.setState({
                    documentsSelected: [],
                    selectedRows: [],
                    payload: defaultState.payload,
                    serviceSelected: defaultState.serviceSelected,
                    filter: defaultState.filter,
                  });
                  await loadPhysicalDocument();
                }}
              >
                Limpar
              </Button>
            </div>
            <Pagination
              className="select-none"
              showLessItems
              showTotal={(total, range) =>
                `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
              }
              showSizeChanger={true}
              current={filter.config.pagination.page}
              onChange={setPagination}
              pageSize={filter.config.pagination.per_page}
              total={apiResult.total}
            />
          </div>
        )}
        expandable={{
          expandedRowRender: (record) => (
            <Descriptions layout="horizontal" className="max-w-max" size="small" column={1} bordered>
              <Descriptions.Item label="Descrição do documento" key="title">
                {record.fs_document_name}
              </Descriptions.Item>
              {record.indexes_values.map((index) => (
                <Descriptions.Item label={index.label} key={index.order}>
                  {convertIndexerValue(index)}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ),
          expandedRowKeys,
          columnTitle: (
            <div className="flex justify-center">
              <Tooltip
                title={`${
                  filter.filter_document.show_index
                    ? "Abrir todos"
                    : "Habilite em pesquisar / documento / exibir indexadores"
                }`}
                trigger={["click", "hover"]}
              >
                <span
                  className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                    expandedRowKeys.length === apiResult.data.length ? "expanded" : "collapsed"
                  } ${filter.filter_document.show_index ? "" : "cursor-disable-important"}`}
                  onClick={(e) => {
                    if (filter.filter_document.show_index) {
                      toggleExpandAll();
                    }
                  }}
                />
              </Tooltip>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <Tooltip
              title={`${
                filter.filter_document.show_index
                  ? ""
                  : "Desativado. Ative em: Pesquisar > Documento > Exibir Indexadores"
              }`}
              trigger={["click", "hover"]}
            >
              <span
                className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                  expanded ? "expanded" : "collapsed"
                } ${filter.filter_document.show_index ? "" : "cursor-disable-important"}`}
                onClick={(e) => {
                  if (filter.filter_document.show_index) {
                    toggleExpand(record.fs_document_id, expanded);
                    onExpand(record, e);
                  }
                }}
              />
            </Tooltip>
          ),
        }}
      >
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="fs_document_physical_tag_id"
          title="Documento"
          dataIndex="fs_document_physical_tag_id"
        />
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="repository_physical_tag_id"
          title="Repositório"
          dataIndex="repository_physical_tag_id"
        />
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="billable_department"
          title="Departamento"
          dataIndex={["billable_department", "description"]}
        />
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="global_visibility"
          title="Visibilidade global"
          dataIndex="global_visibility"
          render={(value) => (value ? "Sim" : "Não")}
        />
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="fs_document_status"
          title="Situação"
          dataIndex="fs_document_status"
          render={(value) => D3RepositorySituation(value)}
        />
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="fs_document_position"
          title="Posição"
          dataIndex="fs_document_position"
        />

        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="document_type_description"
          title="Tipo de documento"
          dataIndex="document_type_description"
        />
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="expected_purge_date"
          title="Prev. Expurgo"
          dataIndex="expected_purge_date"
          render={(value: any, record: PhysicalDocumentItemType, index: number) =>
            record.expected_purge_date ? dayjs(record.expected_purge_date).format("DD/MM/YYYY") : ""
          }
        />
        <Table.Column<RowPhysicalDocumentType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="repository_type_description"
          title="Tipo de repositório"
          dataIndex="repository_type_description"
        />
        <Table.Column<RowPhysicalDocumentType>
          key="action"
          title="Ações"
          width={140}
          render={(value, record) => {
            return (
              <>
                <Space>
                  <D3Can action="view" resource="customer_fs_document" type="hidden">
                    <Tooltip title="Visualizar documento estruturado" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <ViewStructuredDocument fsDocumentCuid={record.fs_document_cuid} />,
                            "right",
                            800,
                            "Visualizar documento estruturado"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="update" resource="customer_fs_document" type="hidden">
                    <Tooltip title="Editar documento estruturado" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-configuration"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <EditStructuredDocumentForm documentCuid={record.fs_document_cuid} />,
                            "right",
                            600,
                            "Editar documento estruturado"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="update" resource="customer_fs_document" type="hidden">
                    <Tooltip title="Editar indexadores" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-signed"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <EditIndexersForm documentCuid={record.fs_document_cuid} />,
                            "right",
                            600,
                            "Editar indexadores"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="customer_fs" type="hidden">
                    <Tooltip title="Ir para documento estruturado" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="pepicons-pop:open"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() => navigate(`/app/customer/spaces/work/${record.fs_document_cuid}`)}
                      />
                    </Tooltip>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default PhysicalDocumentsView;
