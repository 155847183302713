import { Form, Button, Spin, Input, Segmented } from "antd";
import { useCompaniesStore } from "../store";
import { useEffect } from "react";
import { UpdateSystemUserType, ViewProfileType } from "../types";
import { D3Date } from "@utils/date";
import ProfilePicture from "@components/shared/ProfilePicture/ProfilePicture";

enum UserType {
  SYS = "Usuário sistema",
  CPN = "Usuário empresa",
  CTM = "Usuário cliente",
  CGR = "Usuário grupo de cliente",
}

function TabPersonalInformation() {
  const { getProfile, loadingProfile, profile, updateProfile, loadingUpdate, isEditing, setIsEditing } =
    useCompaniesStore();

  const [form] = Form.useForm<ViewProfileType>();

  useEffect(() => {
    (async () => {
      await getProfile();
    })();
  }, []);

  useEffect(() => {
    form.setFieldsValue(
      Object.assign(profile, {
        type: UserType[profile.type as keyof typeof UserType],
        created_at: D3Date.FormatValueApiToPtBR(profile.created_at),
        updated_at: D3Date.FormatValueApiToPtBR(profile.updated_at),
      })
    );
  }, [profile]);

  return (
    <>
      <Spin spinning={loadingProfile || loadingUpdate}>
        <div className="flex items-center py-2 ml-12 w-[600px]">
          <ProfilePicture />
        </div>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          className="select-none w-[600px]"
          form={form}
          layout="horizontal"
          onFinish={async (payload: UpdateSystemUserType) => {
            const isUpdated = await updateProfile(payload);
            if (isUpdated) {
              setIsEditing();
            }
          }}
          autoComplete="off"
        >
          <Form.Item<ViewProfileType>
            label="Usuário"
            name="username"
            rules={[
              {
                required: true,
                message: "Por favor, insira o usuário.",
              },
              {
                max: 30,
                message: "O usuário deve ter menos de 30 caracteres.",
              },
              {
                validator: (_, value) =>
                  /\s/.test(value)
                    ? Promise.reject(new Error("O usuário não pode conter espaços."))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewProfileType>
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome.",
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewProfileType>
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor, insira o e-mail.",
              },
              {
                type: "email",
                message: "Por favor, insira um e-mail válido.",
              },
            ]}
          >
            <Input className="w-64" disabled={!isEditing} variant={isEditing ? "outlined" : "borderless"} />
          </Form.Item>
          <Form.Item<ViewProfileType> label="Tipo" name="type">
            <Input className="w-64" disabled={true} variant="borderless" />
          </Form.Item>
          <Form.Item<ViewProfileType> label="Admin" name="admin">
            <Segmented
              disabled
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ViewProfileType> name="created_at" label="Criado em">
            <Input disabled={true} variant="borderless" />
          </Form.Item>
          <Form.Item<ViewProfileType> name="updated_at" label="Atualizado em">
            <Input disabled={true} variant="borderless" />
          </Form.Item>

          <div className="flex items-center justify-end w-[400px]">
            <Form.Item className={isEditing ? `` : `hidden`}>
              <Button type="primary" htmlType="submit">
                atualizar
              </Button>
            </Form.Item>
            <Form.Item className={isEditing ? `hidden` : ``}>
              <Button type="primary" onClick={() => setIsEditing()}>
                Editar
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </>
  );
}

export default TabPersonalInformation;
