import { create } from "zustand";
import { CustomUploadFile, PayloadType, ResponseGetUrls } from "./types";
import { extension, mineType } from "@utils/mine";
import axios from "axios";
import { RcFile, UploadFile } from "antd/es/upload";
import { UploadChangeParam } from "antd/lib/upload";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import { ctNotification } from "@components/shared/CTNotification";

type UseUploadContextType = {
  fileList: CustomUploadFile[];
  addFiles: (files: CustomUploadFile[]) => void;
  deleteFile: (uid: string) => void;
  handleUpload: (fileCuid: string) => Promise<void>;
  renameFile: (uid: string, fileName: string) => void;
  updateFileProgress: (uid: string, progress: number) => void;
  uploadFileWithProgress: (url: string, file: CustomUploadFile) => Promise<void>;
  handleChange: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
  uploading: boolean;
  loadingFiles: boolean;
  resetFileList: () => void;
  cancelUpload: (uid: string) => void;
  payload: PayloadType;
  onFinish: (fileCuid: string) => Promise<void>;
  loadingFinish: boolean;
};

const defaultState = {
  fileList: [],

  uploading: false,

  loadingFiles: false,

  payload: {
    parent_cuid: "",
    billable_department_cuid: "",
    global_visibility: false,
    files: [],
  },

  loadingFinish: false,
};

export const useUploadContextStore = create<UseUploadContextType & { abortControllers: Map<string, AbortController> }>(
  (set, get) => ({
    ...defaultState,
    abortControllers: new Map(),
    resetFileList: () => {
      set({
        fileList: [],
      });
    },
    addFiles: (files: CustomUploadFile[]) => {
      set({
        fileList: files,
      });
    },
    deleteFile: (uid: string) => {
      get().cancelUpload(uid);
      set((state) => ({
        fileList: state.fileList.filter((file) => file.uid !== uid),
      }));
    },
    renameFile: (uid: string, fileName: string) => {
      set((state) => ({
        fileList: state.fileList.map((file) => {
          if (file.uid === uid) {
            return {
              ...file,
              fileName: fileName + extension(file.type ?? ""),
              name: fileName + extension(file.type ?? ""),
            };
          } else {
            return file;
          }
        }),
      }));
    },

    updateFileProgress: async (uid: string, progress: number) => {
      set((state) => ({
        fileList: state.fileList.map((file) => (file.uid === uid ? { ...file, progress } : file)),
      }));
    },
    uploadFileWithProgress: async (url: string, file: CustomUploadFile) => {
      const controller = new AbortController();
      get().abortControllers.set(file.uid, controller);

      try {
        await axios.put(url, file.originFileObj as RcFile, {
          signal: controller.signal,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              get().updateFileProgress(file.uid, percentComplete);
            }
          },
        });
      } catch (error: any) {
        if (axios.isCancel(error) || error.name === "CanceledError") {
          console.log(`Upload cancelado para o arquivo: ${file.uid}`);
        } else {
          throw error;
        }
      } finally {
        get().abortControllers.delete(file.uid);
      }
    },
    cancelUpload: (uid: string) => {
      const controller = get().abortControllers.get(uid);
      if (controller) {
        controller.abort();
        get().abortControllers.delete(uid);
      }
    },
    handleChange: (info: UploadChangeParam<UploadFile<any>>) => {
      set({ loadingFiles: true });
      let updatedFileList: CustomUploadFile[] = [...info.fileList];
      updatedFileList = updatedFileList.map((file) => {
        if (updatedFileList.filter((f) => f.name === file.name).length > 1) {
          file.duplicate = true;
        } else {
          file.duplicate = false;
        }
        return file as CustomUploadFile;
      });
      setTimeout(() => {
        set({
          fileList: updatedFileList,
          loadingFiles: false,
        });
      }, 1000);
    },
    handleUpload: async (fileCuid: string) => {
      set((state) => ({
        uploading: true,
        payload: {
          ...state.payload,
          parent_cuid: fileCuid,
          files: get().fileList.map((file) => {
            return {
              extension: mineType(file.type!),
              name: file.name,
              file: {
                uid: file.uid,
              },
              size: file.size,
              uid: file.uid,
            };
          }),
        },
      }));
      try {
        await new ClientHttp().post<any, Success<ResponseGetUrls>, BadRequest | StandardError>(
          "http://localhost:8080/api/v1/customer/fs/file",
          JSON.stringify(get().payload),
          async (result: Success<ResponseGetUrls>) => {
            const uploadPromises = get().fileList.map((file, index) => {
              const item = result.body.data.find((resp) => resp.uid === file.uid);

              set((state) => {
                const newFileList = [...state.fileList];
                newFileList[index] = {
                  ...file,
                  url: item?.url,
                  fs_cuid: item?.fs_cuid,
                  children: item?.children,
                };
                return { fileList: newFileList };
              });

              if (item && item.url) {
                return get().uploadFileWithProgress(item.url, file);
              }
              return Promise.reject(`URL não encontrada para o arquivo com UID: ${file.uid}`);
            });

            await Promise.all(uploadPromises);
            ctNotification({
              title: "Sucesso !",
              content: "Upload finalizado com sucesso!",
              type: "success",
              withoutFooter: true,
            });
          }
        );
      } catch (error) {
        ctNotification({ title: "Atenção !", content: "Erro no upload.", type: "error" });
      } finally {
        set({ uploading: false });
      }
    },
    onFinish: async (fileCuid: string) => {
      set({ loadingFinish: true });
      await get().handleUpload(fileCuid);
      //openDrawer(<ListView />, "right", 800, "Gerenciamento de Upload");
      set({ loadingFinish: false });
    },
  })
);
