import dayjs from "dayjs";
import { IndexesValueView } from "../../types";

function convertIndexerValue(item: IndexesValueView) {
  const maskCPF = (value: any) => {
    if (!value) return value;
    return value
      .replace(/\D/g, "") // Remove todos os caracteres não numéricos
      .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o primeiro ponto
      .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Adiciona o traço
  };

  const maskCNPJ = (value: any) => {
    if (!value) return value;
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  };

  const { type, value } = item;
  switch (type) {
    case "EMA":
      return value ? value : "Não preenchido";
    case "CAC":
      return value ? (value.length === 11 ? maskCPF(value) : maskCNPJ(value)) : "Não preenchido";
    case "DAT":
      return value ? dayjs(value).format("DD/MM/YYYY") : "Não preenchido";
    case "DTI":
      const list = value.split("|");
      return list
        ? list.length > 1
          ? `${dayjs(list[0]).format("DD/MM/YYYY")} ao ${dayjs(list[1]).format("DD/MM/YYYY")}`
          : dayjs(list[0]).format("DD/MM/YYYY")
        : "Não preenchido";
    case "BOO":
      return value ? "Sim" : "Não";
    case "NMI":
      return value ? value.replace("|", " ao ") : "Não preenchido";
    case "TXT":
      return value ? value : "Não preenchido";
    case "INT":
      return value ? value : "Não preenchido";
    case "IFT":
      return value ? value : "Não preenchido";
    case "SEL":
      return value ? value : "Não preenchido";
    default:
      return null;
  }
}

export default convertIndexerValue;
