import D3Can from "@components/shared/D3Can";
import D3CustomerRequired from "@components/shared/D3CustomerRequired";
import D3Status from "@components/shared/D3Status";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { Badge, Button, Pagination, Popconfirm, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { CreateDepartmentForm } from "./components/CreateDepartmentForm";
import { SearchDepartmentForm } from "./components/SearchDepartmentForm";
import { UpdateDepartmentForm } from "./components/UpdateDepartmentForm";
import { ViewDepartmentForm } from "./components/ViewDepartmentForm";
import { useDepartmentsStore } from "./store";
import { RowDepartmentType } from "./types";

const DepartmentsView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    selectedRows,
    setPagination,
    setSort,
    loadDepartments,
    lockDepartment,
    unLockDepartment,
    onMultiSelectedRowChange,
    resetFilter,
    resetState,
  } = useDepartmentsStore();
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    (async () => {
      await loadDepartments();
    })();
    return () => {
      resetState();
    };
  }, [loadDepartments]);

  return (
    <D3CustomerRequired>
      <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
        <Table<RowDepartmentType>
          size="small"
          rowKey="cuid"
          dataSource={apiResult.data as RowDepartmentType[]}
          bordered
          className="responsive-table"
          pagination={false}
          loading={loading}
          title={() => {
            return (
              <>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center justify-start">
                    <Popover
                      open={openSearch}
                      placement="rightTop"
                      title="Pesquisar Departamentos"
                      content={
                        <SearchDepartmentForm
                          onCallBackFilter={() => setOpenSearch(false)}
                          onCallBackResetFilter={() => {
                            setOpenSearch(false);
                            resetFilter();
                          }}
                        />
                      }
                      trigger="click"
                      onOpenChange={(open) => setOpenSearch(open)}
                    >
                      <Button
                        onClick={() => setOpenSearch(!openSearch)}
                        type="primary"
                        size="middle"
                        icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                      >
                        Pesquisar
                      </Button>
                    </Popover>
                  </div>
                  <div className="flex items-center justify-center">
                    <span className="text-xl font-semibold text-gray-600">Departamentos</span>
                  </div>
                  <div className="flex items-center justify-end">
                    <D3Can action="create" resource="customer_department">
                      <Button
                        type="primary"
                        size="middle"
                        icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                        onClick={() => openDrawer(<CreateDepartmentForm />, "right", 600, "Cadastrar Departamento")}
                      >
                        Novo Departamento
                      </Button>
                    </D3Can>
                  </div>
                </div>
              </>
            );
          }}
          rowSelection={{
            preserveSelectedRowKeys: true,
            hideSelectAll: false,
            type: "checkbox",
            selectedRowKeys: selectedRows.cuids,
            onChange: (_, selectRows: RowDepartmentType[]) => {
              onMultiSelectedRowChange(selectRows);
            },
          }}
          onChange={(a, b, sorter: any) => {
            if (sorter) {
              setSort({
                sort_by: sorter.field,
                sort_direction: sorter.order,
              });
            }
          }}
          footer={() => {
            return (
              <>
                <div className="flex gap-4">
                  <D3Can action="lock" resource="customer_department">
                    <Badge
                      count={
                        selectedRows.cuids.length > 0 && selectedRows.active === true
                          ? selectedRows.cuids.length
                          : undefined
                      }
                    >
                      <Popconfirm
                        disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
                        title={`Atenção!`}
                        description={`Confirma bloqueio ${selectedRows.cuids.length} ${
                          selectedRows.cuids.length > 1 ? "itens" : "item"
                        }?`}
                        onConfirm={async () => {
                          await lockDepartment(selectedRows.cuids);
                        }}
                        okText="Sim"
                        cancelText="Não"
                        placement="top"
                      >
                        <Button
                          type="primary"
                          danger
                          disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
                          size="middle"
                          icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
                        >
                          Bloquear
                        </Button>
                      </Popconfirm>
                    </Badge>
                  </D3Can>
                  <D3Can action="unlock" resource="customer_department">
                    <Badge
                      color="green"
                      count={
                        selectedRows.cuids.length > 0 && selectedRows.active === false
                          ? selectedRows.cuids.length
                          : undefined
                      }
                    >
                      <Popconfirm
                        disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
                        title={`Atenção!`}
                        description={`Confirma desbloqueio ${selectedRows.cuids.length} ${
                          selectedRows.cuids.length > 1 ? "itens" : "item"
                        }?`}
                        onConfirm={async () => {
                          await unLockDepartment(selectedRows.cuids);
                        }}
                        okText="Sim"
                        cancelText="Não"
                        placement="top"
                      >
                        <Button
                          className=""
                          disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
                          size="middle"
                          icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
                        >
                          Desbloquear
                        </Button>
                      </Popconfirm>
                    </Badge>
                  </D3Can>
                </div>
                <Space>
                  <Pagination
                    showLessItems
                    showTotal={(total, range) =>
                      `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                    }
                    showSizeChanger={true}
                    current={pagination.page}
                    onChange={setPagination}
                    pageSize={pagination.pageSize}
                    total={apiResult.total}
                  />
                </Space>
              </>
            );
          }}
        >
          <Table.Column<RowDepartmentType>
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="description"
            title="Descrição"
            dataIndex="description"
            sorter
          />
          <Table.Column<RowDepartmentType>
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="abbreviation"
            title="Abreviação"
            dataIndex="abbreviation"
            sorter
          />

          <Table.Column<RowDepartmentType>
            showSorterTooltip={false}
            key="active"
            title="Status"
            dataIndex="active"
            sorter
            render={(value, departments) => <D3Status status={departments.active} />}
          />
          <Table.Column<RowDepartmentType>
            key="action"
            title="Ações"
            width={140}
            render={(value, departments, index) => {
              return (
                <>
                  <Space>
                    <D3Can action="update" resource="customer_department">
                      <Tooltip title="Editar" trigger={["click", "hover"]}>
                        <Button
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="iconamoon:edit-fill"
                              height={18}
                              width={18}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <UpdateDepartmentForm cuid={departments.cuid} />,
                              "right",
                              600,
                              "Editar Departamento"
                            )
                          }
                        />
                      </Tooltip>
                    </D3Can>
                    <D3Can action="view" resource="customer_department">
                      <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                        <Button
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="carbon:document-view"
                              height={18}
                              width={18}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                          onClick={() =>
                            openDrawer(
                              <ViewDepartmentForm cuid={departments.cuid} />,
                              "right",
                              600,
                              "Visualizar Departamento"
                            )
                          }
                        />
                      </Tooltip>
                    </D3Can>

                    <ToggleLockAction
                      rules="customer_department.lock"
                      description="Confirma bloqueio?"
                      title="Atenção!"
                      isVisible={departments.active}
                      tooltip="Bloquear"
                      paramsToAction={[departments.cuid]}
                      action={async (params: string[]) => {
                        await lockDepartment(params);
                      }}
                    />

                    <ToggleLockAction
                      rules="customer_department.unlock"
                      title="Atenção!"
                      description="Confirma desbloqueio?"
                      isVisible={!departments.active}
                      tooltip="Desbloquear"
                      paramsToAction={[departments.cuid]}
                      action={async (params: string[]) => {
                        await unLockDepartment(params);
                      }}
                    />
                  </Space>
                </>
              );
            }}
          />
        </Table>
      </div>
    </D3CustomerRequired>
  );
};

export default DepartmentsView;
