import { ctNotification } from "@components/shared/CTNotification";

const IsValidMultiSelectedRow = <T extends { active: boolean; cuid: string }>(
  selectedRows: T[],
  get: any,
  set: any
): void => {
  if (selectedRows.length === 0) {
    get().resetSelectedRows();
  } else {
    let activeItens = selectedRows.filter((item: T) => item.active);
    let inactiveItens = selectedRows.filter((item: T) => !item.active);
    if (activeItens.length > 0 && inactiveItens.length > 0) {
      get().resetSelectedRows();
      ctNotification({
        title: "Atenção !",
        content: "Não é permitido selecionar itens bloqueados e desbloqueados juntos.",
        type: "info",
        withoutFooter: true,
      });
    } else if (activeItens.length > 0) {
      set({
        selectedRows: {
          cuids: activeItens.map((item) => item.cuid),
          active: true,
        },
      });
    } else if (inactiveItens.length > 0) {
      set({
        selectedRows: {
          cuids: inactiveItens.map((item) => item.cuid),
          active: false,
        },
      });
    }
  }
};

export default IsValidMultiSelectedRow;
