import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useReportsStore } from "@views/reports/store";
import { Button, Tabs } from "antd";
import { useContext, useEffect } from "react";
import TabData from "./tabs/TabData";
import TabParams from "./tabs/TabParams";
import TabSessions from "./tabs/TabSessions";
import { UpdateReportType } from "@views/reports/types";
import { ctNotification } from "@components/shared/CTNotification";

type UpdateReportTabsProps = {
  reportCuid: string;
};

export const UpdateReportTabs = ({ reportCuid }: UpdateReportTabsProps) => {
  const { payloadUpdate, viewReport, view, updateReport, loadingUpdate } = useReportsStore();
  const { onClose, setSize } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await viewReport(reportCuid);
    })();
    return () =>
      useReportsStore.setState({
        payloadUpdate: {} as UpdateReportType,
      });
  }, [reportCuid]);

  useEffect(() => {
    if (view.cuid) {
      useReportsStore.setState({
        payloadUpdate: {
          context: view.context,
          customer_cuid: view.customer ? view.customer.cuid : "",
          customer_group_cuid: view.customer_group ? view.customer_group.cuid : "",
          description: view.description,
          report_params: view.report_params,
          report_sessions: view.report_sessions,
          title: view.title,
        },
      });
    }
  }, [view]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden ">
      <div className="flex-1">
        <Tabs
          onTabClick={(tab) => {
            if (tab === "1") {
              setSize(700);
            } else if (tab === "2") {
              setSize(900);
            } else {
              setSize(700);
            }
          }}
          className="flex-1 w-full select-none"
          items={[
            {
              key: "1",
              label: (
                <span className="flex items-center justify-center gap-2">
                  <Icon height={18} icon="ri:profile-line" />
                  Dados
                </span>
              ),
              children: <TabData />,
            },
            {
              key: "2",
              label: (
                <span className="flex items-center justify-center gap-2">
                  <Icon height={18} icon="carbon:parameter" />
                  Parâmetros
                </span>
              ),
              children: <TabParams />,
            },
            {
              key: "3",
              label: (
                <span className="flex items-center justify-center gap-2">
                  <Icon height={18} icon="mdi:database-plus" />
                  Sessões
                </span>
              ),
              children: <TabSessions />,
            },
          ]}
        />
      </div>

      <div className="flex gap-3 border-t-[1px] border-gray-100  items-center justify-end px-2 min-h-12 ">
        <Button
          disabled={loadingUpdate}
          type="default"
          danger
          onClick={() => {
            onClose();
          }}
        >
          Fechar
        </Button>
        <Button
          loading={loadingUpdate}
          type="primary"
          onClick={async () => {
            if (
              payloadUpdate.title === "" ||
              payloadUpdate.context === "" ||
              payloadUpdate.context === undefined ||
              payloadUpdate.description === ""
            ) {
              ctNotification({
                title: "Atenção !",
                content: "Por favor, preencha os campos obrigatórios na aba Dados.",
                type: "info",
              });
            } else if (payloadUpdate.report_sessions.length === 0) {
              ctNotification({
                title: "Atenção !",
                content: "Por favor, é necessário adicionar no mínimo uma sessão.",
                type: "info",
              });
            } else {
              const isRegistered = await updateReport(reportCuid);
              if (isRegistered) {
                onClose();
              }
            }
          }}
        >
          Atualizar
        </Button>
      </div>
    </div>
  );
};
