import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, DatePicker, Form, Input, Segmented, Select, Space } from "antd";
import { useContext, useEffect } from "react";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { UpdateStructuredDocumentType, ViewStructuredDocumentType } from "../types";
import useStructuredDocumentStore from "../store";
import SpinLoading from "@components/shared/SpinLoading";
import StatusSelect from "@components/shared/StatusSelect";

type EditStructuredDocumentFormProps = {
  documentCuid: string;
};

function EditStructuredDocumentForm({ documentCuid }: EditStructuredDocumentFormProps) {
  const {
    viewStructuredDocument,
    view,
    loadingView,
    updateStructuredDocument,
    loadingUpdate,
    simpleListDepartmentsCustomer,
    loadingDepartments,
    listDepartmentsCustomer,
  } = useStructuredDocumentStore();
  const [form] = Form.useForm<UpdateStructuredDocumentType>();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await viewStructuredDocument(documentCuid);
    })();
    return () => useStructuredDocumentStore.setState({ view: {} as ViewStructuredDocumentType });
  }, []);

  useEffect(() => {
    if (view.cuid) {
      form.setFieldsValue({
        billable_department_cuid: view.billable_department.cuid,
        can_insert_in_stored_repository: false,
        description: view.name,
        document_physical_tag_id: view.document_physical_tag_id,
        original_date: view.original_date,
        expected_purge_date: view.expected_purge_date,
        global_visibility: view.global_visibility,
        is_permanent_storage: view.is_permanent_storage,
        physical_or_digital: view.is_physical ? "P" : "D",
        repository_physical_tag_id: view.repository_physical_tag_id,
      });
    }
    if (!view.is_physical) {
      (async () => {
        await simpleListDepartmentsCustomer();
      })();
    }
  }, [view]);

  const NewRepositoryPhysicalTagId = Form.useWatch("repository_physical_tag_id", form);

  const isPermanentStorage = Form.useWatch("is_permanent_storage", form);
  const originalDate = Form.useWatch("original_date", form);

  return loadingView || loadingDepartments ? (
    <SpinLoading />
  ) : (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (payload: UpdateStructuredDocumentType) => {
        const result = await updateStructuredDocument(documentCuid, payload);
        if (result) {
          onClose();
        }
      }}
      onValuesChange={(changedValues: any, values: UpdateStructuredDocumentType) => {
        if (changedValues.original_date || changedValues.is_permanent_storage === false) {
          if (view.document_type.temporality_year && values.is_permanent_storage === false && values.original_date) {
            const purgeDate =
              view.document_type.temporality_year > 0
                ? dayjs(values.original_date).add(view.document_type.temporality_year, "year").format("YYYY-MM-DD")
                : "";
            form.setFieldValue("expected_purge_date", purgeDate);
          }
        }
        if (changedValues.original_date === "" || changedValues.is_permanent_storage) {
          form.setFieldValue("expected_purge_date", "");
        }
      }}
    >
      <Form.Item<UpdateStructuredDocumentType>
        hidden={!view.is_physical}
        name="repository_physical_tag_id"
        label="Etiqueta do repositório"
        rules={[
          {
            required: view.is_physical,
            message: "Por favor, insira o código do repositório.",
          },
          {
            min: 12,
            max: 12,
            message: "O código do repositório deve incluir uma sequência de 12 dígitos numéricos.",
          },
          {
            validator: (_, value) => {
              if (value && value !== "") {
                return /^\d+$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(new Error("O código do repositório deve ser composta exclusivamente por números."));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input minLength={12} maxLength={12} showCount />
      </Form.Item>

      <Form.Item<UpdateStructuredDocumentType>
        hidden={!view.is_physical || NewRepositoryPhysicalTagId === view.repository_physical_tag_id}
        label="Inserção em repositório armazenado?"
        name="can_insert_in_stored_repository"
      >
        <Segmented
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          size="small"
          className="select-none"
        />
      </Form.Item>

      <Form.Item<UpdateStructuredDocumentType>
        hidden={!view.is_physical}
        name="document_physical_tag_id"
        label="Etiqueta do documento"
        rules={[
          {
            required: view.is_physical,
            message: "Por favor, insira o etiqueta do documento.",
          },
          {
            min: 12,
            max: 12,
            message: "A etiqueta do documento deve incluir uma sequência de 12 dígitos numéricos.",
          },
          {
            validator: (_, value) => {
              if (value && value !== "") {
                return /^\d+$/.test(value)
                  ? Promise.resolve()
                  : Promise.reject(new Error("A etiqueta do documento deve ser composta exclusivamente por números."));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input minLength={12} maxLength={12} showCount />
      </Form.Item>

      <Form.Item<UpdateStructuredDocumentType>
        hidden={view.is_physical}
        label="Departamento"
        name="billable_department_cuid"
      >
        <Select
          listItemHeight={10}
          allowClear
          removeIcon={
            <Icon
              icon="iconamoon:close-duotone"
              className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
            />
          }
          menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
          optionLabelProp="dataLabel"
          showSearch
          maxTagCount="responsive"
          size="middle"
          className="w-full truncate select-none"
          optionFilterProp="children"
          filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
        >
          {listDepartmentsCustomer.map(({ active, abbreviation, cuid, description }) => (
            <Select.Option
              key={cuid}
              value={cuid}
              dataFilter={`${abbreviation} - ${description}`}
              dataLabel={`${abbreviation} - ${description}`}
            >
              <div className="flex items-center justify-between ">
                <div className="flex flex-col truncate">
                  <span className="truncate">{`${abbreviation} - ${description}`}</span>
                </div>
                <StatusSelect status={active} />
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item<UpdateStructuredDocumentType>
        hidden={view.is_physical}
        label="Visibilidade global?"
        name="global_visibility"
      >
        <Segmented
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          size="small"
          className="select-none"
        />
      </Form.Item>

      <Form.Item<UpdateStructuredDocumentType> label="Descrição" name="description">
        <Input />
      </Form.Item>

      <div className="flex gap-3 mb-6">
        <Form.Item<UpdateStructuredDocumentType>
          getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          className="p-0 m-0 w-52"
          label="Data original"
          name="original_date"
        >
          <DatePicker
            format={{
              format: "DD/MM/YYYY",
              type: "mask",
            }}
          />
        </Form.Item>

        <Form.Item<UpdateStructuredDocumentType>
          hidden={view.document_type?.temporality_permanent_guard || isPermanentStorage}
          getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          className="p-0 m-0 w-52"
          label="Data de expurgo"
          name="expected_purge_date"
          rules={[
            {
              required: !isPermanentStorage && view.document_type?.is_temporality_mandatory,
            },
          ]}
        >
          <DatePicker
            minDate={originalDate ? dayjs(originalDate) : undefined}
            format={{
              format: "DD/MM/YYYY",
              type: "mask",
            }}
          />
        </Form.Item>
      </div>

      <Form.Item<UpdateStructuredDocumentType>
        label="Armazenamento permanente?"
        name="is_permanent_storage"
        getValueProps={(value) => {
          return { value: value ? value : false };
        }}
      >
        <Segmented
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          disabled={view.document_type?.temporality_permanent_guard}
          size="small"
          className="select-none"
        />
      </Form.Item>

      <Form.Item<UpdateStructuredDocumentType> hidden label="Documento físico?" name="physical_or_digital">
        <Select
          disabled
          options={[
            { label: "Digital", value: "D" },
            { label: "Físico", value: "P" },
          ]}
        />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingUpdate} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>

            <Button loading={loadingUpdate} type="primary" htmlType="submit">
              Atualizar
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default EditStructuredDocumentForm;
