import { Form, Input, Segmented } from "antd";
import { useEffect } from "react";
import { D3Date } from "@utils/date";
import { ViewCompanyUserType } from "../../types";

type TabFormProps = {
  data: ViewCompanyUserType | undefined;
};

function TabData({ data }: TabFormProps) {
  const [form] = Form.useForm<ViewCompanyUserType>();

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue({
        ...data,
        created_at: D3Date.FormatValueApiToPtBR(data.created_at),
        updated_at: D3Date.FormatValueApiToPtBR(data.updated_at),
      });
    }
  }, [data, form]);

  return (
    <>
      <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
        <Form.Item<ViewCompanyUserType> label="Nome" name="name">
          <Input disabled />
        </Form.Item>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item<ViewCompanyUserType> label="Usuário" name="username">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewCompanyUserType> label="Login" name="login">
            <Input disabled />
          </Form.Item>
        </div>
        <Form.Item<ViewCompanyUserType> label="E-mail" name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item<ViewCompanyUserType> name="change_password_next_login" label="Trocar senha no próximo login?">
          <Segmented
            disabled
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>

        <Form.Item<ViewCompanyUserType> name="active" label="Status">
          <Segmented
            disabled
            options={[
              { label: "Desbloqueado", value: true },
              { label: "Bloqueado", value: false },
            ]}
          />
        </Form.Item>
        <Form.Item<ViewCompanyUserType> label="Criado em" name="created_at">
          <Input disabled />
        </Form.Item>
        <Form.Item<ViewCompanyUserType> label="Atualizado em" name="updated_at">
          <Input disabled />
        </Form.Item>
      </Form>
    </>
  );
}

export default TabData;
