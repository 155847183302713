import React, { useState } from "react";
import { Table, Pagination } from "antd";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

const TablePreserveSelectedRow: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });

  const data: DataType[] = Array.from({ length: 20 }, (_, i) => ({
    key: `${i + 1}`,
    name: `User ${i + 1}`,
    age: 20 + (i % 10),
    address: `Address ${i + 1}`,
  }));

  // Filtrar dados com base na página atual e no tamanho da página
  const paginatedData = data.slice((pagination.page - 1) * pagination.pageSize, pagination.page * pagination.pageSize);

  return (
    <div className="flex flex-col w-full h-full p-2 mb-1 mr-1 overflow-hidden bg-white rounded shadow">
      <Table
        size="small"
        rowKey="key"
        bordered
        className="responsive-table"
        rowSelection={{
          selectedRowKeys,
          preserveSelectedRowKeys: true,
          onChange: (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
        }}
        footer={() => (
          <div className="flex items-center justify-between w-full">
            <span>Selecionados: {selectedRowKeys.length}</span>
            <Pagination
              showLessItems
              showTotal={(total, range) =>
                `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
              }
              showSizeChanger
              current={pagination.page}
              onChange={(page, pageSize) => setPagination({ page, pageSize })}
              pageSize={pagination.pageSize}
              total={data.length}
            />
          </div>
        )}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Age",
            dataIndex: "age",
            key: "age",
          },
          {
            title: "Address",
            dataIndex: "address",
            key: "address",
          },
        ]}
        dataSource={paginatedData}
        pagination={false}
      />
    </div>
  );
};

export default TablePreserveSelectedRow;
