import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Tabs, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import SearchCriterionForm from "./SearchCriterionForm";
import SearchDocumentForm from "./SearchDocumentForm";
import SearchRepositoryForm from "./SearchRepositoryForm";
import SearchSortByForm from "./SearchSortByForm ";
import { SearchType } from "./types";
import SearchMarkersForm from "./SearchMarkersForm";
import { Icon } from "@iconify/react";
import useSearchStore from "./store";
import useNavigateStore from "../../store";
import { useNavigate } from "react-router-dom";
import SearchGlobalForm from "./SearchGlobalForm";

type SearchOperationFormProps = {
  onCallBackResetFilter: () => void;
};

export const SearchNavigateForm = ({ onCallBackResetFilter }: SearchOperationFormProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<SearchType>();
  const { filter, setFilter, resetPartFilter, diffFilter, setChangedFilter, changedFilter } = useSearchStore();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (() => {
      form.setFieldsValue(filter);
    })();
  }, [form, filter]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden ">
      <Form
        className="flex h-full overflow-hidden grow "
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (diffFilter("", values)) {
            setFilter(values);
            useNavigateStore.setState({ isFilter: true });
            onClose();
          } else {
            onCallBackResetFilter();
            useNavigateStore.setState({ isFilter: false });
            navigate("/app/customer/spaces/work");
            onClose();
          }
        }}
        autoComplete="off"
        onValuesChange={(changedValues: any, values: SearchType) => {
          const key = Object.keys(changedValues)[0] as keyof SearchType;
          setChangedFilter(key, true);
        }}
      >
        <Tabs
          className="w-full select-none tab-filter-work"
          items={[
            {
              key: "filter_document_type",
              disabled: changedFilter.filter_global_index,
              label: (
                <Tooltip
                  title={
                    changedFilter.filter_global_index
                      ? "Não é permitido aplicar simultaneamente filtros de Critérios e Critérios Globais."
                      : ""
                  }
                  trigger={["click", "hover"]}
                >
                  <div className="items-center">
                    <div className="flex items-center justify-start ">
                      Critérios
                      {changedFilter.filter_document_type ? (
                        <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                          <Button
                            className=" max-h-[22px]"
                            danger
                            type="link"
                            icon={<Icon icon="mdi:filter-off" />}
                            onClick={() => resetPartFilter("filter_document_type" as keyof SearchType)}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                </Tooltip>
              ),
              children: <SearchCriterionForm form={form} />,
            },
            {
              key: "filter_document",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Documentos
                    {changedFilter.filter_document ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_document" as keyof SearchType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchDocumentForm form={form} />,
            },
            {
              key: "filter_repository",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Repositórios
                    {changedFilter.filter_repository ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_repository" as keyof SearchType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchRepositoryForm form={form} />,
            },
            {
              key: "filter_marker",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Marcadores
                    {changedFilter.filter_marker ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_marker" as keyof SearchType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchMarkersForm />,
            },

            {
              key: "filter_global_index",
              disabled: changedFilter.filter_document_type,
              label: (
                <Tooltip
                  title={
                    changedFilter.filter_document_type
                      ? "Não é permitido aplicar simultaneamente filtros de Critérios e Critérios Globais."
                      : ""
                  }
                  trigger={["click", "hover"]}
                >
                  <div className="items-center">
                    <div className="flex items-center justify-start ">
                      Critérios Globais
                      {changedFilter.filter_global_index ? (
                        <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                          <Button
                            className=" max-h-[22px]"
                            danger
                            type="link"
                            icon={<Icon icon="mdi:filter-off" />}
                            onClick={() => resetPartFilter("filter_global_index" as keyof SearchType)}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                </Tooltip>
              ),
              children: <SearchGlobalForm form={form} />,
            },
            {
              key: "config",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Ordenação
                    {changedFilter.config ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("config" as keyof SearchType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchSortByForm />,
            },
          ]}
        />
      </Form>
      <div className="flex gap-3 border-t-[1px] border-gray-100  items-center justify-end px-2 min-h-12 ">
        <Button
          type="default"
          danger
          onClick={() => {
            onCallBackResetFilter();
            navigate("/app/customer/spaces/work");
            onClose();
          }}
        >
          Limpar Filtro
        </Button>
        <Button disabled={false} type="primary" onClick={() => form.submit()}>
          Filtrar
        </Button>
      </div>
    </div>
  );
};
