import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { D3Money } from "@utils/money";
import { Badge, Button, Pagination, Popconfirm, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { CreateEventForm } from "./components/CreateEventForm";
import { SearchEventForm } from "./components/SearchEventForm";
import { UpdateEventForm } from "./components/UpdateEventForm";
import { ViewEventForm } from "./components/ViewEventForm";
import { useEventsStore } from "./store";
import { RowEventType } from "./types";

const EventsView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    selectedRows,
    setPagination,
    setSort,
    loadEvents,
    resetFilter,
    resetState,
    deleteEvent,
  } = useEventsStore();
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    (async () => {
      await loadEvents();
    })();
    return () => resetState();
  }, [loadEvents]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow">
      <Table<RowEventType>
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Eventos"
                    content={
                      <SearchEventForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Eventos</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="billing">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateEventForm />, "right", 600, "Cadastrar Evento")}
                    >
                      Novo Evento
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
        onChange={(a, b, sorter: any) => {
          if (sorter) {
            setSort({
              sort_by: sorter.field,
              sort_direction: sorter.order,
            });
          }
        }}
        footer={() => {
          return (
            <>
              <div className="flex gap-4">
                <D3Can action="lock" resource="billing">
                  <Badge count={selectedRows.cuids.length > 0 ? selectedRows.cuids.length : undefined}>
                    <Popconfirm
                      disabled={!(selectedRows.cuids.length > 0)}
                      title={`Atenção!`}
                      description={`Confirma exclusão de  ${selectedRows.cuids.length} ${
                        selectedRows.cuids.length > 1 ? "eventos" : "evento"
                      }?`}
                      onConfirm={() => {}}
                      okText="Sim"
                      cancelText="Não"
                      placement="top"
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={!(selectedRows.cuids.length > 0)}
                        size="middle"
                        icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
                      >
                        Bloquear
                      </Button>
                    </Popconfirm>
                  </Badge>
                </D3Can>
              </div>

              <Space>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                  }
                  showSizeChanger={true}
                  current={pagination.page}
                  onChange={setPagination}
                  pageSize={pagination.pageSize}
                  total={apiResult.total}
                />
              </Space>
            </>
          );
        }}
      >
        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="customer_name"
          title="Cliente"
          dataIndex="customer_name"
        />

        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="department_description"
          title="Departamento"
          dataIndex="department_description"
        />
        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="invoice_id"
          title="Nota Fiscal"
          dataIndex="invoice_id"
        />
        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="description"
          title="Descrição"
          dataIndex="description"
        />

        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="quantity"
          title="Quantidade"
          dataIndex="quantity"
        />
        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="unit_value"
          title="Valor unitário"
          dataIndex="unit_value"
          render={(value) => D3Money.FormatValueApiToPtBR(value)}
        />
        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="value"
          title="Valor total"
          dataIndex="value"
          render={(value) => D3Money.FormatValueApiToPtBR(value)}
        />
        <Table.Column<RowEventType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="event_date"
          title="Data do evento"
          dataIndex="event_date"
          render={(value) => dayjs(value).format("DD/MM/YYYY")}
          sorter
        />
        <Table.Column<RowEventType>
          key="action"
          title="Ações"
          width={130}
          render={(value, event) => {
            return (
              <>
                <Space>
                  <D3Can action="update" resource="billing">
                    <Tooltip
                      title={event.invoice_id ? "Impossível editar, evento já faturado" : "Editar"}
                      trigger={["click", "hover"]}
                    >
                      <Button
                        disabled={event.invoice_id ? true : false}
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() => openDrawer(<UpdateEventForm cuid={event.cuid} />, "right", 600, "Editar Evento")}
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="company_billing">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<ViewEventForm cuid={event.cuid} />, "right", 700, "Visualizar Evento")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="delete" resource="billing">
                    <Popconfirm
                      title="Atenção!"
                      description="Confirmar exclusão do evento?"
                      onConfirm={() => deleteEvent(event.cuid)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Tooltip
                        title={event.invoice_id ? "Impossível excluir, evento já faturado" : "Excluir"}
                        trigger={["click", "hover"]}
                      >
                        <Button
                          disabled={event.invoice_id ? true : false}
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="material-symbols:delete"
                              height={18}
                              width={18}
                              className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default EventsView;
