import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Segmented, Space, Spin } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import showMessage from "@utils/showMessage";
import { useExtensionsStore } from "../store";
import { ViewExtension } from "../types";
import { UpdateExtensionForm } from "./UpdateExtensionForm";

type ViewMaterialFormProps = {
  id: number;
};

export const ViewExtensionForm = ({ id }: ViewMaterialFormProps) => {
  const { getExtension } = useExtensionsStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewExtension>();
  const { onClose } = useContext(D3DrawerContext);
  useEffect(() => {
    (async () => {
      const result = await getExtension(id);
      if (result.status === "success") {
        const viewExtension = result.body as ViewExtension;
        form.setFieldsValue(viewExtension);
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [id, form, getExtension]);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
          <Form.Item<ViewExtension> name="description" label="Descrição">
            <Input />
          </Form.Item>
          <Form.Item<ViewExtension> name="key" label="Extensão">
            <Input />
          </Form.Item>

          <div className="flex flex-row justify-between">
            <Form.Item<ViewExtension> name="extract_content" label="Extrair conteúdo?">
              <Segmented
                disabled
                options={[
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              />
            </Form.Item>
            <Form.Item<ViewExtension> name="extract_metadata" label="Extrair metadados?">
              <Segmented
                disabled
                options={[
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              />
            </Form.Item>
            <Form.Item<ViewExtension> name="ocr" label="Processar OCR?">
              <Segmented
                disabled
                options={[
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              />
            </Form.Item>
          </div>

          <Form.Item<ViewExtension> name="icon" label="Ícone">
            <Icon icon={form.getFieldValue("icon")} height={50} className="text-gray-700" />
          </Form.Item>

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={false} type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="manage" resource="all">
                  <Button
                    disabled={false}
                    type="primary"
                    onClick={() => openDrawer(<UpdateExtensionForm id={id} />, "right", 600, "Editar Extensão")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
