import { Button, Form, Input, InputNumber, Select, Space, Spin } from "antd";
import { useRepositoryTypesStore } from "../store";
import { useContext, useEffect, useState } from "react";
import { CreateRepositoryTypeType, SelectMaterialItemType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";
import StatusSelect from "@components/shared/StatusSelect";
import Selector from "@components/shared/Selector";

export const CreateRepositoryTypeForm = () => {
  const { createRepositoryType, getMaterialForSelect } = useRepositoryTypesStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateRepositoryTypeType>();

  const [materials, setMaterials] = useState<SelectMaterialItemType[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setMaterials(await getMaterialForSelect());
      setLoading(false);
    })();
  }, [getMaterialForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateRepositoryTypeType) => {
            setLoading(true);
            const result = await createRepositoryType(payload);
            if (result.status === "success") {
              showMessage(result, "Tipo de repositório cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateRepositoryTypeType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira descrição." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<CreateRepositoryTypeType>
            name="material_cuid"
            label="Material"
            rules={[{ required: true, message: "Por favor, selecione material." }]}
          >
            <Selector
              fieldMap={{
                status: "active",
                value: "value",
                label: "label",
                dataLabel: ["label"],
                dataFilter: ["label"],
                disabled: true,
              }}
              options={materials}
              loading={loading}
            />
          </Form.Item>

          <Form.Item<CreateRepositoryTypeType>
            name="mode"
            label="Tipo"
            rules={[{ required: true, message: "Por favor, selecione o tipo." }]}
          >
            <Select
              options={[
                { value: "REP", label: "Repositório" },
                { value: "HAF", label: "Pasta Suspensa" },
              ]}
            />
          </Form.Item>

          <Form.Item<CreateRepositoryTypeType>
            name="equivalence"
            label="Equivalência"
            rules={[{ required: true, message: "Por favor, insira equivalência." }]}
          >
            <InputNumber addonAfter="%" className="w-32" />
          </Form.Item>
          <FormItemInputMoney form={form} name="price" label="Preço" numberType="positive" />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="system_repository_type">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
