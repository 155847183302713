import { Form, Input, Segmented } from "antd";
import { ViewPermissionGroupType } from "../../types";
import { useEffect } from "react";
import { D3Date } from "@utils/date";

type TabFormProps = {
  data: ViewPermissionGroupType | undefined;
};

function TabData({ data }: TabFormProps) {
  const [form] = Form.useForm<ViewPermissionGroupType>();

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue({
        ...data,
        created_at: D3Date.FormatValueApiToPtBR(data.created_at),
        updated_at: D3Date.FormatValueApiToPtBR(data.updated_at),
      });
    }
  }, [data, form]);

  return (
    <>
      <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
        <Form.Item<ViewPermissionGroupType> label="Nome" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item<ViewPermissionGroupType> name="active" label="Status">
          <Segmented
            disabled
            options={[
              { label: "Desbloqueado", value: true },
              { label: "Bloqueado", value: false },
            ]}
          />
        </Form.Item>
        <Form.Item<ViewPermissionGroupType> label="Criado em" name="created_at">
          <Input disabled />
        </Form.Item>
        <Form.Item<ViewPermissionGroupType> label="Atualizado em" name="updated_at">
          <Input disabled />
        </Form.Item>
      </Form>
    </>
  );
}

export default TabData;
