import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import showMessage from "@utils/showMessage";
import { SetSort, SortType } from "@utils/table/SetSort";
import { create } from "zustand";
import {
  CreatePhysicalTagType,
  ListPhysicalTagType,
  SearchPhysicalTagType,
  SearchPhysicalTagViewType,
  ViewPhysicalTagType,
} from "./types";

type PhysicalTagStoreState = {
  apiResult: ListPhysicalTagType;
  filter: SearchPhysicalTagType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchPhysicalTagType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadPhysicalTags: () => Promise<Success<ListPhysicalTagType> | BadRequest | StandardError>;
  createPhysicalTag: (
    physicalTag: CreatePhysicalTagType
  ) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  deletePhysicalTag: (cuid: string) => Promise<Success<void> | StandardError>;
  resetFilter: () => void;
  resetState: () => void;
  deletionPhysicalTag: (
    physicalTagBatchCuid: string,
    physicalTagId: string
  ) => Promise<Success<void> | BadRequest | StandardError>;
  apiResultView: ViewPhysicalTagType;
  filterView: SearchPhysicalTagViewType;
  paginationView: { page: number; pageSize: number };
  loadingView: boolean;
  viewPhysicalTag: (physicalTagBatchCuid: string) => Promise<Success<ViewPhysicalTagType> | BadRequest | StandardError>;
  setFilterView: (filter: SearchPhysicalTagViewType) => void;
  setPaginationView: (page: number, pageSize: number) => void;
  physicalTagBatchCuid: string;
  resetStateView: () => void;
  resetFilterView: (physicalTagBatchCuid: string) => void;
};

const defaultSort = {
  sort_by: "pt_id",
  sort_direction: "desc",
};

const operationsFilter: FilterDefinition = {
  pt_description: { op: "lk", type: "string" },
  pt_type: { op: "eq", type: "string" },
  pt_generated_at: { op: "eq", type: "string" },
  pt_printed_at: { op: "eq", type: "string" },
  ug_name: { op: "lk", type: "string" },
  up_name: { op: "lk", type: "string" },
};

const operationsFilterView: FilterDefinition = {
  id: { op: "lk", type: "string" },
  in_use: { op: "eq", type: "string" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  filter: {
    pt_description: "",
    pt_type: "",
    pt_generated_at: "",
    pt_printed_at: "",
    ug_name: "",
    up_name: "",
  } as SearchPhysicalTagType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

const defaultStateView = {
  physicalTagBatchCuid: "",
  apiResultView: {
    total: 0,
    data: [],
  },
  filterView: {
    id: "",
    in_use: "",
  } as SearchPhysicalTagViewType,
  paginationView: {
    page: 1,
    pageSize: 10,
  },
  loadingView: false,
};

export const usePhysicalTagsStore = create<PhysicalTagStoreState>((set, get) => ({
  ...defaultState,
  ...defaultStateView,
  resetState: () => set(defaultState),
  resetStateView: () => set(defaultStateView),
  setFilter: (filter: SearchPhysicalTagType) => {
    set({
      filter: filter,
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadPhysicalTags();
  },
  resetFilter: () => {
    set({
      filter: defaultState.filter,
    });
    get().loadPhysicalTags();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadPhysicalTags();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["id", "pt_id"],
        ["description", "pt_description"],
        ["type", "pt_type"],
        ["generated_at", "pt_generated_at"],
        ["printed_at", "pt_printed_at"],
      ]),
      set,
      get().loadPhysicalTags
    ),

  loadPhysicalTags: async (): Promise<Success<ListPhysicalTagType> | BadRequest | StandardError> => {
    set({ loading: true });

    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    return await new ClientHttp().get<Success<ListPhysicalTagType>, BadRequest | StandardError>(
      `/api/v1/customer/physical-tag-batches?${qs}`,
      (result: Success<ListPhysicalTagType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadPhysicalTags();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  createPhysicalTag: async (
    physicalTag: CreatePhysicalTagType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreatePhysicalTagType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/customer/physical-tag-batches",
      physicalTag,
      (result: Success<{ cuid: string }>) => {
        get().loadPhysicalTags();
      }
    );
  },
  deletePhysicalTag: async (cuid: string): Promise<Success<void> | StandardError> => {
    return await new ClientHttp().delete<Success<void>, StandardError>(
      `/api/v1/customer/physical-tag-batches/${cuid}`,
      (result: Success<void>) => {
        showMessage(result, "Lote de etiquetas excluído com sucesso.");
        get().loadPhysicalTags();
      },
      (result: StandardError) => {
        showMessage(result);
      }
    );
  },

  viewPhysicalTag: async (
    physicalTagBatchCuid: string
  ): Promise<Success<ViewPhysicalTagType> | BadRequest | StandardError> => {
    set({ loadingView: true, physicalTagBatchCuid: physicalTagBatchCuid });

    let qs = D3QSGenerator(get().filterView, operationsFilterView, get().paginationView);
    return await new ClientHttp().get<Success<ViewPhysicalTagType>, BadRequest | StandardError>(
      `/api/v1/customer/physical-tag-batches/${physicalTagBatchCuid}/tags?${qs}`,
      (result: Success<ViewPhysicalTagType>) => {
        let newPage = get().paginationView.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResultView: body,
            loadingView: false,
            paginationView: { ...get().paginationView, page: newPage },
          });
          get().loadPhysicalTags();
        } else {
          set({ apiResultView: body, loadingView: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  setFilterView: (filter: SearchPhysicalTagViewType) => {
    set({
      filterView: filter,
      paginationView: { ...get().paginationView, page: 1 },
    });
    get().viewPhysicalTag(get().physicalTagBatchCuid);
  },
  resetFilterView: (physicalTagBatchCuid: string) => {
    set({
      filterView: {
        id: "",
        in_use: "",
      },
    });
    get().viewPhysicalTag(physicalTagBatchCuid);
  },
  setPaginationView: (page: number, pageSize: number) => {
    if (pageSize !== get().paginationView.pageSize) {
      page = 1;
    }
    set({ paginationView: { page, pageSize } });
    get().viewPhysicalTag(get().physicalTagBatchCuid);
  },
  deletionPhysicalTag: async (
    physicalTagBatchCuid: string,
    physicalTagId: string
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<null, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/physical-tag-batches/${physicalTagBatchCuid}/tags/${physicalTagId}`,
      null,
      (result: Success<void>) => {
        showMessage(result, "Etiqueta excluída com sucesso.");
        get().viewPhysicalTag(physicalTagBatchCuid);
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
