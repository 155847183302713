import React from "react";
import { AbilityContext } from "@context/CASLContext";
import { useAbility } from "@casl/react";
import { Button, ButtonProps, Tooltip, TooltipProps } from "antd";
import { Can } from "@context/CASLContext";

type D3CanProps = {
  action?: string;
  resource?: any;
  children: React.ReactNode | ((props: { disabled: boolean }) => React.ReactNode);
  type?: "hidden" | "disable";
  rules?: string[];
};

const D3Can = ({ action, resource, children, type = "disable", rules }: D3CanProps) => {
  const ability = useAbility(AbilityContext);

  const isAllowed = React.useMemo(() => {
    if (rules) {
      return rules.some((rule) => {
        const [res, act] = rule.split(".");
        return ability.can(act, res);
      });
    } else if (resource && action) {
      return ability.can(action, resource);
    } else {
      return false;
    }
  }, [rules, resource, action, ability]);

  // Função recursiva para desabilitar botões e ajustar Tooltips
  const disableButtons = (child: React.ReactNode, hasTooltip: boolean = false): React.ReactNode => {
    if (!React.isValidElement(child)) {
      return child;
    }

    const { type, props } = child;

    // Verifica se o child é um Tooltip
    if (type === Tooltip) {
      const tooltipElement = child as React.ReactElement<TooltipProps>;
      const newChildren = React.Children.map(
        props.children,
        (c) => disableButtons(c, true) // Indica que estamos dentro de um Tooltip
      );

      // Se não for permitido, altera o título do Tooltip para "Sem permissão"
      const newProps = !isAllowed ? { ...props, title: "Sem permissão" } : props;

      return React.cloneElement(tooltipElement, newProps, newChildren);
    }

    // Verifica se o child é um Button
    if (type === Button) {
      const buttonElement = child as React.ReactElement<ButtonProps>;
      const newButton = React.cloneElement(buttonElement, {
        disabled: !isAllowed,
      });

      // Se não tiver Tooltip e não for permitido, adiciona um Tooltip
      if (!hasTooltip && !isAllowed) {
        return <Tooltip title="Sem permissão">{newButton}</Tooltip>;
      }

      return newButton;
    }

    // Se o child tem filhos, processa-os recursivamente
    if (props && props.children) {
      const newChildren = React.Children.map(props.children, (c) => disableButtons(c, hasTooltip));
      return React.cloneElement(child, { ...props, children: newChildren });
    }

    return child;
  };

  return type === "disable" ? (
    <>
      {typeof children === "function"
        ? children({ disabled: !isAllowed })
        : React.Children.map(children, (child) => disableButtons(child))}
    </>
  ) : (
    <Can I={action || ""} a={resource || ""}>
      {children as React.ReactNode}
    </Can>
  );
};

export default D3Can;
