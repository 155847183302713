import FormItemConditionalDate from "@components/shared/FormItemConditionalDate";
import { Form, FormInstance, Input } from "antd";
import { useEffect, useState } from "react";
import { SearchType } from "./types";
import FormItemSwitcher from "./FormItemSwitcher";
import useSearchStore from "./store";
import Selector from "@components/shared/Selector";

type SearchRepositoryFormFormProps = {
  form: FormInstance<SearchType>;
};

const options = [
  { label: "Igual a", value: "equal" },
  { label: "Maior que", value: "greater" },
  { label: "Menor que", value: "less" },
  { label: "No intervalo", value: "range" },
];

function SearchRepositoryForm({ form }: SearchRepositoryFormFormProps) {
  const { simpleListRepositoryType, repositoryTypes } = useSearchStore();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (repositoryTypes.length === 0) {
        await simpleListRepositoryType();
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <FormItemSwitcher
        attribute={["filter_repository", "repository_physical_tag_id"]}
        label="Etiquetas de repositório"
        form={form}
      />
      <Form.Item label="Intervalo de repositórios" className="p-0 my-2">
        <div className="flex items-center gap-3">
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "start_repository_physical_tag_id"]}
          >
            <Input showCount maxLength={12} />
          </Form.Item>
          até
          <Form.Item
            className="w-full p-0 m-0"
            name={["filter_repository", "repository_range", "end_repository_physical_tag_id"]}
          >
            <Input showCount maxLength={12} />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item
        className="p-0 my-2"
        label="Tipo de Repositório"
        name={["filter_repository", "repository_types_cuids"]}
      >
        <Selector
          fieldMap={{
            status: "active",
            value: "cuid",
            label: "description",
            dataLabel: ["description"],
            dataFilter: ["description"],
            disabled: true,
          }}
          mode="multiple"
          loading={loading}
          disabled={loading}
          options={repositoryTypes}
        />
      </Form.Item>

      <FormItemConditionalDate<SearchType>
        attribute={["filter_repository", "created_at"]}
        label="Data cadastramento"
        options={options}
        form={form}
      />

      <Form.Item label="Posição" className="p-0 my-2" name={["filter_repository", "position"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Origem" className="p-0 my-2" name={["filter_repository", "origin"]}>
        <Input />
      </Form.Item>
    </div>
  );
}

export default SearchRepositoryForm;
