export const enumSituation: Map<string, string> = new Map<string, string>([
  ["STO", "Armazenado"],
  ["REG", "Cadastrado"],
  ["INC", "Cliente"],
  ["MIG", "Em migração"],
  ["REQ", "Requisitado"],
  ["PUR", "Expurgado"],
]);

function getRepositoryStatusByKey(key: string): string | undefined {
  return enumSituation.get(key);
}

function getAllRepositoryStatusForSelect(excludeKeys: string[] = []): Array<{ label: string; value: string }> {
  const options: Array<{ label: string; value: string }> = [];
  enumSituation.forEach((label, value) => {
    if (!excludeKeys.includes(value)) {
      options.push({ label, value });
    }
  });
  return options;
}

export { getAllRepositoryStatusForSelect, getRepositoryStatusByKey };
