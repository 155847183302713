import { Form, FormInstance, Spin } from "antd";
import { useEffect } from "react";
import IndexersFilter from "./criterion/RenderIndexers";
import useSearchStore from "./store";
import { SearchType } from "./types";
import Selector from "@components/shared/Selector";

type SearchDocumentFormProps = {
  form: FormInstance<any>;
};

function SearchCriterionForm({ form }: SearchDocumentFormProps) {
  const {
    simpleListDocumentType,
    loadingDocumentsType,
    documentsType,
    getListIndexesByDocumentTypeCuid,
    loadingIndexes,
    resetPartFilter,
  } = useSearchStore();

  useEffect(() => {
    if (documentsType.length === 0) {
      (async () => {
        await simpleListDocumentType();
      })();
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item
        className="w-full p-0 my-2"
        name={["filter_document_type", "document_type_cuid"]}
        label="Tipo de Documento"
      >
        <Selector
          fieldMap={{
            status: "active",
            value: "cuid",
            label: "description",
            dataLabel: ["description"],
            dataFilter: ["cuid"],
          }}
          options={documentsType}
          disabled={loadingDocumentsType}
          loading={loadingDocumentsType}
          onClear={() => {
            resetPartFilter("filter_document_type" as keyof SearchType);
          }}
          onChange={async (e) => {
            if (e) {
              await getListIndexesByDocumentTypeCuid(e as string);
            } else {
            }
          }}
        />
      </Form.Item>
      {loadingIndexes ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : !loadingIndexes ? (
        <IndexersFilter form={form} attribute={["filter_document_type", "indexes_values"]} />
      ) : null}
    </div>
  );
}

export default SearchCriterionForm;
