import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { Badge, Button, Checkbox, Divider, Form, Select, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect } from "react";
import { useNotificationStore } from "../notifications/store";
import { UserCompanyNotificationType } from "../notifications/types";
import { channelsNotification, systemOrderTypes } from "../notifications/components/OptionsForSelect";
import { CreateUsersForm } from "../notifications/components/CreateUsersForm";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { diff } from "deep-diff";
import { ctNotification } from "@components/shared/CTNotification";

const TabNotifications: React.FC = () => {
  const {
    loadNotifications,
    loading,
    notifications,
    oldNotifications,
    handleChange,
    resetState,
    selectedRows,
    deleteUser,
    loadingUpdate,
    updateNotifications,
  } = useNotificationStore();
  const { openDrawer } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await loadNotifications();
    })();
    return () => {
      resetState();
    };
  }, [loadNotifications]);

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden ">
      <div className="flex items-center justify-between">
        <h2 className="ml-1 text-lg font-semibold text-gray-600">
          Configurar notificações para os usuários da empresa
        </h2>
        <D3Can action="update" resource="company_notification">
          <Button
            type="primary"
            size="middle"
            icon={<Icon icon="ic:round-plus" height={20} width={20} />}
            onClick={() => openDrawer(<CreateUsersForm />, "right", 600, "Adicionar usuários")}
          >
            Adicionar usuários
          </Button>
        </D3Can>
      </div>
      <Divider className="mt-1 mb-6 " />
      <Table<UserCompanyNotificationType>
        size="small"
        rowKey={(record) => record.user.cuid}
        dataSource={notifications.user_company_notifications}
        bordered
        className="responsive-table tabs-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex w-full">
                <Form
                  className="w-full"
                  colon={false}
                  layout="horizontal"
                  labelCol={{ flex: "455px" }}
                  labelAlign="left"
                  labelWrap
                >
                  <Form.Item
                    className="p-0 m-0"
                    label="Notificar o usuário solicitante sobre a alteração do status da ordem?"
                  >
                    <Select
                      mode="multiple"
                      value={notifications.notify_requesting_user_on_order_status_change}
                      maxTagCount="responsive"
                      className="max-w-48"
                      showSearch={false}
                      options={channelsNotification}
                      onChange={(values) => handleChange("notify_requesting_user_on_order_status_change", values)}
                    />
                  </Form.Item>

                  <Form.Item className="p-0 mt-2" label="Notificar o usuário solicitante sobre a criação da ordem?">
                    <Select
                      mode="multiple"
                      showSearch={false}
                      value={notifications.notify_requesting_user_on_order_creation}
                      maxTagCount="responsive"
                      className="max-w-48"
                      options={channelsNotification}
                      onChange={(values) => handleChange("notify_requesting_user_on_order_creation", values)}
                    />
                  </Form.Item>
                </Form>
              </div>
            </>
          );
        }}
        rowSelection={{
          preserveSelectedRowKeys: true,
          hideSelectAll: false,
          type: "checkbox",
          selectedRowKeys: selectedRows,
          onChange: (_, selectRows: UserCompanyNotificationType[]) => {
            useNotificationStore.setState({
              selectedRows: selectRows.map((user) => user.user.cuid),
            });
          },
        }}
        footer={() => {
          return (
            <div className="flex items-center justify-between w-full">
              <Badge count={selectedRows.length > 0 ? selectedRows.length : undefined}>
                <Button
                  type="primary"
                  danger
                  onClick={() => deleteUser(selectedRows)}
                  disabled={selectedRows.length === 0}
                  size="middle"
                  icon={<Icon icon="material-symbols:delete" height={16} width={16} />}
                >
                  Excluir
                </Button>
              </Badge>
              <div className="flex gap-2">
                <Button
                  className="text-blue-500"
                  disabled={diff(notifications, oldNotifications) === undefined}
                  type="dashed"
                  onClick={async () =>
                    useNotificationStore.setState({
                      notifications: oldNotifications,
                    })
                  }
                  size="middle"
                  icon={<Icon icon="grommet-icons:revert" height={18} width={18} />}
                >
                  Reverter alterações
                </Button>
                <D3Can action="update" resource="company_notification">
                  <Button
                    loading={loadingUpdate}
                    disabled={diff(notifications, oldNotifications) === undefined}
                    type="primary"
                    onClick={async () => {
                      const validateChannelNotification: boolean = notifications.user_company_notifications.some(
                        (obj) =>
                          Array.isArray(obj.notification_channel_types) && obj.notification_channel_types.length === 0
                      );
                      if (validateChannelNotification) {
                        ctNotification({
                          title: "Atenção !",
                          content: 'Preencha os campos em vermelho com os "Canais de notificações".',
                          type: "error",
                        });
                      } else {
                        await updateNotifications();
                      }
                    }}
                    size="middle"
                    icon={<Icon icon="material-symbols:save" height={18} width={18} />}
                  >
                    Salvar alterações
                  </Button>
                </D3Can>
              </div>
            </div>
          );
        }}
      >
        <Table.Column<UserCompanyNotificationType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="user"
          title="Usuário"
          dataIndex={["user", "name"]}
        />
        <Table.Column<UserCompanyNotificationType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="notification_channel_types"
          align="center"
          title="Canais de notificações"
          dataIndex="notification_channel_types"
          render={(value: string[], record: UserCompanyNotificationType, index: number) => (
            <Form.Item
              className="p-0 m-0"
              validateStatus={record.notification_channel_types.length >= 1 ? "" : "error"}
            >
              <Select
                mode="multiple"
                value={value}
                maxTagCount="responsive"
                className="min-w-48 max-w-48"
                showSearch={false}
                options={channelsNotification}
                onChange={(values) => handleChange("notification_channel_types", values, index)}
              />
            </Form.Item>
          )}
        />
        <Table.Column<UserCompanyNotificationType>
          showSorterTooltip={false}
          key="notify_on_expiring_documents"
          align="center"
          title="Notificar quando documento estiver expirando"
          dataIndex="notify_on_expiring_documents"
          render={(value: any, record: UserCompanyNotificationType, index: number) => (
            <Checkbox
              onChange={(e) => handleChange("notify_on_expiring_documents", e.target.checked, index)}
              checked={value}
            />
          )}
        />
        <Table.Column<UserCompanyNotificationType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          align="center"
          key="notify_on_order_creation"
          title="Notificar ao criar ordem"
          dataIndex="notify_on_order_creation"
          render={(value: string[], record: UserCompanyNotificationType, index: number) => (
            <Select
              mode="multiple"
              value={value}
              maxTagCount="responsive"
              className="w-64"
              showSearch={false}
              options={systemOrderTypes}
              onChange={(values) => handleChange("notify_on_order_creation", values, index)}
            />
          )}
        />
        <Table.Column<UserCompanyNotificationType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          align="center"
          key="notify_on_order_status_change"
          title="Notificar na alteração de status da ordem"
          dataIndex="notify_on_order_status_change"
          render={(value: any, record: UserCompanyNotificationType, index: number) => (
            <Select
              mode="multiple"
              value={value}
              maxTagCount="responsive"
              className="w-64"
              showSearch={false}
              options={systemOrderTypes}
              onChange={(values) => handleChange("notify_on_order_status_change", values, index)}
            />
          )}
        />
        <Table.Column<UserCompanyNotificationType>
          key="action"
          title="Ações"
          width={80}
          align="center"
          render={(_, record: UserCompanyNotificationType) => {
            return (
              <>
                <Space>
                  <Tooltip title="Excluir" trigger={["click", "hover"]}>
                    <Button
                      type="text"
                      shape="default"
                      icon={
                        <Icon
                          icon="material-symbols:delete"
                          height={18}
                          width={18}
                          className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                        />
                      }
                      size={"middle"}
                      onClick={() => deleteUser(record.user.cuid)}
                    />
                  </Tooltip>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default TabNotifications;
