import { create } from "zustand";
import {
  CepType,
  CloseIncidentType,
  CreateAddressCustomerType,
  ListAddressType,
  ListAdminsType,
  ListNoPayments,
  MutationAdminType,
  RegisterIncidentType,
  SelectCompanyUserItemType,
  SimpleListCompanyUserType,
  ViewAddressType,
  ViewCustomerType,
  itemTimeline,
} from "./types";

import { Divider } from "antd";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";

type CustomerStoreState = {
  apiResultAddress: ListAddressType;
  apiResultAdmins: ListAdminsType;
  itemsNoPayments: itemTimeline[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  getCustomer: () => Promise<Success<ViewCustomerType> | StandardError>;
  getCep: (cep: string) => Promise<Success<CepType> | StandardError>;
  getAddress: (cuid: string) => Promise<Success<ViewAddressType> | StandardError>;
  loadAddresses: () => Promise<void>;
  createAddressCustomer: (address: CreateAddressCustomerType) => Promise<
    | Success<{
        cuid: string;
      }>
    | BadRequest
    | StandardError
  >;
  updateAddressCustomer: (
    cuid: string,
    address: CreateAddressCustomerType
  ) => Promise<
    | StandardError
    | BadRequest
    | Success<{
        cuid: string;
      }>
  >;
  deleteAddress: (cuid: string) => Promise<Success<void> | StandardError>;

  loadAdmins: () => Promise<void>;
  getCompanyUsersForSelect: () => Promise<SelectCompanyUserItemType[]>;
  createAdmin: (payload: MutationAdminType) => Promise<Success<null> | BadRequest | StandardError>;
  deleteAdmin: (cuid: string) => Promise<Success<void> | StandardError>;

  resetState: () => void;
  getNoPayments: () => Promise<void>;
  registerIncident: (reason: RegisterIncidentType) => Promise<
    | Success<{
        cuid: string;
      }>
    | BadRequest
    | StandardError
  >;
  closeIncident: (payload: CloseIncidentType) => Promise<Success<null> | BadRequest | StandardError>;
};

const defaultState = {
  apiResultAddress: {
    total: 0,
    data: [],
  },
  apiResultAdmins: {
    total: 0,
    data: [],
  },
  apiResultNoPayments: {
    data: [],
  },
  activeTab: "",
  itemsNoPayments: [],
};

export const useCustomerStore = create<CustomerStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setActiveTab: (tab: string) => {
    set({ activeTab: tab });
  },
  getCustomer: async (): Promise<Success<ViewCustomerType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCustomerType>, StandardError>(`/api/v1/customer`);
  },
  loadAddresses: async (): Promise<void> => {
    await new ClientHttp().get<Success<ListAddressType>, BadRequest | StandardError>(
      `/api/v1/customer/addresses`,
      (result: Success<ListAddressType>) => {
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          set({
            apiResultAddress: body,
          });
          get().loadAddresses();
        } else {
          set({ apiResultAddress: body });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  getAddress: async (cuid: string): Promise<Success<ViewAddressType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewAddressType>, StandardError>(`/api/v1/customer/addresses/${cuid}`);
  },

  getCep: async (cep: string): Promise<Success<CepType> | StandardError> => {
    return await new ClientHttp().get<Success<CepType>, StandardError>(
      `/api/v1/system/cep/${cep}/search`,
      (result: Success<CepType>): CepType => {
        return result.body;
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  createAddressCustomer: async (
    address: CreateAddressCustomerType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateAddressCustomerType,
      Success<{ cuid: string }>,
      BadRequest | StandardError
    >("/api/v1/customer/addresses", address, (result: Success<{ cuid: string }>) => {
      get().loadAddresses();
    });
  },
  updateAddressCustomer: async (
    cuid: string,
    address: CreateAddressCustomerType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().put<CreateAddressCustomerType, Success<{ cuid: string }>, BadRequest | StandardError>(
      `/api/v1/customer/addresses/${cuid}`,
      address,
      (result: Success<{ cuid: string }>) => {
        get().loadAddresses();
      }
    );
  },
  deleteAddress: async (cuid: string): Promise<Success<void> | StandardError> => {
    return await new ClientHttp().delete<Success<void>, StandardError>(
      `/api/v1/customer/addresses/${cuid}`,
      (result: Success<void>) => {
        showMessage(result, "Endereço excluído com sucesso.");
        get().loadAddresses();
      },
      (result: StandardError) => {
        showMessage(result);
      }
    );
  },

  loadAdmins: async (): Promise<void> => {
    await new ClientHttp().get<Success<ListAdminsType>, BadRequest | StandardError>(
      `/api/v1/customer/admins`,
      (result: Success<ListAdminsType>) => {
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          set({
            apiResultAdmins: body,
          });
          get().loadAdmins();
        } else {
          set({ apiResultAdmins: body });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  getCompanyUsersForSelect: async (): Promise<SelectCompanyUserItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCompanyUserType>,
      StandardError,
      SelectCompanyUserItemType[]
    >(
      "/api/v1/customer/users/simple-list",
      (result: Success<SimpleListCompanyUserType>): SelectCompanyUserItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.name,
            active: item.active,
            username: item.username,
          } as SelectCompanyUserItemType;
        });
      },
      (error: StandardError): SelectCompanyUserItemType[] => {
        showMessage(error);
        return [] as SelectCompanyUserItemType[];
      }
    );
  },

  createAdmin: async (payload: MutationAdminType): Promise<Success<null> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<MutationAdminType, Success<null>, BadRequest | StandardError>(
      `/api/v1/customer/admins`,
      payload,
      (result: Success<null>) => {
        get().loadAdmins();
      }
    );
  },

  deleteAdmin: async (cuid: string): Promise<Success<void> | StandardError> => {
    return await new ClientHttp().delete<Success<void>, StandardError>(
      `/api/v1/customer/admins/${cuid}`,
      (result: Success<void>) => {
        showMessage(result, "Administrador removido com sucesso.");
        get().loadAdmins();
      },
      (result: StandardError) => {
        showMessage(result);
      }
    );
  },
  getNoPayments: async (): Promise<void> => {
    return await new ClientHttp().getItensForSelect<Success<ListNoPayments>, StandardError, void>(
      `/api/v1/customer/non-payments`,
      (result: Success<ListNoPayments>): void => {
        const items = result.body.data.map(
          ({
            active,
            added_in,
            name_user_added,
            name_user_removed,
            removed_in,
            non_payment_reason,
            removing_non_payment_reason,
          }) => {
            const isInactive = !active;
            return {
              color: isInactive ? "green" : "red",
              children: (
                <div className="flex flex-col">
                  {isInactive && (
                    <>
                      <span>
                        <strong>Encerrada</strong>
                      </span>
                      <span>
                        <strong>Motivo:</strong>
                        {` ${removing_non_payment_reason}`}
                      </span>
                      <span>
                        <strong>Autor:</strong> {name_user_removed}
                      </span>
                      <span>
                        <strong>Data:</strong> {D3DateHourSorter(removed_in)}
                      </span>
                      <Divider className="my-1" />
                    </>
                  )}
                  <span>
                    <strong>Registrada</strong>
                  </span>
                  <span>
                    <strong>Motivo:</strong>
                    {` ${non_payment_reason}`}
                  </span>
                  <span>
                    <strong>Autor:</strong> {name_user_added}
                  </span>
                  <span>
                    <strong>Data:</strong> {D3DateHourSorter(added_in)}
                  </span>
                </div>
              ),
            } as itemTimeline;
          }
        );
        set({
          itemsNoPayments: items,
        });
      },
      (error: StandardError): itemTimeline[] => {
        showMessage(error);
        return [] as itemTimeline[];
      }
    );
  },
  registerIncident: async (
    reason: RegisterIncidentType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<RegisterIncidentType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/customer/non-payments",
      reason,
      (result: Success<{ cuid: string }>) => {
        showMessage(result, "Ocorrência registrada com sucesso.");
        get().getNoPayments();
      }
    );
  },
  closeIncident: async (reason: CloseIncidentType): Promise<Success<null> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<CloseIncidentType, Success<null>, BadRequest | StandardError>(
      `/api/v1/customer/non-payments`,
      reason,
      (result: Success<null>) => {
        showMessage(result, "Ocorrência encerrada com sucesso.");
        get().getNoPayments();
      }
    );
  },
}));
