import { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Space, Spin } from "antd";
import dayjs from "dayjs";
import { usePhysicalTagsStore } from "../store";
import { SearchPhysicalTagType } from "../types";
import "dayjs/locale/pt-br";
import { formatDatePicker } from "@utils/DatePickerFormat";

type SearchPhysicalTagFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchPhysicalTagForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchPhysicalTagFormProps) => {
  const [form] = Form.useForm<SearchPhysicalTagType>();
  const { filter, setFilter } = usePhysicalTagsStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const generated = filter.pt_generated_at ? dayjs(filter.pt_generated_at) : "";
      const printed = filter.pt_printed_at ? dayjs(filter.pt_printed_at) : "";
      form.setFieldsValue({
        ...filter,
        pt_generated_at: generated,
        pt_printed_at: printed,
      });
      setLoading(false);
    })();
  }, [filter]);

  return (
    <Spin spinning={loading}>
      <Form
        className="select-none w-80"
        layout="vertical"
        form={form}
        onFinish={async (payload) => {
          payload.pt_generated_at = payload.pt_generated_at ? formatDatePicker(payload.pt_generated_at) : "";
          payload.pt_printed_at = payload.pt_printed_at ? formatDatePicker(payload.pt_printed_at) : "";
          setFilter(payload);
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchPhysicalTagType> name="pt_description" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item<SearchPhysicalTagType> name="pt_type" label="Tipo da etiqueta">
          <Select
            allowClear
            onClear={() => {
              form.setFieldValue("up_name", "");
            }}
            options={[
              { value: "D", label: "Documento" },
              { value: "R", label: "Repositório" },
            ]}
          />
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> label="Gerador por" name="ug_name">
          <Input />
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> name="pt_generated_at" label="Data de geração">
          <DatePicker format="DD/MM/YYYY" maxDate={dayjs()} />
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> label="Impresso por" name="up_name">
          <Input />
        </Form.Item>

        <Form.Item<SearchPhysicalTagType> name="pt_printed_at" label="Data de impressão">
          <DatePicker format="DD/MM/YYYY" maxDate={dayjs()} />
        </Form.Item>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
