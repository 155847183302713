import D3Can from "@components/shared/D3Can";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";
import SpinLoading from "@components/shared/SpinLoading";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Button, Form, Input, Segmented, Space } from "antd";
import { useEffect } from "react";
import { useDefaultPricesStore } from "./store";
import { DefaultPricesType, FormItemType, ListFormItemType } from "./types";

const listFormItem: ListFormItemType[] = [
  {
    key: "1",
    label: "Cadastro",
    childrens: [
      {
        key: "1-1",
        name: "repository_registration",
        label: "Repositório",
        type: "inputNumber",
      },
      {
        key: "1-2",
        name: "document_registration",
        label: "Documento",
        type: "inputNumber",
      },
    ],
  },
  {
    key: "2",
    label: "Digitalização",
    childrens: [
      {
        key: "2-1",
        name: "digitalized_page_a0_to_a2",
        label: "A0, A1 e A2",
        type: "inputNumber",
      },
      {
        key: "2-2",
        name: "digitalized_page_a3",
        label: "A3",

        type: "inputNumber",
      },
      {
        key: "2-3",
        name: "digitalized_page_a4_to_a8",
        label: "A4, A5, A6, A7 e A8",
        type: "inputNumber",
      },
    ],
  },
  {
    key: "3",
    label: "Implantação",
    childrens: [
      {
        key: "3-1",
        name: "repository_deployment",
        label: "Repositório",
        type: "inputNumber",
      },
      {
        key: "3-2",
        name: "hanging_folder_deployment",
        label: "Pasta suspensa",
        type: "inputNumber",
      },
    ],
  },
  {
    key: "4",
    label: "Armazenamento",
    childrens: [
      {
        key: "4-1",
        name: "gigabyte_storage",
        label: "Imagem em Gigabyte",
        type: "inputNumber",
      },
    ],
  },
  {
    key: "5",
    label: "Atualização",
    childrens: [
      {
        key: "5-1",
        name: "updated_by",
        label: "Usuário",
        type: "input",
      },
      {
        key: "5-2",
        name: "updated_at",
        label: "Data",
        type: "input",
      },
    ],
  },
];

function DefaultPrice() {
  const { getDefaultPrices, updateDefaultPrices, loading, priceDefault } = useDefaultPricesStore();
  const [form] = Form.useForm<DefaultPricesType>();

  useEffect(() => {
    (async () => {
      await getDefaultPrices();
    })();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...priceDefault,
      updated_at: D3DateHourSorter(priceDefault.updated_at),
    });
  }, [priceDefault]);

  const DynamicField: React.FC<FormItemType> = ({ type, name, label }) => {
    switch (type) {
      case "inputNumber":
        return <FormItemInputMoney form={form} numberType="positive" name={name} label={label} required />;
      case "segment":
        return (
          <Form.Item<DefaultPricesType> label={label} name={name}>
            <Segmented
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>
        );
      case "input":
        return (
          <Form.Item<DefaultPricesType> name={name} label={label}>
            <Input disabled />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-hidden">
      {loading ? (
        <SpinLoading />
      ) : (
        <Form
          className="form-item-for-table"
          form={form}
          layout="vertical"
          onFinish={async (payload: DefaultPricesType) => {
            await updateDefaultPrices({
              digitalized_page_a0_to_a2: payload.digitalized_page_a0_to_a2,
              digitalized_page_a3: payload.digitalized_page_a3,
              digitalized_page_a4_to_a8: payload.digitalized_page_a4_to_a8,
              document_registration: payload.document_registration,
              gigabyte_storage: payload.gigabyte_storage,
              repository_deployment: payload.repository_deployment,
              repository_registration: payload.repository_registration,
              hanging_folder_deployment: payload.hanging_folder_deployment,
              has_service_package: payload.has_service_package,
            });
          }}
        >
          <div className="h-full pr-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
            {listFormItem.map(({ childrens, key, label }) => (
              <div key={key} className="">
                <span className="flex w-full border-b-[1px] mb-2 font-semibold text-gray-600 text-base">{label}</span>
                <div className="flex flex-wrap w-full gap-5">
                  {childrens.map(({ key, label, name, type }) => (
                    <DynamicField key={key} type={type} name={name} label={label} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Form>
      )}
      <div className="h-8 pr-4 my-2">
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <D3Can action="update" resource="company">
                <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
                  Atualizar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </div>
    </div>
  );
}

export default DefaultPrice;
