import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import TabAdmins from "./components/tabs/TabAdmins";
import TabData from "./components/tabs/TabData";
import TabIncident from "./components/tabs/TabIncident";
import { useCustomerGroupStore } from "./store";
import TabPrices from "./components/tabs/TabPrices";
import { AbilityContext } from "@context/CASLContext";
import { useAbility } from "@casl/react";

function CustomerGroupView() {
  const { getActiveTab, setActiveTab } = useCustomerGroupStore();
  const usePermission = (action: string, resource: string, multiple?: string[]) => {
    const ability = useAbility(AbilityContext);
    if (multiple) {
			return multiple.some((rule) => {
			  const [res, act] = rule.split(".");
			  return ability.can(act, res);
			});
		  }		
    return ability.can(action, resource);
  };
  return (
    <div className="flex flex-col h-full p-1 mb-1 mr-1 overflow-hidden bg-white rounded shadow-md">
      <Tabs
        tabPosition="left"
        defaultActiveKey="1"
        activeKey={getActiveTab()}
        onTabClick={(tab) => {
          setActiveTab(tab);
        }}
        className="flex h-full overflow-hidden select-none"
        items={[
          {
            disabled: !usePermission( "","",["customer_group.details", "customer_group.view"]),
            key: "1",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ri:profile-line" />
                Dados
              </span>
            ),
            children: <TabData />,
          },
          {
            disabled: !usePermission( "","",["customer_group.details", "customer_group.update"]), 
            key: "2",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="material-symbols:price-change" />
                Preço
              </span>
            ),
            children: <TabPrices />,
          },
          {
            disabled: !usePermission( "view","customer_group_admin"),
            key: "3",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="clarity:group-solid" />
                Administradores
              </span>
            ),
            children: <TabAdmins />,
          },
          {
            disabled: !usePermission( "view","customer_group_non_payment"),
            key: "7",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="ic:round-money-off" width={18} height={18} />
                Registros
              </span>
            ),
            children: <TabIncident />,
          },
        ]}
      />
    </div>
  );
}

export default CustomerGroupView;
