import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Segmented, Space, Spin } from "antd";
import { CurrencyInput } from "react-currency-mask";
import { useTranslation } from "react-i18next";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useMaterialsStore } from "../store";
import D3Can from "@components/shared/D3Can";
import { D3Date } from "@utils/date";
import showMessage from "@utils/showMessage";
import { ViewMaterialType } from "../types";
import { UpdateMaterialForm } from "./UpdateMaterialForm";

type ViewMaterialFormProps = {
  cuid: string;
};

export const ViewMaterialForm = ({ cuid }: ViewMaterialFormProps) => {
  const { getMaterial } = useMaterialsStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewMaterialType>();
  const { onClose } = useContext(D3DrawerContext);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      const result = await getMaterial(cuid);
      if (result.status === "success") {
        const viewMaterial = result.body as ViewMaterialType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewMaterial.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewMaterial.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getMaterial]);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
          <Form.Item<ViewMaterialType> name="description" label={t("system.material.label_description")}>
            <Input />
          </Form.Item>
          <Form.Item<ViewMaterialType> name="price" label={t("system.material.label_price")}>
            <CurrencyInput
              locale="pt-BR"
              currency="BRL"
              onChangeValue={() => {}}
              InputElement={<InputNumber className="min-w-max" />}
            />
          </Form.Item>
          <Form.Item<ViewMaterialType> name="active" label={t("system.material.view.label_status")}>
            <Segmented
              disabled
              options={[
                {
                  label: t("system.material.view.radio_unlocked"),
                  value: true,
                },
                {
                  label: t("system.material.view.radio_locked"),
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewMaterialType> name="created_at" label={t("system.material.view.label_created")}>
            <Input />
          </Form.Item>
          <Form.Item<ViewMaterialType> name="updated_at" label={t("system.material.view.label_updated")}>
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={false} type="default" danger onClick={() => onClose()}>
                  {t("shared.btn_cancel")}
                </Button>
                <D3Can action="update" resource="system_material">
                  <Button
                    disabled={false}
                    type="primary"
                    onClick={() =>
                      openDrawer(<UpdateMaterialForm cuid={cuid} />, "right", 600, t("system.material.edit.title"))
                    }
                  >
                    {t("shared.btn_edit")}
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
