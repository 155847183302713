import { useContext, useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { Button, Col, Row, Space, Tooltip } from "antd";

import Table from "antd/es/table";

import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { CreateExtensionForm } from "./components/CreateExtensionForm";
import { UpdateExtensionForm } from "./components/UpdateExtensionForm";
import { ViewExtensionForm } from "./components/ViewExtensionForm";
import { useExtensionsStore } from "./store";
import { RowExtensionType } from "./types";

const ExtensionsView: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { openDrawer } = useContext(D3DrawerContext);
  const { apiResult, loadExtensions, resetState } = useExtensionsStore();

  useEffect(() => {
    (async () => {
      await loadExtensions();
    })();
    setLoading(false);
    return () => resetState();
  }, [loadExtensions]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow">
      <Table<RowExtensionType>
        size="small"
        rowKey="id"
        dataSource={apiResult.data as RowExtensionType[]}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start"></div>

                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Extensões</span>
                </div>

                <div className="flex items-center justify-end">
                  <D3Can action="manage" resource="all">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateExtensionForm />, "right", 600, "Cadastrar Extensão")}
                    >
                      Nova Extensão
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
      >
        <Table.Column<RowExtensionType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="description"
          title="Descrição"
          dataIndex="description"
        />

        <Table.Column<RowExtensionType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="key"
          title="Extensão"
          dataIndex="key"
        />
        <Table.Column<RowExtensionType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="icon"
          title="Ícone"
          dataIndex="icon"
          render={(value, extension) => <Icon height={20} icon={extension.icon} />}
        />
        <Table.Column<RowExtensionType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="extract_content"
          title="Extração de conteúdo"
          dataIndex="extract_content"
          render={(value, extension) => (extension.extract_content ? <span>Sim</span> : <span>Não</span>)}
        />
        <Table.Column<RowExtensionType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="extract_metadata"
          title="Extração de metadados"
          dataIndex="extract_metadata"
          render={(value, extension) => (extension.extract_metadata ? <span>Sim</span> : <span>Não</span>)}
        />
        <Table.Column<RowExtensionType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="ocr"
          title="Processa OCR"
          dataIndex="ocr"
          render={(value, extension) => (extension.ocr ? <span>Sim</span> : <span>Não</span>)}
        />

        <Table.Column<RowExtensionType>
          key="action"
          title="Ações"
          width={90}
          render={(value, extension) => {
            return (
              <>
                <Space>
                  <D3Can action="manage" resource="all">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="circle"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<ViewExtensionForm id={extension.id} />, "right", 600, "Visualizar Externsão")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="manage" resource="all">
                    <Tooltip title="Editar" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="circle"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<UpdateExtensionForm id={extension.id} />, "right", 600, "Editar Extensão")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default ExtensionsView;
