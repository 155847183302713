import { Button, Col, Form, Input, Space, Spin, Table, Tabs } from "antd";
import { useReportsStore } from "../store";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { D3Date } from "@utils/date";
import { ViewReportParamType, ViewReportSessionType, ViewReportType } from "../types";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import Editor from "@monaco-editor/react";
import { format } from "sql-formatter";
import { UpdateReportTabs } from "./update/UpdateReportTabs";
import formatSql from "@utils/formatSql";

type ViewReportFormProps = {
  reportCuid: string;
};

export enum ContextType {
  SYS = "Sistema",
  CPN = "Empresa",
  CTM = "Cliente",
  CGR = "Grupo de Cliente",
}

export enum ReportParamType {
  C = "Carácter",
  I = "Inteiro",
  F = "Decimal",
  D = "Data",
  B = "Condicional",
}

export const ViewReportForm = ({ reportCuid }: ViewReportFormProps) => {
  const { viewReport, view, loadingView } = useReportsStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ViewReportType>();

  useEffect(() => {
    (async () => {
      await viewReport(reportCuid);
    })();
  }, [reportCuid]);

  useEffect(() => {
    form.setFieldsValue({
      ...view,
      context: ContextType[view.context as keyof typeof ContextType],
      created_at: D3Date.FormatValueApiToPtBR(view.created_at as Date),
      updated_at: D3Date.FormatValueApiToPtBR(view.updated_at as Date),
    });
  }, [view]);

  return (
    <>
      <Spin spinning={loadingView}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off">
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<ViewReportType> name="title" label="Nome">
                      <Input disabled />
                    </Form.Item>

                    <Form.Item<ViewReportType> name="description" label="Descrição">
                      <Input disabled />
                    </Form.Item>

                    <Form.Item<ViewReportType> name="context" label="Perfil">
                      <Input disabled />
                    </Form.Item>

                    <Form.Item<ViewReportType>
                      hidden={!view.customer?.cuid}
                      name={["customer", "fantasy_name"]}
                      label="Cliente"
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item<ViewReportType>
                      hidden={!view.customer_group?.cuid}
                      name={["customer_group", "description"]}
                      label="Grupo de Cliente"
                    >
                      <Input disabled />
                    </Form.Item>

                    <div className="grid grid-cols-2 gap-5">
                      <Col>
                        <Form.Item<ViewReportType> name={["created_by", "name"]} label="Criado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item<ViewReportType> name="created_at" label="Criado em">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </div>

                    <div className="grid grid-cols-2 gap-5">
                      <Col>
                        <Form.Item<ViewReportType> name={["updated_by", "name"]} label="Atualizado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item<ViewReportType> name="updated_at" label="Atualizado em">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </div>
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fa:users" className="w-4 h-4" />
                    Parâmetros
                  </span>
                ),
                children: (
                  <>
                    <Table<ViewReportParamType>
                      rowKey={(record) => record.id}
                      size="small"
                      pagination={false}
                      dataSource={view.report_params}
                      bordered
                    >
                      <Table.Column<ViewReportParamType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="label"
                        title="Descrição"
                        dataIndex="label"
                      />
                      <Table.Column<ViewReportParamType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="name"
                        title="Nome"
                        dataIndex="name"
                      />

                      <Table.Column<ViewReportParamType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="type"
                        title="Tipo"
                        dataIndex="type"
                        render={(value) => ReportParamType[value as keyof typeof ReportParamType]}
                      />
                      <Table.Column<ViewReportParamType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="multiple"
                        title="Múltiplo"
                        dataIndex="multiple"
                        render={(value) => (value ? "Sim" : "Não")}
                      />

                      <Table.Column<ViewReportParamType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="required"
                        title="Obrigatório"
                        dataIndex="required"
                        render={(value) => (value ? "Sim" : "Não")}
                      />
                    </Table>
                  </>
                ),
              },
              {
                key: "3",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fa:users" className="w-4 h-4" />
                    Sessões
                  </span>
                ),
                children: (
                  <>
                    <Table<ViewReportSessionType>
                      rowKey={(record) => record.id}
                      size="small"
                      pagination={false}
                      dataSource={view.report_sessions}
                      bordered
                      expandable={{
                        expandedRowRender: (record) => (
                          <Editor
                            theme="vs-dark"
                            options={{
                              minimap: { enabled: false },
                              readOnly: true,
                              padding: {
                                top: 10,
                                bottom: 10,
                              },
                              scrollbar: {
                                vertical: "visible",
                                horizontal: "visible",
                                verticalScrollbarSize: 8,
                                horizontalScrollbarSize: 8,
                                arrowSize: 15,
                              },
                            }}
                            height="300px"
                            defaultLanguage="sql"
                            value={formatSql(record.query)}
                          />
                        ),
                      }}
                    >
                      <Table.Column<ViewReportParamType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="order"
                        title="Ordem"
                        dataIndex="order"
                      />

                      <Table.Column<ViewReportParamType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="title"
                        title="Descrição"
                        dataIndex="title"
                      />
                    </Table>
                  </>
                ),
              },
            ]}
          />
          <div className="mt-3 text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="all" resource="manage">
                <Button
                  type="primary"
                  onClick={() =>
                    openDrawer(<UpdateReportTabs reportCuid={reportCuid} />, "right", 800, "Editar Relatório")
                  }
                >
                  Editar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form>
      </Spin>
    </>
  );
};
