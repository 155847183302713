import { Form, Input } from "antd";
import { useState } from "react";

type ItemCACProps = {
  name: number;
  required: boolean;
  label: string;
};

const maskCPF = (value: any) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "") // Remove todos os caracteres não numéricos
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o primeiro ponto
    .replace(/(\d{3})(\d)/, "$1.$2") // Adiciona o segundo ponto
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Adiciona o traço
};

const maskCNPJ = (value: any) => {
  if (!value) return value;
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
};

function ItemCAC({ name, required, label, ...props }: ItemCACProps) {
  const [count, setCount] = useState<number>(0);
  return (
    <Form.Item
      {...props}
      getValueProps={(value) => ({
        value: count > 14 ? maskCNPJ(value) : maskCPF(value),
      })}
      normalize={(value) => value.replace(/[^0-9]/g, "")}
      name={[name, "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: "Obrigatório",
        },
      ]}
    >
      <Input
        maxLength={18}
        onChange={(e: any) => {
          const value = e.target.value;
          setCount(value.length);
        }}
      />
    </Form.Item>
  );
}

export default ItemCAC;
