import extractOptions from "@utils/extractOptions";
import { Form, InputNumber, Select } from "antd";
import { useState } from "react";

type ItemIFTProps = {
  options: string;
  name: number;
  search_option: string;
};

function ItemIFT({ name, options,search_option = "", ...props }: ItemIFTProps) {
  const [option, setOption] = useState<string>(search_option);

  const handleInputKeyDown = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      const inputValue = e.target.value;
      if (inputValue) {
        e.preventDefault();
        const select = e.target.closest(".ant-select").querySelector(".ant-select-selection-search-input");
        const selectInstance = select && select.__reactFiber$;
        if (selectInstance) {
          const instanceProps = selectInstance.memoizedProps;
          const onSelect = instanceProps.onSelect;
          if (onSelect) {
            onSelect(inputValue);
          }
        }
      }
    }
  };

  return (
    <div className="flex w-full gap-3 ">
      <Form.Item className="w-40 p-0 m-0" {...props} name={[name, "search_option"]}>
        <Select
          className="min-w-40"
          onChange={(e) => setOption(e)}
          placeholder="Opções de busca"
          options={extractOptions(options)}
        />
      </Form.Item>
      {option === "BT" ? (
        <div className="flex items-center">
          <Form.Item
            {...props}
            className="w-32 p-0 m-0"
            name={[name, "start_value"]}
            getValueProps={(value: number) => {
              return { value: value !== undefined ? value.toString() : "" };
            }}
            normalize={(value: string) => {
              const numericValue = Number(value);
              return !isNaN(numericValue) ? numericValue.toString() : value;
            }}
          >
            <InputNumber type="number" className="w-32" />
          </Form.Item>
          <div className="flex items-center h-full px-2">ao</div>
          <Form.Item
            {...props}
            className="w-32 p-0 m-0"
            name={[name, "end_value"]}
            getValueProps={(value: number) => {
              return { value: value !== undefined ? value.toString() : "" };
            }}
            normalize={(value: string) => {
              const numericValue = Number(value);
              return !isNaN(numericValue) ? numericValue.toString() : value;
            }}
          >
            <InputNumber type="number" className="w-32" />
          </Form.Item>
        </div>
      ) : option === "MV" ? (
        <Form.Item
          {...props}
          className="w-full p-0 m-0"
          name={[name, "values"]}
          normalize={(value: string) => {
            const numericValue = Number(value);
            return !isNaN(numericValue) ? numericValue.toString() : value;
          }}
        >
          <Select allowClear onInputKeyDown={handleInputKeyDown} open={false} mode="tags" suffixIcon={<></>} />
        </Form.Item>
      ) : (
        <Form.Item
          {...props}
          className="w-full p-0 m-0"
          name={[name, "value"]}
          getValueProps={(value: number) => {
            return { value: value !== undefined ? value.toString() : "" };
          }}
          normalize={(value: string) => {
            const numericValue = Number(value);
            return !isNaN(numericValue) ? numericValue.toString() : value;
          }}
        >
          <InputNumber type="number" className="w-32" />
        </Form.Item>
      )}
    </div>
  );
}

export default ItemIFT;
