import { Button, Form, Input, InputNumber, Space, Spin } from "antd";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useContext, useEffect, useState } from "react";
import { useSpecialOrdersStore } from "../store";
import {
  CreateSpecialOrderType,
  ItemSimpleListDepartmentType,
  ServiceItemType,
  UserDepartmentItemType,
} from "../types";
import Selector from "@components/shared/Selector";
const { TextArea } = Input;
export const CreateSpecialOrderForm = () => {
  const {
    createSpecialOrder,
    simpleListUsersByDepartmentCuid,
    simpleListDepartmentCustomer,
    simpleListServicesCustomer,
  } = useSpecialOrdersStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateSpecialOrderType>();
  const [departments, setDepartments] = useState<ItemSimpleListDepartmentType[]>([]);
  const [usersDepartment, setUsersDepartment] = useState<UserDepartmentItemType[]>([]);
  const [services, setServices] = useState<ServiceItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setDepartments(await simpleListDepartmentCustomer());
      setServices(await simpleListServicesCustomer());
      setLoading(false);
    })();
  }, [simpleListDepartmentCustomer, simpleListServicesCustomer]);

  async function GetUsers(cuid: string) {
    setLoadingUsers(true);
    const usersDepartment = await simpleListUsersByDepartmentCuid(cuid);
    setUsersDepartment(usersDepartment);
    setLoadingUsers(false);
  }

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateSpecialOrderType) => {
            setLoading(true);
            payload.observation = payload.observation.trim();
            const isCreated = await createSpecialOrder(payload);
            if (isCreated) {
              onClose();
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<CreateSpecialOrderType>
            name="special_service_cuid"
            label="Serviço"
            rules={[{ required: true, message: "Por favor, selecione o serviço." }]}
          >
            <Selector
              fieldMap={{
                value: "cuid",
                label: "description",
                dataLabel: ["description"],
                dataFilter: ["description"],
              }}
              options={services}
            />
          </Form.Item>
          <Form.Item<CreateSpecialOrderType>
            name="department_cuid"
            label="Departamento"
            rules={[{ required: true, message: "Por favor, selecione o departamento." }]}
          >
            <Selector
              fieldMap={{
                status: "active",
                value: "cuid",
                label: "abbreviation",
                description: "description",
                dataLabel: ["abbreviation", "description"],
                dataFilter: ["abbreviation", "description"],
                disabled: true,
              }}
              onChange={(e) => (e !== undefined ? GetUsers(e as string) : null)}
              options={departments}
              onClear={() => {
                form.setFieldValue("requester_cuid", null);
                setUsersDepartment([]);
              }}
            />
          </Form.Item>
          <Form.Item<CreateSpecialOrderType> label="Usuário requisitante" name="requester_cuid">
            <Selector
              fieldMap={{
                status: "active",
                value: "cuid",
                label: "name",
                description: "username",
                dataLabel: ["name", "username"],
                dataFilter: ["name", "username"],
              }}
              loading={loadingUsers}
              options={usersDepartment}
            />
          </Form.Item>

          <Form.Item<CreateSpecialOrderType> label="Quantidade" name="quantity">
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item<CreateSpecialOrderType>
            label="Observação"
            name="observation"
            rules={[
              { required: true, message: "Por favor, insira a observação." },
              { min: 10, message: "Por favor, a observação deve ter no mínimo 10 caracteres." },
              { max: 3100, message: "Por favor, a descrição deve ter no máximo 3100 caracteres." },
            ]}
          >
            <TextArea autoSize={{ minRows: 5 }} minLength={10} maxLength={3100} showCount />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="order_special">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
