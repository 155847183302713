import { Button, Form, Input, InputNumber, Progress, Row, Segmented, Space, Spin } from "antd";
import { useStoragesStore } from "../store";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateStorageForm } from "./UpdateStorageForm";
import { D3Date } from "@utils/date";
import { AddressType, ViewStorageType } from "../types";
import showMessage from "@utils/showMessage";
import { red, green } from "@ant-design/colors";
import D3Can from "@components/shared/D3Can";

type ViewStorageFormProps = {
  cuid: string;
};

const { TextArea } = Input;

export const ViewStorageForm = ({ cuid }: ViewStorageFormProps) => {
  const { getStorage } = useStoragesStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewStorageType>();

  useEffect(() => {
    (async () => {
      const result = await getStorage(cuid);
      if (result.status === "success") {
        const viewStorage = result.body as ViewStorageType;
        form.setFieldsValue({
          ...result.body,
          created_at: D3Date.FormatValueApiToPtBR(viewStorage.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewStorage.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getStorage]);

  const { onClose } = useContext(D3DrawerContext);
  const percent = Number(
    ((form.getFieldValue("number_of_repositories") / form.getFieldValue("number_of_positions")) * 100).toPrecision(2)
  );

  const customerAddress: AddressType = form.getFieldValue("address") || null;
  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" layout="vertical" form={form} autoComplete="off" disabled>
          <Row className="flex justify-between">
            <Form.Item<ViewStorageType> name="description" label="Descrição" className="w-[270px]">
              <Input />
            </Form.Item>

            <Form.Item<ViewStorageType> name="key_document_storage" label="Código do galpão" className="w-[270px]">
              <Input />
            </Form.Item>
          </Row>

          <Row gutter={3}>
            <Form.Item<ViewStorageType> name="number_of_positions" label="Quantidades de posições">
              <InputNumber />
            </Form.Item>

            <Form.Item<ViewStorageType> name="number_of_repositories" label="Quantidades de caixas armazenadas">
              <InputNumber />
            </Form.Item>

            <Form.Item label="Ocupação" className="text-center">
              <Progress
                steps={10}
                size={[8, 20]}
                percent={percent}
                strokeColor={[green[6], green[5], green[4], green[3], green[2], red[2], red[3], red[4], red[4], red[6]]}
                status="normal"
              />
            </Form.Item>
          </Row>

          <Form.Item<ViewStorageType> name={["address", "description"]} label="Descrição do endereço">
            <Input disabled />
          </Form.Item>

          <Form.Item<ViewStorageType> label="Endereço" className="">
            <TextArea
              value={
                customerAddress
                  ? `${customerAddress.street_address}, ${customerAddress.street_number}${
                      customerAddress.complement ? ` - ${customerAddress.complement}` : ""
                    }, ${customerAddress.neighborhood}, ${customerAddress.city},  ${customerAddress.state} - CEP ${
                      customerAddress.zip_code
                    }`
                  : "requisição sem transporte"
              }
              autoSize
              disabled
            />
          </Form.Item>

          <Form.Item<ViewStorageType> name="active" label="Status">
            <Segmented
              disabled
              options={[
                { label: "Desbloqueado", value: true },
                { label: "Bloqueado", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewStorageType> name="created_at" label="Criado em">
            <Input />
          </Form.Item>
          <Form.Item<ViewStorageType> name="updated_at" label="Atualizado em">
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={false} type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="company_storage">
                  <Button
                    disabled={false}
                    type="primary"
                    onClick={() => openDrawer(<UpdateStorageForm cuid={cuid} />, "right", 600, "Editar Galpão")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
