import { Col, Divider, Form, Input, Row, Spin, Table, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { useServiceOrdersStore } from "../store";
import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import showMessage from "@utils/showMessage";
import { ShowTag, StatusOrders } from "../utils";
import { CustomerAddressType, ViewServiceOrderType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";

type ViewServiceOrderFormProps = {
  cuid: string;
};
const { TextArea } = Input;

enum Status {
  STO = "Armazenado",
  REG = "Cadastrado",
  PUR = "Expurgado",
  DEL = "Deletado",
  MIG = "Em migração",
  REQ = "Solicitado",
  INC = "Cliente",
}

export const ViewServiceOrderForm = ({ cuid }: ViewServiceOrderFormProps) => {
  const { getServiceOrder } = useServiceOrdersStore();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm<ViewServiceOrderType>();
  const { setSize } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      const result = await getServiceOrder(cuid);
      if (result.status === "success") {
        const viewServiceOrder = result.body as ViewServiceOrderType;
        const datesTransformed = {
          canceled_at: D3DateHourSorter(viewServiceOrder.canceled_at),
          created_at: D3DateHourSorter(viewServiceOrder.created_at),
          printed_at: D3DateHourSorter(viewServiceOrder.printed_at),
          ready_at: D3DateHourSorter(viewServiceOrder.ready_at),
          completed_at: D3DateHourSorter(viewServiceOrder.completed_at),
        };

        form.setFieldsValue({
          ...viewServiceOrder,
          ...datesTransformed,
          emergency_transport: viewServiceOrder.emergency_transport ? "Sim" : "Não",
          document_status: Status[viewServiceOrder.document_status as keyof typeof Status],
        });

        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getServiceOrder]);

  const customerAddress: CustomerAddressType = form.getFieldValue("customer_address") || null;

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none form-view" layout="vertical" form={form} autoComplete="off">
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(key: string) => {
              if (key === "2") {
                setSize(1000);
              } else {
                setSize(700);
              }
            }}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Número" name="id">
                          <Input
                            disabled={false}
                            readOnly
                            addonAfter={
                              <Icon
                                onClick={() =>
                                  D3Copy({
                                    text: form.getFieldValue("id"),
                                    success: "Número copiado!",
                                  })
                                }
                                className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                                icon="fluent:copy-32-filled"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Status">
                          {ShowTag(StatusOrders, form.getFieldValue("status"))}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> name="customer_name" label="Cliente">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Descrição do serviço" name="service_description">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> name="created_user_name" label="Criado por">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> name="created_at" label="Data da criação">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Nome do requisitante" name="requester_user_name">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Email do requisitante" name="requester_user_email">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> name="department_description" label="Departamento">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType>
                          name="billable_department_description"
                          label="Departamento de faturamento"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Email" name="email">
                          <Input disabled />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> name="emergency_transport" label="Transporte emergencial?">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Qtd. de documentos" name="number_of_documents">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Qtd. de repositório" name="number_of_repositories">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> name="document_status" label="Situação do documento">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item<ViewServiceOrderType> label="Observação" name="observation">
                          <TextArea autoSize={{ minRows: 3 }} disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item<ViewServiceOrderType> hidden={customerAddress === null} label="Endereço do cliente">
                      <TextArea
                        value={
                          customerAddress
                            ? `${customerAddress.street_address}, ${customerAddress.street_number}${
                                customerAddress.complement ? ` - ${customerAddress.complement}` : ""
                              }, ${customerAddress.neighborhood}, ${customerAddress.city},  ${
                                customerAddress.state
                              } - CEP ${customerAddress.zip_code}`
                            : "requisição sem transporte"
                        }
                        autoSize
                        disabled
                      />
                    </Form.Item>

                    {form.getFieldValue("printed") && (
                      <>
                        <Divider plain>Dados da impressão</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="printed_user_name" label="Impresso por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="printed_at" label="Data da impressão">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("ready") && (
                      <>
                        <Divider plain>Dados da preparação</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="ready_user_name" label="Preparado por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="ready_at" label="Data da preparação">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("completed") && (
                      <>
                        <Divider plain>Dados do atendimento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="completed_user_name" label="Atendido por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="completed_at" label="Data do atendimento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    {form.getFieldValue("canceled") && (
                      <>
                        <Divider plain>Dados do cancelamento</Divider>
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="canceled_user_name" label="Cancelado por">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item<ViewServiceOrderType> name="canceled_at" label="Data do cancelamento">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item<ViewServiceOrderType> name="cancel_reason" label="Motivo do cancelamento">
                          <TextArea autoSize={{ minRows: 3 }} disabled />
                        </Form.Item>
                      </>
                    )}
                  </>
                ),
              },
              {
                key: "2",
                label: (
                  <span className="flex items-center justify-center gap-2 text-gray-600">
                    <Icon icon="ion:documents" className="w-5 h-5" />
                    Documentos
                  </span>
                ),
                children: (
                  <Table
                    className="mt-2"
                    key="items"
                    size="small"
                    bordered
                    rowKey="document_physical_tag_id"
                    pagination={false}
                    dataSource={form.getFieldValue("items")}
                    columns={[
                      {
                        title: "Etiqueta do repositório",
                        dataIndex: "repository_physical_tag_id",
                        key: "repository_physical_tag_id",
                      },
                      {
                        title: "Etiqueta do documento",
                        dataIndex: "document_physical_tag_id",
                        key: "document_physical_tag_id",
                      },
                      {
                        title: "Descrição",
                        dataIndex: "description",
                        key: "description",
                      },
                      {
                        title: "Quantidade",
                        dataIndex: "quantity",
                        key: "quantity",
                      },
                      {
                        title: "Parcial?",
                        dataIndex: "partial",
                        key: "partial",
                        render: (value) => (value ? "Sim" : "Não"),
                      },
                      {
                        title: "Preserva posição?",
                        dataIndex: "preserve_position",
                        key: "preserve_position",
                        render: (value) => (value ? "Sim" : "Não"),
                      },
                      {
                        title: "Última posição",
                        dataIndex: "old_repository_position",
                        key: "old_repository_position",
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </Form>
      </Spin>
    </>
  );
};
