import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin, Tabs } from "antd";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import D3PermCheckTable from "@components/shared/D3PermCheckTable/D3PermCheckTable";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { StandardError } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { useCustomerGroupUsersStore } from "../store";
import { CreateCustomerGroupUserType } from "../types";
import Selector from "@components/shared/Selector";

export const CreateCustomerGroupUserForm = () => {
  const { createCustomerGroupUser, loadingReport, reports, simpleListReports } = useCustomerGroupUsersStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateCustomerGroupUserType>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await simpleListReports();
    })();
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateCustomerGroupUserType) => {
            setLoading(true);
            const result = await createCustomerGroupUser(payload);

            if (result.status === "success") {
              showMessage(result, "Usuário Grupo de Cliente cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(tab) => (tab === "1" ? setSize(600) : setSize(1200))}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<CreateCustomerGroupUserType>
                      name="name"
                      label="Nome"
                      rules={[{ required: true, message: "Por favor. insira nome." }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item<CreateCustomerGroupUserType>
                      label="Usuário"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o usuário.",
                        },
                        {
                          max: 30,
                          message: "O usuário deve ter menos de 30 caracteres.",
                        },
                        {
                          validator: (_, value) =>
                            /\s/.test(value)
                              ? Promise.reject(new Error("O usuário não pode conter espaços."))
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<CreateCustomerGroupUserType>
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o e-mail.",
                        },
                        {
                          type: "email",
                          message: "Por favor, insira um e-mail válido.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<CreateCustomerGroupUserType> label="Relatórios" name="reports_cuids">
                      <Selector
                        fieldMap={{
                          value: "cuid",
                          label: "title",
                          description: "description",
                          dataLabel: ["title"],
                          dataFilter: ["title", "description"],
                        }}
                        mode="multiple"
                        options={reports}
                        loading={loadingReport}
                      />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "w",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                    Permissões
                  </span>
                ),
                children: (
                  <Form.Item<CreateCustomerGroupUserType> name="permissions" label="Permissões Personalizadas">
                    <D3PermCheckTable
                      profile="CGR"
                      onChange={(newValues) => {
                        form.setFieldValue("permissions", newValues);
                      }}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="company_user">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
