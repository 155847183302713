import D3ProtectPage from "@components/shared/D3ProtectPage";
import ChangeUserPassword from "./components/change-user-password";
import DeleteDocumentTypeIndexers from "./components/delete-document-type-indexers";

type ToolItemsType = {
  key: string;
  grupo: string;
  Children: {
    key: string;
    label: string;
    icon: string;
    drawer: JSX.Element;
  }[];
}[];

export const ToolItems: ToolItemsType = [
  {
    key: "1",
    grupo: "Clientes",
    Children: [
      {
        key: "1-1",
        label: "Excluir Indexador",
        icon: "mdi:file-document-delete",
        drawer: (
          <D3ProtectPage
            targetPage={<DeleteDocumentTypeIndexers />}
            isRequiredCustomerGroup={false}
            isRequiredCustomer={true}
            resource="admin_tool"
            action="delete_document_type_index"
          />
        ),
      },
    ],
  },
  {
    key: "2",
    grupo: "Usuários",
    Children: [
      {
        key: "2-1",
        label: "Alterar senha",
        icon: "fluent:password-reset-48-filled",
        drawer: (
          <D3ProtectPage
            targetPage={<ChangeUserPassword />}
            isRequiredCustomerGroup={false}
            isRequiredCustomer={false}
            resource="admin_tool"
            action="manage_user_password"
          />
        ),
      },
    ],
  },
];
