import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, List, Radio, Space, Tag } from "antd";
import { useContext, useEffect } from "react";
import { ReadyOrderType, SelectAddressItemType } from "../types";
import { useMaterialOrdersStore } from "../store";
import SpinLoading from "@components/shared/SpinLoading";

type ServeServiceOrderFormProps = {
  orderCuid: string;
};

function ReadyMaterialOrderForm({ orderCuid }: ServeServiceOrderFormProps) {
  const { simpleListAddress, addresses, loadingAddress, readyOrder, loadingReady } = useMaterialOrdersStore();
  const [form] = Form.useForm<ReadyOrderType>();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await simpleListAddress();
    })();
    return () =>
      useMaterialOrdersStore.setState({
        addresses: [],
      });
  }, [orderCuid]);

  return loadingAddress ? (
    <SpinLoading />
  ) : (
    <Form
      className="w-full h-full select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: ReadyOrderType) => {
        const isReady = await readyOrder(orderCuid, payload);
        if (isReady) {
          onClose();
        }
      }}
    >
      <Form.Item<ReadyOrderType>
        label="Endereço de retirada dos materiais"
        name="pickup_address_cuid"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o endereço.",
          },
        ]}
      >
        <List bordered className="flex flex-col h-full" itemLayout="horizontal" dataSource={addresses}>
          <Radio.Group className="w-full" name="radiogroup">
            {addresses.map(({ active, address, is_default, label, value }: SelectAddressItemType) => (
              <List.Item key={value}>
                <List.Item.Meta
                  avatar={
                    <div className="flex min-h-[70px]">
                      <Radio value={value} />
                    </div>
                  }
                  title={
                    <div className="flex gap-2 ">
                      <span>{label}</span> {is_default && <Tag color="success">Padrão</Tag>}
                    </div>
                  }
                  description={address}
                />
              </List.Item>
            ))}
          </Radio.Group>
        </List>
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingReady} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="serve" resource="order_service">
              <Button loading={loadingReady} type="primary" htmlType="submit">
                Ordem pronta para atendimento
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default ReadyMaterialOrderForm;
