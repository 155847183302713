import type { CSSProperties } from "react";
import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import type { CollapseProps } from "antd";
import { Collapse, theme } from "antd";
import CollapseSuccess from "./components/CollapseSuccess";
import { useDocumentBatchStore } from "./store";
import FormValidation from "./components/FormValidation";
import FormFill from "./components/FormFill";

function RegisterDocumentBatch() {
  const { prePayload, documentTypes, success } = useDocumentBatchStore();

  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (panelStyle) => [
    {
      hidden: success.length === 0,
      key: "1",
      label: <>{`Documentos cadastrados: ${success.length}`}</>,
      children: <CollapseSuccess />,
      style: panelStyle,
    },
    {
      key: "2",
      label: `Repositório ${
        prePayload.repository_physical_tag_id ? `: ${prePayload.repository_physical_tag_id}` : ""
      }  | Tipo de Documento ${
        prePayload.document_type_cuid
          ? `: ${documentTypes.find((doc) => doc.cuid === prePayload.document_type_cuid)?.description}`
          : ""
      }`,
      children: (
        <div className="flex flex-col h-full p-2 overflow-hidden bg-white">
          <FormValidation />
          <FormFill />
        </div>
      ),
      style: panelStyle,
      className: "flex flex-col h-full overflow-hidden neto",
    },
  ];

  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 10,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-hidden bg-white rounded shadow-md">
      <div className="flex flex-col items-center justify-center h-12 border-b border-gray-200 min-h-12 ">
        <div className="flex items-center justify-center">
          <span className="text-xl font-semibold text-gray-600">Cadastramento de Documento Físico em Lote</span>
        </div>
      </div>
      <div className="flex flex-col p-2 overflow-hidden">
        <Collapse
          accordion
          className="flex flex-col min-w-full overflow-hidden select-none"
          bordered={false}
          defaultActiveKey={["2"]}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          style={{ background: token.colorBgContainer }}
          items={getItems(panelStyle)}
        />
      </div>
    </div>
  );
}

export default RegisterDocumentBatch;
