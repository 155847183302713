import { Icon } from "@iconify/react";
import { Badge, Button, Form, Input, List, Segmented, Select } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";
import { useEffect, useState } from "react";
import { SearchPhysicalDocumentType } from "../types";
import { usePhysicalDocumentsStore } from "../store";

type FormItemSwitcherProps = {
  attribute: NamePath | NamePath[];
  label: string;
  form: FormInstance<SearchPhysicalDocumentType>;
  isId?: boolean;
};

const { TextArea } = Input;

function FormItemSwitcher({ attribute, label, form, isId = false }: FormItemSwitcherProps): JSX.Element {
  const { filter } = usePhysicalDocumentsStore();
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [documentsCuid, setDocumentsCuid] = useState<(string | number)[]>([]);
  const [textArea, setTextArea] = useState<string[]>([]);
  const [input, setInput] = useState<string>("");

  const removeItem = (index: number) => {
    const newArray = [...documentsCuid];
    newArray.splice(index, 1);
    setDocumentsCuid(newArray);
  };

  const getFilterAttributesValues = (obj: SearchPhysicalDocumentType, path: NamePath) => {
    return (Array.isArray(path) ? path : [path]).reduce((acc, key) => acc && acc[key], obj);
  };

  useEffect(() => {
    setDocumentsCuid(getFilterAttributesValues(filter, attribute) || []);
  }, []);

  useEffect(() => {
    const setNestedFieldValue = (path: NamePath, value: any) => {
      return path.reduceRight((acc: any, key: any) => ({ [key]: acc }), value);
    };
    form.setFieldsValue(setNestedFieldValue(attribute, documentsCuid));
  }, [documentsCuid, form]);

  const validate = (input: string | string[]) => {
    const validateString = (str: string) => {
      const isValidLength = isId ? str.length >= 1 : str.length === 12;
      const isNumeric = /^\d+$/.test(str);
      const isNotInDocumentsCuid = !documentsCuid.includes(isId ? parseInt(str, 10) : str);

      if (isValidLength && isNumeric && isNotInDocumentsCuid) {
        return isId ? parseInt(str, 10) : str;
      }
      return null;
    };

    if (Array.isArray(input)) {
      const validatedItems = input.map(validateString).filter((item) => item !== null) as (string | number)[];
      setDocumentsCuid([...validatedItems, ...documentsCuid]);
    } else {
      const result = validateString(input);
      if (result !== null) {
        setDocumentsCuid([result, ...documentsCuid]);
      }
    }
  };

  return (
    <Form.Item label={label} className="w-full p-0 my-2">
      <div className="flex flex-col gap-3">
        <Form.Item hidden name={attribute} normalize={() => documentsCuid}>
          <Select
            mode="multiple"
            options={documentsCuid.map((item) => ({
              label: item.toString(),
              value: item,
            }))}
            suffixIcon={<></>}
          />
        </Form.Item>
        <Badge.Ribbon className="select-none" text={documentsCuid.length}>
          <List
            size="small"
            bordered
            className="overflow-y-auto select-none min-h-44 max-h-44 h-44 custom-scroll"
            dataSource={documentsCuid}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                actions={[
                  <Button
                    size="small"
                    icon={<Icon height={18} icon="material-symbols:delete" />}
                    type="text"
                    danger
                    onClick={() => removeItem(index)}
                  />,
                ]}
              >
                {item.toString()}
              </List.Item>
            )}
          />
        </Badge.Ribbon>
        <Segmented
          className="select-none"
          defaultValue="single"
          onChange={(e) => setIsMultiple(e === "multiple")}
          options={[
            {
              value: "single",
              label: "Único",
            },
            {
              value: "multiple",
              label: "Múltiplos",
            },
          ]}
        />

        {!isMultiple ? (
          <Input showCount maxLength={isId ? 10 : 12} value={input} onChange={(e) => setInput(e.target.value.trim())} />
        ) : (
          <TextArea
            rows={3}
            value={textArea.join("\n")}
            onChange={(e) => {
              const newValues = e.target.value.split("\n");
              const values = newValues.filter((item) => !documentsCuid.includes(isId ? parseInt(item, 10) : item));
              setTextArea(values);
            }}
          />
        )}
        <div className="flex gap-3">
          <Button
            onClick={() => {
              if (isMultiple) {
                validate(textArea);
                setTextArea([]);
              } else {
                validate(input);
                setInput("");
              }
            }}
          >
            Adicionar
          </Button>
          <Button danger type="dashed" onClick={() => setDocumentsCuid([])}>
            Limpar lista
          </Button>
        </div>
      </div>
    </Form.Item>
  );
}

export default FormItemSwitcher;
