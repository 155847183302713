import { Button, Form, Input, InputNumber, Segmented, Select, Space, Spin } from "antd";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import extractOptions from "@utils/extractOptions";
import { useDocumentTypesStore } from "../store";
import { ListPreviewType } from "../types";

type ConfigFormType = {
  config?: ListPreviewType;
};

function ConfigForm({ config }: ConfigFormType) {
  const { getIndexadores, setIndexadores, indexadores } = useDocumentTypesStore();

  const [form] = Form.useForm<ListPreviewType>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (config) {
      form.setFieldsValue(config);
      setLoading(false);
    }
    return () => form.resetFields();
  }, [config]);
  const handleValuesChange = (changedValues: any, allValues: ListPreviewType) => {
    const indexadores = getIndexadores();
    const index = indexadores.findIndex((input) => input.order === allValues.order);

    if (index !== -1) {
      const updatedIndexadores = [
        ...indexadores.slice(0, index),
        {
          ...indexadores[index],
          ...allValues,
        },
        ...indexadores.slice(index + 1),
      ];
      setIndexadores(updatedIndexadores);
    }
  };

  const validateValueOptions = async (_: any, value: any) => {
    if (
      form
        .getFieldValue("list_of_key_and_value")
        .filter((item: { value: string }) => item.value.toUpperCase() === value.toUpperCase()).length > 1
    ) {
      return Promise.reject(new Error("Nome não pode se repetir."));
    }
    return Promise.resolve();
  };

  const validateDescription = async (_: any, value: any) => {
    if (
      indexadores.filter(
        ({ label, system_index_type_id }) =>
          label.toUpperCase() === value.toUpperCase() && config?.system_index_type_id === system_index_type_id
      ).length > 1
    ) {
      return Promise.reject(new Error("Descrição não pode se repetir."));
    }
    return Promise.resolve();
  };

  function IsValueUndefined() {
    if (form.getFieldValue("list_of_key_and_value")?.length > 0) {
      return form
        .getFieldValue("list_of_key_and_value")
        .some((elemento: any) => elemento === null || elemento === undefined);
    }
    return false;
  }
  if (config && indexadores.length > 0) {
    return (
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" className="mt-2" autoComplete="off" onValuesChange={handleValuesChange}>
          <Form.Item<ListPreviewType> hidden={true} name="id" label="ID">
            <Input />
          </Form.Item>
          <Form.Item<ListPreviewType> hidden={true} name="order" label="Order">
            <Input />
          </Form.Item>
          <Form.Item<ListPreviewType>
            name="label"
            label="Descrição"
            rules={[
              {
                required: true,
                message: "Por favor, informe a descrição.",
              },
              {
                validator: validateDescription,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<ListPreviewType> name="size" hidden={!config.default_input.can_set_size} label="Tamanho">
            <InputNumber />
          </Form.Item>
          <Form.Item<ListPreviewType>
            hidden={config.default_input.can_set_list_of_key_and_value}
            name="default_search_option"
            label="Tipo de busca padrão"
          >
            <Select
              value={"EQ"}
              options={
                config.default_input.can_set_list_of_key_and_value
                  ? []
                  : extractOptions(config.default_input.search_options)
              }
            />
          </Form.Item>

          <Form.Item<ListPreviewType> name="is_allowed_copy" label="Mostrar Copiar?">
            <Segmented
              size="small"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ListPreviewType>
            hidden={!config.default_input.can_set_global}
            name="is_global_index"
            label="Indexador global?"
          >
            <Segmented
              size="small"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ListPreviewType>
            hidden={!config.default_input.can_set_required}
            name="required"
            label="Obrigatório?"
          >
            <Segmented
              size="small"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ListPreviewType>
            name="search_key"
            hidden={!config.default_input.can_set_search_key}
            label="Chave de Busca?"
          >
            <Segmented
              size="small"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ListPreviewType>
            name="start_interval_required"
            hidden={!config.default_input.can_set_start_interval_required}
            label="Obrigatório valor inicial do intervalo?"
          >
            <Segmented
              size="small"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ListPreviewType>
            name="end_interval_required"
            hidden={!config.default_input.can_set_end_interval_required}
            label="Obrigatório valor final do intervalo?"
          >
            <Segmented
              size="small"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>

          <Form.Item<ListPreviewType>
            name="temporality_date"
            hidden={!config.default_input.can_set_temporality_date}
            label="Calcular Temporalidade?"
          >
            <Segmented
              size="small"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
          </Form.Item>
          <Form.Item<ListPreviewType>
            hidden={!config.default_input.can_set_list_of_key_and_value}
            label="Opções para combo"
          >
            <Form.List name="list_of_key_and_value">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} className="flex " align="baseline">
                      <Form.Item hidden {...restField} name={[name, "key"]}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Por favor, informe a opção.",
                          },
                          {
                            validator: validateValueOptions,
                          },
                        ]}
                      >
                        <Input placeholder="Nome da opção" />
                      </Form.Item>
                      <Icon
                        icon="mdi:delete"
                        height={18}
                        className="text-red-400 transition-all duration-200 ease-in cursor-pointer hover:scale-110 hover:text-red-500"
                        onClick={() => remove(name)}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      className="flex items-center justify-center"
                      type="dashed"
                      onClick={() => add()}
                      block
                      disabled={IsValueUndefined()}
                      icon={<Icon icon="charm:plus" />}
                    >
                      Adicionar opção
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Spin>
    );
  } else {
    return null;
  }
}

export default ConfigForm;
