import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  CancelServiceOrderType,
  CompletedServiceOrderType,
  ItemSimpleListCustomerType,
  ItemSimpleListDepartmentType,
  ListServiceOrderType,
  PrintResendType,
  PrintType,
  ReadyServiceOrderType,
  ResendType,
  SearchServiceOrderType,
  SimpleListCustomerType,
  SimpleListDepartmentType,
  ViewServiceOrderType,
  SimpleListAddressType,
  SelectAddressItemType,
  CompletedPreviewType,
  ListDocumentsOrderType,
  DeletedDocumentsType,
  SearchDocumentOrderType,
  CancelOrderType,
  ItemSimpleListServicesType,
  SimpleListServicesType,
} from "./types";

type ServiceOrderStoreState = {
  apiResult: ListServiceOrderType;
  loadServiceOrders: () => Promise<void>;
  loading: boolean;
  pagination: { page: number; pageSize: number };
  setPagination: (page: number, pageSize: number) => void;
  filter: SearchServiceOrderType;
  setFilter: (filter: SearchServiceOrderType) => void;
  resetFilter: () => void;
  resetState: () => void;

  simpleListDepartmentByCustomerCuid: (customerCuid: string) => Promise<void>;
  loadingDepartments: boolean;
  listDepartments: ItemSimpleListDepartmentType[];

  simpleListCustomer: () => Promise<void>;
  loadingCustomer: boolean;
  listCustomer: ItemSimpleListCustomerType[];

  cancelServiceOrder: (orderCuid: string, payload: CancelOrderType) => Promise<boolean>;
  loadingCancel: boolean;

  getServiceOrder: (orderCuid: string) => Promise<Success<ViewServiceOrderType> | BadRequest | StandardError>;
  getUrl: (orderCuid: string) => Promise<Success<PrintType> | StandardError>;

  printResendOrderServiceByCuid: (orderCuid: string) => Promise<void>;
  urlPrintResendOrder: string;

  resendServiceOrderByCuid: (orderCuid: string) => Promise<void>;
  urlResendOrder: string;

  completedServiceOrder: (orderCuid: string, payload: CompletedServiceOrderType) => Promise<boolean>;
  completedLoading: boolean;

  simpleListAddress: () => Promise<void>;
  addresses: SelectAddressItemType[];
  loadingAddress: boolean;

  readyServiceOrder: (orderCuid: string, payload: ReadyServiceOrderType) => Promise<boolean>;
  loadingReady: boolean;

  completedPreview: (orderCuid: string) => Promise<void>;
  preview: CompletedPreviewType;
  loadingPreview: boolean;

  documentsOrder: (orderCuid: string) => Promise<void>;
  documents: ListDocumentsOrderType;
  loadingDocuments: boolean;

  deletedDocuments: (orderCuid: string, payload: DeletedDocumentsType) => Promise<boolean>;
  loadingDeleted: boolean;

  selectedRows: string[];

  paginationDocuments: { page: number; pageSize: number };
  setPaginationDocuments: (page: number, pageSize: number, orderCuid: string) => void;

  filterDocuments: SearchDocumentOrderType;
  setFilterDocuments: (filter: SearchDocumentOrderType, orderCuid: string) => void;
  resetFilterDocuments: (orderCuid: string) => void;

  openSearchDocuments: boolean;

  getServices: () => Promise<void>;
  listServices: ItemSimpleListServicesType[];
  loadingServices: boolean;
};

const serviceOrdersFilter: FilterDefinition = {
  so_id: { op: "eq", type: "string" },
  c_cuid: { op: "eq", type: "string" },
  department_cuid: { op: "eq", type: "string" },
  service_cuid: { op: "eq", type: "string" },
  uc_name: { op: "lk", type: "string" },
  ru_name: { op: "lk", type: "string" },
  so_observation: { op: "lk", type: "string" },
  so_status: { op: "eq", type: "array" },
  so_has_order_resent: { op: "eq", type: "active" },
};

const documentsFilter: FilterDefinition = {
  document_physical_tag_id: { op: "lk", type: "string" },
  repository_physical_tag_id: { op: "lk", type: "string" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  loading: false,
  filter: {
    so_id: "",
    c_cuid: "",
    department_cuid: "",
    service_cuid: "",
    so_status: ["WTG", "RDY"],
    so_observation: "",
    uc_name: "",
    so_has_order_resent: "all",
  } as SearchServiceOrderType,

  pagination: {
    page: 1,
    pageSize: 10,
  },

  loadingDepartments: false,
  listDepartments: [],

  loadingCustomer: false,
  listCustomer: [],

  urlPrintResendOrder: "",
  urlResendOrder: "",

  completedLoading: false,

  addresses: [],
  loadingAddress: false,

  loadingReady: false,

  preview: {} as CompletedPreviewType,
  loadingPreview: false,

  documents: {
    items: [],
    total: 0,
  },

  loadingDocuments: false,

  loadingDeleted: false,

  selectedRows: [],

  paginationDocuments: {
    page: 1,
    pageSize: 10,
  },

  filterDocuments: {
    document_physical_tag_id: "",
    repository_physical_tag_id: "",
  },

  openSearchDocuments: false,

  loadingCancel: false,

  listServices: [],
  loadingServices: false,
};

export const useServiceOrdersStore = create<ServiceOrderStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchServiceOrderType) => {
    set({
      filter,
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadServiceOrders();
  },
  resetFilter: () => {
    set({ filter: { ...defaultState.filter, so_status: [] } });
    get().loadServiceOrders();
  },

  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadServiceOrders();
  },

  loadServiceOrders: async (): Promise<void> => {
    set({ loading: true });

    let qs = D3QSGenerator(get().filter, serviceOrdersFilter, get().pagination);

    await new ClientHttp().get<Success<ListServiceOrderType>, BadRequest | StandardError>(
      `/api/v1/orders/services?${qs}`,
      (result: Success<ListServiceOrderType>) => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },
  simpleListDepartmentByCustomerCuid: async (customerCuid: string): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      `/api/v1/system/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): void => {
        set({ listDepartments: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingDepartments: false });
  },
  simpleListCustomer: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/system/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomer: false });
  },
  cancelServiceOrder: async (orderCuid: string, payload: CancelOrderType): Promise<boolean> => {
    set({ loadingCancel: true });
    const result = await new ClientHttp().patch<CancelServiceOrderType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}/cancel`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Ordem cancelada com sucesso.");
        set({ loadingCancel: false });
        get().loadServiceOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCancel: false });
      }
    );
    return result.status === "success";
  },

  getServiceOrder: async (orderCuid: string): Promise<Success<ViewServiceOrderType> | BadRequest | StandardError> => {
    return await new ClientHttp().get<Success<ViewServiceOrderType>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}`,
      (result: Success<ViewServiceOrderType>): ViewServiceOrderType => {
        return result.body;
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },

  getUrl: async (orderCuid: string): Promise<Success<PrintType> | StandardError> => {
    return await new ClientHttp().get<Success<PrintType>, StandardError>(
      `/api/v1/orders/services/${orderCuid}/print`,
      (result: Success<PrintType>): PrintType => {
        return result.body;
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },

  printResendOrderServiceByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().get<Success<PrintResendType>, StandardError>(
      `/api/v1/orders/services/${orderCuid}/print-resend`,
      (result: Success<PrintResendType>): void => {
        set({
          urlPrintResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  resendServiceOrderByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().post<undefined, Success<ResendType>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}/resend`,
      undefined,
      (result: Success<ResendType>): void => {
        set({
          urlResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  readyServiceOrder: async (orderCuid: string, payload: ReadyServiceOrderType): Promise<boolean> => {
    set({ loadingReady: true });
    const result = await new ClientHttp().patch<ReadyServiceOrderType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}/ready`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Preparação concluída com sucesso.");
        set({ loadingReady: false });
        get().loadServiceOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingReady: false });
      }
    );
    return result.status === "success";
  },
  completedServiceOrder: async (orderCuid: string, payload: CompletedServiceOrderType): Promise<boolean> => {
    set({ completedLoading: true });
    const result = await new ClientHttp().patch<CompletedServiceOrderType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}/completion`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Ordem atendida com sucesso.");
        set({ completedLoading: false });
        get().loadServiceOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ completedLoading: false });
      }
    );
    return result.status === "success";
  },
  simpleListAddress: async (): Promise<void> => {
    set({ loadingAddress: true });
    await new ClientHttp().get<Success<SimpleListAddressType>, BadRequest | StandardError>(
      "/api/v1/system/addresses/simple-list?isStorageAddress=true",
      (result: Success<SimpleListAddressType>): void => {
        set({
          addresses: result.body.data.map(
            ({
              cuid,
              description,
              active,
              street_address,
              street_number,
              city,
              complement,
              zip_code,
              state,
              is_default,
            }) => {
              return {
                value: cuid,
                label: description,
                active: active,
                is_default: is_default,
                address: `${street_address}, nº ${street_number}${
                  complement ? ", " + complement : ""
                }, ${city} - ${state}, Cep ${zip_code}`,
              } as SelectAddressItemType;
            }
          ),
          loadingAddress: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingAddress: false });
      }
    );
  },
  completedPreview: async (orderCuid: string): Promise<void> => {
    set({ loadingPreview: true });
    await new ClientHttp().get<Success<CompletedPreviewType>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}/completion-previewer`,
      (result: Success<CompletedPreviewType>): void => {
        set({
          preview: result.body,
          loadingPreview: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingPreview: false });
      }
    );
  },
  documentsOrder: async (orderCuid: string): Promise<void> => {
    set({ loadingDocuments: true });

    let qs = D3QSGenerator(get().filterDocuments, documentsFilter, get().paginationDocuments);

    await new ClientHttp().get<Success<ListDocumentsOrderType>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}/documents?${qs}`,
      (result: Success<ListDocumentsOrderType>): void => {
        set({
          documents: result.body,
          loadingDocuments: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingDocuments: false });
      }
    );
  },
  deletedDocuments: async (orderCuid: string, payload: DeletedDocumentsType): Promise<boolean> => {
    set({ loadingDeleted: true });
    const result = await new ClientHttp().put<DeletedDocumentsType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/services/${orderCuid}/documents`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Documentos excluídos com sucesso.");
        set({ loadingDeleted: false });
        get().loadServiceOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingDeleted: false });
      }
    );
    return result.status === "success";
  },

  setPaginationDocuments: (page: number, pageSize: number, orderCuid: string) => {
    if (pageSize !== get().paginationDocuments.pageSize) {
      page = 1;
    }
    set({ paginationDocuments: { page, pageSize } });
    get().documentsOrder(orderCuid);
  },

  setFilterDocuments: (filterDocuments: SearchDocumentOrderType, orderCuid: string) => {
    set((state) => ({
      filterDocuments,
      paginationDocuments: { ...state.paginationDocuments, page: 1 },
      openSearchDocuments: false,
    }));
    get().documentsOrder(orderCuid);
  },
  resetFilterDocuments: (orderCuid: string) => {
    set({
      filterDocuments: { ...defaultState.filterDocuments },
      openSearchDocuments: false,
    });
    get().documentsOrder(orderCuid);
  },

  getServices: async (): Promise<void> => {
    set({ loadingServices: true });
    await new ClientHttp().get<Success<SimpleListServicesType>, BadRequest | StandardError>(
      `/api/v1/system/services/simple-list`,
      (result: Success<SimpleListServicesType>) => {
        set({
          listServices: result.body.data,
          loadingServices: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingServices: false });
      }
    );
  },
}));
