import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { D3ColorContrast } from "@utils/D3HexContrast";
import { Button, Popconfirm, Popover, Space, Tag, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { CreateMarkerForm } from "./components/CreateMarkerForm";
import { SearchMarkerForm } from "./components/SearchMarkerForm";
import { UpdateMarkerForm } from "./components/UpdateMarkerForm";
import { ViewMarkerForm } from "./components/ViewMarkerForm";
import { useMarkersStore } from "./store";
import { ItemListMarkersType } from "./types";

const MarkersView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const { apiResult, loading, loadMarkers, resetFilter, resetState, deleteMarker } = useMarkersStore();
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    (async () => {
      await loadMarkers();
    })();
    return () => {
      resetState();
    };
  }, [loadMarkers]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<ItemListMarkersType>
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Marcadores"
                    content={
                      <SearchMarkerForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Marcadores</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="customer_marker">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateMarkerForm />, "right", 600, "Cadastrar Marcador")}
                    >
                      Novo Marcador
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
      >
        <Table.Column<ItemListMarkersType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="key"
          title="Marcador"
          dataIndex="key"
          className="text-red-500"
          render={(value, marker) => (
            <Tag
              icon={<Icon icon="mingcute:tag-fill" className={`${D3ColorContrast(marker.color)}`} />}
              className={`flex flex-row items-center justify-start space-x-1 shadow max-w-max `}
              color={marker.color}
            >
              <p className={`${D3ColorContrast(marker.color)} font-semibold`}>{value}</p>
            </Tag>
          )}
        />
        <Table.Column<ItemListMarkersType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_by"
          title="Criado por"
          dataIndex="created_by"
        />

        <Table.Column<ItemListMarkersType>
          showSorterTooltip={false}
          key="created_by"
          title="Criado em"
          dataIndex="created_at"
          render={(value, marker) => {
            return new Date(marker.created_at)
              .toLocaleString("pt-BR", {
                dateStyle: "short",
                timeStyle: "short",
              })
              .replace(",", " às ");
          }}
          sorter
        />
        <Table.Column<ItemListMarkersType>
          key="action"
          title="Ações"
          width={130}
          render={(value, marker, index) => {
            return (
              <>
                <Space>
                  <D3Can action="update" resource="customer_marker">
                    <Tooltip title="Editar" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<UpdateMarkerForm cuid={marker.cuid} />, "right", 600, "Editar Marcador")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="customer_marker">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<ViewMarkerForm cuid={marker.cuid} />, "right", 600, "Visualizar Marcador")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="delete" resource="customer_marker">
                    <Popconfirm
                      title="Atenção!"
                      description="Confirmar exclusão do marcador?"
                      onConfirm={() => deleteMarker(marker.cuid)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Tooltip title="Delete" trigger={["click", "hover"]}>
                        <Button
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="material-symbols:delete"
                              height={18}
                              width={18}
                              className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default MarkersView;
