import { Icon } from "@iconify/react";
import { Editor, loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import { useReportsStore } from "@views/reports/store";
import { UpdateReportType, UpdateReportSessionType } from "@views/reports/types";
import { Button, Form, Input, List, Popover, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import formatSql from "@utils/formatSql";

function TabSessions() {
  const { payloadUpdate } = useReportsStore();
  const [form] = Form.useForm<UpdateReportSessionType>();
  const providerDisposableRef = useRef<monaco.IDisposable | null>(null);
  const [edit, setEdit] = useState<UpdateReportSessionType>({} as UpdateReportSessionType);

  useEffect(() => {
    loader.init().then((monaco) => {
      if (providerDisposableRef.current) {
        providerDisposableRef.current.dispose();
      }
      providerDisposableRef.current = monaco.languages.registerCompletionItemProvider("sql", {
        provideCompletionItems: (model, position) => {
          const wordInfo = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            startColumn: wordInfo.startColumn,
            endLineNumber: position.lineNumber,
            endColumn: wordInfo.endColumn,
          };

          const suggestions = payloadUpdate.report_params.map((param) => {
            return {
              label: param.label,
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: param.required ? `${param.name}` : `[${param.name}] ${param.name} ${"${1}"}[/${param.name}]`,
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation: param.required
                ? `Inserir o parâmetro ${param.name} no script`
                : `Inserir o parâmetro [${param.name}] ${param.name} [/${param.name}] no script`,
              range: range,
            };
          });

          if (payloadUpdate.context === "CTM" || payloadUpdate.context === "CGR") {
            suggestions.push({
              label: payloadUpdate.context === "CTM" ? "Contexto Cliente" : "Contexto Grupo de Cliente",
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: payloadUpdate.context === "CTM" ? "@ENV_CUSTOMER_ID@" : "@ENV_CUSTOMER_GROUP_ID@",
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              documentation:
                payloadUpdate.context === "CTM"
                  ? "Inserir o parâmetro @ENV_CUSTOMER_ID@ no script."
                  : "Inserir o parâmetro @ENV_CUSTOMER_GROUP_ID@ no script.",
              range: range,
            });
          }

          return {
            suggestions: [...new Set(suggestions)],
          };
        },
      });
    });
    return () => {
      if (providerDisposableRef.current) {
        providerDisposableRef.current.dispose();
        providerDisposableRef.current = null;
      }
    };
  }, [payloadUpdate.report_params, edit, payloadUpdate.context]);

  useEffect(() => {
    if (edit.title) {
      form.setFieldsValue(edit);
    }
  }, [edit, form]);

  const onDragEnd = (result: DropResult, payloadUpdate: UpdateReportType, setState: any) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedItems = Array.from(payloadUpdate.report_sessions);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    setState((state: any) => ({
      payloadUpdate: {
        ...state.payloadUpdate,
        report_sessions: updatedItems,
      },
    }));
  };

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <Form
        key="params"
        form={form}
        className="w-full "
        layout="vertical"
        onFinish={(values: UpdateReportSessionType) => {
          if (edit.title) {
            useReportsStore.setState((state) => ({
              payloadUpdate: {
                ...state.payloadUpdate,
                report_sessions: state.payloadUpdate.report_sessions.map((item) =>
                  item.title === edit.title ? { ...values } : item
                ),
              },
            }));
            form.resetFields();
            setEdit({} as UpdateReportSessionType);
          } else {
            const newSession = {
              ...values,
              order: payloadUpdate.report_sessions.length + 1,
            };
            useReportsStore.setState((state) => ({
              payloadUpdate: {
                ...state.payloadUpdate,
                report_sessions: [...state.payloadUpdate.report_sessions, newSession],
              },
            }));
            form.resetFields();
          }
        }}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="order">
          <Input />
        </Form.Item>
        <Form.Item
          name="title"
          label="Nome"
          rules={[
            {
              required: true,
              message: "Por favor, preencha o nome.",
            },
            {
              validator: (_, value) => {
                if (Array.isArray(payloadUpdate.report_sessions)) {
                  const existingName = payloadUpdate.report_sessions.find(
                    (item) => item.title.toLowerCase() === value.toLowerCase()
                  );
                  if (existingName && existingName.title !== edit.title) {
                    return Promise.reject(new Error(`O nome '${value.replace(/^@|@$/g, "")}' já está em uso.`));
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="query"
          label="Script"
          rules={[
            {
              required: true,
              message: "Por favor, preencha o script.",
            },
          ]}
        >
          <Editor
            theme="vs-dark"
            options={{
              minimap: { enabled: false },
              padding: {
                top: 10,
                bottom: 10,
              },
              scrollbar: {
                vertical: "visible",
                horizontal: "visible",
                verticalScrollbarSize: 8,
                horizontalScrollbarSize: 8,
                arrowSize: 15,
              },
            }}
            height="300px"
            defaultLanguage="sql"
          />
        </Form.Item>

        <Form.Item className="w-full">
          <Button size="large" block type="primary" htmlType="button" onClick={() => form.submit()}>
            {edit.title ? "Editar Sessão" : "Adicionar Sessão"}
          </Button>
        </Form.Item>
      </Form>
      <div className="flex flex-col h-full px-3 overflow-hidden">
        <DragDropContext onDragEnd={(result) => onDragEnd(result, payloadUpdate, useReportsStore.setState)}>
          <Droppable droppableId="reportSessionsList">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  bordered
                  itemLayout="vertical"
                  size="small"
                  className="flex flex-col overflow-y-auto custom-scroll "
                  header={
                    <div className="grid grid-cols-12 divide-x">
                      <div className="flex justify-start col-span-8">Nome</div>
                      <div className="flex justify-center col-span-2">Script</div>
                      <div className="flex justify-center col-span-2">Ações</div>
                    </div>
                  }
                  dataSource={payloadUpdate.report_sessions}
                  renderItem={(item, index) => (
                    <Draggable key={item.title} draggableId={item.title} index={index}>
                      {(provided) => (
                        <List.Item
                          key={item.order}
                          className="bg-white"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div className="grid items-center grid-cols-12 divide-x min-h-8">
                            <div className="flex items-center justify-start col-span-8 min-h-8">
                              <Tooltip title={item.title} trigger={["click", "hover"]}>
                                <span className="truncate">{item.title}</span>
                              </Tooltip>
                            </div>
                            <div className="flex items-center justify-center col-span-2 min-h-8">
                              <Popover
                                content={
                                  <div className="flex w-96 h-80">
                                    <Editor
                                      theme="vs-dark"
                                      options={{
                                        minimap: { enabled: false },
                                        readOnly: true,
                                        padding: {
                                          top: 10,
                                          bottom: 10,
                                        },
                                        scrollbar: {
                                          vertical: "visible",
                                          horizontal: "visible",
                                          verticalScrollbarSize: 8,
                                          horizontalScrollbarSize: 8,
                                          arrowSize: 15,
                                        },
                                      }}
                                      height="300px"
                                      defaultLanguage="sql"
                                      value={formatSql(item.query)}
                                    />
                                  </div>
                                }
                                title={`Script da sessão ${item.title.toUpperCase()}`}
                                trigger="click"
                              >
                                <Button
                                  type="text"
                                  shape="default"
                                  icon={
                                    <Icon
                                      height={18}
                                      width={18}
                                      className="text-blue-400 transition-all duration-100 ease-in hover:blue-red-500"
                                      icon="carbon:script"
                                    />
                                  }
                                />
                              </Popover>
                            </div>
                            <div className="flex items-center justify-center col-span-2 gap-2 min-h-8">
                              <Button
                                type="text"
                                shape="default"
                                icon={
                                  <Icon
                                    icon="iconamoon:edit-fill"
                                    height={18}
                                    width={18}
                                    className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                                  />
                                }
                                onClick={() => {
                                  setEdit({
                                    ...item,
                                    query: formatSql(item.query),
                                  });
                                }}
                                size={"middle"}
                              />
                              <Button
                                type="text"
                                shape="default"
                                icon={
                                  <Icon
                                    icon="material-symbols:delete"
                                    height={18}
                                    width={18}
                                    className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                                  />
                                }
                                onClick={() => {
                                  useReportsStore.setState((state) => {
                                    const updatedSessions = state.payloadUpdate.report_sessions.filter(
                                      (param) => param.title !== item.title
                                    );
                                    const reorderedSessions = updatedSessions.map((param, index) => ({
                                      ...param,
                                      order: index + 1,
                                    }));
                                    return {
                                      payloadUpdate: {
                                        ...state.payloadUpdate,
                                        report_sessions: reorderedSessions,
                                      },
                                    };
                                  });
                                }}
                                size={"middle"}
                              />
                            </div>
                          </div>
                        </List.Item>
                      )}
                    </Draggable>
                  )}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default TabSessions;
