import { Divider, Table, Button, Space, Tooltip, Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import { useContext, useEffect, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useCompanyStore } from "../../store";
import { AdminType, RowAdminType } from "../../types";
import { CreateAdminForm } from "../admins/CreateAdminForm";
import D3Status from "@components/shared/D3Status";
import D3Can from "@components/shared/D3Can";

function TabAdmins() {
  const { openDrawer } = useContext(D3DrawerContext);
  const { loadAdmins, apiResultAdmin, deleteAdmin } = useCompanyStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await loadAdmins();
      setLoading(false);
    })();
  }, [loadAdmins]);

  return (
    <div className="p-2">
      <div className="flex items-center justify-between">
        <h2 className="ml-1 text-lg font-semibold text-gray-600">Administradores da empresa</h2>
        <D3Can action="update" resource="company_admin">
          <Button
            className="flex items-center justify-start"
            type="primary"
            size="middle"
            icon={<Icon icon="ic:round-plus" height={20} width={20} />}
            onClick={() =>
              openDrawer(
                <CreateAdminForm users={apiResultAdmin.data.map(({ cuid }) => cuid)} />,
                "right",
                600,
                "Cadastrar Administrador"
              )
            }
            >
            Novo Administrador
          </Button>
        </D3Can>
      </div>
      <Divider className="mt-1 mb-6 " />
      <Table<RowAdminType>
        size="small"
        rowKey="cuid"
        dataSource={apiResultAdmin.data as AdminType[]}
        bordered
        sticky
        pagination={false}
        loading={loading}
      >
        <Table.Column<RowAdminType>
          showSorterTooltip={false}
          key="name"
          defaultSortOrder="ascend"
          title="Nome completo"
          dataIndex="name"
        />
        <Table.Column<RowAdminType> showSorterTooltip={false} key="username" title="Usuário" dataIndex="username" />
        <Table.Column<RowAdminType> showSorterTooltip={false} key="email" title="Email" dataIndex="email" />
        <Table.Column<RowAdminType>
          showSorterTooltip={false}
          key="active"
          title="Status"
          dataIndex="active"
          render={(value, admin) => <D3Status status={admin.active} />}
        />
        <Table.Column<RowAdminType>
          key="action"
          title="Ações"
          width={80}
          align="center"
          render={(value, admin) => {
            return (
              <>
                <Space>
                <D3Can action="update" resource="company_admin">
                  <Popconfirm
                    title="Atenção!"
                    description="Confirmar exclusão do administrador?"
                    onConfirm={() => deleteAdmin(admin.cuid)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Tooltip title="Delete" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="circle"
                        icon={
                          <Icon
                            icon="material-symbols:delete"
                            height={18}
                            width={18}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  </Popconfirm>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
}

export default TabAdmins;
