import { Form, Segmented } from "antd";
import { IndexItemType } from "../physical/types";

type ItemBOOPropsType = {
  item: IndexItemType;
};

function ItemBOO({ item }: ItemBOOPropsType) {
  const { id, label, required } = item;
  return (
    <Form.Item
      name={[id, "BOO", "value"]}
      getValueProps={(value) => {
        return { value: value ? value : false };
      }}
      normalize={(value) => value}
      label={label}
      rules={[
        {
          required: required,
          message: `Por favor, preencha o campo ${label.toLowerCase()}`,
        },
      ]}
    >
      <Segmented
        options={[
          { label: "Sim", value: true },
          { label: "Não", value: false },
        ]}
        className="select-none"
      />
    </Form.Item>
  );
}

export default ItemBOO;
