import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Segmented, Select, Space, Spin } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useExtensionsStore } from "../store";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import showMessage from "@utils/showMessage";
import { IconDataType, UpdateExtensionType } from "../types";

type UpdateExtensionProps = {
  id: number;
};

const { Option } = Select;

export const UpdateExtensionForm = ({ id }: UpdateExtensionProps) => {
  const { getExtension, updateExtension, getIconsForSelect } = useExtensionsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateExtensionType>();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [icons, setIcons] = useState<IconDataType[]>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      const getExtensionResult = await getExtension(id);
      if (getExtensionResult.status === "success") {
        const viewExtension = getExtensionResult.body as UpdateExtensionType;
        form.setFieldsValue(viewExtension);
        setLoading(false);
      } else {
        showMessage(getExtensionResult);
      }
    })();
  }, [id, form, getExtension]);

  useEffect(() => {
    (async () => {
      const fetchIconsResult = await getIconsForSelect(searchTerm, page);
      setIcons(fetchIconsResult);
    })();
  }, [id, form, getIconsForSelect, page, searchTerm]);

  const handleScroll = (event: any) => {
    const bottom = event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight;
    if (bottom) setPage((prev) => prev + 1);
  };

  const handleSearch = (value: string) => {
    setIcons([]);
    setSearchTerm(value);
    setPage(1);
  };
  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateExtensionType) => {
            setLoading(true);
            const result = await updateExtension(id, payload);
            if (result.code === 200) {
              showMessage(result, "Extensão atualizada com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateExtensionType>
            name="description"
            label="Descrição"
            rules={[
              {
                required: true,
                message: "Por favor, insira a descrição.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateExtensionType>
            name="key"
            label="Extensão"
            rules={[
              {
                required: true,
                message: "Por favor, insira a extensão.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="flex flex-row justify-between">
            <Form.Item<UpdateExtensionType> name="extract_content" label="Extrair conteúdo?" initialValue={false}>
              <Segmented
                options={[
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              />
            </Form.Item>
            <Form.Item<UpdateExtensionType> name="extract_metadata" label="Extrair metadados?" initialValue={false}>
              <Segmented
                options={[
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              />
            </Form.Item>
            <Form.Item<UpdateExtensionType> initialValue={false} name="ocr" label="Processar OCR?">
              <Segmented
                options={[
                  { value: true, label: "Sim" },
                  { value: false, label: "Não" },
                ]}
              />
            </Form.Item>
          </div>

          <Form.Item<UpdateExtensionType>
            name="icon"
            label="Ícone"
            rules={[
              {
                required: true,
                message: "Por favor, selecione um ícone.",
              },
            ]}
          >
            <Select
              showSearch
              notFoundContent={loading ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={(value) => (value.length >= 3 ? handleSearch(value) : value)}
              onPopupScroll={handleScroll}
            >
              {icons.map((icon) => (
                <Option key={icon.id} value={icon.id} className="rounded">
                  <span className="flex flex-row items-center gap-2">
                    <Icon height={20} className="text-gray-700 " icon={icon.id} /> {icon.id}
                  </span>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="manage" resource="all">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
