import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  CancelOrderType,
  CancelProtocolOrderType,
  CreateProtocolOrderType,
  ItemSimpleListCustomerType,
  ItemSimpleListDepartmentType,
  ListProtocolOrderType,
  PrintResendType,
  ResendType,
  SearchProtocolOrderType,
  SimpleListCustomerType,
  SimpleListDepartmentType,
  SimpleListUserDepartmentType,
  UrlType,
  UserDepartmentItemType,
  ViewProtocolOrderType,
} from "./types";

type ProtocolOrderStoreState = {
  apiResult: ListProtocolOrderType;
  filter: SearchProtocolOrderType;
  pagination: { page: number; pageSize: number };
  loading: boolean;
  setFilter: (filter: SearchProtocolOrderType) => void;
  setPagination: (page: number, pageSize: number) => void;
  loadProtocolOrders: () => Promise<void>;
  getProtocolOrder: (cuid: string) => Promise<Success<ViewProtocolOrderType> | StandardError>;
  createProtocolOrder: (
    protocolOrder: CreateProtocolOrderType
  ) => Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError>;
  savedProtocolOrder: (orderCuid: string) => Promise<void>;

  cancelProtocolOrder: (orderCuid: string, payload: CancelOrderType) => Promise<boolean>;
  loadingCancel: boolean;

  simpleListDepartmentCustomer: () => Promise<ItemSimpleListDepartmentType[]>;
  simpleListUsersByDepartmentCuid: (departmentCuid: string) => Promise<UserDepartmentItemType[]>;

  getUrl: (orderCuid: string) => Promise<Success<UrlType> | StandardError>;

  resendProtocolOrderByCuid: (orderCuid: string) => Promise<void>;
  urlResendOrder: string;

  printResendOrderProtocolByCuid: (orderCuid: string) => Promise<void>;
  urlPrintResendOrder: string;

  resetFilter: () => void;
  resetState: () => void;

  simpleListCustomer: () => Promise<void>;
  loadingCustomer: boolean;
  listCustomer: ItemSimpleListCustomerType[];

  simpleListDepartmentByCustomerCuid: (customerCuid: string) => Promise<void>;
  loadingDepartments: boolean;
  listDepartments: ItemSimpleListDepartmentType[];

  openSearch: boolean;
};

const operationsFilter: FilterDefinition = {
  po_id: { op: "eq", type: "string" },
  dep_cuid: { op: "eq", type: "string" },
  uc_name: { op: "lk", type: "string" },
  ur_name: { op: "lk", type: "string" },
  po_status: { op: "eq", type: "string" },
  c_cuid: { op: "eq", type: "string" },
  po_has_order_resent: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  filter: {
    po_id: "",
    dep_cuid: "",
    uc_name: "",
    ur_name: "",
    po_status: "WTG",
    c_cuid: "",
    po_has_order_resent: "all",
  } as SearchProtocolOrderType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  loading: false,
  urlResendOrder: "",
  urlPrintResendOrder: "",

  loadingCustomer: false,
  listCustomer: [],

  loadingDepartments: false,
  listDepartments: [],
  openSearch: false,
  loadingCancel: false,
};

export const useProtocolOrdersStore = create<ProtocolOrderStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchProtocolOrderType) => {
    set({
      filter,
      pagination: { ...get().pagination, page: 1 },
      openSearch: false,
    });
    get().loadProtocolOrders();
  },
  resetFilter: () => {
    set({
      filter: {
        ...defaultState.filter,
        po_status: "",
      },
      openSearch: false,
    });
    get().loadProtocolOrders();
  },

  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadProtocolOrders();
  },

  loadProtocolOrders: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination);
    await new ClientHttp().get<Success<ListProtocolOrderType>, BadRequest | StandardError>(
      `/api/v1/orders/protocols?${qs}`,
      (result: Success<ListProtocolOrderType>) => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  getProtocolOrder: async (cuid: string): Promise<Success<ViewProtocolOrderType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewProtocolOrderType>, StandardError>(
      `/api/v1/orders/protocols/${cuid}`
    );
  },

  createProtocolOrder: async (
    protocolOrder: CreateProtocolOrderType
  ): Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateProtocolOrderType,
      Success<{ data: { cuid: string } }>,
      BadRequest | StandardError
    >("/api/v1/orders/protocols", protocolOrder, (result: Success<{ data: { cuid: string } }>) => {
      get().loadProtocolOrders();
    });
  },

  savedProtocolOrder: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().patch<
      null,
      Success<{
        data: string;
        message: string;
        status: string;
      }>,
      BadRequest | StandardError
    >(
      `/api/v1/orders/protocols/${orderCuid}/serve`,
      null,
      (
        result: Success<{
          data: string;
          message: string;
          status: string;
        }>
      ) => {
        if (result.status === "success") {
          showMessage(result, "Ordem baixada com sucesso.");
          get().loadProtocolOrders();
        } else {
          showMessage(result, "Algo deu errado na baixa da ordem.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  cancelProtocolOrder: async (orderCuid: string, payload: CancelOrderType): Promise<boolean> => {
    set({ loadingCancel: true });
    const result = await new ClientHttp().patch<CancelProtocolOrderType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/protocols/${orderCuid}/cancel`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Ordem cancelada com sucesso.");
        set({ loadingCancel: false });
        get().loadProtocolOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCancel: false });
      }
    );
    return result.status === "success";
  },

  simpleListDepartmentCustomer: async (): Promise<ItemSimpleListDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemSimpleListDepartmentType[]
    >(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): ItemSimpleListDepartmentType[] => {
        return result.body.data as ItemSimpleListDepartmentType[];
      },
      (error: StandardError): ItemSimpleListDepartmentType[] => {
        showMessage(error);
        return [] as ItemSimpleListDepartmentType[];
      }
    );
  },
  simpleListUsersByDepartmentCuid: async (departmentCuid: string): Promise<UserDepartmentItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListUserDepartmentType>,
      StandardError,
      UserDepartmentItemType[]
    >(
      `/api/v1/customer/departments/${departmentCuid}/users/simple-list`,
      (result: Success<SimpleListUserDepartmentType>): UserDepartmentItemType[] => {
        return result.body.data as UserDepartmentItemType[];
      },
      (error: StandardError): UserDepartmentItemType[] => {
        showMessage(error);
        return [] as UserDepartmentItemType[];
      }
    );
  },
  /* 
  simpleListCustomer: async (): Promise<ItemSimpleListCustomerType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCustomerType>,
      StandardError,
      ItemSimpleListCustomerType[]
    >(
      "/api/v1/company/customers/simple-list",
      (result: Success<SimpleListCustomerType>): ItemSimpleListCustomerType[] => {
        return result.body.data as ItemSimpleListCustomerType[];
      },
      (error: StandardError): ItemSimpleListCustomerType[] => {
        showMessage(error);
        return [] as ItemSimpleListCustomerType[];
      }
    );
  }, */
  simpleListCustomersCustomerGroup: async (): Promise<ItemSimpleListCustomerType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCustomerType>,
      StandardError,
      ItemSimpleListCustomerType[]
    >(
      "/api/v1/customer-group/customers/simple-list",
      (result: Success<SimpleListCustomerType>): ItemSimpleListCustomerType[] => {
        return result.body.data as ItemSimpleListCustomerType[];
      },
      (error: StandardError): ItemSimpleListCustomerType[] => {
        showMessage(error);
        return [] as ItemSimpleListCustomerType[];
      }
    );
  },
  getUrl: async (orderCuid: string): Promise<Success<UrlType> | StandardError> => {
    return await new ClientHttp().get<Success<UrlType>, StandardError>(
      `/api/v1/orders/protocols/${orderCuid}/print`,
      (result: Success<UrlType>): UrlType => {
        return result.body;
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },
  resendProtocolOrderByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().post<undefined, Success<ResendType>, BadRequest | StandardError>(
      `/api/v1/orders/protocols/${orderCuid}/resend`,
      undefined,
      (result: Success<ResendType>): void => {
        set({
          urlResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  printResendOrderProtocolByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().get<Success<PrintResendType>, StandardError>(
      `/api/v1/orders/protocols/${orderCuid}/print-resend`,
      (result: Success<PrintResendType>): void => {
        set({
          urlPrintResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  simpleListCustomer: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/system/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomer: false });
  },
  simpleListDepartmentByCustomerCuid: async (customerCuid: string): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      `/api/v1/system/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): void => {
        set({ listDepartments: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingDepartments: false });
  },
}));
