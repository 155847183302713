import { Button, Form, Input, Space } from "antd";
import { useEffect } from "react";
import { useServiceOrdersStore } from "../store";
import { SearchDocumentOrderType } from "../types";

type SearchDocumentsFormProps = {
  orderCuid: string;
};

export const SearchDocumentsForm = ({ orderCuid }: SearchDocumentsFormProps) => {
  const [form] = Form.useForm<SearchDocumentOrderType>();
  const { filterDocuments, setFilterDocuments, resetFilterDocuments } = useServiceOrdersStore();

  useEffect(() => {
    form.setFieldsValue(filterDocuments);
  }, [filterDocuments]);

  return (
    <>
      <Form
        className="select-none w-[300px]"
        layout="vertical"
        form={form}
        onFinish={async (values: SearchDocumentOrderType) => {
          setFilterDocuments(values, orderCuid);
        }}
        autoComplete="off"
      >
        <Form.Item<SearchDocumentOrderType> name="document_physical_tag_id" label="Etiqueta do documento">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item<SearchDocumentOrderType> name="repository_physical_tag_id" label="Etiqueta do repositório">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => resetFilterDocuments(orderCuid)}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
