import { create } from "zustand";
import {
  CepType,
  CreateAddressCompanyType,
  CreateCompanyType,
  ListAddressType,
  ListAdminsType,
  MutationAdminType,
  SelectCompanyUserItemType,
  SimpleListCompanyUserType,
  UpdateCompanyType,
  ViewAddressType,
  ViewCompanyType,
} from "./types";

import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";

type CompanyStoreState = {
  apiResultAddress: ListAddressType;
  apiResultAdmin: ListAdminsType;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  getActiveTab: () => string;
  getCompany: () => Promise<Success<ViewCompanyType> | StandardError>;
  createCompany: (company: CreateCompanyType) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateCompany: (company: UpdateCompanyType) => Promise<Success<void> | BadRequest | StandardError>;
  getCep: (cep: string) => Promise<Success<CepType> | StandardError>;
  getAddress: (cuid: string) => Promise<Success<ViewAddressType> | StandardError>;
  loadAddresses: () => Promise<void>;

  createAddressCompany: (address: CreateAddressCompanyType) => Promise<
    | Success<{
        cuid: string;
      }>
    | BadRequest
    | StandardError
  >;
  updateAddressCompany: (
    cuid: string,
    address: CreateAddressCompanyType
  ) => Promise<
    | StandardError
    | BadRequest
    | Success<{
        cuid: string;
      }>
  >;
  deleteAddress: (cuid: string) => Promise<Success<void> | StandardError>;
  loadAdmins: () => Promise<void>;
  getCompanyUsersForSelect: () => Promise<SelectCompanyUserItemType[]>;
  createAdmin: (payload: MutationAdminType) => Promise<Success<null> | BadRequest | StandardError>;
  deleteAdmin: (cuid: string) => Promise<Success<void> | StandardError>;
};

export const useCompanyStore = create<CompanyStoreState>((set, get) => ({
  activeTab: "1",
  setActiveTab: (tab: string) => {
    set({ activeTab: tab });
  },
  getActiveTab: () => {
    return get().activeTab;
  },
  apiResultAddress: {
    total: 0,
    data: [],
  },
  apiResultAdmin: {
    total: 0,
    data: [],
  },
  getCompany: async (): Promise<Success<ViewCompanyType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCompanyType>, StandardError>(`/api/v1/company`);
  },

  createCompany: async (
    company: CreateCompanyType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreateCompanyType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/company",
      company,
      (result: Success<{ cuid: string }>) => {
        get().getCompany();
      }
    );
  },

  updateCompany: async (company: UpdateCompanyType): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateCompanyType, Success<void>, BadRequest | StandardError>(
      `/api/v1/company`,
      company,
      (result: Success<void>) => {
        get().getCompany();
      }
    );
  },

  loadAddresses: async (): Promise<void> => {
    await new ClientHttp().get<Success<ListAddressType>, BadRequest | StandardError>(
      `/api/v1/company/addresses`,
      (result: Success<ListAddressType>) => {
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          set({
            apiResultAddress: body,
          });
          get().loadAddresses();
        } else {
          set({ apiResultAddress: body });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  getAddress: async (cuid: string): Promise<Success<ViewAddressType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewAddressType>, StandardError>(`/api/v1/company/addresses/${cuid}`);
  },

  getCep: async (cep: string): Promise<Success<CepType> | StandardError> => {
    return await new ClientHttp().get<Success<CepType>, StandardError>(
      `/api/v1/system/cep/${cep}/search`,
      (result: Success<CepType>) => {
        return result;
      },
      (result: StandardError) => {
        showMessage(result);
      }
    );
  },

  createAddressCompany: async (
    address: CreateAddressCompanyType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreateAddressCompanyType, Success<{ cuid: string }>, BadRequest | StandardError>(
      "/api/v1/company/addresses",
      address,
      (result: Success<{ cuid: string }>) => {
        get().loadAddresses();
      }
    );
  },
  updateAddressCompany: async (
    cuid: string,
    address: CreateAddressCompanyType
  ): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().put<CreateAddressCompanyType, Success<{ cuid: string }>, BadRequest | StandardError>(
      `/api/v1/company/addresses/${cuid}`,
      address,
      (result: Success<{ cuid: string }>) => {
        get().loadAddresses();
      }
    );
  },

  deleteAddress: async (cuid: string): Promise<Success<void> | StandardError> => {
    return await new ClientHttp().delete<Success<void>, StandardError>(
      `/api/v1/company/addresses/${cuid}`,
      (result: Success<void>) => {
        showMessage(result, "Endereço excluído com sucesso.");
        get().loadAddresses();
      },
      (result: StandardError) => {
        showMessage(result);
      }
    );
  },

  loadAdmins: async (): Promise<void> => {
    await new ClientHttp().get<Success<ListAdminsType>, BadRequest | StandardError>(
      `/api/v1/company/admins`,
      (result: Success<ListAdminsType>) => {
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          set({
            apiResultAdmin: body,
          });
          get().loadAdmins();
        } else {
          set({ apiResultAdmin: body });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  getCompanyUsersForSelect: async (): Promise<SelectCompanyUserItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCompanyUserType>,
      StandardError,
      SelectCompanyUserItemType[]
    >(
      "/api/v1/company/users/simple-list",
      (result: Success<SimpleListCompanyUserType>): SelectCompanyUserItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.name,
            active: item.active,
            username: item.username,
          } as SelectCompanyUserItemType;
        });
      },
      (error: StandardError): SelectCompanyUserItemType[] => {
        showMessage(error);
        return [] as SelectCompanyUserItemType[];
      }
    );
  },

  createAdmin: async (payload: MutationAdminType): Promise<Success<null> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<MutationAdminType, Success<null>, BadRequest | StandardError>(
      `/api/v1/company/admins`,
      payload,
      (result: Success<null>) => {
        get().loadAdmins();
      }
    );
  },

  deleteAdmin: async (cuid: string): Promise<Success<void> | StandardError> => {
    return await new ClientHttp().delete<Success<void>, StandardError>(
      `/api/v1/company/admins/${cuid}`,
      (result: Success<void>) => {
        showMessage(result, "Administrador excluído com sucesso.");
        get().loadAdmins();
      },
      (result: StandardError) => {
        showMessage(result);
      }
    );
  },
}));
