import { BadRequest, StandardError, Success } from "./clientHttp";
import { ctNotification } from "@components/shared/CTNotification";

type PayloadType = BadRequest | StandardError | Success<any>;

const showMessage = (payload: PayloadType, standardMessage?: string, duration: number = 3) => {
  duration = duration * 1000;
  if ((payload.code === 200 || payload.code === 201) && payload.status === "success") {
    ctNotification({
      type: "success",
      title: "Sucesso !",
      content: standardMessage ?? "Operação realizada com sucesso.",
      withoutFooter: true,
    });
  } else if (payload.status === "warning") {
    ctNotification({
      type: "warning",
      title: "Atenção !",
      content: standardMessage ?? "Algo deu errado durante a operação. Tente novamente mais tarde, por favor.",
      withoutFooter: true,
    });
  } else if (payload.status === "error") {
    let description = "";
    if (
      payload.code === 400 ||
      payload.code === 401 ||
      payload.code === 403 ||
      payload.code === 404 ||
      payload.code === 409 ||
      payload.code === 410 ||
      payload.code === 412 ||
      payload.code === 413 ||
      payload.code === 422 ||
      payload.code === 423 ||
      payload.code === 500
    ) {
      let resultError = payload as StandardError;
      description =
        standardMessage ?? resultError?.body?.message ?? "Ocorreu um erro desconhecido ao realizar a operação";
    } else if (payload.code === 400) {
      let resultError: BadRequest = payload as BadRequest;
      description = resultError?.body?.data
        ? JSON.stringify(resultError.body.data)
        : "Ocorreu um erro desconhecido ao realizar a operação";
    }
    if (description !== "") {
      ctNotification({
        type: "error",
        title: "Atenção !",
        content: description ?? "Algo deu errado durante a operação. Tente novamente mais tarde, por favor.",
        withoutFooter: true,
      });
    }
  }
};

export default showMessage;
