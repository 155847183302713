import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useDepartmentsStore } from "../store";
import { SelectCustomerUserItemType, UpdateDepartmentType, ViewDepartmentType } from "../types";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";
import { StandardError } from "@utils/clientHttp";
import Selector from "@components/shared/Selector";

type UpdateDepartmentProps = {
  cuid: string;
};

export const UpdateDepartmentForm = ({ cuid }: UpdateDepartmentProps) => {
  const { updateDepartment, getDepartment, getCustomerUserForSelect } = useDepartmentsStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateDepartmentType>();
  const [loading, setLoading] = useState(true);
  const [customerUsers, setCustomerUsers] = useState<SelectCustomerUserItemType[]>([]);

  useEffect(() => {
    (async () => {
      const [getDepartmentResult, getCustomerUserForSelectResult] = await Promise.all([
        getDepartment(cuid),
        getCustomerUserForSelect(),
      ]);
      if (getDepartmentResult.status === "success") {
        const viewDepartment = getDepartmentResult.body as ViewDepartmentType;
        form.setFieldsValue({
          description: viewDepartment.description,
          abbreviation: viewDepartment.abbreviation,
          customer_users_cuids: viewDepartment.users.map((group) => group.cuid),
        });
        setCustomerUsers(getCustomerUserForSelectResult);
        setLoading(false);
      } else {
        showMessage(getDepartmentResult);
      }
    })();
  }, [cuid, form, getCustomerUserForSelect, getDepartment]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          layout="vertical"
          form={form}
          onFinish={async (payload: UpdateDepartmentType) => {
            setLoading(true);
            const result = await updateDepartment(cuid, payload);
            if (result.code === 200) {
              showMessage(result, "Departamento atualizado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<UpdateDepartmentType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira descrição." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateDepartmentType>
            name="abbreviation"
            label="Abreviação"
            rules={[{ required: true, message: "Por favor, insira abreviação." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<UpdateDepartmentType> label="Usuários Cliente" name="customer_users_cuids">
            <Selector
              fieldMap={{
                status: "active",
                value: "value",
                label: "label",
                description: "name",
                dataLabel: ["label"],
                dataFilter: ["label", "name"],
              }}
              loading={loading}
              mode="multiple"
              options={customerUsers}
            />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_department">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
