import showMessage from "@utils/showMessage";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import FileInfo from "../view-file/components/FileInfo";
import { ViewFileType } from "../types";
import useFileStore from "../store";
import { ctNotification } from "@components/shared/CTNotification";

type DetailsFileProps = {
  fileCuid: string;
};

function DetailsFile({ fileCuid }: DetailsFileProps) {
  const { viewFile } = useFileStore();
  const [infos, setInfos] = useState<ViewFileType>();

  useEffect(() => {
    (async () => {
      try {
        const result = await viewFile(fileCuid);
        if (result.code === 200) {
          setInfos(result.body as ViewFileType);
        } else {
          showMessage(result, "Impossível carregar o arquivo.");
        }
      } catch (error) {
        ctNotification({ title: "Atenção !", content: "Impossível carregar o arquivo.", type: "error" });
      }
    })();
  }, [fileCuid, viewFile]);

  return infos ? (
    <FileInfo info={infos} />
  ) : (
    <div className="flex items-center justify-center w-full h-full">
      <Spin spinning />
    </div>
  );
}

export default DetailsFile;
