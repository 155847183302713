import { Button, Drawer, Form, Space, Tooltip, Upload } from "antd";
import { useContext, useState } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UploadOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import D3PDFView from "@components/shared/D3PDF/D3PDFView";
import { UploadContractType } from "../types";
import { useContractStore } from "../store";
import { ctNotification } from "@components/shared/CTNotification";

type UploadFormProps = {
  contractCuid: string;
  fileName: string;
  isUploadContract: boolean;
};

export const UploadForm = ({ contractCuid, fileName, isUploadContract }: UploadFormProps) => {
  const { viewFileContract, viewFileUrl, loadingViewFile, uploadContract, uploadUrl } = useContractStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UploadContractType>();
  const [open, setOpen] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

  return (
    <>
      <Form className="select-none" form={form} layout="vertical" autoComplete="off">
        <Form.Item label="Visualizar contrato enviado">
          <Tooltip
            title={`${!isUploadContract && !uploadSuccess ? "Nenhum upload de contrato foi realizado." : ""}`}
            trigger={["click", "hover"]}
          >
            <Button
              loading={loadingViewFile}
              disabled={!isUploadContract && !uploadSuccess}
              type="link"
              onClick={async () => {
                await viewFileContract(contractCuid);
                setOpen(true);
                setSize(1000);
              }}
              icon={
                <Icon
                  icon="vscode-icons:file-type-pdf2"
                  height={30}
                  width={30}
                  className="text-blue-400 transition-all duration-100 ease-in drop-shadow hover:text-blue-500"
                />
              }
            />
          </Tooltip>
        </Form.Item>
        <Form.Item name="anexo" label="Anexo" rules={[{ required: true, message: "Por favor, anexe um contrato." }]}>
          <Upload
            accept=".pdf"
            action={uploadUrl}
            listType="picture"
            showUploadList={{
              showRemoveIcon: false,
              showPreviewIcon: false,
              showDownloadIcon: false,
            }}
            iconRender={() => (
              <div className="flex items-center justify-center h-full drop-shadow ">
                <Icon height={40} icon="vscode-icons:file-type-pdf2" />
              </div>
            )}
            maxCount={1}
            method="PUT"
            beforeUpload={async (file) => {
              const isPdf = file.type === "application/pdf";
              const limitFileSize = file.size / 1024 / 1024 < 50;
              if (!isPdf) {
                ctNotification({
                  title: "Atenção !",
                  content: "Somente arquivos PDF são permitidos para upload.",
                  type: "error",
                });
                return Upload.LIST_IGNORE;
              }
              if (!limitFileSize) {
                ctNotification({
                  title: "Atenção !",
                  content: "O arquivo deve ser menor que 50MB.",
                  type: "error",
                });
                return Upload.LIST_IGNORE;
              }
              await uploadContract(contractCuid);
              return true;
            }}
            onChange={async (info) => {
              if (info.file.status === "done") {
                await viewFileContract(contractCuid);
                setUploadSuccess(true);
                ctNotification({
                  title: "Sucesso !",
                  content: "O upload do contrato foi concluído.",
                  type: "success",
                  withoutFooter: true,
                });
              } else if (info.file.status === "error") {
                ctNotification({
                  title: "Atenção !",
                  content: "Ocorreu um erro ao fazer o upload do contrato.",
                  type: "error",
                  withoutFooter: true,
                });
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Selecione um contrato</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Fechar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
      <Drawer
        title={fileName}
        open={open}
        width={900}
        onClose={() => {
          setOpen(false);
          setSize(600);
        }}
      >
        <D3PDFView url={viewFileUrl} fileName={fileName} />
      </Drawer>
    </>
  );
};
