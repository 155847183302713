import { create } from "zustand";
import {
  ListDepartmentType,
  CreateDepartmentType,
  RowDepartmentType,
  LockOrUnlockPayloadType,
  SearchDepartmentType,
  UpdateDepartmentType,
  ViewDepartmentType,
  SelectCustomerUserItemType,
  SimpleListCustomerUserType,
} from "./types";

import showMessage from "@utils/showMessage";

import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";
import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";

type DepartmentStoreState = {
  apiResult: ListDepartmentType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchDepartmentType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchDepartmentType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadDepartments: () => Promise<Success<ListDepartmentType> | BadRequest | StandardError>;
  getCustomerUserForSelect: () => Promise<SelectCustomerUserItemType[]>;
  getDepartment: (cuid: string) => Promise<Success<ViewDepartmentType> | StandardError>;
  createDepartment: (
    department: CreateDepartmentType
  ) => Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError>;
  updateDepartment: (
    cuid: string,
    department: UpdateDepartmentType
  ) => Promise<Success<{ status: string }> | BadRequest | StandardError>;
  lockDepartment: (cuids: string[]) => Promise<void>;
  unLockDepartment: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: RowDepartmentType[]) => void;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "dep_description",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  dep_description: { op: "lk", type: "string" },
  dep_abbreviation: { op: "lk", type: "string" },
  dep_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    dep_description: "",
    dep_abbreviation: "",
    dep_active: "on",
  } as SearchDepartmentType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

export const useDepartmentsStore = create<DepartmentStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchDepartmentType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadDepartments();
  },
  onMultiSelectedRowChange: (selectedRows: RowDepartmentType[]): void =>
    IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({ filter: { dep_description: "", dep_abbreviation: "", dep_active: "on" } });
    get().loadDepartments();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadDepartments();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["description", "dep_description"],
        ["abbreviation", "dep_abbreviation"],
        ["active", "dep_active"],
      ]),
      set,
      get().loadDepartments
    ),
  loadDepartments: async (): Promise<Success<ListDepartmentType> | BadRequest | StandardError> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    return await new ClientHttp().get<Success<ListDepartmentType>, BadRequest | StandardError>(
      `/api/v1/customer/departments?${qs}`,
      (result: Success<ListDepartmentType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        set({
          apiResult: body,
          loading: false,
          pagination: { ...get().pagination, page: newPage },
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getCustomerUserForSelect: async (): Promise<SelectCustomerUserItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListCustomerUserType>,
      StandardError,
      SelectCustomerUserItemType[]
    >(
      "/api/v1/customer/users/simple-list",
      (result: Success<SimpleListCustomerUserType>): SelectCustomerUserItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.username,
            active: item.active,
            name: item.name,
          } as SelectCustomerUserItemType;
        });
      },
      (error: StandardError): SelectCustomerUserItemType[] => {
        showMessage(error);
        return [] as SelectCustomerUserItemType[];
      }
    );
  },

  getDepartment: async (cuid: string): Promise<Success<ViewDepartmentType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewDepartmentType>, StandardError>(
      `/api/v1/customer/departments/${cuid}`
    );
  },

  createDepartment: async (
    department: CreateDepartmentType
  ): Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateDepartmentType,
      Success<{ data: { cuid: string } }>,
      BadRequest | StandardError
    >("/api/v1/customer/departments", department, (result: Success<{ data: { cuid: string } }>) => {
      get().loadDepartments();
    });
  },
  updateDepartment: async (
    cuid: string,
    department: UpdateDepartmentType
  ): Promise<Success<{ status: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateDepartmentType, Success<{ status: string }>, BadRequest | StandardError>(
      `/api/v1/customer/departments/${cuid}`,
      department,
      (result: Success<{ status: string }>) => {
        get().loadDepartments();
      }
    );
  },
  lockDepartment: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/departments/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadDepartments();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockDepartment: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/departments/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadDepartments();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
