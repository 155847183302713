import { Icon } from "@iconify/react";
import { Tabs } from "antd";

import { useState } from "react";
import DefaultPrice from "../prices/default";
import ListMaterial from "../prices/materials/ListMaterial";
import ListRepositoryTypes from "../prices/repository-types/ListRepositoryTypes";
import ListServices from "../prices/services/ListServices";
import Transport from "../prices/transport";
import { AbilityContext } from "@context/CASLContext";
import { useAbility } from "@casl/react";

function TabPrices() {
	const [activeKey, setActiveKey] = useState("1");

	
	const usePermission = (action: string, resource: string, multiple?: string[]) => {
		const ability = useAbility(AbilityContext);
		if (multiple) {
			return multiple.some((rule) => {
			  const [res, act] = rule.split(".");
			  return ability.can(act, res);
			});
		  }		
		return ability.can(action, resource);
	  };
	return (
		<div className="flex flex-col h-full overflow-hidden bg-white">
			<Tabs
				tabPosition="top"
				defaultActiveKey={activeKey}
				onChange={setActiveKey}
				className="flex h-full overflow-hidden select-none"
				items={[
					{
						disabled: !usePermission("details", "customer"),
						key: "1",
						label: (
							<span className="flex items-center justify-center gap-2">
								<Icon icon="ic:baseline-price-change" width={18} height={18} />
								Geral
							</span>
						),
						children: activeKey === "1" && <DefaultPrice key={activeKey} />,
					},
					{
						disabled: !usePermission("update", "customer"),
						key: "2",
						label: (
							<span className="flex items-center justify-center gap-2">
								<Icon icon="tabler:wallpaper" width={18} height={18} />
								Materiais
							</span>
						),
						children: activeKey === "2" && <ListMaterial key={activeKey} />,
					},
					{
						disabled: !usePermission("update", "customer"),
						key: "3",
						label: (
							<span className="flex items-center justify-center gap-2">
								<Icon icon="carbon:box" width={18} height={18} />
								Tipos de Repositório
							</span>
						),
						children: activeKey === "3" && <ListRepositoryTypes key={activeKey} />,
					},
					{
						disabled: !usePermission( "","",["customer.details", "customer.update"]),
						key: "4",
						label: (
							<span className="flex items-center justify-center gap-2">
								<Icon icon="carbon:task-settings" width={18} height={18} />
								Serviços
							</span>
						),
						children: activeKey === "4" && <ListServices key={activeKey} />,
					},
					{
						disabled: !usePermission("details", "customer"),
						key: "5",
						label: (
							<span className="flex items-center justify-center gap-2">
								<Icon icon="mdi:truck" width={18} height={18} />
								Transportes
							</span>
						),
						children: activeKey === "5" && <Transport key={activeKey} />,
					},
				]}
			/>
		</div>
	);
}

export default TabPrices;
