import { useContext, useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { Badge, Button, Pagination, Popconfirm, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";

import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useTranslation } from "react-i18next";
import { useMaterialsStore } from "./store";

import D3Can from "@components/shared/D3Can";
import D3Status from "@components/shared/D3Status";
import { ToggleLockAction } from "@utils/ToggleLockAction";
import { D3Money } from "@utils/money";
import { CreateMaterialForm } from "./components/CreateMaterialForm";
import { SearchMaterialForm } from "./components/SearchMaterialForm";
import { UpdateMaterialForm } from "./components/UpdateMaterialForm";
import { ViewMaterialForm } from "./components/ViewMaterialForm";
import { RowMaterialType } from "./types";

const MaterialsView: React.FC = () => {
  const [openSearch, setOpenSearch] = useState(false);
  const { t } = useTranslation();

  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    selectedRows,
    setPagination,
    setSort,
    loadMaterials,
    lockMaterial,
    unLockMaterial,
    onMultiSelectedRowChange,
    resetFilter,
    resetState,
  } = useMaterialsStore();

  useEffect(() => {
    (async () => {
      await loadMaterials();
    })();
    return () => {
      resetState();
    };
  }, [loadMaterials]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<RowMaterialType>
        className="responsive-table"
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data as RowMaterialType[]}
        bordered
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title={t("system.material.filter.title")}
                    content={
                      <SearchMaterialForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      className="button-icon"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      {t("shared.list.btn_filter")}
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">{t("system.material.list.title")}</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="system_material">
                    <Button
                      className="button-icon"
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateMaterialForm />, "right", 600, t("system.material.new.title"))}
                    >
                      {t("shared.list.btn_new")}
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
        rowSelection={{
          preserveSelectedRowKeys: true,
          hideSelectAll: false,
          type: "checkbox",
          selectedRowKeys: selectedRows.cuids,
          onChange: (_, selectRows: RowMaterialType[]) => {
            onMultiSelectedRowChange(selectRows);
          },
        }}
        onChange={(a, b, sorter: any) => {
          if (sorter) {
            setSort({
              sort_by: sorter.field,
              sort_direction: sorter.order,
            });
          }
        }}
        footer={() => {
          return (
            <>
              <div className="flex gap-4">
                <D3Can action="lock" resource="system_material">
                  <Badge
                    count={
                      selectedRows.cuids.length > 0 && selectedRows.active === true
                        ? selectedRows.cuids.length
                        : undefined
                    }
                  >
                    <Popconfirm
                      disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
                      title={t("shared.popconfirm.title")}
                      description={`${t("shared.popconfirm.lock")} ${selectedRows.cuids.length} ${
                        selectedRows.cuids.length > 1 ? t("shared.popconfirm.itens") : t("shared.popconfirm.item")
                      }?`}
                      onConfirm={async () => {
                        await lockMaterial(selectedRows.cuids);
                      }}
                      okText={t("shared.popconfirm.ok")}
                      cancelText={t("shared.popconfirm.cancel")}
                      placement="top"
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === true)}
                        size="middle"
                        icon={<Icon icon="basil:lock-solid" height={16} width={16} />}
                      >
                        {t("shared.list.btn_lock")}
                      </Button>
                    </Popconfirm>
                  </Badge>
                </D3Can>
                <D3Can action="unlock" resource="system_material">
                  <Badge
                    color="green"
                    count={
                      selectedRows.cuids.length > 0 && selectedRows.active === false
                        ? selectedRows.cuids.length
                        : undefined
                    }
                  >
                    <Popconfirm
                      disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
                      title={t("shared.popconfirm.title")}
                      description={`${t("shared.popconfirm.unlock")} ${selectedRows.cuids.length} ${
                        selectedRows.cuids.length > 1 ? t("shared.popconfirm.itens") : t("shared.popconfirm.item")
                      }?`}
                      onConfirm={async () => {
                        await unLockMaterial(selectedRows.cuids);
                      }}
                      okText={t("shared.popconfirm.ok")}
                      cancelText={t("shared.popconfirm.cancel")}
                      placement="top"
                    >
                      <Button
                        className=""
                        disabled={!(selectedRows.cuids.length > 0 && selectedRows.active === false)}
                        size="middle"
                        icon={<Icon icon="basil:unlock-solid" height={16} width={16} />}
                      >
                        {t("shared.list.btn_unlock")}
                      </Button>
                    </Popconfirm>
                  </Badge>
                </D3Can>
              </div>
              <Space>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `${t("shared.pagination.showing")} ${range[0]}  ${t("shared.pagination.a")} ${range[1]} ${t(
                      "shared.pagination.total"
                    )} ${total} ${total > 1 ? t("shared.pagination.records") : t("shared.pagination.record")}`
                  }
                  showSizeChanger={true}
                  current={pagination.page}
                  onChange={setPagination}
                  pageSize={pagination.pageSize}
                  total={apiResult.total}
                  locale={{ items_per_page: t("shared.pagination.perpage") }}
                />
              </Space>
            </>
          );
        }}
      >
        <Table.Column<RowMaterialType>
          title={t("system.material.list.table_description")}
          defaultSortOrder="ascend"
          key="description"
          dataIndex="description"
          sorter
        />
        <Table.Column<RowMaterialType>
          showSorterTooltip={false}
          key="price"
          title={t("system.material.list.table_price")}
          dataIndex="price"
          sorter
          render={(value, material) => D3Money.FormatValueApiToPtBR(material.price)}
        />
        <Table.Column<RowMaterialType>
          showSorterTooltip={false}
          key="active"
          title={t("shared.table_status")}
          dataIndex="active"
          sorter
          render={(value, material) => <D3Status status={material.active} />}
        />
        <Table.Column<RowMaterialType>
          title={t("shared.table_actions")}
          width={120}
          key="action"
          render={(value, material) => {
            return (
              <>
                <Space>
                  <D3Can action="update" resource="system_material">
                    <Tooltip trigger={["click", "hover"]} title={t("shared.list.btn_edit")}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <UpdateMaterialForm cuid={material.cuid} />,
                            "right",
                            600,
                            t("system.material.edit.title")
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="system_material">
                    <Tooltip trigger={["click", "hover"]} title={t("shared.list.btn_view")}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <ViewMaterialForm cuid={material.cuid} />,
                            "right",
                            600,
                            t("system.material.view.title")
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>

                  <ToggleLockAction
                    rules="system_material.lock"
                    description={`${t("shared.popconfirm.lock")} ?`}
                    title={t("shared.popconfirm.title")}
                    isVisible={material.active}
                    tooltip={t("shared.list.btn_lock")}
                    paramsToAction={[material.cuid]}
                    action={async (params: string[]) => {
                      await lockMaterial(params);
                    }}
                  />

                  <ToggleLockAction
                    rules="system_material.unlock"
                    title={t("shared.popconfirm.title")}
                    description={`${t("shared.popconfirm.unlock")} ?`}
                    isVisible={!material.active}
                    tooltip={t("shared.list.btn_unlock")}
                    paramsToAction={[material.cuid]}
                    action={async (params: string[]) => {
                      await unLockMaterial(params);
                    }}
                  />
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default MaterialsView;
