import { Button, Checkbox, DatePicker, Divider, Form, Input, Segmented } from "antd";
import { IndexesValue, PayloadType } from "../types";
import dayjs from "dayjs";
import { DynamicFormElement } from "@views/customer/document-registration/indexers/DynamicFormElement";
import { useDocumentBatchStore } from "../store";
import { useEffect } from "react";
import SpinLoading from "@components/shared/SpinLoading";

function FormFill() {
  const {
    isValid,
    documentTypeSelected,
    setCheckBoxState,
    payload,
    indexes,
    checkBoxState,
    registrationDocument,
    loadingRegistration,
    loadingValidate,
    success,
  } = useDocumentBatchStore();
  const [form] = Form.useForm<PayloadType>();

  useEffect(() => {
    form.setFieldsValue({
      ...payload,
      is_permanent_storage: documentTypeSelected.temporality_permanent_guard,
    });
    if (
      documentTypeSelected.cuid &&
      !documentTypeSelected.temporality_permanent_guard &&
      !payload.is_permanent_storage
    ) {
      const purgeDate =
        documentTypeSelected.temporality_year > 0
          ? payload.original_date
            ? dayjs(payload.original_date).add(documentTypeSelected.temporality_year, "year").format("YYYY-MM-DD")
            : ""
          : "";
      form.setFieldValue("expected_purge_date", purgeDate);
      useDocumentBatchStore.setState((state) => ({
        payload: {
          ...state.payload,
          expected_purge_date: purgeDate,
        },
      }));
    }
  }, [documentTypeSelected, success]);

  const resetIndexers = () => {
    const fieldsValues = form.getFieldsValue();
    const excludeFields = [
      "description",
      "document_physical_tag_id",
      "expected_purge_date",
      "indexes_values",
      "is_permanent_storage",
      "original_date",
      "physical_or_digital",
    ];
    const fieldsToReset = Object.keys(fieldsValues).filter((field) => !excludeFields.includes(field));
    form.resetFields(fieldsToReset as Array<keyof typeof fieldsValues>);
  };

  return (
    <Form
      key="2"
      form={form}
      layout="vertical"
      className={`flex   flex-col overflow-hidden h-full max-w-[950px] ${!isValid ? "hidden" : ""}`}
      onFinish={async (payload: Partial<PayloadType>) => {
        const {
          description,
          document_physical_tag_id,
          expected_purge_date,
          indexes_values,
          is_permanent_storage,
          original_date,
          physical_or_digital,
          ...rest
        } = payload;
        const indexesValues = Object.entries(rest as Record<string, any>).map(([key, val]) => {
          const [typeKey, typeVal] = Object.entries(val as Record<string, any>)[0];
          const { end_value, start_value, value } = typeVal as IndexesValue;
          return {
            end_value: end_value ? end_value.toString() : "",
            document_type_index_id: parseInt(key),
            start_value: start_value ? start_value.toString() : "",
            value: value ? value.toString() : "",
          };
        }) as IndexesValue[];
        useDocumentBatchStore.setState((state) => ({
          payload: {
            ...state.payload,
            indexes_values: indexesValues,
          },
        }));
        const result = await registrationDocument();
        if (result) {
          useDocumentBatchStore.setState((state) => ({
            payload: {
              physical_or_digital: "P",
              document_physical_tag_id: checkBoxState.document_physical_tag_id
                ? state.payload.document_physical_tag_id
                  ? state.payload.document_physical_tag_id.slice(0, -3)
                  : ""
                : "",
              description: checkBoxState.description
                ? state.payload.description
                  ? state.payload.description
                  : ""
                : "",
              is_permanent_storage: checkBoxState.is_permanent_storage
                ? state.payload.is_permanent_storage
                  ? state.payload.is_permanent_storage
                  : false
                : false,
              original_date: checkBoxState.original_date
                ? state.payload.original_date
                  ? dayjs(state.payload.original_date).format("YYYY-MM-DD")
                  : ""
                : "",
              expected_purge_date: checkBoxState.expected_purge_date
                ? state.payload.expected_purge_date
                  ? dayjs(state.payload.expected_purge_date).format("YYYY-MM-DD")
                  : ""
                : "",
              indexes_values: [],
            },
          }));
          resetIndexers();
          form.setFieldsValue({
            physical_or_digital: "P",
            document_physical_tag_id: checkBoxState.document_physical_tag_id
              ? payload.document_physical_tag_id
                ? payload.document_physical_tag_id?.slice(0, -3)
                : ""
              : "",
            description: checkBoxState.description ? (payload.description ? payload.description : "") : "",
            is_permanent_storage: checkBoxState.is_permanent_storage
              ? payload.is_permanent_storage
                ? payload.is_permanent_storage
                : false
              : false,
            original_date: checkBoxState.original_date
              ? payload.original_date
                ? dayjs(payload.original_date).format("YYYY-MM-DD")
                : ""
              : "",
            expected_purge_date: checkBoxState.expected_purge_date
              ? payload.expected_purge_date
                ? dayjs(payload.expected_purge_date).format("YYYY-MM-DD")
                : ""
              : "",
            indexes_values: [],
          });
        }
      }}
      onValuesChange={(changedValues, allValues) => {
        const key = Object.keys(changedValues)[0];
        const value = changedValues[key];
        if (isNaN(Number(key))) {
          useDocumentBatchStore.setState((state) => ({
            payload: {
              ...state.payload,
              [key]: value,
            },
          }));
        }

        if (changedValues.original_date || allValues.is_permanent_storage === false) {
          if (documentTypeSelected && changedValues.original_date) {
            const purgeDate =
              documentTypeSelected.temporality_year > 0
                ? dayjs(changedValues.original_date)
                    .add(documentTypeSelected.temporality_year, "year")
                    .format("YYYY-MM-DD")
                : "";
            form.setFieldValue("expected_purge_date", purgeDate);
            useDocumentBatchStore.setState((state) => ({
              payload: {
                ...state.payload,
                expected_purge_date: purgeDate,
              },
            }));
          }
        }

        if (changedValues.original_date === "" || allValues.is_permanent_storage) {
          form.setFieldValue("expected_purge_date", "");
          useDocumentBatchStore.setState((state) => ({
            payload: {
              ...state.payload,
              expected_purge_date: "",
            },
          }));
        }
      }}
    >
      <div className="flex flex-row w-full h-full gap-3 overflow-hidden">
        <div className="flex flex-col min-w-[450px] h-full  overflow-y-auto custom-scroll pr-2">
          <Form.Item<PayloadType>
            name="document_physical_tag_id"
            label={
              <div className="flex gap-2">
                <span>Etiqueta de documento</span>
                <Checkbox
                  name="document_physical_tag_id"
                  checked={checkBoxState.document_physical_tag_id}
                  onChange={setCheckBoxState}
                >
                  Manter parcial?
                </Checkbox>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Por favor, insira o código de etiqueta.",
              },
              {
                min: 12,
                max: 12,
                message: "O código de etiqueta deve incluir uma sequência de 12 dígitos numéricos.",
              },
              {
                validator: (_, value) => {
                  if (value && value !== "") {
                    return /^\d+$/.test(value)
                      ? Promise.resolve()
                      : Promise.reject(new Error("O código de etiqueta deve ser composta exclusivamente por números."));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input minLength={12} maxLength={12} showCount />
          </Form.Item>
          <Form.Item<PayloadType>
            name="description"
            label={
              <div className="flex gap-2">
                <span>Descrição</span>
                <Checkbox name="description" onChange={setCheckBoxState}>
                  Manter?
                </Checkbox>
              </div>
            }
          >
            <Input />
          </Form.Item>
          <Form.Item<PayloadType>
            name="is_permanent_storage"
            label={
              <div className="flex gap-2">
                <span>Guarda permanente</span>
                <Checkbox name="is_permanent_storage" onChange={setCheckBoxState}>
                  Manter?
                </Checkbox>
              </div>
            }
          >
            <Segmented
              disabled={documentTypeSelected.temporality_permanent_guard}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              size="small"
              className="select-none"
            />
          </Form.Item>
          <Form.Item<PayloadType>
            name="original_date"
            label={
              <div className="flex gap-2">
                <span>Data original</span>
                <Checkbox name="original_date" onChange={setCheckBoxState}>
                  Manter?
                </Checkbox>
              </div>
            }
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item<PayloadType>
            name="expected_purge_date"
            label={
              <div className="flex gap-2">
                <span>Data previsão expurgo</span>
                <Checkbox name="expected_purge_date" onChange={setCheckBoxState}>
                  Manter?
                </Checkbox>
              </div>
            }
            getValueProps={(value) => ({ value: value ? dayjs(value) : "" })}
            normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
            rules={[
              {
                required: documentTypeSelected.is_temporality_mandatory && payload.is_permanent_storage === false,
                message: "Tipo de documento selecionado requer uma data previsão de expurgo.",
              },
            ]}
          >
            <DatePicker
              disabled={documentTypeSelected.temporality_permanent_guard || payload.is_permanent_storage}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </div>
        <Divider type="vertical" className="self-stretch h-auto" />
        {loadingValidate ? (
          <SpinLoading />
        ) : (
          <Form.Item
            label="Indexadores"
            className="flex flex-col min-w-[450px] min-h-full pr-2 overflow-y-auto  custom-scroll"
          >
            {DynamicFormElement(indexes)}
          </Form.Item>
        )}
      </div>
      <Form.Item className="px-2 mt-4">
        <Button loading={loadingRegistration} block size="middle" htmlType="submit" type="primary">
          Finalizar Cadastro
        </Button>
      </Form.Item>
    </Form>
  );
}

export default FormFill;
