import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Alert, Button, Form, Input, InputNumber, Segmented, Space, Table, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import { useServiceOrdersStore } from "../store";
import { CompletedServiceOrderType } from "../types";
import SpinLoading from "@components/shared/SpinLoading";
import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import { divide } from "lodash";

type ServeServiceOrderFormProps = {
  orderCuid: string;
};

const { TextArea } = Input;

function ServeServiceOrderForm({ orderCuid }: ServeServiceOrderFormProps) {
  const { completedPreview, preview, loadingPreview, completedServiceOrder, completedLoading } =
    useServiceOrdersStore();
  const [form] = Form.useForm<CompletedServiceOrderType>();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await completedPreview(orderCuid);
    })();
  }, [orderCuid]);

  useEffect(() => {
    if (preview.cuid) {
      form.setFieldsValue({
        emergency_transport: preview.emergency_transport,
        items: preview.items,
        migrate_to_digital: false,
        observation: preview.observation,
        preserve_position: false,
      });
    }
  }, [preview]);

  const columns = [
    {
      title: "Etiqueta do documento",
      dataIndex: "document_physical_tag_id",
      key: "document_physical_tag_id",
    },
    {
      title: "Parcial ?",
      dataIndex: "partial",
      key: "partial",
      render: (value: boolean) => (value ? "Sim" : "Não"),
    },

    {
      title: "Quantidade",
      dataIndex: "quantity",
      align: "center" as const,
      hidden: !preview.is_quantity_variable,
      key: "quantity",
      render: (value: any, record: any) => (
        <Form.Item
          noStyle
          name={[record.fs_document_physical_tag_id, "quantity"]}
          rules={[
            {
              required: record.required_description,
              message: "",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
      ),
    },
    {
      title: "Descrição para Localização",
      dataIndex: "description",
      hidden: !preview.requires_document_description_for_location,
      align: "center" as const,
      key: "description",
      render: (value: any, record: any) => (
        <Form.Item
          rules={[
            {
              required: record.required_description,
              message: "",
            },
          ]}
          noStyle
          name={[record.fs_document_physical_tag_id, "description"]}
        >
          <Input />
        </Form.Item>
      ),
    },
  ];

  return loadingPreview ? (
    <SpinLoading />
  ) : (
    <Form
      className="w-full h-full select-none"
      form={form}
      layout="vertical"
      onFinish={async (payload: CompletedServiceOrderType) => {
        const isCompleted = await completedServiceOrder(orderCuid, payload);
        if (isCompleted) {
          onClose();
        }
      }}
    >
      <Form.Item hidden={preview.allows_taking_original_documents_to_customer} label="Atenção">
        <Alert
          message={
            <div className="flex flex-col">
              <span>Serviço está configurado para não enviar documento original para o cliente.</span>
              <strong>Devido essa restrição o status do documento não será alterado</strong>
            </div>
          }
          type="warning"
          showIcon
        />
      </Form.Item>

      <Form.Item label="Serviço">
        <Input disabled value={preview.service_description} />
      </Form.Item>

      <Form.Item hidden={!preview.email} label="Email">
        <Space.Compact className="w-full">
          <Input disabled value={preview.email} />
          <Tooltip title="Copiar email" placement="topRight" trigger={["click", "hover"]}>
            <Button
              className="transition-all duration-300 ease-in-out hover:bg-gray-200"
              type="primary"
              icon={<Icon height={18} className="text-white" icon="fluent:mail-copy-20-filled" />}
              onClick={() => D3Copy({ text: preview.email, success: "Email copiado! " })}
            />
          </Tooltip>
        </Space.Compact>
      </Form.Item>

      {!preview.should_purge_document ? (
        <Form.Item label="Documentos" name="items">
          <Table
            columns={columns}
            dataSource={preview.items}
            rowKey="document_physical_tag_id"
            bordered
            size="small"
            pagination={false}
          />
        </Form.Item>
      ) : null}
      <div className="flex justify-between">
        <Form.Item<CompletedServiceOrderType> name="emergency_transport" label="Transporte de emergência? ">
          <Segmented
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>

        {preview.should_purge_document ? (
          <>
            <Form.Item<CompletedServiceOrderType> name="migrate_to_digital" label="Migrar para o digital? ">
              <Segmented
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </Form.Item>

            <Form.Item<CompletedServiceOrderType> name="preserve_position" label="Preservar posição? ">
              <Segmented
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </Form.Item>
          </>
        ) : null}
      </div>

      <Form.Item<CompletedServiceOrderType> name="observation" label="Observação">
        <TextArea rows={6} />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={completedLoading} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="serve" resource="order_service">
              <Button loading={completedLoading} type="primary" htmlType="submit">
                Atender
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default ServeServiceOrderForm;
