import { Form } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";
import { ItemBOO, ItemCAC, ItemDAT, ItemDTI, ItemEMA, ItemIFT, ItemINT, ItemNMI, ItemSEL, ItemTXT } from ".";
import { DocumentTypeIndexType } from "../../../types";

type ItemsProps = {
  name: number;
  item: DocumentTypeIndexType;
};

const Items = ({
  name,
  item: {
    required,
    label,
    list_of_key_and_values,
    start_interval_required,
    end_interval_required,
    system_index_type: { key },
  },
  ...props
}: ItemsProps) => {
  switch (key) {
    case "EMA":
      return <ItemEMA {...props} name={name} required={required} label={label} />;
    case "CAC":
      return <ItemCAC {...props} name={name} required={required} label={label} />;
    case "DAT":
      return <ItemDAT {...props} name={name} required={required} label={label} />;
    case "DTI":
      return (
        <ItemDTI
          {...props}
          name={name}
          requiredStart={start_interval_required}
          requiredEnd={end_interval_required}
          label={label}
        />
      );
    case "BOO":
      return <ItemBOO {...props} name={name} label={label} />;
    case "NMI":
      return (
        <ItemNMI
          {...props}
          name={name}
          requiredStart={start_interval_required}
          requiredEnd={end_interval_required}
          label={label}
        />
      );
    case "TXT":
      return <ItemTXT {...props} name={name} required={required} label={label} />;
    case "INT":
      return <ItemINT {...props} name={name} required={required} label={label} />;
    case "IFT":
      return <ItemIFT {...props} name={name} required={required} label={label} />;
    case "SEL":
      return <ItemSEL {...props} name={name} values={list_of_key_and_values} required={required} label={label} />;
    default:
      return null;
  }
};

type RenderIndexersProps = {
  indexes: DocumentTypeIndexType[];
  form: FormInstance<any>;
  attribute: NamePath | NamePath[];
};

function RenderIndexers({ indexes, form, attribute }: RenderIndexersProps) {
  return (
    <Form.List name={attribute}>
      {(fields) => {
        return (
          <>
            {indexes.length > 0 &&
              fields.length === 0 &&
              form.setFieldsValue({
                indexes_values: indexes.map((item: DocumentTypeIndexType) => {
                  return {
                    id: item.id,
                    key: item.system_index_type.key,
                    start_value: "",
                    value: "",
                    end_value: "",
                  };
                }),
              })}
            {fields.map(({ key, name, ...restField }) => {
              const { document_type_index_id } = form.getFieldValue(["indexes_values"])[key];
              const index = indexes.find((item) => item.id === document_type_index_id) as DocumentTypeIndexType;
              return <Items key={key} {...restField} name={name} item={index} />;
            })}
          </>
        );
      }}
    </Form.List>
  );
}

export default RenderIndexers;
