import Selector from "@components/shared/Selector";
import SpinLoading from "@components/shared/SpinLoading";
import { useReportsStore } from "@views/reports/store";
import { RegisterReportType } from "@views/reports/types";
import { Form, Input } from "antd";
import { useEffect, useState } from "react";

export enum ContextType {
  SYS = "Sistema",
  CPN = "Empresa",
  CTM = "Cliente",
  CGR = "Grupo de Cliente",
}

export enum ReportParamType {
  C = "Carácter",
  I = "Inteiro",
  F = "Decimal",
  D = "Data",
  B = "Condicional",
}

const listContext = Object.entries(ContextType).map(([value, label]) => ({
  value,
  label,
}));

function TabData() {
  const {
    listCustomer,
    loadingCustomer,
    listCustomerGroup,
    loadingCustomerGroup,
    payloadUpdate,
    simpleListCustomerGroup,
    simpleListCustomer,
  } = useReportsStore();
  const [form] = Form.useForm<RegisterReportType>();
  const context = Form.useWatch("context", form);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (payloadUpdate.customer_cuid || payloadUpdate.context === "CTM") {
        await simpleListCustomer();
        form.setFieldsValue(payloadUpdate);
        setLoading(false);
      } else if (payloadUpdate.customer_group_cuid || payloadUpdate.context === "CGR") {
        await simpleListCustomerGroup();
        form.setFieldsValue(payloadUpdate);
        setLoading(false);
      } else if (payloadUpdate.context) {
        form.setFieldsValue(payloadUpdate);
        setLoading(false);
      }
    })();
  }, [payloadUpdate]);

  return loading ? (
    <SpinLoading />
  ) : (
    <Form
      className="flex flex-col h-full overflow-hidden grow"
      layout="vertical"
      form={form}
      onFinish={async (values) => {}}
      autoComplete="off"
      onValuesChange={async (changedValues: any, values: RegisterReportType) => {
        if (changedValues.context) {
          if (!["CPN", "SYS"].includes(changedValues.context)) {
            if (changedValues.context === "CTM") {
              if (listCustomer.length === 0) {
                await simpleListCustomer();
              }
            } else {
              if (listCustomerGroup.length === 0) {
                await simpleListCustomerGroup();
              }
            }
          } else {
            useReportsStore.setState((state) => ({
              payloadUpdate: {
                ...state.payloadUpdate,
                customer_cuid: "",
                customer_group_cuid: "",
              },
            }));
          }
        }
        const key = Object.keys(changedValues)[0];
        const value = changedValues[key];

        useReportsStore.setState((state) => ({
          payloadUpdate: {
            ...state.payloadUpdate,
            [key]: value,
          },
        }));
      }}
    >
      <Form.Item<RegisterReportType>
        name="title"
        label="Nome"
        rules={[{ required: true, message: "Por favor, insira um nome." }]}
      >
        <Input className="w-full" />
      </Form.Item>
      <Form.Item<RegisterReportType>
        name="description"
        label="Descrição"
        rules={[{ required: true, message: "Por favor, insira uma descrição." }]}
      >
        <Input className="w-full" />
      </Form.Item>
      <Form.Item<RegisterReportType>
        name="context"
        label="Perfil"
        rules={[{ required: true, message: "Por favor,  selecione um perfil." }]}
      >
        <Selector
          fieldMap={{
            value: "value",
            label: "label",
            dataLabel: ["label"],
            dataFilter: ["label"],
          }}
          options={listContext}
        />
      </Form.Item>

      <Form.Item<RegisterReportType>
        hidden={["CPN", "SYS", "CGR"].includes(context) || !context}
        label="Cliente"
        name="customer_cuid"
      >
        <Selector
          fieldMap={{
            value: "cuid",
            label: "fantasy_name",
            description: "name",
            dataLabel: ["fantasy_name"],
            dataFilter: ["fantasy_name", "name", "id", "document"],
          }}
          options={listCustomer}
          loading={loadingCustomer}
          disabled={listCustomer.length === 0 || loadingCustomer}
          placeholder={
            loadingCustomer ? "carregando..." : listCustomer.length === 0 ? "Nenhum cliente cadastrado" : null
          }
        />
      </Form.Item>

      <Form.Item<RegisterReportType>
        hidden={["CPN", "SYS", "CTM"].includes(context) || !context}
        label="Grupo de Cliente"
        name="customer_group_cuid"
      >
        <Selector
          fieldMap={{
            value: "cuid",
            label: "description",
            dataLabel: ["description"],
            dataFilter: ["description"],
          }}
          options={listCustomerGroup}
          loading={loadingCustomerGroup}
          disabled={listCustomerGroup.length === 0 || loadingCustomerGroup}
          placeholder={
            loadingCustomerGroup ? "carregando..." : listCustomerGroup.length === 0 ? "Nenhum cliente cadastrado" : null
          }
        />
      </Form.Item>
    </Form>
  );
}

export default TabData;
