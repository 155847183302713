import { Icon } from "@iconify/react";
import { Tabs } from "antd";
import { useState } from "react";
import ListMaterial from "../prices/materials/ListMaterial";

import ListServices from "../prices/services/ListServices";
import ListRepositoryTypes from "../prices/repository-types/ListRepositoryTypes";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@context/CASLContext";

function TabPrices() {
  const [activeKey, setActiveKey] = useState("1");

  const usePermission = (action: string, resource: string, multiple?: string[]) => {
    const ability = useAbility(AbilityContext);
    if (multiple) {
			return multiple.some((rule) => {
			  const [res, act] = rule.split(".");
			  return ability.can(act, res);
			});
		  }		
    return ability.can(action, resource);
  };

  return (
    <div className="flex flex-col h-full overflow-hidden bg-white">
      <Tabs
        tabPosition="top"
        defaultActiveKey="1"
        onTabClick={(activeKey: string) => setActiveKey(activeKey)}
        className="flex h-full overflow-hidden select-none"
        items={[
          {
            disabled: !usePermission( "","",["customer_group.details", "customer_group.update"]),
            key: "1",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="tabler:wallpaper" width={18} height={18} />
                Materiais
              </span>
            ),
            children: activeKey === "1" && <ListMaterial key={activeKey} />,
          },
          {
            disabled: !usePermission( "","",["customer_group.details", "customer_group.update"]),
            key: "2",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="carbon:box" width={18} height={18} />
                Tipos de Repositório
              </span>
            ),
            children: activeKey === "2" && <ListRepositoryTypes key={activeKey} />,
          },
          {
            disabled: !usePermission( "","",["customer_group.details", "customer_group.update"]),
            key: "3",
            label: (
              <span className="flex items-center justify-center gap-2">
                <Icon icon="carbon:task-settings" width={18} height={18} />
                Serviços
              </span>
            ),
            children: activeKey === "3" && <ListServices key={activeKey} />,
          },
        ]}
      />
    </div>
  );
}

export default TabPrices;
