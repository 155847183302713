import D3Can from "@components/shared/D3Can";
import D3ProtectPage from "@components/shared/D3ProtectPage";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Button, Col, Flex, Pagination, Popover, Row, Space, Tag, Tooltip } from "antd";
import Table from "antd/es/table";
import { Fixed, FixedType } from "@utils/Fixed";
import { useContext, useEffect, useState } from "react";
import { CreateProtocolOrderForm } from "./components/CreateProtocolOrderForm";
import PrintProtocolOrderForm from "./components/PrintProtocolOrderForm";
import { SearchProtocolOrderForm } from "./components/SearchProtocolOrderForm";
import { ViewProtocolOrderForm } from "./components/ViewProtocolOrderForm";
import { StatusOrders } from "./components/statusOrders";
import { useProtocolOrdersStore } from "./store";
import { ProtocolOrderListItemType } from "./types";
import FixedColumn from "@components/shared/FixedColumn";
import { SendSignedProtocolOrderForm } from "./components/SendSignedProtocolOrderForm";
import { ctNotification } from "@components/shared/CTNotification";
import CancelProtocolOrderForm from "./components/CancelProtocolOrderForm";

const ProtocolOrdersView: React.FC = () => {
  const {
    apiResult,
    pagination,
    loading,
    setPagination,
    loadProtocolOrders,
    resetState,
    savedProtocolOrder,
    openSearch,
  } = useProtocolOrdersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [fixed, setFixed] = useState<FixedType[]>([
    {
      dataIndex: "",
      direction: "left",
    },
    {
      dataIndex: "",
      direction: "right",
    },
  ]);

  useEffect(() => {
    (async () => {
      await loadProtocolOrders();
    })();
    return () => resetState();
  }, [loadProtocolOrders]);

  return (
    <div className="flex h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<ProtocolOrderListItemType>
        className="responsive-table"
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <Row className="w-full">
                <Col span={8} className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Ordem de protocolo"
                    content={<SearchProtocolOrderForm />}
                    trigger="click"
                    onOpenChange={(open) => useProtocolOrdersStore.setState({ openSearch: open })}
                  >
                    <Button
                      onClick={() => useProtocolOrdersStore.setState({ openSearch: !openSearch })}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </Col>
                <Col span={8} className="text-center">
                  <span className="text-xl font-semibold text-gray-600">Ordens de Protocolo</span>
                </Col>
                <Col span={8} className="flex items-center justify-end">
                  <D3Can action="create" resource="order_protocol">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() =>
                        openDrawer(
                          <D3ProtectPage
                            targetPage={<CreateProtocolOrderForm />}
                            isRequiredCustomerGroup={false}
                            isRequiredCustomer={true}
                            resource="order_protocol"
                            action="view"
                          />,

                          "right",
                          600,
                          "Cadastrar Ordem de protocolo"
                        )
                      }
                    >
                      Ordem de Protocolo
                    </Button>
                  </D3Can>
                </Col>
              </Row>
            </>
          );
        }}
        footer={() => {
          return (
            <Flex className="w-full" align="center" justify="end">
              <Pagination
                showLessItems
                showTotal={(total, range) =>
                  `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                }
                showSizeChanger={true}
                current={pagination.page}
                onChange={setPagination}
                pageSize={pagination.pageSize}
                total={apiResult.total}
              />
            </Flex>
          );
        }}
      >
        <Table.Column<ProtocolOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="id"
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "id")}
              dataIndex="id"
              direction="left"
              title="Número"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "id")}
          dataIndex="id"
          render={(value) => {
            return (
              <div className="flex items-center justify-between gap-2">
                {value}
                <Tooltip trigger={["click", "hover"]} title="Copiar número da ordem">
                  <Button
                    className="transition-all duration-300 ease-in-out hover:bg-gray-200"
                    type="link"
                    shape="default"
                    icon={<Icon className="text-blue-500" icon="fluent:copy-32-filled" />}
                    size="small"
                    onClick={() => D3Copy({ text: value, success: "Número copiado! " })}
                  />
                </Tooltip>
              </div>
            );
          }}
        />

        <Table.Column<ProtocolOrderListItemType>
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "customer_name")}
              dataIndex="customer_name"
              direction="left"
              title="Cliente"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "customer_name")}
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="customer_name"
          dataIndex="customer_name"
        />
        <Table.Column<ProtocolOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="requester_department"
          title="Departamento"
          dataIndex="requester_department"
        />
        <Table.Column<ProtocolOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="requester_user"
          title="Requisitado por"
          dataIndex="requester_user"
        />
        <Table.Column<ProtocolOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_by"
          title="Criado por"
          dataIndex="created_by"
        />
        <Table.Column<ProtocolOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_at"
          title="Data criação"
          dataIndex="created_at"
          render={(value, protocolOrder) => D3DateHourSorter(protocolOrder.created_at)}
        />

        <Table.Column<ProtocolOrderListItemType>
          showSorterTooltip={false}
          key="active"
          title="Status"
          dataIndex="active"
          render={(value, protocolOrder) => {
            return (
              <Space>
                {protocolOrder.printed && (
                  <Tooltip title="Ordem impressa" trigger={["click", "hover"]}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:print-duotone"
                          className="text-blue-400 transition-all duration-100 ease-in hover:scale-110 hover:text-blue-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {protocolOrder.canceled && (
                  <Tooltip title="Ordem cancelada" trigger={["click", "hover"]}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:cancel"
                          className="text-red-400 transition-all duration-100 ease-in hover:scale-110 hover:text-red-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {protocolOrder.served && (
                  <Tooltip title="Ordem atendida" trigger={["click", "hover"]}>
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="fluent:document-checkmark-16-filled"
                          className="text-green-400 transition-all duration-100 ease-in hover:scale-110 hover:text-green-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />

        <Table.Column<ProtocolOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="status"
          title="Status atual"
          dataIndex="status"
          render={(value, protocolOrder) => {
            const statusObj = StatusOrders.find((item) => item.status === protocolOrder.status);
            if (statusObj) {
              return (
                <Tag
                  className="flex items-center gap-1 py-[1px] max-w-max"
                  color={statusObj.color}
                  icon={<Icon icon={statusObj.icon} />}
                >
                  {statusObj.label}
                </Tag>
              );
            }

            return null;
          }}
        />

        <Table.Column<ProtocolOrderListItemType>
          key="actions"
          width={200}
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "actions")}
              dataIndex="actions"
              direction="right"
              title="Ações"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "actions")}
          render={(value, protocolOrder) => {
            return (
              <div className="flex items-center justify-center gap-1 px-2 ">
                <D3Can action="view" resource="order_protocol" type="hidden">
                  <Tooltip title="Visualizar ordem" trigger={["click", "hover"]}>
                    <Button
                      className="peer"
                      type="text"
                      shape="default"
                      icon={
                        <Icon
                          icon="carbon:document-view"
                          height={18}
                          width={18}
                          className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                        />
                      }
                      size={"middle"}
                      onClick={() =>
                        openDrawer(
                          <ViewProtocolOrderForm cuid={protocolOrder.cuid} />,
                          "right",
                          600,
                          "Visualizar Ordem de Protocolo"
                        )
                      }
                    />
                  </Tooltip>
                </D3Can>

                <D3Can action="print" resource="order_protocol" type="hidden">
                  {protocolOrder.canceled ? null : (
                    <Tooltip title="Imprimir ordem" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="lets-icons:print-duotone"
                            height={24}
                            width={24}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <PrintProtocolOrderForm
                              printed={protocolOrder.printed}
                              cuid={protocolOrder.cuid}
                              fileName={`Ordem de material nº ${protocolOrder.id}`}
                            />,
                            "right",
                            1000,
                            "Imprimir Ordem de Protocolo",
                            true
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="serve" resource="order_protocol" type="hidden">
                  {protocolOrder.canceled || protocolOrder.served ? null : (
                    <Tooltip title="Atender a ordem" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="fluent:document-checkmark-16-filled"
                            height={20}
                            width={20}
                            className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                          />
                        }
                        size={"middle"}
                        onClick={() => {
                          ctNotification({
                            type: "warning",
                            title: `Atenção !`,
                            content: "Confirma o atendimento da ordem de protocolo?",
                            withoutFooter: false,
                            okText: `Confirmar`,
                            doNotClose: true,
                            cancelText: "Cancelar",
                            onOk: async () => {
                              await savedProtocolOrder(protocolOrder.cuid);
                            },
                          });
                        }}
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="serve" resource="order_protocol" type="hidden">
                  {protocolOrder.canceled ? null : (
                    <Tooltip trigger={["click", "hover"]} title="Envio/reenvio da ordem digitalizada">
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="fa6-solid:file-signature"
                            height={16}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <SendSignedProtocolOrderForm
                              orderCuid={protocolOrder.cuid}
                              fileName={`Ordem de protocolo nº ${protocolOrder.id}`}
                              isUploadOrder={protocolOrder.has_order_resent}
                            />,
                            "right",
                            600,
                            "Envio / reenvio da ordem digitalizada"
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="cancel" resource="order_protocol" type="hidden">
                  {protocolOrder.served || protocolOrder.canceled ? null : (
                    <Tooltip title="Cancelar ordem" trigger={["click", "hover"]}>
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        onClick={() =>
                          openDrawer(
                            <CancelProtocolOrderForm orderCuid={protocolOrder.cuid} />,
                            "right",
                            600,
                            "Cancelar Ordem de Protocolo"
                          )
                        }
                        icon={
                          <Icon
                            icon="lets-icons:cancel"
                            height={20}
                            width={20}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  )}
                </D3Can>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default ProtocolOrdersView;
