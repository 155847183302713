import React, { useState, useCallback, useEffect, useRef } from "react";
import { Upload, Slider, Button, Typography, Modal, message, Tooltip } from "antd";
import Cropper from "react-easy-crop";
import Webcam from "react-webcam";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import { RcFile, UploadChangeParam } from "antd/es/upload/interface";
import { useUploadImageStore } from "./store";
import { Icon } from "@iconify/react";
import useAuthStore from "@provider/AuthProvider";

const ORIENTATION_TO_ANGLE: { [key: string]: number } = {
  "3": 180,
  "6": 90,
  "8": -90,
};

const ProfilePicture: React.FC = () => {
  const webcamRef = useRef<Webcam>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectionVisible, setSelectionVisible] = useState<boolean>(false);
  const [isWebcamMode, setIsWebcamMode] = useState<boolean>(false);
  const { uploadImage, deleteImage } = useUploadImageStore();
  const { getUserInfo } = useAuthStore();

  useEffect(() => {
    if (getUserInfo().ProfilePicture) {
      setCroppedImage(getUserInfo().ProfilePicture);
    }
  }, []);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (imageSrc && croppedAreaPixels) {
      try {
        const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
        setCroppedImage(croppedImg);
        setModalVisible(false);
        uploadImage(croppedImg as string);
      } catch (e) {
        console.error(e);
        message.error("Erro ao cortar a imagem.");
      }
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setModalVisible(false);
    setImageSrc(null);
    setIsWebcamMode(false);
  }, []);

  const readFile = async (file: RcFile): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as string));
      reader.readAsDataURL(file);
    });
  };

  const onFileChange = async (info: UploadChangeParam) => {
    const file = info.file.originFileObj as RcFile;
    if (file) {
      let imageDataUrl = await readFile(file);

      try {
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation as keyof typeof ORIENTATION_TO_ANGLE];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn("Falha ao detectar a orientação");
      }

      setImageSrc(imageDataUrl);
      setModalVisible(true);
    }
  };

  const captureFromWebcam = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc || null);
      setModalVisible(true);
      setIsWebcamMode(false);
    }
  }, [webcamRef]);

  const handleEditClick = () => {
    setSelectionVisible(true); // Abre modal para escolher entre Computador e Webcam
  };

  const handleSelection = (method: "computer" | "webcam") => {
    setSelectionVisible(false);
    if (method === "webcam") {
      setIsWebcamMode(true);
      setModalVisible(true);
    } else {
      document.getElementById("fileUploadButton")?.click();
    }
  };

  const uploadButton = (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation();
        handleEditClick();
      }}
    >
      <div className="flex flex-col items-center justify-center gap-1 ">
        <Icon height={28} className="text-gray-500 " icon="lets-icons:img-load-box-fill" />
        <span className="font-bold text-gray-500 ">Upload</span>
      </div>
    </Button>
  );

  return (
    <>
      <Upload
        accept="image/*"
        name="avatar"
        listType="picture-circle"
        className="rounded-full drop-shadow-md"
        showUploadList={false}
        customRequest={() => {}}
        onChange={onFileChange}
      >
        {croppedImage ? (
          <div className="relative flex items-center justify-center rounded-full">
            <div className="absolute flex items-center justify-center w-full h-full gap-1 bg-gray-200 rounded-full bg-opacity-70">
              <Tooltip title="Editar">
                <Button
                  type="link"
                  icon={
                    <Icon
                      height={18}
                      className="text-blue-400 transition-all duration-200 ease-linear drop-shadow hover:scale-125 hover:text-blue-500"
                      icon="material-symbols:edit"
                    />
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick();
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir">
                <Button
                  onClick={async (e) => {
                    e.stopPropagation();
                    await deleteImage();
                    setCroppedImage(null);
                  }}
                  type="link"
                  icon={
                    <Icon
                      height={18}
                      className="text-red-400 transition-all duration-200 ease-linear drop-shadow hover:scale-125 hover:text-red-500"
                      icon="icon-park-solid:delete-four"
                    />
                  }
                />
              </Tooltip>
            </div>
            <img src={croppedImage} alt="avatar" className="rounded-full" />
          </div>
        ) : (
          uploadButton
        )}
      </Upload>

      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="fileUploadButton"
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            onFileChange({ file: { originFileObj: e.target.files[0] } } as UploadChangeParam);
          }
        }}
      />

      <Modal
        className="select-none"
        maskClosable={false}
        open={modalVisible}
        onCancel={onClose}
        onOk={showCroppedImage}
        title={isWebcamMode ? "Capturar Imagem com Webcam" : "Ajuste sua Imagem"}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancelar
          </Button>,
          isWebcamMode ? (
            <Button key="capture" type="primary" onClick={captureFromWebcam}>
              Capturar Foto
            </Button>
          ) : (
            <Button key="submit" type="primary" onClick={showCroppedImage}>
              Enviar
            </Button>
          ),
        ]}
      >
        {isWebcamMode ? (
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/png" width="100%" />
        ) : imageSrc ? (
          <div className="flex flex-col pt-7">
            <div className="relative w-full bg-gray-600 h-96">
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                cropShape="round"
                aspect={1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className="flex items-center justify-between gap-6 py-4">
              <div className="w-full">
                <Typography.Text>Zoom</Typography.Text>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(value) => setZoom(value as number)}
                  className="w-full"
                />
              </div>
              <div className="w-full">
                <Typography.Text>Rotacionar</Typography.Text>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  onChange={(value) => setRotation(value as number)}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        ) : null}
      </Modal>

      <Modal
        maskClosable={false}
        open={selectionVisible}
        onCancel={() => setSelectionVisible(false)}
        footer={null}
        title="Escolha uma opção"
      >
        <div className="flex items-center gap-4">
          <Button icon={<Icon icon="icon-park-outline:laptop-computer" />} onClick={() => handleSelection("computer")}>
            Fazer upload do computador
          </Button>
          <Button
            icon={<Icon icon="material-symbols:photo-camera-outline" />}
            onClick={() => handleSelection("webcam")}
          >
            Tirar um foto
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ProfilePicture;
