import { DatePicker, Form } from "antd";
import dayjs from "dayjs";

type ItemDATProps = {
  name: number;
  required: boolean;
  label: string;
};

function ItemDAT({ name, required, label, ...props }: ItemDATProps) {
  return (
    <Form.Item
      getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
      normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
      {...props}
      name={[name, "value"]}
      label={label}
      rules={[
        {
          required: required,
          message: "Obrigatório",
        },
      ]}
    >
      <DatePicker
        format={{
          format: "DD/MM/YYYY",
          type: "mask",
        }}
      />
    </Form.Item>
  );
}

export default ItemDAT;
