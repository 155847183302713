import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { Badge, Button, Pagination, Popconfirm, Popover, Space, Tag, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useRepositoriesStore } from "./store";
import { RowTrashType } from "./types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { SearchTrashForm } from "./components/SearchTrashForm";
import ViewTrashForm from "./components/ViewTrashForm";

export enum FileType {
  FLE = "Arquivo",
  FLD = "Pasta",
  SDC = "Documento Estruturado",
}

const TrashView: React.FC = () => {
  const {
    apiResult,
    pagination,
    loading,
    setPagination,
    setSort,
    loadTrash,
    resetFilter,
    resetState,
    selectedRows,
    restore,
    deleteToDay,
    empty,
    loadingEmpty,
    onMultiSelectedRowChange,
  } = useRepositoriesStore();
  const [openSearch, setOpenSearch] = useState(false);
  const { openDrawer } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await loadTrash();
    })();
    return () => {
      resetState();
    };
  }, [loadTrash]);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<RowTrashType>
        size="small"
        rowKey="fs_cuid"
        dataSource={apiResult.data as RowTrashType[]}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div
                className={`flex items-center justify-between w-full ${selectedRows.cuids.length > 0 ? "pr-3" : ""}`}
              >
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar lixeira"
                    content={
                      <SearchTrashForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Lixeira</span>
                </div>
                <div className="flex items-center justify-end">
                  <Popconfirm
                    title="Atenção"
                    description={
                      <div className="flex text-justify max-w-64">
                        Confirmar exclusão permanente? Essa ação não pode ser desfeita.
                      </div>
                    }
                    onConfirm={async () => {
                      await empty();
                    }}
                    okText="Sim"
                    okButtonProps={{ loading: loadingEmpty }}
                    cancelText="Não"
                    placement="leftBottom"
                  >
                    <Badge size="small" count={apiResult.total} showZero={false}>
                      <Button
                        icon={<Icon height={18} icon="basil:trash-alt-solid" />}
                        loading={loadingEmpty}
                        danger
                        disabled={apiResult.data.length === 0}
                        type="dashed"
                      >
                        Esvaziar lixeira
                      </Button>
                    </Badge>
                  </Popconfirm>
                </div>
              </div>
            </>
          );
        }}
        onChange={(a, b, sorter: any) => {
          if (sorter) {
            setSort({
              sort_by: sorter.field,
              sort_direction: sorter.order,
            });
          }
        }}
        rowSelection={{
          preserveSelectedRowKeys: true,
          hideSelectAll: false,
          type: "checkbox",
          selectedRowKeys: selectedRows.cuids,
          onChange: (_, selectRows: RowTrashType[]) => {
            onMultiSelectedRowChange(selectRows);
          },
        }}
        footer={() => {
          return (
            <div className="flex items-center justify-between w-full">
              <div className="flex gap-4">
                <D3Can action="restore" resource="customer_fs_trash">
                  <Badge color="blue" count={selectedRows.cuids.length}>
                    <Popconfirm
                      disabled={selectedRows.cuids.length === 0}
                      title="Atenção"
                      description={
                        selectedRows.cuids.length > 1
                          ? `Confirma a restauração de ${selectedRows.cuids.length} itens?`
                          : "Confirma a restauração de 1 item?"
                      }
                      onConfirm={async () => {
                        await restore();
                      }}
                      okText="Sim"
                      cancelText="Não"
                      placement="top"
                    >
                      <Button
                        type="primary"
                        disabled={selectedRows.cuids.length === 0}
                        size="middle"
                        icon={<Icon icon="codicon:debug-restart" height={16} width={16} />}
                      >
                        Restaurar
                      </Button>
                    </Popconfirm>
                  </Badge>
                </D3Can>
                <D3Can action="delete_today" resource="customer_fs_trash">
                  <Badge color="red" count={selectedRows.cuids.length}>
                    <Popconfirm
                      disabled={selectedRows.cuids.length === 0}
                      title="Atenção"
                      description={
                        <div className="flex text-justify max-w-64">{`Confirmar exclusão permanente de ${
                          selectedRows.cuids.length
                        }  ${
                          selectedRows.cuids.length > 1 ? "itens" : "item"
                        } ? Essa ação não pode ser desfeita.`}</div>
                      }
                      onConfirm={async () => {
                        await deleteToDay();
                      }}
                      okText="Sim"
                      cancelText="Não"
                      placement="top"
                    >
                      <Button
                        danger
                        type="primary"
                        disabled={selectedRows.cuids.length === 0}
                        size="middle"
                        icon={<Icon icon="fa6-solid:trash-arrow-up" height={14} width={14} />}
                      >
                        Excluir
                      </Button>
                    </Popconfirm>
                  </Badge>
                </D3Can>
              </div>
              <Space>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                  }
                  showSizeChanger={true}
                  current={pagination.page}
                  onChange={setPagination}
                  pageSize={pagination.pageSize}
                  total={apiResult.total}
                />
              </Space>
            </div>
          );
        }}
      >
        <Table.Column<RowTrashType> showSorterTooltip={false} key="fs_name" title="Nome" dataIndex="fs_name" sorter />

        <Table.Column<RowTrashType>
          showSorterTooltip={false}
          key="fs_type"
          title="Tipo"
          dataIndex="fs_type"
          sorter
          render={(value) => FileType[value as keyof typeof FileType]}
        />

        <Table.Column<RowTrashType>
          showSorterTooltip={false}
          key="fs_is_physical"
          title="Documento"
          render={(value) => (value ? "Físico" : "Digital")}
          dataIndex="fs_is_physical"
        />

        <Table.Column<RowTrashType>
          showSorterTooltip={false}
          key="fs_number_of_childrens"
          title="Quantidade de itens"
          dataIndex="fs_number_of_childrens"
        />

        <Table.Column<RowTrashType>
          showSorterTooltip={false}
          key="trash_days_remaining"
          title="Dias restantes"
          render={(value) => (
            <Tag color={value > 19 ? "#4ade80" : value > 9 ? "#facc15" : value > 3 ? "#fb923c" : "#f87171"}>
              <div className="flex items-center gap-1 ">
                <Icon height={16} icon="mdi:calendar-remove" />
                <span>{value > 1 ? value + " dias" : value + " dia"} </span>
              </div>
            </Tag>
          )}
          dataIndex="trash_days_remaining"
        />

        <Table.Column<RowTrashType>
          showSorterTooltip={false}
          key="trash_deleted_user_name"
          title="Excluído por"
          dataIndex="trash_deleted_user_name"
          sorter
        />
        <Table.Column<RowTrashType>
          showSorterTooltip={false}
          key="deletion_reason"
          title="Motivo"
          dataIndex="deletion_reason"
          render={(value) => (
            <Tooltip title={value} trigger={["click", "hover"]}>
              <span>
                <Icon
                  height={18}
                  className="text-red-400 transition-all duration-200 ease-in hover:text-red-500 cursor-help"
                  icon="mdi:file-document-delete"
                />
              </span>
            </Tooltip>
          )}
        />

        <Table.Column<RowTrashType>
          showSorterTooltip={false}
          key="trash_scheduled_deletion_date"
          title="Data agendada para exclusão"
          dataIndex="trash_scheduled_deletion_date"
          render={(value) => dayjs(value).format("DD/MM/YYYY")}
          sorter
        />
        <Table.Column<RowTrashType>
          key="action"
          title="Ações"
          width={100}
          render={(value, trash) => {
            return (
              <>
                <Space>
                  <D3Can action="view" resource="customer_fs_trash">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <ViewTrashForm item={trash} />,
                            "right",
                            600,
                            `Visualizar ${FileType[trash.fs_type as keyof typeof FileType].toLowerCase()}`
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="restore" resource="customer_fs_trash">
                    <Popconfirm
                      title="Atenção"
                      description="Confirma a restauração do item?"
                      onConfirm={async () => {
                        await restore(trash.fs_cuid);
                      }}
                      okText="Sim"
                      cancelText="Não"
                      placement="top"
                    >
                      <Tooltip title="Restaurar" trigger={["click", "hover"]}>
                        <Button
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="codicon:debug-restart"
                              height={18}
                              width={18}
                              className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                            />
                          }
                          size={"middle"}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </D3Can>
                  <D3Can action="delete_today" resource="customer_fs_trash">
                    <Popconfirm
                      title="Atenção"
                      description={
                        <div className="flex text-justify max-w-64">
                          Confirmar exclusão permanente? Essa ação não pode ser desfeita.
                        </div>
                      }
                      onConfirm={async () => {
                        await deleteToDay(trash.fs_cuid);
                      }}
                      okText="Sim"
                      cancelText="Não"
                      placement="topLeft"
                    >
                      <Tooltip title="Excluir hoje" trigger={["click", "hover"]}>
                        <Button
                          className="peer"
                          type="text"
                          shape="default"
                          icon={
                            <Icon
                              icon="fa6-solid:trash-arrow-up"
                              height={18}
                              width={18}
                              className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                            />
                          }
                          size={"middle"}
                          onClick={() => {}}
                        />
                      </Tooltip>
                    </Popconfirm>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default TrashView;
