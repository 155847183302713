import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  ItemCustomerGroupType,
  ItemParamsReportType,
  ItemSimpleListCustomerType,
  ListReportsType,
  ParamsReportType,
  RegisterReportType,
  RunReportType,
  SearchType,
  SimpleListCustomerGroupType,
  SimpleListCustomerType,
  UpdateReportType,
  ViewReportType,
} from "./types";
import { SetSort, SortType } from "@utils/table/SetSort";

type ReportStoreType = {
  loadReports: () => Promise<void>;
  apiResult: ListReportsType;
  loading: boolean;

  filter: SearchType;
  setFilter: (filter: SearchType) => void;
  resetFilter: () => void;

  openSearch: boolean;
  closeSearch: (open: boolean) => void;

  pagination: { page: number; pageSize: number };
  setPagination: (page: number, pageSize: number) => void;

  setSort: (newSort: SortType) => void;
  sort: { sort_by: string; sort_direction: string };

  resetState: () => void;

  viewReport: (reportCuid: string) => Promise<void>;
  view: ViewReportType;
  loadingView: boolean;

  registerReport: () => Promise<boolean>;
  loadingRegister: boolean;

  updateReport: (reportCuid: string) => Promise<boolean>;
  loadingUpdate: boolean;
  payloadUpdate: UpdateReportType;

  deleteReport: (reportCuid: string) => Promise<boolean>;
  loadingDelete: boolean;

  runReport: (reportCuid: string, payload: RunReportType) => Promise<boolean>;
  loadingRun: boolean;

  simpleListCustomer: () => Promise<void>;
  loadingCustomer: boolean;
  listCustomer: ItemSimpleListCustomerType[];

  simpleListCustomerGroup: () => Promise<void>;
  loadingCustomerGroup: boolean;
  listCustomerGroup: ItemCustomerGroupType[];

  payload: RegisterReportType;

  listParamsReport: (reportCuid: string) => Promise<void>;
  listParams: ItemParamsReportType[];
  loadingListParams: boolean;
};

const defaultSort = {
  sort_by: "report_title",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  report_title: { op: "lk", type: "string" },
  report_description: { op: "lk", type: "string" },
  report_context: { op: "eq", type: "string" },
  customer_cuid: { op: "eq", type: "string" },
  customer_group_cuid: { op: "eq", type: "string" },
};

export const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  loading: false,

  filter: {
    report_title: "",
    report_description: "",
    report_context: "",
    customer_cuid: "",
    customer_group_cuid: "",
  } as SearchType,

  openSearch: false,

  pagination: {
    page: 1,
    pageSize: 10,
  },

  sort: defaultSort,

  view: {} as ViewReportType,
  loadingView: false,
  loadingRegister: false,
  loadingUpdate: false,
  loadingDelete: false,
  loadingRun: false,

  loadingCustomer: false,
  listCustomer: [],

  loadingCustomerGroup: false,
  listCustomerGroup: [],

  payload: {
    context: "",
    customer_cuid: "",
    customer_group_cuid: "",
    description: "",
    report_params: [],
    report_sessions: [],
    title: "",
  },

  payloadUpdate: {
    context: "",
    customer_cuid: "",
    customer_group_cuid: "",
    description: "",
    report_params: [],
    report_sessions: [],
    title: "",
  },

  listParams: [],
  loadingListParams: false,
};

export const useReportsStore = create<ReportStoreType>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchType) => {
    set({
      filter,
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadReports();
  },

  resetFilter: () => {
    set({
      filter: { ...defaultState.filter },
    });
    get().loadReports();
  },

  closeSearch: (open: boolean) => {
    set({ openSearch: open });
  },

  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["title", "report_title"],
        ["description", "report_description"],
        ["context", "report_context"],
      ]),
      set,
      get().loadReports
    ),

  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadReports();
  },

  loadReports: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);
    await new ClientHttp().get<Success<ListReportsType>, BadRequest | StandardError>(
      `/api/v1/reports?${qs}`,
      (result: Success<ListReportsType>) => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  viewReport: async (reportCuid: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewReportType>, BadRequest | StandardError>(
      `/api/v1/reports/${reportCuid}`,
      (result: Success<ViewReportType>) => {
        set({
          view: result.body,
          loadingView: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingView: false });
      }
    );
  },

  registerReport: async (): Promise<boolean> => {
    set({ loadingRegister: true });
    const payload: RegisterReportType = get().payload;
    const result = await new ClientHttp().post<RegisterReportType, Success<void>, BadRequest | StandardError>(
      "/api/v1/reports",
      payload,
      (result: Success<void>) => {
        set({ loadingRegister: false });
        showMessage(result, "Relatório cadastrado com sucesso.");
        get().loadReports();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingRegister: false });
      }
    );
    return result.status === "success";
  },

  updateReport: async (reportCuid: string): Promise<boolean> => {
    set({ loadingUpdate: true });
    const payloadUpdate: UpdateReportType = get().payloadUpdate;
    const result = await new ClientHttp().put<UpdateReportType, Success<void>, BadRequest | StandardError>(
      `/api/v1/reports/${reportCuid}`,
      payloadUpdate,
      (result: Success<void>) => {
        set({ loadingUpdate: false });
        showMessage(result, "Relatório atualizado com sucesso.");
        get().loadReports();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdate: false });
      }
    );
    return result.status === "success";
  },

  deleteReport: async (reportCuid: string): Promise<boolean> => {
    set({ loadingDelete: true });
    const result = await new ClientHttp().delete<Success<void>, BadRequest | StandardError>(
      `/api/v1/reports/${reportCuid}`,
      (result: Success<void>) => {
        set({ loadingDelete: false });
        showMessage(result, "Relatório excluído com sucesso.");
        get().loadReports();
      },
      (result: BadRequest | StandardError) => {
        showMessage(result);
        set({ loadingDelete: false });
      }
    );
    return result.status === "success";
  },

  runReport: async (reportCuid: string, payload: RunReportType): Promise<boolean> => {
    set({ loadingRun: true });
    const result = await new ClientHttp().down<RunReportType, Success<void>, BadRequest | StandardError>(
      `/api/v1/reports/${reportCuid}/run`,
      payload,
      (result: Success<void>) => {
        set({ loadingRun: false });
        showMessage(result, "Relatório gerado com sucesso.");
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingRun: false });
      }
    );
    return result.status === "success";
  },

  simpleListCustomer: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/system/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data, loadingCustomer: false });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({ loadingCustomer: false });
      }
    );
  },
  simpleListCustomerGroup: async (): Promise<void> => {
    set({ loadingCustomerGroup: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerGroupType>, StandardError, void>(
      "/api/v1/customer-group/simple-list",
      (result: Success<SimpleListCustomerGroupType>): void => {
        set({ listCustomerGroup: result.body.data, loadingCustomerGroup: false });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({ loadingCustomerGroup: false });
      }
    );
  },
  listParamsReport: async (reportCuid: string): Promise<void> => {
    set({ loadingListParams: true });
    await new ClientHttp().get<Success<ParamsReportType>, BadRequest | StandardError>(
      `/api/v1/reports/${reportCuid}/params`,
      (result: Success<ParamsReportType>) => {
        set({
          listParams: result.body.data,
          loadingListParams: false,
        });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingListParams: false });
      }
    );
  },
}));
