import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { Button, Popconfirm, Tooltip } from "antd";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type Params = any[];
type ToggleLockActionProps = {
  rules: string;
  isVisible: boolean;
  title: string;
  description: ReactElement | string;
  paramsToAction: Params;
  tooltip: string;
  action: (...params: Params) => Promise<void>;
};
export const ToggleLockAction = ({
  rules,
  isVisible,
  title,
  description,
  action,
  tooltip,
  paramsToAction,
}: ToggleLockActionProps) => {
  const { t } = useTranslation();
  return isVisible ? (
    <Popconfirm
      className="select-none"
      title={title}
      description={<>{description}</>}
      onConfirm={() => {
        action(paramsToAction);
      }}
      okText={t("shared.popconfirm.ok")}
      cancelText={t("shared.popconfirm.cancel")}
      placement="left"
    >
      <D3Can rules={[rules ?? ""]}>
        <Tooltip title={tooltip} className="select-none" trigger="hover">
          <Button
            type="text"
            shape="default"
            icon={
              <Icon
                icon={`basil:${tooltip === t("shared.list.btn_lock") ? "lock" : "unlock"}-solid`}
                height={18}
                width={18}
                className={`${
                  tooltip === t("shared.list.btn_lock")
                    ? "text-red-400 hover:text-red-500"
                    : "text-green-400 hover:text-green-500"
                } transition-all duration-100 ease-in`}
              />
            }
            size={"middle"}
          />
        </Tooltip>
      </D3Can>
    </Popconfirm>
  ) : (
    <></>
  );
};
