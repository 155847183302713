import { StatusOrdersType } from "../types";

export const StatusOrders: StatusOrdersType[] = [
  {
    status: "WTG",
    label: "Aguardando atendimento",
    color: "processing",
    icon: "mingcute:service-fill",
  },
  {
    status: "SRV",
    label: "Atendida",
    color: "success",
    icon: "entypo:check",
  },
  {
    status: "CLD",
    label: "Cancelada",
    color: "error",
    icon: "eva:close-fill",
  },
];
