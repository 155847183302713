import axios, { AxiosInstance } from "axios";
import apiClient from "../apiClient";

export default class ClientHttp {
  httpClient: AxiosInstance;

  constructor() {
    this.httpClient = apiClient;
  }

  async post<TPayload, TSuccess, TError>(
    url: string,
    data: TPayload,
    callbackSuccess?: (result: TSuccess) => void,
    callbackError?: (result: TError) => void
  ): Promise<TSuccess | TError> {
    let result = {} as TSuccess | TError;

    try {
      let response = await this.httpClient.post<TSuccess>(url, data);

      result = {
        code: response.status,
        status: "success",
        body: response.data,
      } as TSuccess;

      if (callbackSuccess) {
        callbackSuccess(result);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result = {
          code: error?.response?.status ?? 500,
          status: "error",
          body: error?.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido",
          },
        } as TError;
      }
      if (callbackError) {
        callbackError(result);
      }
    }

    return result;
  }

  async put<TPayload, TSuccess, TError>(
    url: string,
    data: TPayload,
    callbackSuccess?: (result: TSuccess) => void,
    callbackError?: (result: TError) => void
  ): Promise<TSuccess | TError> {
    let result = {} as TSuccess | TError;

    try {
      let response = await this.httpClient.put<TSuccess>(url, data);

      result = {
        code: response.status,
        status: "success",
        body: response.data,
      } as TSuccess;

      if (callbackSuccess) {
        callbackSuccess(result);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result = {
          code: error?.response?.status ?? 500,
          status: "error",
          body: error?.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido",
          },
        } as TError;
      }

      if (callbackError) {
        callbackError(result);
      }
    }

    return result;
  }

  async patch<TPayload, TSuccess, TError>(
    url: string,
    data: TPayload,
    callbackSuccess?: (result: TSuccess) => void,
    callbackError?: (result: TError) => void
  ): Promise<TSuccess | TError> {
    let result = {} as TSuccess | TError;

    try {
      let response = await this.httpClient.patch<TSuccess>(url, data);
      result = {
        code: response.status,
        status: "success",
        body: response.data,
      } as TSuccess;

      if (callbackSuccess) {
        callbackSuccess(result);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result = {
          code: error?.response?.status ?? 500,
          status: "error",
          body: error?.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido",
          },
        } as TError;
      }

      if (callbackError) {
        callbackError(result);
      }
    }

    return result;
  }

  async delete<TSuccess, TError>(
    url: string,
    callbackSuccess?: (result: TSuccess) => void,
    callbackError?: (result: TError) => void
  ): Promise<TSuccess | TError> {
    let result = {} as TSuccess | TError;

    try {
      let response = await this.httpClient.delete<TSuccess>(url);

      result = {
        code: response.status,
        status: "success",
        body: response.data,
      } as TSuccess;

      if (callbackSuccess) {
        callbackSuccess(result);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result = {
          code: error?.response?.status ?? 500,
          status: "error",
          body: error?.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido",
          },
        } as TError;
      }

      if (callbackError) {
        callbackError(result);
      }
    }

    return result;
  }

  async get<TSuccess, TError>(
    url: string,
    callbackSuccess?: (result: TSuccess) => void,
    callbackError?: (result: TError) => void
  ): Promise<TSuccess | TError> {
    let result = {} as TSuccess | TError;

    try {
      let response = await this.httpClient.get(url);

      result = {
        code: response.status,
        status: "success",
        body: await response.data,
      } as TSuccess;

      if (callbackSuccess) {
        callbackSuccess(result);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response) {
        result = {
          code: error?.response?.status ?? 500,
          status: "error",
          body: error?.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido",
          },
        } as TError;
      }

      if (callbackError) {
        callbackError(result);
      }
    }

    return result;
  }

  async getItensForSelect<TSuccess, TError, TReturn>(
    url: string,
    callbackSuccess: (result: TSuccess) => TReturn,
    callbackError: (result: TError) => TReturn
  ): Promise<TReturn> {
    let result = {} as TSuccess | TError;

    try {
      let response = await this.httpClient.get(url);
      result = {
        code: response.status,
        status: "success",
        body: response.data,
      } as TSuccess;

      return callbackSuccess(result);
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response) {
        result = {
          code: error?.response?.status ?? 500,
          status: "error",
          body: error?.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido ao buscar a lista de itens",
          },
        } as TError;
      }

      return callbackError(result);
    }
  }

  async down<TPayload, TSuccess, TError>(
    url: string,
    data: TPayload,
    callbackSuccess?: (result: TSuccess) => void,
    callbackError?: (result: TError) => void
  ): Promise<TSuccess | TError> {
    let result = {} as TSuccess | TError;

    try {
      let response = await this.httpClient.post<Blob>(url, data, {
        responseType: "blob",
      });
      const contentDisposition = response.headers["content-disposition"];

      const filename = contentDisposition ? contentDisposition.split("filename=")[1] : "arquivo.xlsx";

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const urlCreate = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlCreate;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlCreate);

      result = {
        code: response.status,
        status: "success",
        body: response.data,
      } as TSuccess;

      if (callbackSuccess) {
        callbackSuccess(result);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result = {
          code: error?.response?.status ?? 500,
          status: "error",
          body: error?.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido",
          },
        } as TError;
      }

      if (callbackError) {
        callbackError(result);
      }
    }

    return result;
  }
  async uploadImage<TSuccess, TError>(
    url: string,
    data: FormData,
    callbackSuccess?: (result: TSuccess) => void,
    callbackError?: (result: TError) => void
  ): Promise<TSuccess | TError> {
    let result = {} as TSuccess | TError;

    try {
      const response = await apiClient.patch<TSuccess>(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      result = {
        code: response.status,
        status: "success",
        body: response.data,
      } as TSuccess;

      if (callbackSuccess) {
        callbackSuccess(result);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result = {
          code: error.response?.status ?? 500,
          status: "error",
          body: error.response?.data,
        } as TError;
      } else {
        result = {
          code: 500,
          status: "error",
          body: {
            message: "Ops! Ocorreu um erro desconhecido",
          },
        } as TError;
      }

      if (callbackError) {
        callbackError(result);
      }
    }

    return result;
  }
}
