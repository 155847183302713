import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Input, Select, Space } from "antd";
import { useContext, useEffect } from "react";
import { SendEmailType } from "../types";
import useFileStore from "../store";
import Selector from "@components/shared/Selector";
import { ctNotification } from "@components/shared/CTNotification";

type SendEmailProps = {
  fileCuid: string;
};

const { TextArea } = Input;

function SendEmail({ fileCuid }: SendEmailProps) {
  const { simpleListUsersCustomer, loadingUsers, users, sendEmail, loadingSendEmail } = useFileStore();
  const [form] = Form.useForm<SendEmailType>();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await simpleListUsersCustomer();
    })();
  }, [fileCuid]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (payload: SendEmailType) => {
        if (payload.customer_users_cuids === undefined && payload.emails === undefined) {
          ctNotification({
            title: "Atenção",
            content: "Pelo menos um dos campos deve ser preenchido.",
          });
        } else {
          const isSend = await sendEmail(fileCuid, payload);
          if (isSend) {
            onClose();
          }
        }
      }}
    >
      <Form.Item<SendEmailType> name="customer_users_cuids" label="Usuários">
        <Selector
          fieldMap={{
            status: "active",
            value: "cuid",
            label: "username",
            description: "name",
            dataLabel: ["username"],
            dataFilter: ["name", "username", "login"],
            disabled: true,
          }}
          mode="multiple"
          loading={loadingUsers}
          options={users}
        />
      </Form.Item>

      <Form.Item<SendEmailType> name="emails" label="Emails">
        <Select mode="tags" open={false} suffixIcon={<></>} tokenSeparators={[" ", ",", "\n"]} />
      </Form.Item>

      <Form.Item<SendEmailType> name="message" label="Mensagem">
        <TextArea rows={5} />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingSendEmail} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button loading={loadingSendEmail} type="primary" htmlType="submit">
              Enviar
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}
export default SendEmail;
