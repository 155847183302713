import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Badge, Button, Pagination, Popover, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect } from "react";
import { defaultState, useReportsStore } from "./store";
import { ItemListReportsType } from "./types";
import { SearchReportForm } from "./components/SearchReportForm";
import { ViewReportForm } from "./components/ViewReportForm";
import { CreateReportTabs } from "./components/create/CreateReportTabs";
import { GenerateReportForm } from "./components/GenerateReportForm";
import { UpdateReportTabs } from "./components/update/UpdateReportTabs";
import { diff } from "deep-diff";
import { ctNotification } from "@components/shared/CTNotification";

export enum ContextType {
  SYS = "Sistema",
  CPN = "Empresa",
  CTM = "Cliente",
  CGR = "Grupo de Cliente",
}

const ReportView: React.FC = () => {
  const { openDrawer } = useContext(D3DrawerContext);
  const {
    apiResult,
    pagination,
    loading,
    loadReports,
    setPagination,
    resetState,
    setSort,
    deleteReport,
    filter,
    openSearch,
    closeSearch,
  } = useReportsStore();
  useEffect(() => {
    (async () => {
      await loadReports();
    })();
    return () => {
      resetState();
    };
  }, []);

  return (
    <div className="flex flex-col h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<ItemListReportsType>
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Relatórios"
                    content={<SearchReportForm />}
                    trigger="click"
                    onOpenChange={(open) => closeSearch(open)}
                  >
                    <Badge dot={diff(defaultState.filter, filter) ? true : false}>
                      <Button
                        onClick={() => closeSearch(!openSearch)}
                        type="primary"
                        size="middle"
                        icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                      >
                        Pesquisar
                      </Button>
                    </Badge>
                  </Popover>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-xl font-semibold text-gray-600">Relatórios</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="all" resource="manage">
                    <Button
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => openDrawer(<CreateReportTabs />, "right", 600, "Cadastrar Relatório")}
                    >
                      Cadastrar Relatório
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
        onChange={(a, b, sorter: any) => {
          if (sorter) {
            setSort({
              sort_by: sorter.field,
              sort_direction: sorter.order,
            });
          }
        }}
        footer={() => {
          return (
            <>
              <div className="flex gap-4"></div>
              <Space>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                  }
                  showSizeChanger={true}
                  current={pagination.page}
                  onChange={setPagination}
                  pageSize={pagination.pageSize}
                  total={apiResult.total}
                />
              </Space>
            </>
          );
        }}
      >
        <Table.Column<ItemListReportsType>
          defaultSortOrder="ascend"
          showSorterTooltip={false}
          key="title"
          title="Nome"
          dataIndex="title"
          sorter
        />
        <Table.Column<ItemListReportsType>
          showSorterTooltip={false}
          key="description"
          title="Descrição"
          dataIndex="description"
          sorter
        />
        <Table.Column<ItemListReportsType>
          showSorterTooltip={false}
          key="context"
          title="Perfil"
          dataIndex="context"
          render={(value) => ContextType[value as keyof typeof ContextType]}
          sorter
        />
        <Table.Column<ItemListReportsType>
          showSorterTooltip={false}
          key="customer"
          title="Cliente"
          dataIndex="customer"
        />

        <Table.Column<ItemListReportsType>
          showSorterTooltip={false}
          key="customer_group"
          title="Grupo de cliente"
          dataIndex="customer_group"
        />
        <Table.Column<ItemListReportsType>
          key="action"
          title="Ações"
          width={170}
          render={(value, record, index) => {
            return (
              <>
                <Space>
                  <D3Can action="all" resource="manage" type="hidden">
                    <Tooltip title="Editar" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<UpdateReportTabs reportCuid={record.cuid} />, "right", 800, "Editar Relatório")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="all" resource="manage" type="hidden">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(<ViewReportForm reportCuid={record.cuid} />, "right", 700, "Visualizar Relatório")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="view" resource="report" type="hidden">
                    <Tooltip title="Gerar relatório" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="uil:file-download-alt"
                            height={20}
                            width={20}
                            className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                          />
                        }
                        size={"middle"}
                        onClick={async () =>
                          openDrawer(<GenerateReportForm reportCuid={record.cuid} />, "right", 700, "Gerar Relatório")
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="all" resource="manage" type="hidden">
                    <Tooltip title="Excluir" trigger={["click", "hover"]}>
                      <Button
                        className="peer"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="material-symbols:delete"
                            height={18}
                            width={18}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        onClick={() =>
                          ctNotification({
                            type: "warning",
                            title: "Atenção !",
                            content: "Confirma exclusão do relatório?",
                            withoutFooter: false,
                            doNotClose: true,
                            okText: "Sim",
                            cancelText: "Não",
                            onOk: async () => {
                              await deleteReport(record.cuid);
                            },
                          })
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default ReportView;
