import { Button, Form, Input, Space } from "antd";

import { CancelOrderType } from "../types";
import D3Can from "@components/shared/D3Can";
import { useContext } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useMaterialOrdersStore } from "../store";

type CancelMaterialOrderFormProps = {
  orderCuid: string;
};

const { TextArea } = Input;

function CancelMaterialOrderForm({ orderCuid }: CancelMaterialOrderFormProps) {
  const [form] = Form.useForm<CancelOrderType>();
  const { cancelMaterialOrder, loadingCancel } = useMaterialOrdersStore();
  const { onClose } = useContext(D3DrawerContext);

  return (
    <Form
      form={form}
      layout="vertical"
      className="select-none"
      onFinish={async (payload: CancelOrderType) => {
        const isCancel = await cancelMaterialOrder(orderCuid, payload);
        if (isCancel) {
          onClose();
        }
      }}
    >
      <Form.Item<CancelOrderType>
        label="Informe o motivo do cancelamento"
        name="cancel_reason"
        rules={[{ required: true, message: "Por favor, preencha o motivo do cancelamento." }]}
      >
        <TextArea rows={6} />
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingCancel} type="default" danger onClick={() => onClose()}>
              Fechar
            </Button>
            <D3Can action="cancel" resource="order_material">
              <Button loading={loadingCancel} danger type="primary" htmlType="submit">
                Cancelar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default CancelMaterialOrderForm;
