import axios from "axios";
import useAuthStore from "@provider/AuthProvider";
import i18n from "i18next"; // Importando o i18n diretamente

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 30000, // Ajuste conforme necessário
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const { isAuthenticated, token } = useAuthStore.getState();
    const language = i18n.language; // Obtendo a linguagem diretamente do i18n

    if (token !== undefined && isAuthenticated === true) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    // Adicionando a linguagem no cabeçalho dinamicamente
    config.headers["Accept-Language"] = language === "pt" ? "pt-BR" : "en-US";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /**
     * Envia o usuário para a tela de login caso o token esteja expirado
     *  401 - InvalidToken
     *  401 - Unauthorized
     */
    if (error.response && error.response.status === 401) {
      useAuthStore.getState().logout();
    }
    return Promise.reject(error);
  }
);

export default apiClient;
