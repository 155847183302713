import { create } from "zustand";
import { Checkbox } from "antd";
import {
  CellView,
  CellsConfigurator,
  ItemViewEventsPositionType,
  LockOrUnlockPayloadType,
  SelectRepositoryTypeItemType,
  Selected,
  ShelfView,
  SimpleListRepositoryTypeType,
  ViewCellType,
  ViewEventsPositionType,
} from "./types";
import SelectColumn from "./components/SelectColumn";
import SelectRow from "./components/SelectRow";
import Cell from "./components/Cell";
import { ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { ctNotification } from "@components/shared/CTNotification";

type ShelfStore = {
  storageCuid: string;
  shelfCuid: string;
  shelfView: ShelfView;
  CellsView: CellView[];
  columns: any[];
  dataSource: any[];
  loading: boolean;
  cellMap: Map<string, CellView>;
  selected: Selected;
  init: (storageCuid: string, shelfCuid: string) => Promise<void>;
  getTotalSelected: () => Selected;
  processColumns: () => any[];
  processDataSource: () => any[];
  clickCell: (item: CellView) => void;
  clickRow: (row: number, newValue: boolean) => void;
  clickColumn: (column: number, newValue: boolean) => void;
  clickAll: (newValue: boolean) => void;
  lock: () => void;
  unlock: () => void;
  getRepositoryTypeForSelect: () => Promise<SelectRepositoryTypeItemType[]>;
  ApplyConfigurationCell: (payload: CellsConfigurator) => Promise<void>;
  getCell: (shelfCuid: string, cellCuid: string) => Promise<Success<ViewCellType> | StandardError>;
  eventsPosition: (positionId: string) => Promise<void>;
  events: ItemViewEventsPositionType[];
  loadingEvents: boolean;
};

const defaultState = {
  storageCuid: "",
  shelfCuid: "",
  shelfView: {} as ShelfView,
  CellsView: [],
  columns: [],
  dataSource: [],
  loading: false,
  cellMap: new Map(),
  selected: {
    total: [],
    totalActive: [],
    totalInactive: [],
  } as Selected,
  events: [],
  loadingEvents: false,
};

const cellStore = create<ShelfStore>((set, get) => ({
  ...defaultState,
  init: async (storageCuid: string, shelfCuid: string) => {
    set({
      ...defaultState,
      storageCuid: storageCuid,
      shelfCuid: shelfCuid,
      loading: true,
    });

    const shelfViewResult = await new ClientHttp().get<Success<ShelfView>, StandardError>(
      `/api/v1/company/storages/${storageCuid}/shelves/${shelfCuid}`
    );

    if (shelfViewResult.status === "success") {
      const cellsViewResult = await new ClientHttp().get<Success<{ data: CellView[] }>, StandardError>(
        `/api/v1/company/storages/${storageCuid}/shelves/${shelfCuid}/cells`
      );
      if (cellsViewResult.status === "success") {
        const cellsView = cellsViewResult.body as { data: CellView[] };
        const cellMap: Map<string, CellView> = new Map();

        cellsView.data.forEach((cell) => {
          cellMap.set(`V${cell.column}.P${cell.row}`, cell);
        });

        set({
          shelfView: shelfViewResult.body as ShelfView,
          CellsView: cellsView.data as CellView[],
          cellMap: cellMap,
        });

        const columns = get().processColumns();
        const dataSource = get().processDataSource();

        set({
          columns: columns,
          dataSource: dataSource,
          loading: false,
        });
      } else {
        showMessage(cellsViewResult);
      }
    } else {
      showMessage(shelfViewResult);
    }
  },
  getTotalSelected: () => {
    const cellMap = get().cellMap;
    let totalSelected: string[] = [];
    let totalActiveSelected: string[] = [];
    let totalInactiveSelected: string[] = [];
    for (let row = get().shelfView.number_of_rows; row > 0; row--) {
      for (let column = 1; column < get().shelfView.number_of_columns + 1; column++) {
        const info = cellMap.get(`V${column}.P${row}`);
        if (info && info.checked) {
          if (info.active) {
            totalActiveSelected.push(info.cuid);
          } else {
            totalInactiveSelected.push(info.cuid);
          }
          totalSelected.push(info.cuid);
        }
      }
    }
    return {
      total: totalSelected,
      totalActive: totalActiveSelected,
      totalInactive: totalInactiveSelected,
    } as Selected;
  },
  processColumns: () => {
    return Array.from({ length: get().shelfView.number_of_columns + 1 }, (_, i) => i).map((item) => {
      if (item === 0) {
        return {
          title: (
            <div className="p-2 border-2">
              <Checkbox key={`column-${item}`} onChange={(e) => get().clickAll(e.target.checked)} />
            </div>
          ),
          dataIndex: `all`,
          key: `all`,
          className: "font-semibold hover:text-blue-500 border-1",
          align: "center" as const,
          fixed: "left",
        };
      }
      return {
        title: <SelectColumn key={`column-${item}`} column={item} click={get().clickColumn} />,
        dataIndex: `V${item}`,
        key: `V${item}`,
        className: "font-semibold",
        align: "center" as const,
      };
    });
  },
  processDataSource: () => {
    const cellMap = get().cellMap;
    const dataSource: any[] = [];
    for (let row = get().shelfView.number_of_rows; row > 0; row--) {
      const temp: any = { rowId: `row-${row}` }; // Adiciona uma chave única para a linha
      for (let column = 0; column < get().shelfView.number_of_columns + 1; column++) {
        if (column === 0) {
          temp["all"] = <SelectRow key={`row-${row}-column-${column}`} row={row} click={get().clickRow} />;
        } else {
          const info = cellMap.get(`V${column}.P${row}`);
          if (info) {
            temp[`V${column}`] = (
              <Cell
                key={`row-${row}-column-${column}`}
                row={row}
                column={column}
                info={info}
                clickFnc={get().clickCell}
              />
            );
          } else {
            temp[`V${column}`] = "Erro";
          }
        }
      }
      dataSource.push(temp);
    }
    return dataSource;
  },
  clickCell: (item: CellView) => {
    set({ loading: true });
    const cell = get().cellMap.get(`V${item.column}.P${item.row}`);
    if (cell) {
      cell.checked = !cell.checked;
      set({
        loading: false,
        dataSource: get().processDataSource(),
        selected: get().getTotalSelected(),
      });
    } else {
      set({ loading: false });
    }
  },
  clickRow: (row: number, newValue: boolean) => {
    set({ loading: true });
    const map = get().cellMap;
    for (var column = 1; column < get().shelfView.number_of_columns + 1; column++) {
      const cell = map.get(`V${column}.P${row}`);
      if (cell) {
        cell.checked = newValue;
      }
    }
    set({
      loading: false,
      dataSource: get().processDataSource(),
      selected: get().getTotalSelected(),
    });
  },
  clickColumn: (column: number, newValue: boolean) => {
    set({ loading: true });
    for (var row = 1; row < get().shelfView.number_of_rows + 1; row++) {
      const cell = get().cellMap.get(`V${column}.P${row}`);
      if (cell) {
        cell.checked = newValue;
      }
    }
    set({
      loading: false,
      dataSource: get().processDataSource(),
      selected: get().getTotalSelected(),
    });
  },
  clickAll: async (newValue: boolean) => {
    set({ loading: true });
    const map = get().cellMap;
    for (var row = 1; row < get().shelfView.number_of_rows + 1; row++) {
      for (var column = 1; column < get().shelfView.number_of_columns + 1; column++) {
        const cell = map.get(`V${column}.P${row}`);
        if (cell) {
          cell.checked = newValue;
        }
      }
    }
    set({
      loading: false,
      dataSource: get().processDataSource(),
      selected: get().getTotalSelected(),
    });
  },
  lock: async () => {
    const totalActiveSelected = get().selected.totalActive;
    if (totalActiveSelected.length > 0) {
      const result = await new ClientHttp().patch<
        LockOrUnlockPayloadType,
        Success<{ rows_affected: number }>,
        StandardError
      >(
        `/api/v1/company/storages/${get().storageCuid}/shelves/${get().shelfCuid}/cells/lock`,
        { cuids: totalActiveSelected },
        (result: Success<{ rows_affected: number }>) => {
          showMessage(
            result,
            get().selected.total.length === 1 ? "Item bloqueado com sucesso." : "Itens bloqueados com sucesso."
          );
        },
        (error: StandardError) => {
          showMessage(error);
        }
      );
      if (result.status === "success") {
        get().init(get().storageCuid, get().shelfCuid);
      }
    } else {
      ctNotification({ title: "Atenção !", content: "Nenhum item selecionado para bloqueio.", type: "error" });
    }
  },
  unlock: async () => {
    const totalInactiveSelected = get().selected.totalInactive;
    if (totalInactiveSelected.length > 0) {
      const result = await new ClientHttp().patch<
        LockOrUnlockPayloadType,
        Success<{ rows_affected: number }>,
        StandardError
      >(
        `/api/v1/company/storages/${get().storageCuid}/shelves/${get().shelfCuid}/cells/unlock`,
        { cuids: totalInactiveSelected },
        (result: Success<{ rows_affected: number }>) => {
          showMessage(
            result,
            get().selected.total.length === 1 ? "Item desbloqueado com sucesso." : "Itens debloqueados com sucesso."
          );
        },
        (error: StandardError) => {
          showMessage(error);
        }
      );
      if (result.status === "success") {
        get().init(get().storageCuid, get().shelfCuid);
      }
    } else {
      ctNotification({ title: "Atenção !", content: "Nenhum item selecionado para desbloqueio.", type: "error" });
    }
  },
  getRepositoryTypeForSelect: async (): Promise<SelectRepositoryTypeItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListRepositoryTypeType>,
      StandardError,
      SelectRepositoryTypeItemType[]
    >(
      "/api/v1/system/repository-types/simple-list",
      (result: Success<SimpleListRepositoryTypeType>): SelectRepositoryTypeItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.description,
            active: item.active,
          } as SelectRepositoryTypeItemType;
        });
      },
      (error: StandardError): SelectRepositoryTypeItemType[] => {
        showMessage(error);
        return [] as SelectRepositoryTypeItemType[];
      }
    );
  },
  ApplyConfigurationCell: async (payload: CellsConfigurator) => {
    let cellsCuids = get().selected.total;
    if (cellsCuids.length > 0) {
      payload = {
        ...payload,
        cuids: cellsCuids,
      };
      await new ClientHttp().put<CellsConfigurator, Success<{ rows_affected: number }>, StandardError>(
        `/api/v1/company/storages/${get().storageCuid}/shelves/${get().shelfCuid}/cells`,
        payload,
        (result: Success<{ rows_affected: number }>) => {
          showMessage(
            result,
            get().selected.total.length === 1
              ? "Vão/Prateleira atualizado com sucesso."
              : "Vãos/Prateleiras atualizados com sucesso."
          );
          get().init(get().storageCuid, get().shelfCuid);
        },
        (error: StandardError) => {
          showMessage(error);
        }
      );
    } else {
      ctNotification({
        title: "Atenção !",
        content: "Nenhum item selecionado para realizar a configuração.",
        type: "error",
      });
    }
  },

  getCell: async (cellCuid: string, shelfCuid: string): Promise<Success<ViewCellType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCellType>, StandardError>(
      `/api/v1/company/storages/${get().storageCuid}/shelves/${shelfCuid}/cells/${cellCuid}`
    );
  },

  eventsPosition: async (positionId: string): Promise<void> => {
    set({
      loadingEvents: true,
    });
    await new ClientHttp().get<Success<ViewEventsPositionType>, StandardError>(
      `/api/v1/system/positions/${positionId}/events`,
      (result: Success<ViewEventsPositionType>) => {
        set({
          events: result.body.data,
          loadingEvents: false,
        });
      },
      (error: StandardError) => {
        showMessage(error);
        set({
          loadingEvents: false,
        });
      }
    );
  },
}));

export default cellStore;
