import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Col, Flex, Pagination, Popover, Row, Table, Tooltip } from "antd";
import { Key, useContext, useEffect } from "react";
import { useServiceOrdersStore } from "../store";
import { DocumentOrderType } from "../types";
import { SearchDocumentsForm } from "./SearchDocumentsForm";
import D3Can from "@components/shared/D3Can";

type DocumentsServiceOrderFormProps = {
  orderCuid: string;
};

function DocumentsServiceOrderForm({ orderCuid }: DocumentsServiceOrderFormProps) {
  const {
    documentsOrder,
    documents,
    loadingDocuments,
    deletedDocuments,
    loadingDeleted,
    selectedRows,
    paginationDocuments,
    setPaginationDocuments,
    openSearchDocuments,
  } = useServiceOrdersStore();

  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await documentsOrder(orderCuid);
    })();
    return () =>
      useServiceOrdersStore.setState({
        documents: {
          items: [],
          total: 0,
        },
        selectedRows: [],
        paginationDocuments: {
          page: 1,
          pageSize: 10,
        },
      });
  }, [orderCuid]);

  const columns = [
    {
      title: "Etiqueta do documento",
      dataIndex: "document_physical_tag_id",
      key: "document_physical_tag_id",
    },
    {
      title: "Etiqueta do repositório",
      dataIndex: "repository_physical_tag_id",
      key: "repository_physical_tag_id",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      align: "center" as const,
      render: (_: any, record: DocumentOrderType) => (
        <div className="flex items-center justify-center w-full">
          <D3Can rules={["order_service.create", "order_service.cancel", "order_service.serve"]}>
            <Tooltip title="Excluir" trigger={["click", "hover"]}>
              <Button
                type="text"
                shape="default"
                icon={
                  <Icon
                    icon="material-symbols:delete"
                    height={18}
                    width={18}
                    className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                  />
                }
                onClick={async () => {
                  const isDeleted = await deletedDocuments(orderCuid, {
                    document_physical_tag_ids: [record.document_physical_tag_id],
                  });
                  if (isDeleted) {
                    onClose();
                  }
                }}
              />
            </Tooltip>
          </D3Can>
        </div>
      ),
    },
  ];
  return (
    <div className="flex flex-col w-full h-full gap-3 p-4 overflow-hidden">
      <div className="flex flex-col w-full h-full overflow-hidden ">
        <Table<DocumentOrderType>
          title={() => {
            return (
              <>
                <Row className="w-full">
                  <Col span={8} className="flex items-center justify-start">
                    <Popover
                      open={openSearchDocuments}
                      placement="bottomLeft"
                      title="Pesquisar Documentos"
                      content={<SearchDocumentsForm orderCuid={orderCuid} />}
                      trigger="click"
                      onOpenChange={(open) =>
                        useServiceOrdersStore.setState({
                          openSearchDocuments: open,
                        })
                      }
                    >
                      <Button
                        onClick={() =>
                          useServiceOrdersStore.setState({
                            openSearchDocuments: !openSearchDocuments,
                          })
                        }
                        type="primary"
                        size="middle"
                        className="flex items-center"
                        icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                      >
                        Pesquisar
                      </Button>
                    </Popover>
                  </Col>
                  <Col span={8} className="text-center">
                    <span className="text-xl font-semibold text-gray-600">Documentos</span>
                  </Col>
                  <Col span={8} className="flex items-center justify-end"></Col>
                </Row>
              </>
            );
          }}
          rowSelection={{
            hideSelectAll: false,
            type: "checkbox",
            selectedRowKeys: selectedRows,
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys: Key[]) => {
              useServiceOrdersStore.setState({
                selectedRows: selectedRowKeys as string[],
              });
            },
          }}
          loading={loadingDocuments || loadingDeleted}
          columns={columns}
          dataSource={documents.items}
          rowKey="document_physical_tag_id"
          className="border-r responsive-table"
          bordered
          size="small"
          pagination={false}
          footer={() => {
            return (
              <Flex className="w-full" align="center" justify="space-between">
                <D3Can rules={["order_service.create", "order_service.cancel", "order_service.serve"]}>
                  <Button
                    danger
                    type="dashed"
                    onClick={async () => {
                      const isDeleted = await deletedDocuments(orderCuid, { document_physical_tag_ids: selectedRows });
                      if (isDeleted) {
                        onClose();
                      }
                    }}
                  >
                    {`Excluir ${
                      selectedRows.length > 0
                        ? selectedRows.length === 1
                          ? "1 documento"
                          : `${selectedRows.length} documentos`
                        : ""
                    }`}
                  </Button>
                </D3Can>
                <Pagination
                  showLessItems
                  showTotal={(total, range) =>
                    `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                  }
                  showSizeChanger={true}
                  current={paginationDocuments.page}
                  onChange={(page: number, pageSize: number) => setPaginationDocuments(page, pageSize, orderCuid)}
                  pageSize={paginationDocuments.pageSize}
                  total={documents.total}
                />
              </Flex>
            );
          }}
        />
      </div>
      <div className="flex items-center justify-end w-full min-h-10">
        <Button type="default" danger onClick={() => onClose()}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}

export default DocumentsServiceOrderForm;
