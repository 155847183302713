import { Button, Form, Input, Segmented, Space } from "antd";
import { useEffect } from "react";

import { useDocumentTypesStore } from "../../store";
import { SearchDocumentTypeType } from "../../types";

type SearchDocumentTypeFormProps = {
  onCallBackFilter: () => void;
  onCallBackResetFilter: () => void;
};

export const SearchDocumentTypeForm = ({ onCallBackFilter, onCallBackResetFilter }: SearchDocumentTypeFormProps) => {
  const [form] = Form.useForm<SearchDocumentTypeType>();
  const { filter, setFilter } = useDocumentTypesStore();

  useEffect(() => {
    form.setFieldsValue(filter);
  }, [form, filter]);

  return (
    <>
      <Form
        className="select-none"
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          if (form.isFieldsTouched(true)) {
            setFilter(values);
          }
          onCallBackFilter();
        }}
        autoComplete="off"
      >
        <Form.Item<SearchDocumentTypeType> name="dt_description" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item<SearchDocumentTypeType> name="dt_active" label="Status">
          <Segmented
            value={filter.dt_active}
            options={[
              { value: "off", label: "Bloqueados" },
              { value: "on", label: "Desbloqueados" },
              { value: "all", label: "Todos" },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={onCallBackResetFilter}>
                Limpar Filtro
              </Button>
              <Button type="primary" htmlType="submit">
                Filtrar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
