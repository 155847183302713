import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Tabs, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import { usePhysicalDocumentsStore } from "../store";
import { SearchPhysicalDocumentType } from "../types";
import SearchDocumentForm from "./SearchDocumentForm";
import SearchOrdersForm from "./SearchOrdersForm ";
import SearchRepositoryForm from "./SearchRepositoryForm";
import SearchSortByForm from "./SearchSortByForm ";
import SearchCriterionForm from "./SearchCriterionForm";
import { Icon } from "@iconify/react";

type SearchOperationFormProps = {
  onCallBackResetFilter: () => void;
};

export const SearchPhysicalDocumentForm = ({ onCallBackResetFilter }: SearchOperationFormProps) => {
  const [form] = Form.useForm<SearchPhysicalDocumentType>();
  const { filter, setFilter, changedFilter, resetPartFilter, setChangedFilter } = usePhysicalDocumentsStore();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (() => {
      form.setFieldsValue(filter);
    })();
  }, [form, filter]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden ">
      <Form
        className="flex h-full overflow-hidden grow "
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          setFilter(values);
          onClose();
        }}
        autoComplete="off"
        onValuesChange={(changedValues: any) => {
          const key = Object.keys(changedValues)[0] as keyof SearchPhysicalDocumentType;
          setChangedFilter(key, true);
        }}
      >
        <Tabs
          className="w-full select-none"
          items={[
            {
              key: "1",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Critérios
                    {changedFilter.filter_document_type ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_document_type" as keyof SearchPhysicalDocumentType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchCriterionForm form={form} />,
            },
            {
              key: "2",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Documento
                    {changedFilter.filter_document ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_document" as keyof SearchPhysicalDocumentType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchDocumentForm form={form} />,
            },
            {
              key: "3",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Repositório
                    {changedFilter.filter_repository ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_repository" as keyof SearchPhysicalDocumentType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchRepositoryForm form={form} />,
            },
            {
              key: "4",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Ordens
                    {changedFilter.filter_order ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("filter_order" as keyof SearchPhysicalDocumentType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchOrdersForm form={form} />,
            },
            {
              key: "5",
              label: (
                <div className="items-center">
                  <div className="flex items-center justify-start ">
                    Ordenação
                    {changedFilter.config ? (
                      <Tooltip title="Limpar filtro" trigger={["click", "hover"]}>
                        <Button
                          className=" max-h-[22px]"
                          danger
                          type="link"
                          icon={<Icon icon="mdi:filter-off" />}
                          onClick={() => resetPartFilter("config" as keyof SearchPhysicalDocumentType)}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ),
              children: <SearchSortByForm form={form} />,
            },
          ]}
        />
      </Form>
      <div className="flex gap-3 border-t-[1px] border-gray-100  items-center justify-end px-2 min-h-12 ">
        <Button
          type="default"
          danger
          onClick={() => {
            onCallBackResetFilter();
            usePhysicalDocumentsStore.setState((state) => ({
              changedFilter: {
                config: false,
                filter_document: false,
                filter_document_type: false,
                filter_order: false,
                filter_repository: false,
                filter_rule: false,
              },
            }));
            onClose();
          }}
        >
          Limpar Filtro
        </Button>
        <Button type="primary" onClick={() => form.submit()}>
          Filtrar
        </Button>
      </div>
    </div>
  );
};
