import { Icon } from "@iconify/react";
import { Badge, Button, Form, Input, List, Segmented, Select } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";
import { useEffect, useState } from "react";
import { SearchType } from "./types";
import useSearchStore from "./store";

type FormItemSwitcherProps = {
  attribute: NamePath | NamePath[];
  label: string;
  form: FormInstance<SearchType>;
};
const { TextArea } = Input;

function FormItemSwitcher({ attribute, label, form }: FormItemSwitcherProps): JSX.Element {
  const { filter } = useSearchStore();
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [physicalTags, setPhysicalTags] = useState<string[]>([]);
  const [textArea, setTextArea] = useState<string[]>([]);
  const [input, setInput] = useState<string>("");

  const removeItem = (index: any) => {
    const newArray = [...physicalTags];
    newArray.splice(index, 1);
    setPhysicalTags(newArray);
  };

  const getFilterAttributesValues = (obj: SearchType, path: NamePath) => {
    return (Array.isArray(path) ? path : [path]).reduce((acc, key) => acc && acc[key], obj);
  };

  useEffect(() => {
    setPhysicalTags(getFilterAttributesValues(filter, attribute));
  }, []);

  useEffect(() => {
    const setNestedFieldValue = (path: any, value: any) => {
      return path.reduceRight((acc: any, key: any) => ({ [key]: acc }), value);
    };
    form.setFieldsValue(setNestedFieldValue(attribute, physicalTags));
  }, [physicalTags, form]);

  const validate = (input: string | string[]) => {
    const validateString = (str: string) => {
      const isValidLength = str.length === 12;
      const isNumeric = /^\d+$/.test(str);
      const isNotInDocumentsCuid = !physicalTags.includes(str);
      return isValidLength && isNumeric && isNotInDocumentsCuid ? str : "";
    };

    if (Array.isArray(input)) {
      setPhysicalTags([...input.map(validateString).filter(Boolean), ...physicalTags]);
    } else {
      const result = validateString(input);
      if (result) {
        setPhysicalTags([result as string, ...physicalTags]);
      } else {
        setPhysicalTags(physicalTags);
      }
    }
  };

  return (
    <Form.Item label={label} className="w-full p-0 my-2">
      <div className="flex flex-col gap-3">
        <Form.Item hidden name={attribute} normalize={(value) => physicalTags}>
          <Select
            mode="multiple"
            options={physicalTags.map((item) => {
              return { label: item, value: item };
            })}
            suffixIcon={<></>}
          />
        </Form.Item>
        <Badge.Ribbon className="select-none" text={physicalTags.length}>
          <List
            size="small"
            bordered
            className="overflow-y-auto select-none min-h-44 max-h-44 h-44 custom-scroll"
            dataSource={physicalTags}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                actions={[
                  <Button
                    size="small"
                    icon={<Icon height={18} icon="material-symbols:delete" />}
                    type="text"
                    danger
                    onClick={() => removeItem(index)}
                  />,
                ]}
              >
                {item}
              </List.Item>
            )}
          />
        </Badge.Ribbon>
        <Segmented
          className="select-none"
          defaultValue="single"
          onChange={(e) => setIsMultiple(e === "single" ? false : true)}
          options={[
            { value: "single", label: "Único" },
            { value: "multiple", label: "Múltiplos" },
          ]}
        />

        {!isMultiple ? (
          <Input
            showCount
            maxLength={12}
            minLength={12}
            value={input}
            onChange={(e) => {
              setInput(e.target.value.trim());
            }}
          />
        ) : (
          <TextArea
            rows={3}
            value={textArea.join("\n")}
            onChange={(e) => {
              const newValues = e.target.value.split("\n");
              const values = newValues.filter((item) => !physicalTags.includes(item));
              setTextArea(values);
            }}
          />
        )}
        <div className="flex gap-3">
          <Button
            onClick={() => {
              if (isMultiple) {
                validate(textArea);
                setTextArea([]);
              } else {
                validate(input);
                setInput("");
              }
            }}
          >
            Adicionar
          </Button>
          <Button danger type="dashed" onClick={() => setPhysicalTags([])}>
            Limpar lista
          </Button>
        </div>
      </div>
    </Form.Item>
  );
}

export default FormItemSwitcher;
