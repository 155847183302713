import { Form } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd/lib";
import { GlobalIndexValueType, ItemGlobalIndexersType } from "../types";
import RenderIndexer from "./components/Items";
import { useEffect } from "react";
import useSearchStore, { defaultState } from "../store";

type IndexersFilterProps = {
  form: FormInstance<any>;
  attribute: NamePath | NamePath[];
};

function RenderGlobalIndexers({ form, attribute }: IndexersFilterProps) {
  const { filter, listGlobalIndexers } = useSearchStore();
  useEffect(() => {
    if (listGlobalIndexers.length > 0) {
      const setIndexers = listGlobalIndexers.map((item: ItemGlobalIndexersType) => {
        const indexer =
          filter.filter_global_index.global_indexes_values.length > 0
            ? filter.filter_global_index.global_indexes_values.find(
                (i) => i.label === item.label && i.key === item.system_index_type.key
              )
            : undefined;

        if (indexer) {
          return indexer;
        } else {
          return {
            end_value: "",
            key: item.system_index_type.key,
            label: item.label,
            search_options: item.system_index_type.search_options,
            search_option: item.system_index_type.default_search_option,
            start_value: "",
            value: "",
            values: [] as string[],
          };
        }
      });
      form.setFieldsValue({
        filter_global_index: {
          global_indexes_values: setIndexers,
        },
      });
      defaultState.filter.filter_global_index.global_indexes_values = setIndexers.map((index) => {
        return {
          ...index,
          end_value: "",
          value: "",
          values: [],
        };
      });
    }
  }, []);

  return (
    <Form.List name={attribute}>
      {(fields) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const index: GlobalIndexValueType = form.getFieldValue(attribute)[key];
              return index ? (
                <Form.Item key={index.key + index.label} label={index.label} className="w-full p-0 my-2">
                  <RenderIndexer {...restField} name={name} item={index} />
                </Form.Item>
              ) : null;
            })}
          </>
        );
      }}
    </Form.List>
  );
}

export default RenderGlobalIndexers;
