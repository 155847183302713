import { Icon } from "@iconify/react";
import useAuthStore from "@provider/AuthProvider";
import { Avatar, Image } from "antd";

type PreviewProfilePictureProps = {
  preview?: boolean;
  size?: number;
};

function PreviewProfilePicture({ preview = false, size = 32 }: PreviewProfilePictureProps) {
  const { getInitialName, getUserInfo } = useAuthStore();
  const profilePicture = getUserInfo().ProfilePicture;
  return profilePicture ? (
    <Image
      preview={
        preview
          ? {
              mask: <Icon height={16} icon="lets-icons:view" />,
              maskClassName: "rounded-full",
            }
          : false
      }
      alt="profile picture"
      height={size}
      className="rounded-full shadow ring-2 ring-green-300"
      src={profilePicture}
    />
  ) : (
    <Avatar size="large" className="text-blue-700 bg-blue-400 ">
      <span className="flex items-center justify-center h-full">{getInitialName() ?? "?"}</span>
    </Avatar>
  );
}

export default PreviewProfilePicture;
