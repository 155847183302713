import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Row, Segmented, Select, Space, Spin } from "antd";
import { useCustomerStore } from "../../store";
import { ViewAddressType } from "../../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { listUfs } from "@utils/listUfs";
import Search from "antd/es/input/Search";
import { PatternFormat } from "react-number-format";
import { UpdateAddressForm } from "./UpdateAddressForm";
import { D3Date } from "@utils/date";
import D3Can from "@components/shared/D3Can";

type UpdateAddressProps = {
  cuid: string;
};

export const ViewAddressForm = ({ cuid }: UpdateAddressProps) => {
  const { getAddress } = useCustomerStore();
  const { onClose, openDrawer } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ViewAddressType>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getAddress(cuid);
      if (result.status === "success") {
        const viewAddress = result.body as ViewAddressType;
        form.setFieldsValue({
          ...viewAddress,
          created_at: D3Date.FormatValueApiToPtBR(viewAddress.created_at),
          updated_at: D3Date.FormatValueApiToPtBR(viewAddress.updated_at),
        });
        setLoading(false);
      } else {
        showMessage(result);
      }
    })();
  }, [cuid, form, getAddress]);

  return (
    <>
      <Spin spinning={loading}>
        <Form className="select-none" form={form} layout="vertical" autoComplete="off">
          <Row className="flex items-center justify-between">
            <Form.Item<ViewAddressType> className="w-[190px]" name="zip_code" label="CEP">
              <PatternFormat format="#####-###" enterButton disabled customInput={Search} />
            </Form.Item>
            <Form.Item<ViewAddressType> name="is_default" label="Endereço padrão">
              <Segmented
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                disabled
              />
            </Form.Item>
            <Form.Item<ViewAddressType> name="is_storage_address" label="Endereço de Galpão?">
              <Segmented
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                disabled
              />
            </Form.Item>
          </Row>
          <Form.Item<ViewAddressType> name="description" label="Descrição">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewAddressType> name="street_address" label="Logradouro">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewAddressType> name="neighborhood" label="Bairro">
            <Input disabled />
          </Form.Item>
          <Row className="flex justify-between">
            <Form.Item<ViewAddressType> name="street_number" label="Número" className="w-28">
              <Input disabled />
            </Form.Item>
            <Form.Item<ViewAddressType> name="complement" label="Complemento" className="w-[425px]">
              <Input disabled />
            </Form.Item>
          </Row>
          <Row className="flex justify-between">
            <Form.Item<ViewAddressType> className="w-[370px]" name="city" label="Cidade">
              <Input disabled />
            </Form.Item>
            <Form.Item<ViewAddressType> name="state" label="Estado">
              <Select
                disabled
                showSearch
                allowClear
                listItemHeight={10}
                listHeight={150}
                style={{ width: 170 }}
                options={listUfs.map(({ nome, sigla }) => ({
                  label: nome,
                  value: sigla,
                }))}
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLowerCase()) ||
                  (option?.value.toLowerCase() ?? "").includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Row>
          <Form.Item<ViewAddressType> name="created_at" label="Criado em">
            <Input disabled />
          </Form.Item>
          <Form.Item<ViewAddressType> name="updated_at" label="Atualizado em">
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="customer_address">
                  <Button
                    type="primary"
                    onClick={() => openDrawer(<UpdateAddressForm cuid={cuid} />, "right", 600, "Editar Material")}
                  >
                    Editar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
