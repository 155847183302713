import { Form, Table } from "antd";
import { ReportType } from "../../types";

type TabUsersProps = {
  reports: ReportType[];
};

function TabReports({ reports }: TabUsersProps) {
  return (
    <>
      <Form.Item className="m-3" layout="vertical" label="Relatórios">
        <Table rowKey={(record) => record.cuid} size="small" pagination={false} dataSource={reports} bordered>
          <Table.Column<ReportType>
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="title"
            title="Nome"
            dataIndex="title"
          />
          <Table.Column<ReportType>
            showSorterTooltip={false}
            defaultSortOrder="ascend"
            key="description"
            title="Descrição"
            dataIndex="description"
          />
        </Table>
      </Form.Item>
    </>
  );
}

export default TabReports;
