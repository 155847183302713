import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  AddressItemType,
  CancelMaterialOrderType,
  CancelOrderType,
  CreateMaterialOrderType,
  ItemSimpleListCustomerType,
  ItemSimpleListDepartmentType,
  ListMaterialOrderType,
  MaterialItemType,
  PrintResendType,
  ReadyOrderType,
  ResendType,
  ResponseType,
  SearchMaterialOrderType,
  SelectAddressItemType,
  SimpleListAddressType,
  SimpleListCustomerType,
  SimpleListDepartmentType,
  SimpleListUserDepartmentType,
  UrlType,
  UserDepartmentItemType,
  ViewMaterialOrderType,
} from "./types";

type MaterialOrderStoreState = {
  apiResult: ListMaterialOrderType;
  filter: SearchMaterialOrderType;
  pagination: { page: number; pageSize: number };
  loading: boolean;
  setFilter: (filter: SearchMaterialOrderType) => void;
  setPagination: (page: number, pageSize: number) => void;
  loadMaterialOrders: () => Promise<void>;
  getMaterialOrder: (cuid: string) => Promise<Success<ViewMaterialOrderType> | StandardError>;
  getUrl: (orderCuid: string) => Promise<Success<UrlType> | StandardError>;
  createMaterialOrder: (
    MaterialOrder: CreateMaterialOrderType
  ) => Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError>;

  cancelMaterialOrder: (orderCuid: string, payload: CancelOrderType) => Promise<boolean>;
  loadingCancel: boolean;

  getDepartmentsForSelect: () => Promise<ItemSimpleListDepartmentType[]>;
  getUsersDepartmentForSelect: (departmentCuid: string) => Promise<UserDepartmentItemType[]>;
  getAddressForSelect: () => Promise<AddressItemType[]>;
  getMaterialsForSelect: () => Promise<MaterialItemType[]>;

  savedMaterialOrder: (orderCuid: string) => Promise<void>;

  simpleListDepartmentsByCustomerCuidCustomerGroup: (customerCuid: string) => Promise<ItemSimpleListDepartmentType[]>;

  urlResendOrder: string;
  resendMaterialOrderByCuid: (orderCuid: string) => Promise<void>;

  printResendOrderMaterialByCuid: (orderCuid: string) => Promise<void>;
  urlPrintResendOrder: string;

  resetFilter: () => void;
  resetState: () => void;

  simpleListCustomer: () => Promise<void>;
  loadingCustomer: boolean;
  listCustomer: ItemSimpleListCustomerType[];

  simpleListDepartmentByCustomerCuid: (customerCuid: string) => Promise<void>;
  loadingDepartments: boolean;
  listDepartments: ItemSimpleListDepartmentType[];

  readyOrder: (orderCuid: string, payload: ReadyOrderType) => Promise<boolean>;
  loadingReady: boolean;

  simpleListAddress: () => Promise<void>;
  addresses: SelectAddressItemType[];
  loadingAddress: boolean;
};

const operationsFilter: FilterDefinition = {
  mo_id: { op: "eq", type: "string" },
  dep_cuid: { op: "eq", type: "string" },
  uc_name: { op: "lk", type: "string" },
  ur_name: { op: "lk", type: "string" },
  mo_status: { op: "eq", type: "array" },
  mo_request_type: { op: "eq", type: "string" },
  c_cuid: { op: "eq", type: "string" },
  mo_has_order_resent: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  filter: {
    mo_id: "",
    dep_cuid: "",
    uc_name: "",
    ur_name: "",
    c_cuid: "",
    mo_status: ["WTG", "RDY"],
    mo_request_type: "",
    mo_has_order_resent: "all",
  } as SearchMaterialOrderType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  loading: false,
  urlResendOrder: "",
  urlPrintResendOrder: "",

  loadingCustomer: false,
  listCustomer: [],

  loadingDepartments: false,
  listDepartments: [],

  loadingReady: false,

  addresses: [],
  loadingAddress: false,

  loadingCancel: false,
};

export const useMaterialOrdersStore = create<MaterialOrderStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),

  setFilter: (filter: SearchMaterialOrderType) => {
    set({
      filter,
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadMaterialOrders();
  },
  resetFilter: () => {
    set({
      filter: { ...defaultState.filter, mo_status: [] },
    });
    get().loadMaterialOrders();
  },

  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().loadMaterialOrders();
  },

  loadMaterialOrders: async (): Promise<void> => {
    set({ loading: true });
    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination);
    await new ClientHttp().get<Success<ListMaterialOrderType>, BadRequest | StandardError>(
      `/api/v1/orders/materials?${qs}`,
      (result: Success<ListMaterialOrderType>) => {
        set({ apiResult: result.body, loading: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  getMaterialOrder: async (cuid: string): Promise<Success<ViewMaterialOrderType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewMaterialOrderType>, StandardError>(
      `/api/v1/orders/materials/${cuid}`
    );
  },

  createMaterialOrder: async (
    materialOrder: CreateMaterialOrderType
  ): Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateMaterialOrderType,
      Success<{ data: { cuid: string } }>,
      BadRequest | StandardError
    >("/api/v1/orders/materials", materialOrder, (result: Success<{ data: { cuid: string } }>) => {
      get().loadMaterialOrders();
    });
  },

  savedMaterialOrder: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().patch<null, Success<ResponseType>, BadRequest | StandardError>(
      `/api/v1/orders/materials/${orderCuid}/completion`,
      null,
      (result: Success<ResponseType>) => {
        showMessage(result, "Ordem baixada com sucesso.");
        get().loadMaterialOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  cancelMaterialOrder: async (orderCuid: string, payload: CancelOrderType): Promise<boolean> => {
    set({ loadingCancel: true });
    const result = await new ClientHttp().patch<
      CancelMaterialOrderType,
      Success<ResponseType>,
      BadRequest | StandardError
    >(
      `/api/v1/orders/materials/${orderCuid}/cancel`,
      payload,
      (result: Success<ResponseType>) => {
        showMessage(result, "Ordem cancelada com sucesso.");
        set({ loadingCancel: false });
        get().loadMaterialOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingCancel: false });
      }
    );
    return result.status === "success";
  },

  getDepartmentsForSelect: async (): Promise<ItemSimpleListDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemSimpleListDepartmentType[]
    >(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): ItemSimpleListDepartmentType[] => {
        return result.body.data as ItemSimpleListDepartmentType[];
      },
      (error: StandardError): ItemSimpleListDepartmentType[] => {
        showMessage(error);
        return [] as ItemSimpleListDepartmentType[];
      }
    );
  },

  getUsersDepartmentForSelect: async (departmentCuid: string): Promise<UserDepartmentItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListUserDepartmentType>,
      StandardError,
      UserDepartmentItemType[]
    >(
      `/api/v1/customer/departments/${departmentCuid}/users/simple-list`,
      (result: Success<SimpleListUserDepartmentType>): UserDepartmentItemType[] => {
        return result.body.data as UserDepartmentItemType[];
      },
      (error: StandardError): UserDepartmentItemType[] => {
        showMessage(error);
        return [] as UserDepartmentItemType[];
      }
    );
  },

  getAddressForSelect: async (): Promise<AddressItemType[]> => {
    return await new ClientHttp().getItensForSelect<Success<SimpleListAddressType>, StandardError, AddressItemType[]>(
      `/api/v1/customer/addresses/simple-list`,
      (result: Success<SimpleListAddressType>): AddressItemType[] => {
        return result.body.data as AddressItemType[];
      },
      (error: StandardError): AddressItemType[] => {
        showMessage(error);
        return [] as AddressItemType[];
      }
    );
  },

  getMaterialsForSelect: async (): Promise<MaterialItemType[]> => {
    return await new ClientHttp().getItensForSelect<Success<SimpleListAddressType>, StandardError, MaterialItemType[]>(
      `/api/v1/customer/materials/simple-list`,
      (result: Success<SimpleListAddressType>): MaterialItemType[] => {
        return result.body.data as MaterialItemType[];
      },
      (error: StandardError): MaterialItemType[] => {
        showMessage(error);
        return [] as MaterialItemType[];
      }
    );
  },
  getUrl: async (orderCuid: string): Promise<Success<UrlType> | StandardError> => {
    return await new ClientHttp().get<Success<UrlType>, StandardError>(
      `/api/v1/orders/materials/${orderCuid}/print`,
      (result: Success<UrlType>): UrlType => {
        return result.body;
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
  },

  simpleListCustomersCustomerGroup: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/customer-group/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomer: false });
  },
  simpleListDepartmentsByCustomerCuidCustomerGroup: async (
    customerCuid: string
  ): Promise<ItemSimpleListDepartmentType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      ItemSimpleListDepartmentType[]
    >(
      `/api/v1/customer-group/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): ItemSimpleListDepartmentType[] => {
        return result.body.data as ItemSimpleListDepartmentType[];
      },
      (error: StandardError): ItemSimpleListDepartmentType[] => {
        showMessage(error);
        return [] as ItemSimpleListDepartmentType[];
      }
    );
  },

  resendMaterialOrderByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().post<undefined, Success<ResendType>, BadRequest | StandardError>(
      `/api/v1/orders/materials/${orderCuid}/resend`,
      undefined,
      (result: Success<ResendType>): void => {
        set({
          urlResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },
  printResendOrderMaterialByCuid: async (orderCuid: string): Promise<void> => {
    await new ClientHttp().get<Success<PrintResendType>, StandardError>(
      `/api/v1/orders/materials/${orderCuid}/print-resend`,
      (result: Success<PrintResendType>): void => {
        set({
          urlPrintResendOrder: result.body.url,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
  },

  simpleListCustomer: async (): Promise<void> => {
    set({ loadingCustomer: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListCustomerType>, StandardError, void>(
      "/api/v1/system/customers/simple-list",
      (result: Success<SimpleListCustomerType>): void => {
        set({ listCustomer: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingCustomer: false });
  },
  simpleListDepartmentByCustomerCuid: async (customerCuid: string): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      `/api/v1/system/customers/${customerCuid}/departments/simple-list`,
      (result: Success<SimpleListDepartmentType>): void => {
        set({ listDepartments: result.body.data });
      },
      (error: StandardError): void => {
        showMessage(error);
      }
    );
    set({ loadingDepartments: false });
  },
  readyOrder: async (orderCuid: string, payload: ReadyOrderType): Promise<boolean> => {
    set({ loadingReady: true });
    const result = await new ClientHttp().patch<ReadyOrderType, Success<void>, BadRequest | StandardError>(
      `/api/v1/orders/materials/${orderCuid}/ready`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Preparação concluída com sucesso.");
        set({ loadingReady: false });
        get().loadMaterialOrders();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingReady: false });
      }
    );
    return result.status === "success";
  },

  simpleListAddress: async (): Promise<void> => {
    set({ loadingAddress: true });
    await new ClientHttp().get<Success<SimpleListAddressType>, BadRequest | StandardError>(
      "/api/v1/system/addresses/simple-list",
      (result: Success<SimpleListAddressType>): void => {
        set({
          addresses: result.body.data.map(
            ({
              cuid,
              description,
              active,
              street_address,
              street_number,
              city,
              complement,
              zip_code,
              state,
              is_default,
            }) => {
              return {
                value: cuid,
                label: description,
                active: active,
                is_default: is_default,
                address: `${street_address}, nº ${street_number}${
                  complement ? ", " + complement : ""
                }, ${city} - ${state}, Cep ${zip_code}`,
              } as SelectAddressItemType;
            }
          ),
          loadingAddress: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingAddress: false });
      }
    );
  },
}));
