import { Form, FormInstance, Select } from "antd";
import { useEffect } from "react";
import { usePhysicalDocumentsStore } from "../store";
import { SearchPhysicalDocumentType } from "../types";

type SearchSortByFormFormProps = {
  form: FormInstance<SearchPhysicalDocumentType>;
};

function SearchSortByForm({ form }: SearchSortByFormFormProps) {
  const { filter } = usePhysicalDocumentsStore();
  useEffect(() => {
    (async () => {
      form.setFieldsValue({
        config: filter.config,
      });
    })();
  }, [form]);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      <Form.Item label="Ordenar por" name={["config", "sort", "by"]} className="p-0 my-2 w-60">
        <Select
          options={[
            {
              label: "Data de criação do documento",
              value: "fs_document_id",
            },
            {
              label: "Posição do galpão",
              value: "position_id",
            },
            {
              label: "Etiqueta de documento",
              value: "document_physical_tag_id",
            },
            {
              label: " Etiqueta de repositório",
              value: "repository_physical_tag_id",
            },
          ]}
        />
      </Form.Item>
      <Form.Item label="Direção" name={["config", "sort", "direction"]} className="p-0 my-2 w-60">
        <Select
          options={[
            {
              label: "Ascendente",
              value: "asc",
            },
            {
              label: "Descendente",
              value: "desc",
            },
          ]}
        />
      </Form.Item>
    </div>
  );
}

export default SearchSortByForm;
