import { Icon } from "@iconify/react";
import {
  Button,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import { useEffect } from "react";
import { CurrencyInput } from "react-currency-mask";
import "../style.css";
import { useCustomerOperationStore } from "./store";
import { ListItemType } from "./types";

const { Option } = Select;

function ListOperations() {
  const {
    savePriceSettings,
    alteredOperations,
    loadItemsList,
    apiResult,
    loading,
    loadingSave,
    editApiResult,
    resetState,
    deletePriceSetup,
    clearPriceSetup,
  } = useCustomerOperationStore();

  useEffect(() => {
    (async () => {
      await loadItemsList();
    })();
    return () => {
      resetState();
    };
  }, [loadItemsList]);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Preço padrão",
      dataIndex: "price_default",
      key: "price_default",
      width: 120,
      render: (_: any, record: ListItemType) => (
        <span>
          {record.price_default.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
    {
      title: "Aplicar preço padrão?",
      dataIndex: "apply_default_price",
      key: "apply_default_price",
      align: "center" as const,
      width: 200,
      render: (_: any, record: ListItemType) => (
        <Checkbox
          checked={record.apply_default_price}
          onChange={(e) => {
            editApiResult([
              {
                operation_cuid: record.operation_cuid,
                field: "apply_default_price",
                value: e.target.checked,
              },
            ]);
            if (e.target.checked) {
              editApiResult([
                {
                  operation_cuid: record.operation_cuid,
                  field: "price",
                  value: null,
                },
              ]);
            }
          }}
        />
      ),
    },
    {
      title: "Preço definido",
      dataIndex: "price",
      key: "price",
      width: 150,
      render: (_: any, record: ListItemType) => (
        <Form.Item
          className="p-0 m-0"
          validateStatus={
            record.apply_default_price === false && (record.price === null || record.price === undefined) ? "error" : ""
          }
        >
          <CurrencyInput
            locale="pt-BR"
            currency="BRL"
            value={record.price == null ? "" : record.price === 0 ? "0,00" : record.price.toFixed(2).replace(".", ",")}
            onChangeValue={(_, value) => {
              editApiResult([
                {
                  operation_cuid: record.operation_cuid,
                  field: "price",
                  value: parseFloat(value.toString().replace(",", ".")),
                },
              ]);
            }}
            InputElement={<Input disabled={record.apply_default_price} className="w-4/5" />}
          />
        </Form.Item>
      ),
    },
    {
      title: "Regras de desconto",
      children: [
        {
          title: "Aplicação",
          dataIndex: "rule",
          key: "rule",
          width: 210,
          render: (_: any, record: ListItemType) => (
            <Select
              allowClear
              onClear={() => clearPriceSetup(record.operation_cuid)}
              disabled={record.rule_discount.cuid ? true : false}
              className="w-full"
              value={record.rule_discount.rule}
              onChange={(value) =>
                editApiResult([
                  {
                    operation_cuid: record.operation_cuid,
                    field: "rule",
                    value: value === undefined ? null : value,
                  },
                ])
              }
            >
              <Option value="Q_IT">Qtd. de itens</Option>
              <Option value="Q_IN">Qtd. de faturas</Option>
              <Option value="Q_IT_PER_IN">Qtd. de itens por fatura</Option>
            </Select>
          ),
        },
        {
          title: "% Desconto",
          dataIndex: "discount_percent",
          key: "discount_percent",
          width: 110,
          render: (_: any, record: ListItemType) =>
            record.rule_discount.rule && (
              <Form.Item
                className="p-0 m-0"
                validateStatus={
                  record.rule_discount.rule && record.rule_discount.discount_percent === null ? "error" : ""
                }
              >
                <InputNumber
                  min={1}
                  max={100}
                  disabled={record.rule_discount.cuid ? true : false}
                  value={record.rule_discount.discount_percent}
                  onChange={(value) =>
                    editApiResult([
                      {
                        operation_cuid: record.operation_cuid,
                        field: "discount_percent",
                        value: value,
                      },
                    ])
                  }
                />
              </Form.Item>
            ),
        },
        {
          title: "Quantidade de item",
          dataIndex: "number_of_items_limit",
          key: "number_of_items_limit",
          width: 150,
          render: (_: any, record: ListItemType) =>
            (record.rule_discount.rule === "Q_IT" || record.rule_discount.rule === "Q_IT_PER_IN") && (
              <Form.Item
                className="p-0 m-0"
                validateStatus={
                  record.rule_discount.rule && record.rule_discount.number_of_items_limit === null ? "error" : ""
                }
              >
                <InputNumber
                  min={1}
                  disabled={record.rule_discount.cuid ? true : false}
                  value={record.rule_discount.number_of_items_limit}
                  onChange={(value) =>
                    editApiResult([
                      {
                        operation_cuid: record.operation_cuid,
                        field: "number_of_items_limit",
                        value: value,
                      },
                    ])
                  }
                />
              </Form.Item>
            ),
        },
        {
          title: "Quantidade de fatura",
          dataIndex: "number_of_invoices_limit",
          key: "number_of_invoices_limit",
          width: 170,
          render: (_: any, record: ListItemType) =>
            (record.rule_discount.rule === "Q_IN" || record.rule_discount.rule === "Q_IT_PER_IN") && (
              <Form.Item
                className="p-0 m-0"
                validateStatus={
                  record.rule_discount.rule && record.rule_discount.number_of_invoices_limit === null ? "error" : ""
                }
              >
                <InputNumber
                  min={1}
                  disabled={record.rule_discount.cuid ? true : false}
                  value={record.rule_discount.number_of_invoices_limit}
                  onChange={(value) =>
                    editApiResult([
                      {
                        operation_cuid: record.operation_cuid,
                        field: "number_of_invoices_limit",
                        value: value,
                      },
                    ])
                  }
                />
              </Form.Item>
            ),
        },
        {
          title: "Ações",
          key: "actions",
          align: "center" as const,
          width: 70,
          render: (_: any, record: ListItemType) => (
            <div className="flex items-center justify-center">
              <Tooltip
                trigger={["click", "hover"]}
                title={record.rule_discount.cuid ? "" : "Operação não possui regra de desconto."}
              >
                <Popconfirm
                  disabled={record.rule_discount.cuid ? false : true}
                  title="Atenção!"
                  description="Confirmar remoção da regra de desconto?"
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={() => deletePriceSetup(record.operation_cuid, record.rule_discount.cuid)}
                >
                  <Button
                    disabled={record.rule_discount.cuid ? false : true}
                    type="text"
                    icon={
                      <Icon
                        width={20}
                        className="text-red-400 transition-all duration-200 ease-in hover:text-red-500"
                        icon="ic:round-money-off-csred"
                      />
                    }
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col w-full h-full pb-1 overflow-hidden select-none new-table">
      <Table
        rowClassName={(record) => (alteredOperations.includes(record.operation_cuid) ? "change-row" : "")}
        size="small"
        dataSource={apiResult.data}
        columns={columns}
        pagination={false}
        bordered
        loading={loading}
        sticky
        rowKey={(record) => record.operation_cuid}
        title={() => (
          <>
            <Row className="w-full">
              <Col span={8} className="flex items-center justify-start">
                <span className="text-sm font-semibold text-gray-600">
                  Configurações de operações que podem ser solicitados pelo cliente.
                </span>
              </Col>
              <Col span={8} className="text-center">
                <span className="text-xl font-semibold text-gray-600">Preço de Operações</span>
              </Col>
              <Col span={8} className="flex items-center justify-end"></Col>
            </Row>
          </>
        )}
        footer={() => (
          <>
            <Row className="w-full">
              <Col span={8} className="flex items-center justify-start gap-4">
                <div className="flex items-center justify-center gap-2">
                  <ColorPicker value="#dbeafe" size="small" disabled /> Operação alterada
                </div>
              </Col>
              <Col span={8} className="text-center"></Col>
              <Col span={8} className="flex items-center justify-end">
                <Button
                  loading={loadingSave}
                  disabled={alteredOperations.length === 0}
                  type="primary"
                  size="middle"
                  icon={<Icon icon="ant-design:save-filled" height={20} width={20} />}
                  onClick={() => savePriceSettings()}
                >
                  Salvar {alteredOperations.length > 1 ? "alterações" : "alteração"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      />
    </div>
  );
}

export default ListOperations;
