import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import {
  DepartmentType,
  IndexItemType,
  ItemDocumentTypeType,
  ListIndexesType,
  SimpleListDepartmentCustomerType,
  SimpleListDocumentTypeType,
  UpdateIndexersType,
  UpdateStructuredDocumentType,
  ViewStructuredDocumentType,
} from "./types";
import useNavigateStore from "../navigate/store";

type NavigateStoreType = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  getActiveTab: () => string;
  viewStructuredDocument: (fs_document_cuid: string) => Promise<void>;
  view: ViewStructuredDocumentType;
  loadingView: boolean;

  updateStructuredDocument: (documentCuid: string, payload: UpdateStructuredDocumentType) => Promise<boolean>;
  loadingUpdate: boolean;

  simpleListDepartmentsCustomer: () => Promise<void>;
  listDepartmentsCustomer: DepartmentType[];
  loadingDepartments: boolean;

  simpleListDocumentTypesCustomer: () => Promise<ItemDocumentTypeType[]>;
  listIndexersDocumentTypesByCuid: (documentTypeCuid: string) => Promise<IndexItemType[]>;
  updateIndexers: (documentCuid: string, payload: UpdateIndexersType) => Promise<boolean>;
};

const keepState = {
  activeTab: "1",
};

const useStructuredDocumentStore = create<NavigateStoreType>((set, get) => ({
  ...keepState,
  view: {} as ViewStructuredDocumentType,
  loadingView: false,
  listDepartmentsCustomer: [],
  loadingDepartments: false,
  loadingUpdate: false,
  setActiveTab: (tab: string) => {
    set({ activeTab: tab });
  },
  getActiveTab: () => {
    return get().activeTab;
  },
  viewStructuredDocument: async (fs_document_cuid: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewStructuredDocumentType>, StandardError>(
      `/api/v1/customer/fs/document/${fs_document_cuid}`,
      (result: Success<ViewStructuredDocumentType>) => {
        set({
          view: result.body,
          loadingView: false,
        });
      },
      (error: StandardError) => {
        showMessage(error);
        set({ loadingView: false });
      }
    );
  },
  updateStructuredDocument: async (documentCuid: string, payload: UpdateStructuredDocumentType): Promise<boolean> => {
    set({ loadingUpdate: true });
    const result = await new ClientHttp().put<UpdateStructuredDocumentType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/fs/document/${documentCuid}`,
      payload,
      (result: Success<void>): void => {
        const content = useNavigateStore.getState().content;
        let childrens = content.children;
        for (var i = 0; i < childrens.length; i++) {
          if (childrens[i].cuid === documentCuid && childrens[i].name !== payload.description) {
            childrens[i].name = payload.description;
            useNavigateStore.setState({
              content: {
                ...content,
                children: childrens,
              },
            });
            break;
          }
        }
        set({ loadingUpdate: false });
        showMessage(result, "Arquivo estruturado atualizado com sucesso.");
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingUpdate: false });
      }
    );
    return result.status === "success";
  },

  simpleListDepartmentsCustomer: async (): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentCustomerType>, StandardError, void>(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentCustomerType>): void => {
        set({
          listDepartmentsCustomer: result.body.data,
          loadingDepartments: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({ loadingDepartments: false });
      }
    );
  },
  simpleListDocumentTypesCustomer: async (): Promise<ItemDocumentTypeType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDocumentTypeType>,
      StandardError,
      ItemDocumentTypeType[]
    >(
      "/api/v1/customer/document-types/simple-list",
      (result: Success<SimpleListDocumentTypeType>): ItemDocumentTypeType[] => {
        return result.body.data as ItemDocumentTypeType[];
      },
      (error: StandardError): ItemDocumentTypeType[] => {
        showMessage(error);
        return [] as ItemDocumentTypeType[];
      }
    );
  },
  listIndexersDocumentTypesByCuid: async (documentTypeCuid: string): Promise<IndexItemType[]> => {
    return await new ClientHttp().getItensForSelect<Success<ListIndexesType>, StandardError, IndexItemType[]>(
      `/api/v1/customer/document-types/${documentTypeCuid}/document-type-indexes`,
      (result: Success<ListIndexesType>): IndexItemType[] => {
        return result.body.data as IndexItemType[];
      },
      (error: StandardError): IndexItemType[] => {
        showMessage(error);
        return [] as IndexItemType[];
      }
    );
  },
  updateIndexers: async (documentCuid: string, payload: UpdateIndexersType): Promise<boolean> => {
    const result = await new ClientHttp().put<
      UpdateIndexersType,
      Success<{ status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/fs/document/${documentCuid}/indexes-values`,
      payload,
      (result: Success<{}>): void => {
        showMessage(result);
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
      }
    );
    return result.status === "success";
  },
}));

export default useStructuredDocumentStore;
