import { create } from "zustand";
import { UpdatePasswordSystemUserType, UpdateSystemUserType, ViewProfileType } from "./types";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";

type CompanyStoreState = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  getActiveTab: () => string;

  getProfile: () => Promise<void>;
  loadingProfile: boolean;
  profile: ViewProfileType;

  setIsEditing: () => void;
  isEditing: boolean;

  updateProfile: (payload: UpdateSystemUserType) => Promise<boolean>;
  loadingUpdate: boolean;

  updatePasswordSystemUser: (
    payload: UpdatePasswordSystemUserType
  ) => Promise<Success<void> | BadRequest | StandardError>;
};

export const useCompaniesStore = create<CompanyStoreState>((set, get) => ({
  activeTab: "1",
  isEditing: false,
  loadingProfile: false,
  profile: {} as ViewProfileType,
  loadingUpdate: false,

  setIsEditing: () => {
    set((state) => ({ isEditing: !state.isEditing }));
  },

  setActiveTab: (tab: string) => {
    set({ activeTab: tab });
  },
  getActiveTab: () => {
    return get().activeTab;
  },
  getProfile: async (): Promise<void> => {
    set({ loadingProfile: true });
    await new ClientHttp().get<Success<ViewProfileType>, StandardError>(
      `/api/v1/system/user`,
      (result: Success<ViewProfileType>) => {
        set({
          profile: result.body,
          loadingProfile: false,
        });
      },
      (error: StandardError) => {
        showMessage(error);
        set({ loadingProfile: false });
      }
    );
  },

  updateProfile: async (payload: UpdateSystemUserType): Promise<boolean> => {
    set({ loadingProfile: true });
    const result = await new ClientHttp().put<UpdateSystemUserType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/user`,
      payload,
      (result: Success<void>) => {
        showMessage(result, "Perfil atualizado com sucesso.");
        get().getProfile();
        set({ loadingProfile: false });
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingProfile: false });
      }
    );
    return result.status === "success";
  },

  updatePasswordSystemUser: async (
    payload: UpdatePasswordSystemUserType
  ): Promise<Success<void> | BadRequest | StandardError> => {
    return await new ClientHttp().patch<UpdatePasswordSystemUserType, Success<void>, BadRequest | StandardError>(
      `/api/v1/system/user`,
      payload,
      (result: Success<void>) => {
        get().getProfile();
      }
    );
  },
}));
