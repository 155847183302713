import { Button, Table, Tooltip } from "antd";
import { useDocumentBatchStore } from "../store";
import { PayloadType } from "../types";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import { useContext } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { ViewStructuredDocument } from "@views/customer/spaces/work/components/structured-document/view/ViewStructuredDocument";

function CollapseSuccess() {
  const { success } = useDocumentBatchStore();
  const { openDrawer } = useContext(D3DrawerContext);

  const columns = [
    {
      title: "Etiqueta do documento",
      dataIndex: "document_physical_tag_id",
      key: "document_physical_tag_id",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Permanente?",
      dataIndex: "is_permanent_storage",
      key: "is_permanent_storage",
      render: (value: any, record: PayloadType) => <span>{record.is_permanent_storage ? "Sim" : "Não"}</span>,
    },
    {
      title: "Data original",
      dataIndex: "original_date",
      key: "original_date",
      render: (value: any, record: PayloadType) => (
        <span>{record.original_date ? dayjs(record.original_date).format("DD/MM/YYYY") : ""}</span>
      ),
    },
    {
      title: "Data previsão expurgo",
      dataIndex: "expected_purge_date",
      key: "expected_purge_date",
      render: (value: any, record: PayloadType) => (
        <span>{record.expected_purge_date ? dayjs(record.expected_purge_date).format("DD/MM/YYYY") : ""}</span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "cuid",
      key: "cuid",
      align: "center" as const,
      width: 80,
      render: (
        value: any,
        record: PayloadType & {
          cuid: string;
        }
      ) => (
        <div className="flex items-center justify-center w-full">
          <Tooltip title="Visualizar" trigger={["click", "hover"]}>
            <Button
              className="peer"
              type="text"
              shape="default"
              icon={
                <Icon
                  icon="carbon:document-view"
                  height={18}
                  width={18}
                  className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                />
              }
              size={"middle"}
              onClick={() =>
                openDrawer(
                  <ViewStructuredDocument fsDocumentCuid={record.cuid} />,
                  "right",
                  600,
                  "Visualizar documento"
                )
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full p-2 bg-white ">
      <Table
        rowKey="cuid"
        pagination={false}
        size="small"
        bordered
        dataSource={
          success as (PayloadType & {
            cuid: string;
          })[]
        }
        columns={columns}
      />
    </div>
  );
}

export default CollapseSuccess;
