import { Form, InputNumber } from "antd";

type ItemIFTProps = {
  name: number;
  label: string;
  required: boolean;
};

function ItemIFT({ name, label, required, ...props }: ItemIFTProps) {
  return (
    <Form.Item
      {...props}
      name={[name, "value"]}
      getValueProps={(value: number) => {
        return { value: value !== undefined ? value.toString() : "" };
      }}
      normalize={(value: string) => {
        const numericValue = Number(value);
        return !isNaN(numericValue) ? numericValue.toString() : value;
      }}
      label={label}
      rules={[
        {
          required: required,
          message: "Obrigatório",
        },
      ]}
    >
      <InputNumber type="number" className="w-32" />
    </Form.Item>
  );
}

export default ItemIFT;
