import { useUploadContext } from "@context/CTUpload/UploadContext";
import { Icon } from "@iconify/react";
import formatBytes from "@utils/formatBytes";
import { extension } from "@utils/mine";
import { Button, Progress, Tooltip, UploadFile } from "antd";
import VirtualList from "rc-virtual-list";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { UseUploadManagedStore } from "./store";
import ViewFile from "@views/customer/spaces/work/components/file/view-file/ViewFile";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import EditFile from "@views/customer/spaces/work/components/file/edit-file/EditFile";

interface CustomUploadFile extends UploadFile {
  duplicate?: boolean;
  progress?: number;
  fs_cuid?: string;
  children?: any;
}

function ListView() {
  const { fileList, cancelUpload, deleteFile } = useUploadContext();
  const { extensions } = UseUploadManagedStore();
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const { openDrawer } = useContext(D3DrawerContext);

  useEffect(() => {
    const divElement = divRef.current;
    if (!divElement) return;
    setHeight(divElement.offsetHeight);
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          setHeight(entry.contentRect.height);
        }
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(divElement);
    return () => {
      resizeObserver.unobserve(divElement);
    };
  }, [divRef, fileList]);

  const renderItem = useMemo(
    () => (file: CustomUploadFile) => {
      const ext = extension(file.type ?? "não reconhecido");
      return (
        <div className="relative flex flex-col w-full select-none">
          <Progress
            size={{
              height: 3,
            }}
            className="absolute bottom-0 z-0 p-0 max-h-[16px] m-0"
            showInfo={false}
            percent={file.progress || 0}
            strokeLinecap="butt"
            status="active"
            strokeColor={{ from: "#4ade80", to: "#4ade80" }}
          />
          <div className="z-50 grid grid-cols-12 border-b h-11">
            <div className="flex items-center justify-center col-span-1 ">
              <Icon
                className="drop-shadow-md"
                height={24}
                icon={extensions.find((ext) => ext.key === extension(file.type ?? ""))?.icon ?? ""}
              />
            </div>
            <div className="flex items-center justify-start col-span-4 px-2 ">
              <span className="font-medium truncate max-w-[300px] ">{file.name.replace(String(ext), "")} </span>
            </div>
            <div className="flex items-center justify-center col-span-2">
              <span className="uppercase">{ext?.replace(".", "")}</span>
            </div>
            <div className="flex items-center justify-center col-span-2">
              <span className="uppercase"> {formatBytes(file.size ?? 0)}</span>
            </div>
            <div className="flex items-center justify-center col-span-1">
              <span>{file.progress || 0} %</span>
            </div>
            <div className="flex items-center justify-center col-span-2 gap-2">
              <Tooltip title="Visualizar arquivo" trigger={["click", "hover"]}>
                <Button
                  type="text"
                  onClick={() => {
                    openDrawer(<ViewFile fileCuid={file?.fs_cuid || ""} />, "right", 800, "Visualizar arquivo");
                  }}
                  icon={
                    <Icon
                      height={18}
                      className="text-blue-400 transition-all duration-200 ease-in hover:text-blue-500 hover:scale-105 "
                      icon="lets-icons:view"
                    />
                  }
                />
              </Tooltip>
              <Tooltip title="Editar arquivo" trigger={["click", "hover"]}>
                <Button
                  type="text"
                  onClick={() => {
                    openDrawer(<EditFile fileCuid={file.fs_cuid || ""} />, "right", 600, "Editar arquivo");
                  }}
                  icon={
                    <Icon
                      height={18}
                      className="text-blue-400 transition-all duration-200 ease-in hover:text-blue-500 hover:scale-105 "
                      icon="iconamoon:edit-fill"
                    />
                  }
                />
              </Tooltip>
              <Tooltip
                title={
                  file.progress
                    ? file.progress > 85 && file.progress < 99
                      ? "Impossível cancelar, processo maior que 85%."
                      : file.progress === 100
                      ? "Impossível cancelar, upload concluído."
                      : "Cancelar upload"
                    : "Cancelar upload"
                }
                trigger={["click", "hover"]}
              >
                <Button
                  disabled={file.progress ? (file.progress < 80 ? false : true) : false}
                  type="text"
                  onClick={() => deleteFile(file.uid)}
                  icon={
                    <Icon
                      height={18}
                      className="text-red-400 transition-all duration-200 ease-in hover:text-red-500 hover:scale-105 "
                      icon="material-symbols:delete"
                    />
                  }
                />
              </Tooltip>
            </div>
          </div>
        </div>
      );
    },
    [fileList]
  );
  return (
    <div className="flex flex-col w-full h-full overflow-hidden select-none">
      <div className="flex flex-col w-full h-full border rounded">
        <div className="grid h-12 grid-cols-12 font-semibold text-gray-600 border-b divide-x shadow-b">
          <div className="flex items-center justify-center col-span-1 ">Icon</div>
          <div className="flex items-center justify-start col-span-4 px-2 ">Nome</div>
          <div className="flex items-center justify-center col-span-2">
            <span>Tipo</span>
          </div>
          <div className="flex items-center justify-center col-span-2">
            <span>Tamanho</span>
          </div>
          <div className="flex items-center justify-center col-span-1">
            <span>%</span>
          </div>
          <div className="flex items-center justify-center col-span-2 gap-2">Ações</div>
        </div>
        <div ref={divRef} className="w-full h-full overflow-hidden">
          <VirtualList data={fileList} height={height} itemHeight={47} itemKey="uid">
            {(item: CustomUploadFile) => renderItem(item)}
          </VirtualList>
        </div>
        <div className="grid flex-none w-full h-10 grid-cols-2 px-2 border-t shadow-t max-h-10">
          <div className="flex items-center justify-start"></div>
          <div className="flex items-center justify-end "></div>
        </div>
      </div>
    </div>
  );
}

export default ListView;
