import { Icon } from "@iconify/react";
import { Button, Spin, Tabs } from "antd";
import { useEffect } from "react";
import CreateData from "./components/TabData";
import CreateIndex from "./components/TabIndexers";
import useStructuredDocumentStore from "../store";

type ViewDocumentTypeFormProps = {
  fsDocumentCuid: string;
};

export const ViewStructuredDocument = ({ fsDocumentCuid }: ViewDocumentTypeFormProps) => {
  const { viewStructuredDocument, view, loadingView, getActiveTab, setActiveTab } = useStructuredDocumentStore();

  useEffect(() => {
    (async () => {
      await viewStructuredDocument(fsDocumentCuid);
    })();
    return () => setActiveTab("1");
  }, [fsDocumentCuid]);

  return (
    <>
      <Spin spinning={loadingView}>
        <Tabs
          tabPosition="top"
          defaultActiveKey="1"
          activeKey={getActiveTab()}
          onTabClick={(tab) => {
            setActiveTab(tab);
          }}
          className="flex h-full overflow-hidden select-none"
          items={[
            {
              key: "1",
              label: (
                <Button className="flex items-center" type="link" icon={<Icon icon="ri:profile-line" />} size="small">
                  Dados
                </Button>
              ),
              children: <CreateData data={view} />,
            },
            {
              key: "2",
              label: (
                <Button
                  className="flex items-center"
                  type="link"
                  icon={<Icon icon="dashicons:index-card" />}
                  size="small"
                >
                  Indexadores
                </Button>
              ),
              children: <CreateIndex indexers={view?.indexes_values} />,
            },
          ]}
        />
      </Spin>
    </>
  );
};
