import { Button, Form, Input, Space } from "antd";
import { DeletionReasonType } from "../types";
import D3Can from "@components/shared/D3Can";
import { useContext } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { useRepositoriesStore } from "../store";

type DeletedRepositoryFormProps = {
  repositoryCuid: string;
};

const { TextArea } = Input;

function DeletedRepositoryForm({ repositoryCuid }: DeletedRepositoryFormProps) {
  const [form] = Form.useForm<DeletionReasonType>();
  const { deletionRepository, loadingDeletion } = useRepositoriesStore();
  const { onClose } = useContext(D3DrawerContext);

  return (
    <Form
      form={form}
      layout="vertical"
      className="select-none"
      onFinish={async (payload: DeletionReasonType) => {
        const isCancel = await deletionRepository(repositoryCuid, payload);
        if (isCancel) {
          onClose();
        }
      }}
    >
      <Form.Item<DeletionReasonType> label="Informe o motivo da exclusão" name="deletion_reason">
        <TextArea rows={6} />
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingDeletion} type="default" danger onClick={() => onClose()}>
              Fechar
            </Button>
            <D3Can action="cancel" resource="order_deployment">
              <Button loading={loadingDeletion} danger type="primary" htmlType="submit">
                Excluir
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default DeletedRepositoryForm;
