import { Form } from "antd";
import { viewIndexer } from "./ListInputs";
import { IndexesValueType } from "../../types";

type ViewIndexPropsType = {
  indexers: IndexesValueType[];
};

function ViewIndexers({ indexers }: ViewIndexPropsType) {
  return (
    <div className="flex select-none">
      <Form layout="vertical" disabled className="w-full py-2 form-view">
        <ul>
          {indexers?.map((item) => (
            <li key={item.document_type_index.id}>{viewIndexer(item)}</li>
          ))}
        </ul>
      </Form>
    </div>
  );
}

export default ViewIndexers;
