import { format } from "sql-formatter";

export default function formatSql(sql: string): string {
  function preserveAtVariables(sql: string): string {
    return sql.replace(/@(\w+)@/g, (match, p1) => `__PLACEHOLDER_${p1}__`);
  }
  function restoreAtVariables(formattedSql: string): string {
    return formattedSql.replace(/__PLACEHOLDER_(\w+)__/g, (match, p1) => `@${p1}@`);
  }
  const preservedSql = preserveAtVariables(sql);
  const formattedSql = format(preservedSql, { language: "postgresql" });

  return restoreAtVariables(formattedSql);
}
