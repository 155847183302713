import { useContext, useEffect, useState } from "react";
import { Button, Form, Space, Spin } from "antd";
import { useCompanyStore } from "../../store";
import { MutationAdminType, SelectCompanyUserItemType } from "../../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";
import Selector from "@components/shared/Selector";

type CreateAdminPropsType = {
  users: string[];
};

export const CreateAdminForm = ({ users }: CreateAdminPropsType) => {
  const { createAdmin, getCompanyUsersForSelect } = useCompanyStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<MutationAdminType>();

  const [companyUsers, setCompanyUsers] = useState<SelectCompanyUserItemType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getCompanyUsersForSelect();
      setCompanyUsers(result.filter((user) => !users.includes(user.value)));
      setLoading(false);
    })();
  }, [getCompanyUsersForSelect, users]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: MutationAdminType) => {
            setLoading(true);
            const result = await createAdmin(payload);
            if (result.status === "success") {
              showMessage(result, "Administrador cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item<MutationAdminType> label="Usuários" name="users_cuids">
            <Selector
              fieldMap={{
                status: "active",
                value: "value",
                label: "label",
                description: "username",
                dataLabel: ["label"],
                dataFilter: ["label", "username"],
              }}
              mode="multiple"
              options={companyUsers}
            />
          </Form.Item>

          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="company_admin">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
