import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import dayjs from "dayjs";
import {
  CheckBoxState,
  IndexItemType,
  ItemDocumentTypeType,
  ListIndexesType,
  PayloadType,
  PayloadDocumentType,
  PrePayloadType,
  ResponseType,
  SimpleListDocumentTypeType,
  ValidateRepository,
  ChildrenType,
} from "./types";
import { CheckboxChangeEvent } from "antd/es/checkbox";

type DocumentStoreState = {
  simpleListDocumentTypeCustomer: () => Promise<void>;
  documentTypes: ItemDocumentTypeType[];
  loadingDocumentTypes: boolean;

  validateRepository: (dataValidate: ValidateRepository, documentTypeCuid: string) => Promise<boolean>;
  isValid: boolean;
  loadingValidate: boolean;
  documentTypeSelected: ItemDocumentTypeType;

  listIndexesDocumentType: (documentTypeCuid: string) => Promise<void>;
  indexes: IndexItemType[];

  registrationDocument: () => Promise<boolean>;
  loadingRegistration: boolean;

  setCheckBoxState: (e: CheckboxChangeEvent) => void;

  success: PayloadType[];
  checkBoxState: CheckBoxState;

  prePayload: PrePayloadType;
  payload: PayloadType;

  resetState: () => void;
};

const defaultState = {
  documentTypes: [],
  indexes: [],
  loadingDocumentTypes: false,

  // cadastro de documento em lote
  prePayload: {
    can_insert_in_stored_repository: false,
    repository_physical_tag_id: "",
    document_type_cuid: "",
  },
  success: [],
  errors: [],
  isValid: false,
  documentTypeSelected: {} as ItemDocumentTypeType,
  checkBoxState: {
    description: false,
    document_physical_tag_id: false,
    expected_purge_date: false,
    is_permanent_storage: false,
    original_date: false,
  } as CheckBoxState,
  payload: {
    description: "",
    document_physical_tag_id: "",
    expected_purge_date: "",
    indexes_values: [],
    is_permanent_storage: false,
    original_date: dayjs().format("YYYY-MM-DD"),
    physical_or_digital: "P",
  },
  loadingValidate: false,
  loadingRegistration: false,
};

export const useDocumentBatchStore = create<DocumentStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),

  simpleListDocumentTypeCustomer: async (): Promise<void> => {
    set({ loadingDocumentTypes: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDocumentTypeType>, StandardError, void>(
      "/api/v1/customer/document-types/simple-list",
      (result: Success<SimpleListDocumentTypeType>): void => {
        set({
          documentTypes: result.body.data,
          loadingDocumentTypes: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({ loadingDocumentTypes: false });
      }
    );
  },

  listIndexesDocumentType: async (documentTypeCuid: string): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<ListIndexesType>, StandardError, void>(
      `/api/v1/customer/document-types/${documentTypeCuid}/document-type-indexes`,
      (result: Success<ListIndexesType>): void => {
        set({
          indexes: result.body.data as IndexItemType[],
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({
          indexes: [],
        });
      }
    );
  },

  validateRepository: async (dataValidate: ValidateRepository, documentTypeCuid: string): Promise<boolean> => {
    set({ loadingValidate: true });
    const result = await new ClientHttp().post<ValidateRepository, Success<ResponseType>, BadRequest | StandardError>(
      "/api/v1/customer/repositories/documents/validate-insert",
      dataValidate,
      (result: Success<ResponseType>): void => {
        (async () => {
          await get().listIndexesDocumentType(documentTypeCuid);
          set({ loadingValidate: false });
        })();
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingValidate: false });
      }
    );

    return result.status === "success";
  },

  registrationDocument: async (): Promise<boolean> => {
    set({ loadingRegistration: true });
    const payload = get().payload;
    const prePayload = get().prePayload;
    const result = await new ClientHttp().post<PayloadDocumentType, Success<ChildrenType>, BadRequest | StandardError>(
      "/api/v1/customer/fs/document",
      {
        ...prePayload,
        ...payload,
      },
      (result: Success<ChildrenType>): void => {
        showMessage(result, "Documento cadastrado com sucesso.");
        set((state) => ({
          success: [...state.success, { ...payload, cuid: result.body.cuid}],
          loadingRegistration: false,
        }));
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingRegistration: false });
      }
    );
    return result.status === "success";
  },
  setCheckBoxState: (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target as { name: string; checked: boolean };
    set((state) => ({
      checkBoxState: {
        ...state.checkBoxState,
        [name]: checked,
      },
    }));
  },
}));
