import { Button, Form, Input, Segmented, Space, Spin, Table, Tabs, Tag } from "antd";
import { useCustomerGroupsStore } from "../store";
import { useContext, useEffect } from "react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { UpdateCustomerGroupForm } from "./UpdateCustomerGroupForm";
import { D3Date } from "@utils/date";
import { CustomerType, ViewCustomerGroupType } from "../types";
import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";

type ViewCustomerGroupFormProps = {
  cuid: string;
};

export const ViewCustomerGroupForm = ({ cuid }: ViewCustomerGroupFormProps) => {
  const { viewCustomerGroup, view, loadingView } = useCustomerGroupsStore();
  const { openDrawer, onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ViewCustomerGroupType>();

  useEffect(() => {
    (async () => {
      await viewCustomerGroup(cuid);
    })();
    return () => {
      useCustomerGroupsStore.setState({ view: {} as ViewCustomerGroupType });
    };
  }, [viewCustomerGroup, cuid]);

  useEffect(() => {
    form.setFieldsValue({
      ...view,
      created_at: D3Date.FormatValueApiToPtBR(view.created_at),
      updated_at: D3Date.FormatValueApiToPtBR(view.updated_at),
    });
  }, [view, form]);

  return (
    <>
      <Spin spinning={loadingView}>
        <Tabs
          defaultActiveKey="1"
          className="select-none"
          items={[
            {
              key: "1",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                  Dados
                </span>
              ),
              children: (
                <Form className="select-none" layout="vertical" form={form} autoComplete="off">
                  <Form.Item<ViewCustomerGroupType> name="description" label="Descrição">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item<ViewCustomerGroupType> name="active" label="Status">
                    <Segmented
                      disabled
                      options={[
                        { label: "Desbloqueado", value: true },
                        { label: "Bloqueado", value: false },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item<ViewCustomerGroupType> name="created_at" label="Criado em">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item<ViewCustomerGroupType> name="updated_at" label="Atualizado em">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item>
                    <div className="text-right">
                      <Space size="small">
                        <Button type="default" danger onClick={() => onClose()}>
                          Cancelar
                        </Button>
                        <D3Can action="update" resource="customer_group">
                          <Button
                            type="primary"
                            onClick={() =>
                              openDrawer(
                                <UpdateCustomerGroupForm cuid={cuid} />,
                                "right",
                                600,
                                "Editar Grupo de Clientes"
                              )
                            }
                          >
                            Editar
                          </Button>
                        </D3Can>
                      </Space>
                    </div>
                  </Form.Item>
                </Form>
              ),
            },
            {
              key: "2",
              label: (
                <span className="flex items-center justify-center gap-2 ">
                  <Icon icon="clarity:group-solid" className="w-[18px] h-[18px]" />
                  Clientes
                </span>
              ),
              children: (
                <Form className="select-none" layout="vertical">
                  <Form.Item label="Clientes do grupo">
                    <Table<CustomerType>
                      rowKey={(record) => record.cuid}
                      size="small"
                      pagination={false}
                      dataSource={view.customers ? view.customers : undefined}
                      bordered
                    >
                      <Table.Column<CustomerType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="id"
                        title="Código"
                        dataIndex="id"
                      />
                      <Table.Column<CustomerType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="fantasy_name"
                        title="Nome Fantasia"
                        dataIndex="fantasy_name"
                      />
                      <Table.Column<CustomerType>
                        showSorterTooltip={false}
                        defaultSortOrder="ascend"
                        key="name"
                        title="Razão Social"
                        dataIndex="name"
                      />

                      <Table.Column<CustomerType>
                        showSorterTooltip={false}
                        key="active"
                        title="Status"
                        dataIndex="active"
                        render={(value, record) =>
                          record.active ? (
                            <Tag
                              className="flex items-center justify-start gap-1 max-w-max"
                              icon={<Icon icon="basil:unlock-solid" height={14} width={14} />}
                              color="success"
                            >
                              Desbloqueado
                            </Tag>
                          ) : (
                            <Tag
                              className="flex items-center justify-start gap-1 max-w-max"
                              icon={<Icon icon="basil:lock-solid" height={14} width={14} />}
                              color="error"
                            >
                              Bloqueado
                            </Tag>
                          )
                        }
                      />
                    </Table>
                  </Form.Item>
                </Form>
              ),
            },
          ]}
        />
      </Spin>
    </>
  );
};
