import { DatePicker, Form } from "antd";
import dayjs from "dayjs";

type ItemDTIProps = {
  name: number;
  label: string;
  requiredStart: boolean;
  requiredEnd: boolean;
};

function ItemDTI({ name, label, requiredEnd, requiredStart, ...props }: ItemDTIProps) {
  return (
    <Form.Item className="p-0 m-0" label={label}>
      <div className="flex w-full gap-3 ">
        <Form.Item
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          {...props}
          className="w-40 p-0 m-0"
          name={[name, "start_value"]}
          rules={[
            {
              required: requiredStart,
              message: "Obrigatório",
            },
          ]}
        >
          <DatePicker
            placeholder="Data inicial"
            format={{
              format: "DD/MM/YYYY",
              type: "mask",
            }}
          />
        </Form.Item>
        <div className="flex items-center h-8 ">ao</div>
        <Form.Item
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : "")}
          {...props}
          name={[name, "end_value"]}
          rules={[
            {
              required: requiredEnd,
              message: "Obrigatório",
            },
          ]}
        >
          <DatePicker
            placeholder="Data final"
            format={{
              format: "DD/MM/YYYY",
              type: "mask",
            }}
          />
        </Form.Item>
      </div>
    </Form.Item>
  );
}

export default ItemDTI;
