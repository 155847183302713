import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin, Tabs } from "antd";
import { useCustomerUsersStore } from "../store";
import { CreateCustomerUserType, SelectDepartmentItemType, SelectPermissionsGroupItemType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { StandardError } from "@utils/clientHttp";
import { Icon } from "@iconify/react";
import D3PermCheckTable from "@components/shared/D3PermCheckTable/D3PermCheckTable";
import D3Can from "@components/shared/D3Can";
import Selector from "@components/shared/Selector";

export const CreateCustomerUserForm = () => {
  const {
    createCustomerUser,
    getDepartmentsForSelect,
    getPermissionsGroupForSelect,
    loadingReport,
    reports,
    simpleListReports,
  } = useCustomerUsersStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateCustomerUserType>();
  const [permissionsGroups, setPermissionsGroups] = useState<SelectPermissionsGroupItemType[]>([]);
  const [departments, setDepartments] = useState<SelectDepartmentItemType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [getDepartmentsForSelectResult, getPermissionsGroupForSelectResult] = await Promise.all([
        getDepartmentsForSelect(),
        getPermissionsGroupForSelect(),
        simpleListReports(),
      ]);

      setDepartments(getDepartmentsForSelectResult);
      setPermissionsGroups(getPermissionsGroupForSelectResult);

      setLoading(false);
    })();
  }, [getDepartmentsForSelect, getPermissionsGroupForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateCustomerUserType) => {
            setLoading(true);
            const result = await createCustomerUser(payload);
            if (result.status === "success") {
              showMessage(result, "Usuário Cliente cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(tab) => (tab === "1" ? setSize(600) : setSize(1200))}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<CreateCustomerUserType>
                      name="name"
                      label="Nome"
                      rules={[{ required: true, message: "Por favor, insira o nome." }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item<CreateCustomerUserType>
                      label="Usuário"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o usuário.",
                        },
                        {
                          max: 30,
                          message: "O usuário deve ter menos de 30 caracteres.",
                        },
                        {
                          validator: (_, value) =>
                            /\s/.test(value)
                              ? Promise.reject(new Error("O usuário não pode conter espaços."))
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<CreateCustomerUserType>
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o e-mail.",
                        },
                        {
                          type: "email",
                          message: "Por favor, insira um e-mail válido.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item<CreateCustomerUserType> label="Departamentos" name="departments_cuids">
                      <Selector
                        fieldMap={{
                          status: "active",
                          value: "value",
                          label: "abbreviation",
                          description: "abbreviation",
                          dataLabel: ["abbreviation", "label"],
                          dataFilter: ["abbreviation", "label"],
                          disabled: true,
                        }}
                        mode="multiple"
                        options={departments}
                      />
                    </Form.Item>

                    <Form.Item<CreateCustomerUserType>
                      label="Grupos de Permissões"
                      name="customer_permissions_groups_cuids"
                    >
                      <Selector
                        fieldMap={{
                          status: "active",
                          value: "value",
                          label: "label",
                          dataLabel: ["label"],
                          dataFilter: ["label"],
                        }}
                        mode="multiple"
                        options={permissionsGroups}
                      />
                    </Form.Item>
                    <Form.Item<CreateCustomerUserType> label="Relatórios" name="reports_cuids">
                      <Selector
                        fieldMap={{
                          value: "cuid",
                          label: "title",
                          description: "description",
                          dataLabel: ["title"],
                          dataFilter: ["title", "description"],
                        }}
                        mode="multiple"
                        options={reports}
                        loading={loadingReport}
                      />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "w",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                    Permissões
                  </span>
                ),
                children: (
                  <Form.Item<CreateCustomerUserType> name="permissions" label="Permissões Personalizadas">
                    <D3PermCheckTable
                      profile="CTM"
                      onChange={(newValues) => {
                        form.setFieldValue("permissions", newValues);
                      }}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="customer_user">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
