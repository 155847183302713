import useAuthStore from "@provider/AuthProvider";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";

type UploadImageStoreState = {
  uploadImage: (imageUrl: string) => Promise<void>;
  deleteImage: () => Promise<void>;
};

export const useUploadImageStore = create<UploadImageStoreState>((set, get) => ({
  uploadImage: async (imageUrl: string): Promise<void> => {
    const blob = await fetch(imageUrl).then((res) => res.blob());
    const formData = new FormData();
    formData.append("file", blob, "profile-picture.png");
    await new ClientHttp().uploadImage<Success<void>, BadRequest | StandardError>(
      "/api/v1/system/user/profile-picture",
      formData,
      (result: Success<void>) => {
        useAuthStore.getState().refreshToken(false);
        showMessage(result, "Upload de imagem finalizado com sucesso.");
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  deleteImage: async (): Promise<void> => {
    await new ClientHttp().delete<Success<void>, BadRequest | StandardError>(
      "/api/v1/system/user/profile-picture",
      (result: Success<void>) => {
        showMessage(result, "Imagem deletada com sucesso.");
        useAuthStore.getState().refreshToken(false);
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
