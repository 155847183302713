import D3Can from "@components/shared/D3Can";
import D3ProtectPage from "@components/shared/D3ProtectPage";
import FixedColumn from "@components/shared/FixedColumn";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Fixed, FixedType } from "@utils/Fixed";
import { Button, Col, Flex, Pagination, Popover, Row, Space, Tooltip } from "antd";
import Table from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { ShowTag, StatusOrders } from "../Utils";
import { CreateSpecialOrderForm } from "./components/CreateSpecialOrderForm";
import PrintSpecialOrderForm from "./components/PrintSpecialOrderForm";
import { SearchSpecialOrderForm } from "./components/SearchSpecialOrderForm";
import { ViewSpecialOrderForm } from "./components/ViewSpecialOrderForm";
import { useSpecialOrdersStore } from "./store";
import { SpecialOrderListItemType } from "./types";
import { SendSignedSpecialOrderForm } from "./components/SendSignedSpecialOrderForm";
import { ctNotification } from "@components/shared/CTNotification";
import CancelSpecialOrderForm from "./components/CancelSpecialOrderForm";

const SpecialOrdersView: React.FC = () => {
  const {
    apiResult,
    pagination,
    loading,
    setPagination,
    loadSpecialOrders,
    resetFilter,
    resetState,
    savedSpecialOrder,
  } = useSpecialOrdersStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const [openSearch, setOpenSearch] = useState(false);
  const [fixed, setFixed] = useState<FixedType[]>([
    {
      dataIndex: "",
      direction: "left",
    },
    {
      dataIndex: "",
      direction: "right",
    },
  ]);

  useEffect(() => {
    (async () => {
      await loadSpecialOrders();
    })();
    return () => resetState();
  }, [loadSpecialOrders]);

  return (
    <div className="flex h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table
        size="small"
        rowKey="cuid"
        dataSource={apiResult.data}
        bordered
        className="responsive-table"
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <Row className="w-full">
                <Col span={8} className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar Ordem Especial"
                    content={
                      <SearchSpecialOrderForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      className="flex items-center"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      Pesquisar
                    </Button>
                  </Popover>
                </Col>
                <Col span={8} className="text-center">
                  <span className="text-xl font-semibold text-gray-600">Ordens Especial</span>
                </Col>
                <Col span={8} className="flex items-center justify-end">
                  <D3Can action="create" resource="order_special">
                    <Button
                      type="primary"
                      size="middle"
                      className="flex items-center"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() =>
                        openDrawer(
                          <D3ProtectPage
                            targetPage={<CreateSpecialOrderForm />}
                            isRequiredCustomerGroup={false}
                            isRequiredCustomer={true}
                            resource="order_special"
                            action="view"
                          />,
                          "right",
                          600,
                          "Cadastrar Ordem Especial"
                        )
                      }
                    >
                      Ordem Especial
                    </Button>
                  </D3Can>
                </Col>
              </Row>
            </>
          );
        }}
        footer={() => {
          return (
            <Flex className="w-full" align="center" justify="end">
              <Pagination
                showLessItems
                showTotal={(total, range) =>
                  `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                }
                showSizeChanger={true}
                current={pagination.page}
                onChange={setPagination}
                pageSize={pagination.pageSize}
                total={apiResult.total}
              />
            </Flex>
          );
        }}
      >
        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="id"
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "id")}
              dataIndex="id"
              direction="left"
              title="Número"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "id")}
          dataIndex="id"
          render={(value) => {
            return (
              <div className="flex items-center justify-between gap-2">
                {value}
                <Tooltip title="Copiar número da ordem" trigger={["click", "hover"]}>
                  <Button
                    className="transition-all duration-300 ease-in-out hover:bg-gray-200"
                    type="link"
                    shape="circle"
                    icon={<Icon className="text-blue-500" icon="fluent:copy-32-filled" />}
                    size="small"
                    onClick={() => D3Copy({ text: value, success: "Número copiado! " })}
                  />
                </Tooltip>
              </div>
            );
          }}
        />
        <Table.Column<SpecialOrderListItemType>
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "customer_name")}
              dataIndex="customer_name"
              direction="left"
              title="Cliente"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "customer_name")}
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="customer_name"
          dataIndex="customer_name"
        />
        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="special_service_description"
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "special_service_description")}
              dataIndex="special_service_description"
              direction="left"
              title="Serviço"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "special_service_description")}
          dataIndex="special_service_description"
        />
        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="requester_department"
          title="Departamento"
          dataIndex="requester_department"
        />

        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="requester_user"
          title="Requisitado por"
          dataIndex="requester_user"
        />
        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_by"
          title="Criado por"
          dataIndex="created_by"
        />
        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_at"
          title="Data criação"
          dataIndex="created_at"
          render={(value, specialOrder) => D3DateHourSorter(specialOrder.created_at)}
        />
        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          key="active"
          title="Status"
          dataIndex="active"
          render={(value, specialOrder) => {
            return (
              <Space>
                {specialOrder.printed && (
                  <Tooltip trigger={["click", "hover"]} title="Ordem impressa">
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:print-duotone"
                          className="text-blue-400 transition-all duration-100 ease-in hover:scale-110 hover:text-blue-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {specialOrder.canceled && (
                  <Tooltip trigger={["click", "hover"]} title="Ordem cancelada">
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:cancel"
                          className="text-red-400 transition-all duration-100 ease-in hover:scale-110 hover:text-red-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {specialOrder.served && (
                  <Tooltip trigger={["click", "hover"]} title="Ordem atendida">
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="fluent:document-checkmark-16-filled"
                          className="text-green-400 transition-all duration-100 ease-in hover:scale-110 hover:text-green-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />
        <Table.Column<SpecialOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="status"
          title="Status atual"
          dataIndex="status"
          render={(value, specialOrder) => ShowTag(StatusOrders, specialOrder.status)}
        />

        <Table.Column<SpecialOrderListItemType>
          key="actions"
          width={200}
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "actions")}
              dataIndex="actions"
              direction="right"
              title="Ações"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "actions")}
          render={(value, specialOrder) => {
            return (
              <div className="flex items-center justify-center gap-1 px-2 ">
                <D3Can action="view" resource="order_special" type="hidden">
                  <Tooltip trigger={["click", "hover"]} title="Visualizar ordem">
                    <Button
                      className="flex items-center justify-center"
                      type="text"
                      shape="default"
                      icon={
                        <Icon
                          icon="carbon:document-view"
                          height={18}
                          width={18}
                          className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                        />
                      }
                      size={"middle"}
                      onClick={() =>
                        openDrawer(
                          <ViewSpecialOrderForm cuid={specialOrder.cuid} />,
                          "right",
                          600,
                          "Visualizar Ordem Special"
                        )
                      }
                    />
                  </Tooltip>
                </D3Can>

                <D3Can action="print" resource="order_special" type="hidden">
                  {specialOrder.canceled ? null : (
                    <Tooltip title="Imprimir ordem" trigger={["click", "hover"]}>
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="lets-icons:print-duotone"
                            height={24}
                            width={24}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <PrintSpecialOrderForm
                              cuid={specialOrder.cuid}
                              fileName={`Ordem especial nº ${specialOrder.id}`}
                              printed={specialOrder.printed}
                            />,
                            "right",
                            1000,
                            "Imprimir Ordem Especial",
                            true
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="serve" resource="order_special" type="hidden">
                  {specialOrder.canceled || specialOrder.served ? null : (
                    <Tooltip title="Atender a ordem" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="fluent:document-checkmark-16-filled"
                            height={20}
                            width={20}
                            className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                          />
                        }
                        size={"middle"}
                        onClick={() => {
                          ctNotification({
                            type: "warning",
                            title: `Atenção !`,
                            content: "Confirma o atendimento da ordem especial?",
                            withoutFooter: false,
                            okText: `Confirmar`,
                            doNotClose: true,
                            cancelText: "Cancelar",
                            onOk: async () => {
                              await savedSpecialOrder(specialOrder.cuid);
                            },
                          });
                        }}
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="serve" resource="order_special" type="hidden">
                  {specialOrder.canceled ? null : (
                    <Tooltip trigger={["click", "hover"]} title="Envio/reenvio da ordem digitalizada">
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="fa6-solid:file-signature"
                            height={16}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <SendSignedSpecialOrderForm
                              orderCuid={specialOrder.cuid}
                              fileName={`Ordem especial nº ${specialOrder.id}`}
                              isUploadOrder={specialOrder.has_order_resent}
                            />,
                            "right",
                            600,
                            "Envio / reenvio da ordem digitalizada"
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="cancel" resource="order_special" type="hidden">
                  {specialOrder.served || specialOrder.canceled ? null : (
                    <Tooltip title="Cancelar ordem" trigger={["click", "hover"]}>
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        onClick={() =>
                          openDrawer(
                            <CancelSpecialOrderForm orderCuid={specialOrder.cuid} />,
                            "right",
                            600,
                            "Cancelar Ordem Especial"
                          )
                        }
                        icon={
                          <Icon
                            icon="lets-icons:cancel"
                            height={20}
                            width={20}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  )}
                </D3Can>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default SpecialOrdersView;
