import D3Can from "@components/shared/D3Can";
import FixedColumn from "@components/shared/FixedColumn";
import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Copy from "@utils/D3Copy";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Fixed, FixedType } from "@utils/Fixed";
import { ShowTag, StatusOrders, TypeOfRequest } from "@views/orders/Utils";
import { Button, Flex, Grid, Pagination, Popover, Space, Table, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrintDeploymentOrderForm from "./components/PrintDeploymentOrderForm";
import { SearchDeploymentOrderForm } from "./components/SearchDeploymentOrderForm";
import ServeDeploymentOrderForm from "./components/ServeDeploymentOrderForm";
import { ViewDeploymentOrderForm } from "./components/ViewDeploymentOrderForm";
import { useDeploymentOrdersStore } from "./store";
import { DeploymentOrderListItemType } from "./types";
import { SendSignedDeploymentOrderForm } from "./components/SendSignedDeploymentOrderForm";
import CancelDeploymentOrderForm from "./components/CancelDeploymentOrderForm";

const { useBreakpoint } = Grid;

const DeploymentOrdersView: React.FC = () => {
  const { apiResult, pagination, loading, setPagination, loadDeploymentOrders, resetFilter, resetState } =
    useDeploymentOrdersStore();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { openDrawer } = useContext(D3DrawerContext);
  const [openSearch, setOpenSearch] = useState(false);

  const [fixed, setFixed] = useState<FixedType[]>([
    {
      dataIndex: "",
      direction: "left",
    },
    {
      dataIndex: "",
      direction: "right",
    },
  ]);

  useEffect(() => {
    (async () => {
      await loadDeploymentOrders();
    })();
    return () => resetState();
  }, [loadDeploymentOrders]);

  return (
    <div className="flex h-full mb-1 mr-1 overflow-auto bg-white rounded shadow-md">
      <Table<DeploymentOrderListItemType>
        size="small"
        rowKey="cuid"
        className="responsive-table"
        dataSource={apiResult.data}
        bordered
        pagination={false}
        loading={loading}
        title={() => {
          return (
            <>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-start">
                  <Popover
                    open={openSearch}
                    placement="rightTop"
                    title="Pesquisar ordem de implantação"
                    content={
                      <SearchDeploymentOrderForm
                        onCallBackFilter={() => setOpenSearch(false)}
                        onCallBackResetFilter={() => {
                          setOpenSearch(false);
                          resetFilter();
                        }}
                      />
                    }
                    trigger="click"
                    onOpenChange={(open) => setOpenSearch(open)}
                  >
                    <Button
                      className={screens.md ? "flex justify-center items-center" : ""}
                      onClick={() => setOpenSearch(!openSearch)}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                    >
                      {screens.md ? "Pesquisar" : null}
                    </Button>
                  </Popover>
                </div>
                <div>
                  <span className="text-xl font-semibold text-gray-600 whitespace-nowrap">Ordens de Implantação</span>
                </div>
                <div className="flex items-center justify-end">
                  <D3Can action="create" resource="order_deployment">
                    <Button
                      className={screens.md ? "flex justify-center items-center" : ""}
                      type="primary"
                      size="middle"
                      icon={<Icon icon="ic:round-plus" height={20} width={20} />}
                      onClick={() => navigate("create")}
                    >
                      {screens.md ? "Ordem de Implantação" : null}
                    </Button>
                  </D3Can>
                </div>
              </div>
            </>
          );
        }}
        footer={() => {
          return (
            <Flex className="w-full" align="center" justify="end">
              <Pagination
                showLessItems
                showTotal={(total, range) =>
                  screens.md
                    ? `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                    : undefined
                }
                showSizeChanger={true}
                current={pagination.page}
                onChange={setPagination}
                pageSize={pagination.pageSize}
                total={apiResult.total}
              />
            </Flex>
          );
        }}
      >
        <Table.Column<DeploymentOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "id")}
              dataIndex="id"
              direction="left"
              title="Número"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "id")}
          key="id"
          dataIndex="id"
          width={120}
          render={(value) => {
            return (
              <div className="flex items-center justify-between gap-2">
                {value}
                <Tooltip trigger={["click", "hover"]} title="Copiar número da ordem">
                  <Button
                    className="transition-all duration-300 ease-in-out hover:bg-gray-200"
                    type="link"
                    shape="default"
                    icon={<Icon className="text-blue-500" icon="fluent:copy-32-filled" />}
                    size="small"
                    onClick={() => D3Copy({ text: value, success: "Número copiado! " })}
                  />
                </Tooltip>
              </div>
            );
          }}
        />
        <Table.Column<DeploymentOrderListItemType>
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "customer_name")}
              dataIndex="customer_name"
              direction="left"
              title="Cliente"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "customer_name")}
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="customer_name"
          dataIndex="customer_name"
        />
        <Table.Column<DeploymentOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="department"
          title="Departamento"
          dataIndex="department"
        />
        <Table.Column<DeploymentOrderListItemType>
          title="Criado por"
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_by"
          dataIndex="created_by"
        />
        <Table.Column<DeploymentOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="created_at"
          title="Data criação"
          dataIndex="created_at"
          render={(value, deploymentOrder) => D3DateHourSorter(deploymentOrder.created_at)}
        />
        <Table.Column<DeploymentOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="status"
          title="Tipo de requisição"
          dataIndex="status"
          render={(value, deploymentOrder) => ShowTag(TypeOfRequest, deploymentOrder.request_type)}
        />
        <Table.Column<DeploymentOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="repository_type"
          title="Tipo de Repositório"
          dataIndex="repository_type"
        />
        <Table.Column<DeploymentOrderListItemType>
          width={120}
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="number_of_repositories"
          title="Quantidade"
          dataIndex="number_of_repositories"
        />

        <Table.Column<DeploymentOrderListItemType>
          showSorterTooltip={false}
          key="active"
          title="Status"
          dataIndex="active"
          render={(value, deploymentOrder) => {
            return (
              <Space>
                {deploymentOrder.printed && (
                  <Tooltip trigger={["click", "hover"]} title="Ordem impressa">
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="lets-icons:print-duotone"
                          className="text-blue-400 hover:text-blue-500"
                        />
                      }
                    />
                  </Tooltip>
                )}

                {deploymentOrder.canceled && (
                  <Tooltip trigger={["click", "hover"]} title="Ordem cancelada">
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={<Icon height={20} icon="lets-icons:cancel" className="text-red-400 hover:text-red-500" />}
                    />
                  </Tooltip>
                )}

                {deploymentOrder.served && (
                  <Tooltip trigger={["click", "hover"]} title="Ordem atendida">
                    <Button
                      className="cursor-help"
                      type="link"
                      icon={
                        <Icon
                          height={20}
                          icon="fluent:document-checkmark-16-filled"
                          className="text-green-400 hover:text-green-500"
                        />
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />

        <Table.Column<DeploymentOrderListItemType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="status"
          title="Status atual"
          dataIndex="status"
          render={(value, deploymentOrder) => ShowTag(StatusOrders, deploymentOrder.status)}
        />

        <Table.Column<DeploymentOrderListItemType>
          key="actions"
          width={200}
          title={
            <FixedColumn
              active={fixed.some((item) => item.dataIndex === "actions")}
              dataIndex="actions"
              direction="right"
              title="Ações"
              setFixed={setFixed}
            />
          }
          fixed={Fixed(fixed, "actions")}
          render={(value, deploymentOrder) => {
            return (
              <div className="flex items-center justify-center gap-1 px-2 ">
                <D3Can action="view" resource="order_deployment" type="hidden">
                  <Tooltip trigger={["click", "hover"]} title="Visualizar ordem">
                    <Button
                      className="flex items-center justify-center"
                      type="text"
                      shape="default"
                      icon={
                        <Icon
                          icon="carbon:document-view"
                          height={18}
                          width={18}
                          className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                        />
                      }
                      size={"middle"}
                      onClick={() =>
                        openDrawer(
                          <ViewDeploymentOrderForm cuid={deploymentOrder.cuid} />,
                          "right",
                          700,
                          "Visualizar Ordem de Implantação"
                        )
                      }
                    />
                  </Tooltip>
                </D3Can>

                <D3Can action="print" resource="order_deployment" type="hidden">
                  {deploymentOrder.canceled ? null : (
                    <Tooltip title="Imprimir ordem" trigger={["click", "hover"]}>
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="lets-icons:print-duotone"
                            height={24}
                            width={24}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <PrintDeploymentOrderForm
                              cuid={deploymentOrder.cuid}
                              fileName={`Ordem de implantação nº ${deploymentOrder.id}`}
                              printed={deploymentOrder.printed}
                            />,
                            "right",
                            1000,
                            "Imprimir Ordem de Implantação",
                            true
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="serve" resource="order_deployment" type="hidden">
                  {deploymentOrder.canceled || deploymentOrder.served ? null : (
                    <Tooltip title="Atender a ordem" trigger={["click", "hover"]}>
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        onClick={() =>
                          openDrawer(
                            <ServeDeploymentOrderForm orderCuid={deploymentOrder.cuid} />,
                            "right",
                            600,
                            "Baixar Ordem de Implantação"
                          )
                        }
                        icon={
                          <Icon
                            icon="fluent:document-checkmark-16-filled"
                            height={20}
                            width={20}
                            className="text-green-400 transition-all duration-100 ease-in hover:text-green-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="serve" resource="order_deployment" type="hidden">
                  {deploymentOrder.canceled ? null : (
                    <Tooltip trigger={["click", "hover"]} title="Envio/reenvio da ordem digitalizada">
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="fa6-solid:file-signature"
                            height={16}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <SendSignedDeploymentOrderForm
                              orderCuid={deploymentOrder.cuid}
                              fileName={`Ordem de implantação nº ${deploymentOrder.id}`}
                              isUploadOrder={deploymentOrder.has_order_resent}
                            />,
                            "right",
                            600,
                            "Envio / reenvio da ordem digitalizada"
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </D3Can>

                <D3Can action="cancel" resource="order_deployment" type="hidden">
                  {deploymentOrder.served || deploymentOrder.canceled ? null : (
                    <Tooltip title="Cancelar ordem" trigger={["click", "hover"]}>
                      <Button
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        onClick={() =>
                          openDrawer(
                            <CancelDeploymentOrderForm orderCuid={deploymentOrder.cuid} />,
                            "right",
                            600,
                            "Cancelar Ordem de Implantação"
                          )
                        }
                        icon={
                          <Icon
                            icon="lets-icons:cancel"
                            height={20}
                            width={20}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        size={"middle"}
                      />
                    </Tooltip>
                  )}
                </D3Can>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default DeploymentOrdersView;
