import { FormInstance, Spin } from "antd";
import { useEffect } from "react";
import useSearchStore from "./store";
import RenderGlobalIndexers from "./criterion/RenderGlobalIndexers";

type SearchGlobalFormProps = {
  form: FormInstance<any>;
};

function SearchGlobalForm({ form }: SearchGlobalFormProps) {
  const { listDocumentTypeIndexersGlobal, listGlobalIndexers, loadingGlobalIndexers } = useSearchStore();

  useEffect(() => {
    (async () => {
      if (listGlobalIndexers.length === 0) {
        await listDocumentTypeIndexersGlobal();
      }
    })();
  }, []);

  return (
    <div className="flex flex-col w-full h-full p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important">
      {loadingGlobalIndexers ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : listGlobalIndexers ? (
        <RenderGlobalIndexers form={form} attribute={["filter_global_index", "global_indexes_values"]} />
      ) : null}
    </div>
  );
}

export default SearchGlobalForm;
