import { useContext, useEffect } from "react";
import { Button, Form, Input, Segmented, Space } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { UpdateNoteForm } from "./UpdateNoteForm ";
import { D3Date } from "@utils/date";
import { ViewNoteType } from "../types";
import { useNotesStore } from "../store";
import SpinLoading from "@components/shared/SpinLoading";

type ViewNoteFormProps = {
  noteCuid: string;
};

const { TextArea } = Input;

export const ViewNoteForm = ({ noteCuid }: ViewNoteFormProps) => {
  const { onClose, openDrawer } = useContext(D3DrawerContext);
  const { loadView, view, loadingView } = useNotesStore();

  const [form] = Form.useForm<ViewNoteType>();

  useEffect(() => {
    (async () => {
      await loadView(noteCuid);
    })();
  }, [loadView, noteCuid]);

  useEffect(() => {
    if (view.cuid) {
      form.setFieldsValue({
        ...view,
        created_at: D3Date.FormatValueApiToPtBR(view.created_at as Date),
        updated_at: D3Date.FormatValueApiToPtBR(view.updated_at as Date),
      });
    }
  }, [view, form]);

  return loadingView ? (
    <SpinLoading />
  ) : (
    <Form className="select-none form-view " form={form} layout="vertical" disabled autoComplete="off">
      <Form.Item<ViewNoteType> name="title" label="Descrição">
        <Input />
      </Form.Item>

      <Form.Item<ViewNoteType> name="content" label="Nota de observação">
        <TextArea rows={6} />
      </Form.Item>

      <Form.Item<ViewNoteType> name="active" label="Status">
        <Segmented
          disabled
          options={[
            {
              label: "Desbloqueado",
              value: true,
            },
            {
              label: "Bloqueado",
              value: false,
            },
          ]}
        />
      </Form.Item>

      <Form.Item<ViewNoteType> name={["created_by", "name"]} label="Criado por">
        <Input />
      </Form.Item>

      <Form.Item<ViewNoteType> name="created_at" label="Criado em">
        <Input />
      </Form.Item>

      <Form.Item<ViewNoteType> name={["created_by", "name"]} label="Atualizado por">
        <Input />
      </Form.Item>

      <Form.Item<ViewNoteType> name="updated_at" label="Atualizado em">
        <Input />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={false} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="customer_note" resource="update">
              <Button
                disabled={false}
                type="primary"
                onClick={() => openDrawer(<UpdateNoteForm noteCuid={noteCuid} />, "right", 600, "Editar nota")}
              >
                Editar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
};
