import { create } from "zustand";
import { ListShelfType, CreateShelfType, UpdateShelfType, ViewShelfType } from "./types";

import showMessage from "@utils/showMessage";

import { ClientHttp, BadRequest, StandardError, Success } from "@utils/clientHttp";

type ShelfStoreState = {
  apiResult: ListShelfType;
  loading: boolean;
  storageCuid: string;
  setStorageCuid: (storageCuid: string) => void;
  loadShelves: () => Promise<void>;
  loadView: (shelfCuid: string) => Promise<void>;
  view: ViewShelfType;
  loadingView: boolean;
  createShelf: (shelf: CreateShelfType) => Promise<Success<{ cuid: string }> | BadRequest | StandardError>;
  updateShelf: (cuid: string, shelf: UpdateShelfType) => Promise<boolean>;
  loadingUpdate: boolean;
  lockShelf: (cuidShelf: string) => Promise<void>;
  unLockShelf: (cuidShelf: string) => Promise<void>;
};

export const useShelvesStore = create<ShelfStoreState>((set, get) => ({
  apiResult: {
    data: [],
  },
  loading: false,
  storageCuid: "",
  view: {} as ViewShelfType,
  loadingView: false,
  loadingUpdate: false,
  setStorageCuid: (storageCuid: string) => set({ storageCuid: storageCuid }),
  loadShelves: async (): Promise<void> => {
    set({ loading: true });
    await new ClientHttp().get<Success<ListShelfType>, BadRequest | StandardError>(
      `/api/v1/customer/storages/${get().storageCuid}/shelves`,
      (result: Success<ListShelfType>) => {
        const { body } = result;
        set({ apiResult: body, loading: false });
        //get().loadShelves();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  loadView: async (shelfCuid: string): Promise<void> => {
    set({ loadingView: true });
    await new ClientHttp().get<Success<ViewShelfType>, StandardError>(
      `/api/v1/customer/storages/${get().storageCuid}/shelves/${shelfCuid}`,
      (result: Success<ViewShelfType>): void => {
        set({
          view: result.body,
          loadingView: false,
        });
      },
      (error: BadRequest | StandardError) => {
        set({ loadingView: false });
        showMessage(error);
      }
    );
  },

  createShelf: async (shelf: CreateShelfType): Promise<Success<{ cuid: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<CreateShelfType, Success<{ cuid: string }>, BadRequest | StandardError>(
      `/api/v1/customer/storages/${get().storageCuid}/shelves`,
      shelf,
      (result: Success<{ cuid: string }>) => {
        get().loadShelves();
      }
    );
  },
  updateShelf: async (cuid: string, shelf: UpdateShelfType): Promise<boolean> => {
    set({ loadingUpdate: true });
    const result = await new ClientHttp().put<UpdateShelfType, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/storages/${get().storageCuid}/shelves/${cuid}`,
      shelf,
      (result: Success<void>) => {
        showMessage(result, "Estante atualizada com sucesso.");
        set({ loadingUpdate: false });
        get().loadShelves();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
        set({ loadingUpdate: false });
      }
    );
    return result.status === "success";
  },
  lockShelf: async (cuidShelf: string): Promise<void> => {
    await new ClientHttp().patch<
      undefined,
      Success<{
        message: "string";
        status: "string";
      }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/storages/${get().storageCuid}/shelves/${cuidShelf}/lock`,
      undefined,
      (
        result: Success<{
          message: "string";
          status: "string";
        }>
      ) => {
        get().loadShelves();
        if (result.status === "success") {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockShelf: async (cuidShelf: string): Promise<void> => {
    await new ClientHttp().patch<
      undefined,
      Success<{
        message: "string";
        status: "string";
      }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/storages/${get().storageCuid}/shelves/${cuidShelf}/unlock`,
      undefined,
      (
        result: Success<{
          message: "string";
          status: "string";
        }>
      ) => {
        if (result.status === "success") {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
        get().loadShelves();
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
