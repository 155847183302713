import { create } from "zustand";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { ChildrenType, ContentNavigateType, DownloadFile } from "./types";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import useBreadCrumbStore from "./components/bread-crumb/store";
import useSearchStore from "./components/search/store";

type NavigateStoreType = {
  fsCuid: string;
  content: ContentNavigateType;
  page: number;
  loading: boolean;
  loadContent: (cuid: string) => Promise<void>;
  downloadFile: (fileCuid: string) => Promise<Success<DownloadFile> | StandardError>;
  resetState: () => void;
  isScroll: boolean;
  viewType: "list" | "grid";
  selected: {
    checkAll: boolean;
    indeterminate: boolean;
    cuids: string[];
  };
  onCheckAllChange: (e: CheckboxChangeEvent) => void;
  onChangeCheck: (cuid: string) => void;
  addChildren: (newChildren: ChildrenType) => void;
  addChildrens: (newChildrens: ChildrenType[]) => void;
  renameFolder: (folderCuid: string, newName: string) => void;
  removeChildren: (cuids: string[]) => void;
  isFilter: boolean;
};

const defaultState = {
  fsCuid: "",
  content: {
    cuid: "",
    name: "",
    parent: {
      cuid: "",
      name: "",
      type: "FLE",
    },
    total_children: 0,
    type: "FLE",
    children: [],
    can_upload_files_here: false,
    can_create_folder_here: false,
    require_departments_to_upload_files_here: false,
    breadcrumbs: [],
  } as ContentNavigateType,
  page: 1,
  loading: true,
  isScroll: false,
 
};

const keepState = {
  viewType: "list" as "list" | "grid",
  selected: {
    checkAll: false,
    indeterminate: false,
    cuids: [],
  },
};

const useNavigateStore = create<NavigateStoreType>((set, get) => ({
  ...defaultState,
  ...keepState,
  isFilter: Object.values(useSearchStore.getState().changedFilter).some((value) => value === true),
  addChildren: (newChildren: ChildrenType) => {
    const contentOld = get().content;
    set({
      content: {
        ...contentOld,
        total_children: contentOld.total_children + 1,
        children: [...contentOld.children, newChildren],
      },
    });
  },
  addChildrens: (newChildrens: ChildrenType[]) => {
    const contentOld = get().content;
    set({
      content: {
        ...contentOld,
        total_children: contentOld.total_children + newChildrens.length,
        children: [...contentOld.children, ...newChildrens],
      },
    });
  },

  renameFolder: (folderCuid: string, newName: string) => {
    const contentOld = get().content;
    const updatedChildren = contentOld.children.map((child) =>
      child.cuid === folderCuid ? { ...child, name: newName } : child
    );
    set({
      content: {
        ...contentOld,
        children: updatedChildren,
      },
    });
  },
  removeChildren: (cuids: string[]) => {
    const contentOld = get().content;
    const updatedChildren = contentOld.children.filter((child) => !cuids.includes(child.cuid));
    set({
      content: {
        ...contentOld,
        total_children: contentOld.total_children - cuids.length,
        children: updatedChildren,
      },
      selected: {
        checkAll: false,
        indeterminate: false,
        cuids: [],
      },
    });
  },

  onCheckAllChange: (e: CheckboxChangeEvent) => {
    const allCuids = get().content.children.reduce((acc: string[], child: ChildrenType) => {
      if (child.type !== "DTF" && !(child.type === "SDC" && child.is_physical)) {
        acc.push(child.cuid);
      }
      return acc;
    }, []);
    set({
      selected: {
        checkAll: e.target.checked,
        indeterminate: false,
        cuids: e.target.checked ? allCuids : [],
      },
    });
  },
  onChangeCheck: (cuid: string) => {
    const allCuids = get().content.children.reduce((acc: string[], child: ChildrenType) => {
      if (child.type !== "DTF" && !(child.type === "SDC" && child.is_physical)) {
        acc.push(child.cuid);
      }
      return acc;
    }, []);
    const stateSelected = get().selected;
    const isSelected = stateSelected.cuids.includes(cuid);
    const newCuids = isSelected ? stateSelected.cuids.filter((item) => item !== cuid) : [...stateSelected.cuids, cuid];
    set({
      selected: {
        checkAll: newCuids.length === allCuids.length,
        indeterminate: newCuids.length > 0 && newCuids.length < allCuids.length,
        cuids: isSelected ? stateSelected.cuids.filter((item) => item !== cuid) : [...stateSelected.cuids, cuid],
      },
    });
  },

  resetState: () => {
    set(defaultState);
  },

  loadContent: async (cuid: string): Promise<void> => {
    set({ loading: true });
    if (cuid !== get().content.cuid) {
      set({
        content: defaultState.content,
        page: 1,
        selected: {
          checkAll: false,
          indeterminate: false,
          cuids: [],
        },
      });
    }

    const page = get().page;
    await new ClientHttp().get<Success<ContentNavigateType>, BadRequest | StandardError>(
      `/api/v1/customer/fs/${cuid}/navigate?page=${page}&per_page=50`,
      (result: Success<ContentNavigateType>) => {
        const { body } = result;
        useBreadCrumbStore.setState({
          breadCrumb: body.breadcrumbs,
        });
        set((state) => ({
          fsCuid: cuid,
          page: page + 1,
          loading: false,
          content: {
            ...body,
            children: [...state.content.children, ...body.children],
          },
        }));
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  downloadFile: async (fileCuid: string): Promise<Success<DownloadFile> | StandardError> => {
    return await new ClientHttp().get<Success<DownloadFile>, StandardError>(
      `/api/v1/customer/fs/file/${fileCuid}/download`,
      (result: Success<DownloadFile>) => {
        return result.body as DownloadFile;
      },
      (error: StandardError) => {
        showMessage(error);
        return {} as DownloadFile;
      }
    );
  },
}));

export default useNavigateStore;
