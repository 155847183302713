import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Form, Input, Space, Spin } from "antd";
import { useContext, useEffect } from "react";
import { useManageStore } from "./store";
import { ChangePasswordType } from "./types";
import Selector from "@components/shared/Selector";

function ChangeUserPassword() {
  const { simpleListUserSystem, listUser, loadingList, changePassword, loadingChange } = useManageStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<ChangePasswordType & { user_cuid: string }>();

  useEffect(() => {
    (async () => {
      await simpleListUserSystem();
    })();
  }, [simpleListUserSystem]);

  return (
    <Spin spinning={loadingList}>
      <Form
        className="select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: ChangePasswordType & { user_cuid: string }) => {
          const isUpdated = await changePassword(payload, payload.user_cuid);
          if (isUpdated) {
            onClose();
          }
        }}
        autoComplete="off"
      >
        <Form.Item
          name="user_cuid"
          label="Usuários"
          rules={[
            {
              required: true,
              message: "Por favor, selecione o usuário.",
            },
          ]}
        >
          <Selector
            fieldMap={{
              status: "active",
              value: "cuid",
              label: "name",
              description: "login",
              dataLabel: ["name", "login"],
              dataFilter: ["name", "login"],
            }}
            options={listUser}
          />
        </Form.Item>

        <Form.Item<ChangePasswordType>
          name="new_password"
          label="Nova senha"
          rules={[
            {
              required: true,
              message: "Por favor, digite sua nova senha.",
            },
            {
              min: 6,
              message: "A senha deve ter no mínimo 6 caracteres.",
            },
          ]}
          hasFeedback
        >
          <Input.Password className="w-64" autoComplete="new-password" />
        </Form.Item>

        <Form.Item<ChangePasswordType>
          name="repeated_new_password"
          label="Confirme senha"
          dependencies={["new_password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Por favor, confirme sua nova senha.",
            },
            {
              min: 6,
              message: "A senha deve ter no mínimo 6 caracteres.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("As senhas não são iguais."));
              },
            }),
          ]}
        >
          <Input.Password className="w-64" autoComplete="repeat-new-password" />
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button disabled={loadingChange} type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <D3Can action="manage_password" resource="system_user">
                <Button loading={loadingChange} type="primary" htmlType="submit">
                  Atualizar
                </Button>
              </D3Can>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default ChangeUserPassword;
