import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, Divider, Space, Table, Tag, Tooltip } from "antd";
import { Key, useContext, useEffect, useState } from "react";
import { useContractStore } from "../contracts/store";
import { Contract, ItemsListContractsType } from "../contracts/types";
import dayjs from "dayjs";
import CreateContractForm from "../contracts/components/CreateContractForm";
import D3Can from "@components/shared/D3Can";
import UpdateContractForm from "../contracts/components/UpdateContractForm";
import CreateAmendmentForm from "../contracts/components/CreateAmendmentForm";
import CreateRescissionForm from "../contracts/components/CreateRescissionForm ";
import ViewForm from "../contracts/components/ViewForm";
import { UploadForm } from "../contracts/components/UploadForm";

export enum contractType {
  C = "Contrato",
  A = "Aditivo",
  R = "Distrato",
}

function TabContracts() {
  const { openDrawer } = useContext(D3DrawerContext);
  const { loadContracts, loading, listOfContracts, resetState } = useContractStore();

  useEffect(() => {
    (async () => {
      await loadContracts();
    })();
    return () => {
      resetState();
    };
  }, [loadContracts]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

  const toggleExpandAll = () => {
    const canExpand = listOfContracts.filter((contract) => contract.childrens.length > 0);
    if (expandedRowKeys.length !== canExpand.length) {
      setExpandedRowKeys(canExpand.map((contract) => contract.contract.cuid));
    } else {
      setExpandedRowKeys([]);
    }
  };

  const toggleExpand = (key: Key, expand: boolean) => {
    if (expand) {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== key));
    } else {
      setExpandedRowKeys([key, ...expandedRowKeys]);
    }
  };

  return (
    <div className="flex flex-col h-full p-2 overflow-hidden ">
      <div className="flex items-center justify-between">
        <h2 className="ml-1 text-lg font-semibold text-gray-600">Contratos</h2>
        <D3Can action="create" resource="customer_contract">
          <Button
            type="primary"
            size="middle"
            icon={<Icon icon="ic:round-plus" height={20} width={20} />}
            onClick={() => openDrawer(<CreateContractForm />, "right", 600, "Cadastrar contrato")}
          >
            Novo contrato
          </Button>
        </D3Can>
      </div>
      <Divider className="mt-1 mb-6 " />
      <Table<ItemsListContractsType>
        size="small"
        rowKey={(record) => record.contract.cuid}
        dataSource={listOfContracts}
        bordered
        className="responsive-table tabs-table "
        pagination={false}
        loading={loading}
        footer={() => {
          return (
            <div className="flex items-center justify-between w-full">
              <div className="flex gap-2">
                Total de {listOfContracts.length} {listOfContracts.length > 1 ? "contratos" : "contrato"}.
              </div>
            </div>
          );
        }}
        expandable={{
          expandedRowRender: (recordTable) => (
            <div className="flex p-3 bg-white rounded">
              <Table<Contract>
                size="small"
                rowKey={(record) => record.cuid}
                dataSource={recordTable.childrens}
                bordered
                className="responsive-table expanded-table"
                pagination={false}
              >
                <Table.Column<Contract>
                  showSorterTooltip={false}
                  defaultSortOrder="ascend"
                  key="type"
                  title="Tipo"
                  dataIndex="type"
                  render={(_, record) => contractType[record.type]}
                />
                <Table.Column<Contract>
                  showSorterTooltip={false}
                  defaultSortOrder="ascend"
                  key="description"
                  title="Descrição"
                  dataIndex="description"
                />
                <Table.Column<Contract>
                  showSorterTooltip={false}
                  defaultSortOrder="ascend"
                  key="date"
                  title="Data"
                  dataIndex="date"
                  render={(value, _) => dayjs(value).format("DD/MM/YYYY")}
                />
                <Table.Column<Contract>
                  showSorterTooltip={false}
                  defaultSortOrder="ascend"
                  key="expiration_date"
                  title="Data de expiração"
                  dataIndex="expiration_date"
                  render={(value, _) => (value ? dayjs(value).format("DD/MM/YYYY") : "")}
                />

                <Table.Column<Contract>
                  showSorterTooltip={false}
                  defaultSortOrder="ascend"
                  key="renovation"
                  title="Renovável"
                  dataIndex="renovation"
                  render={(value, _) => (value ? "Sim" : "Não")}
                />
                <Table.Column<Contract>
                  showSorterTooltip={false}
                  defaultSortOrder="ascend"
                  key="has_file"
                  title="Aditivo/Distrato anexado"
                  dataIndex="has_file"
                  render={(value, _) => (value ? "Sim" : "Não")}
                />

                <Table.Column<Contract>
                  showSorterTooltip={false}
                  defaultSortOrder="ascend"
                  key="renovation"
                  title="Situação"
                  dataIndex="active"
                  render={(value, _) =>
                    value ? (
                      <Tag
                        className="flex items-center justify-start gap-1 max-w-max"
                        icon={<Icon icon="fa6-solid:handshake" />}
                        color="success"
                      >
                        Em vigor
                      </Tag>
                    ) : (
                      <Tag
                        className="flex items-center justify-start gap-1 max-w-max"
                        icon={<Icon icon="fa6-solid:handshake-slash" />}
                        color="red"
                      >
                        Distratado
                      </Tag>
                    )
                  }
                />

                <Table.Column<Contract>
                  key="action"
                  title="Ações"
                  width={80}
                  align="center"
                  render={(_, record: Contract) => {
                    return (
                      <>
                        <Space>
                          <D3Can action="view" resource="customer_contract">
                            <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                              <Button
                                type="text"
                                shape="default"
                                icon={
                                  <Icon
                                    icon="carbon:document-view"
                                    height={18}
                                    width={18}
                                    className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                                  />
                                }
                                size={"middle"}
                                onClick={() =>
                                  openDrawer(
                                    <ViewForm contractCuid={record.cuid} />,
                                    "right",
                                    600,
                                    `Visualizar ${contractType[record.type].toLowerCase()}`
                                  )
                                }
                              />
                            </Tooltip>
                          </D3Can>
                          <D3Can action="update" resource="customer_contract">
                            <Tooltip
                              title={record.active ? "Editar" : `Impossível editar aditivo, aditivo foi distratado.`}
                              trigger={["click", "hover"]}
                            >
                              <Button
                                disabled={!record.active}
                                type="text"
                                shape="default"
                                icon={
                                  <Icon
                                    icon="iconamoon:edit-fill"
                                    height={18}
                                    width={18}
                                    className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                                  />
                                }
                                size={"middle"}
                                onClick={() =>
                                  openDrawer(
                                    <UpdateContractForm contractCuid={record.cuid} contractType={record.type} />,
                                    "right",
                                    600,
                                    `Editar ${contractType[record.type].toLowerCase()}`
                                  )
                                }
                              />
                            </Tooltip>
                          </D3Can>

                          <D3Can action="create" resource="customer_contract">
                            <Tooltip
                              trigger={["click", "hover"]}
                              title={
                                record.active
                                  ? `Envio/reenvio de ${record.type === "A" ? "aditivo" : "distrato"} digitalizado.`
                                  : "Impossível fazer upload, aditivo foi distratado."
                              }
                            >
                              <Button
                                disabled={!record.active}
                                className="flex items-center justify-center"
                                type="text"
                                shape="default"
                                icon={
                                  <Icon
                                    icon="fa6-solid:file-signature"
                                    height={16}
                                    className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                                  />
                                }
                                size={"middle"}
                                onClick={() =>
                                  openDrawer(
                                    <UploadForm
                                      contractCuid={record.cuid}
                                      fileName={`Contrato - ${record.description}`}
                                      isUploadContract={record.has_file}
                                    />,
                                    "right",
                                    600,
                                    "Envio / reenvio de contrato  digitalizado"
                                  )
                                }
                              />
                            </Tooltip>
                          </D3Can>

                          {record.type === "A" && (
                            <D3Can action="create" resource="customer_contract">
                              <Tooltip
                                trigger={["click", "hover"]}
                                title={
                                  record.active
                                    ? "Adicionar distrato"
                                    : `Impossível adicionar distrato, aditivo foi distratado.`
                                }
                              >
                                <Button
                                  disabled={!record.active}
                                  type="text"
                                  shape="default"
                                  icon={
                                    <Icon
                                      icon="fluent:document-dismiss-16-filled"
                                      height={18}
                                      width={18}
                                      className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                                    />
                                  }
                                  size={"middle"}
                                  onClick={() =>
                                    openDrawer(
                                      <CreateRescissionForm contractCuid={record.cuid} />,
                                      "right",
                                      600,
                                      "Adicionar distrato"
                                    )
                                  }
                                />
                              </Tooltip>
                            </D3Can>
                          )}
                        </Space>
                      </>
                    );
                  }}
                />
              </Table>
            </div>
          ),
          expandedRowKeys,
          columnTitle: (
            <div className="flex justify-center">
              <Tooltip title="Expandir todos" trigger={["click", "hover"]}>
                <span
                  className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                    expandedRowKeys.length ===
                    listOfContracts.filter((contract) => contract.childrens.length > 0).length
                      ? "expanded"
                      : "collapsed"
                  } ${
                    listOfContracts.filter((contract) => contract.childrens.length > 0).length > 0
                      ? ""
                      : "cursor-disable-important"
                  }`}
                  onClick={(e) => {
                    if (true) {
                      toggleExpandAll();
                    }
                  }}
                />
              </Tooltip>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <Tooltip
              title={`${record.childrens.length > 0 ? "" : "Contrato não possui aditivo ou distrato."}`}
              trigger={["click", "hover"]}
            >
              <span
                className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                  expanded ? "expanded" : "collapsed"
                } ${record.childrens.length > 0 ? "" : "cursor-disable-important"}`}
                onClick={(e) => {
                  if (record.childrens.length > 0) {
                    toggleExpand(record.contract.cuid, expanded);
                    onExpand(record, e);
                  }
                }}
              />
            </Tooltip>
          ),
        }}
      >
        <Table.Column<ItemsListContractsType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="type"
          title="Tipo"
          dataIndex={["contract", "type"]}
          render={(_, record) => contractType[record.contract.type]}
        />
        <Table.Column<ItemsListContractsType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="description"
          title="Descrição"
          dataIndex={["contract", "description"]}
        />
        <Table.Column<ItemsListContractsType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="date"
          title="Data"
          dataIndex={["contract", "date"]}
          render={(value, _) => dayjs(value).format("DD/MM/YYYY")}
        />
        <Table.Column<ItemsListContractsType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="expiration_date"
          title="Data de expiração"
          dataIndex={["contract", "expiration_date"]}
          render={(value, _) => dayjs(value).format("DD/MM/YYYY")}
        />

        <Table.Column<ItemsListContractsType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="renovation"
          title="Renovável"
          dataIndex={["contract", "renovation"]}
          render={(value, _) => (value ? "Sim" : "Não")}
        />

        <Table.Column<ItemsListContractsType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="has_file"
          title="Contrato anexado"
          dataIndex={["contract", "has_file"]}
          render={(value, _) => (value ? "Sim" : "Não")}
        />

        <Table.Column<ItemsListContractsType>
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          key="renovation"
          title="Situação"
          dataIndex={["contract", "active"]}
          render={(value, _) =>
            value ? (
              <Tag
                className="flex items-center justify-start gap-1 max-w-max"
                icon={<Icon icon="fa6-solid:handshake" />}
                color="success"
              >
                Em vigor
              </Tag>
            ) : (
              <Tag
                className="flex items-center justify-start gap-1 max-w-max"
                icon={<Icon icon="fa6-solid:handshake-slash" />}
                color="red"
              >
                Distratado
              </Tag>
            )
          }
        />

        <Table.Column<ItemsListContractsType>
          key="action"
          title="Ações"
          width={80}
          align="center"
          render={(_, record: ItemsListContractsType) => {
            return (
              <>
                <Space>
                  <D3Can action="view" resource="customer_contract">
                    <Tooltip title="Visualizar" trigger={["click", "hover"]}>
                      <Button
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="carbon:document-view"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <ViewForm contractCuid={record.contract.cuid} />,
                            "right",
                            600,
                            "Visualizar contrato"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="update" resource="customer_contract">
                    <Tooltip
                      trigger={["click", "hover"]}
                      title={record.contract.active ? "Editar" : "Impossível editar contrato, contrato foi distratado."}
                    >
                      <Button
                        disabled={!record.contract.active}
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="iconamoon:edit-fill"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <UpdateContractForm contractCuid={record.contract.cuid} />,
                            "right",
                            600,
                            "Editar contrato"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                  <D3Can action="create" resource="customer_contract">
                    <Tooltip
                      trigger={["click", "hover"]}
                      title={
                        record.contract.active
                          ? "Adicionar aditivo"
                          : "Impossível adicionar aditivo, contrato foi distratado."
                      }
                    >
                      <Button
                        disabled={!record.contract.active}
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="solar:document-medicine-bold"
                            height={18}
                            width={18}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <CreateAmendmentForm contractCuid={record.contract.cuid} />,
                            "right",
                            600,
                            "Adicionar aditivo"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>

                  <D3Can action="create" resource="customer_contract">
                    <Tooltip
                      trigger={["click", "hover"]}
                      title={
                        record.contract.active
                          ? "Envio/reenvio de contrato digitalizado."
                          : "Impossível fazer upload, contrato foi distratado."
                      }
                    >
                      <Button
                        disabled={!record.contract.active}
                        className="flex items-center justify-center"
                        type="text"
                        shape="default"
                        icon={
                          <Icon
                            icon="fa6-solid:file-signature"
                            height={16}
                            className="text-blue-400 transition-all duration-100 ease-in hover:text-blue-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <UploadForm
                              contractCuid={record.contract.cuid}
                              fileName={`Contrato - ${record.contract.description}`}
                              isUploadContract={record.contract.has_file}
                            />,
                            "right",
                            600,
                            "Envio / reenvio de contrato  digitalizado"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>

                  <D3Can action="create" resource="customer_contract">
                    <Tooltip
                      trigger={["click", "hover"]}
                      title={
                        record.contract.active
                          ? "Adicionar distrato"
                          : "Impossível adicionar distrato, contrato foi distratado."
                      }
                    >
                      <Button
                        type="text"
                        disabled={!record.contract.active}
                        shape="default"
                        icon={
                          <Icon
                            icon="fluent:document-dismiss-16-filled"
                            height={18}
                            width={18}
                            className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                          />
                        }
                        size={"middle"}
                        onClick={() =>
                          openDrawer(
                            <CreateRescissionForm contractCuid={record.contract.cuid} />,
                            "right",
                            600,
                            "Adicionar distrato"
                          )
                        }
                      />
                    </Tooltip>
                  </D3Can>
                </Space>
              </>
            );
          }}
        />
      </Table>
    </div>
  );
}

export default TabContracts;
