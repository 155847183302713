import Selector from "@components/shared/Selector";
import SpinLoading from "@components/shared/SpinLoading";
import { Alert, Button, Form, Input, List, Segmented, Space, Tooltip } from "antd";
import { useContext, useEffect } from "react";
import { useDocumentTypesStore } from "../store";
import { MutationDescriptionTemplateType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

type DescriptionTemplateDocumentTypeFormProps = {
  documentTypeCuid: string;
};

const optionsType = [
  { label: "Descrição do tipo de documento", value: "DTD" },
  { label: "Data atual", value: "CDT" },
];

function DescriptionTemplateDocumentTypeForm({ documentTypeCuid }: DescriptionTemplateDocumentTypeFormProps) {
  const [form] = Form.useForm<
    MutationDescriptionTemplateType & {
      types: string[];
      indexers: number[];
      use_indexers: boolean;
    }
  >();
  const {
    viewDescriptionTemplate,
    viewTemplate,
    loadingViewTemplate,
    listIndexers,
    indexers,
    loadingIndexers,
    payloadTemplate,
    preview,
    updateTemplate,
    loadingUpdateTemplate,
    onMountTemplate,
    deleteDescription,
    removeIndexersTemplate,
  } = useDocumentTypesStore();
  const { onClose } = useContext(D3DrawerContext);

  useEffect(() => {
    (async () => {
      await viewDescriptionTemplate(documentTypeCuid);
    })();
    return () => {
      onMountTemplate();
    };
  }, [documentTypeCuid]);

  useEffect(() => {
    if (indexers.length > 0) {
      useDocumentTypesStore.setState((state) => {
        const newPreviews = indexers.map((idx, index) => ({
          document_type_index_id: idx.id,
          item: "IDX",
          order: state.preview.length + (index + 1),
        }));
        return {
          preview: [...state.preview, ...newPreviews],
        };
      });
    }
  }, [indexers]);

  useEffect(() => {
    if (viewTemplate) {
      const types = viewTemplate.items
        ? viewTemplate.items
            .map((item) => (item.item !== "IDX" ? item.item : undefined))
            .filter((item): item is string => typeof item === "string")
        : [];

      const indexers = viewTemplate.items
        ? viewTemplate.items
            .map((item) => (item.item === "IDX" && item.document_type_index ? item.document_type_index.id : null))
            .filter((item): item is number => typeof item === "number")
        : [];

      if (indexers.length > 0) {
        (async () => {
          await listIndexers(documentTypeCuid);
        })();
      }

      form.setFieldsValue({
        separator: viewTemplate.separator,
        types,
        indexers,
        use_indexers: indexers.length > 0,
      });

      useDocumentTypesStore.setState({
        payloadTemplate: {
          separator: viewTemplate.separator,
          items:
            viewTemplate.items?.map(({ item, order, document_type_index }) => ({
              document_type_index_id: document_type_index?.id ?? null,
              item: item,
              order: order,
            })) || [],
        },
      });
    }
  }, [viewTemplate]);

  const useIndexers = Form.useWatch("use_indexers", form);

  function returnLabel(item: string, id: number | null) {
    if (item !== "IDX") {
      return optionsType.find((opt) => opt.value === item)?.label;
    } else {
      return indexers.find((idx) => idx.id === id)?.label;
    }
  }

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedItems = Array.from(payloadTemplate.items);
    const [removed] = reorderedItems.splice(source.index, 1); // Remove o item
    reorderedItems.splice(destination.index, 0, removed); // Insere no novo local

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    useDocumentTypesStore.setState((state) => ({
      payloadTemplate: {
        ...state.payloadTemplate,
        items: updatedItems,
      },
    }));
  };

  return loadingViewTemplate || loadingIndexers ? (
    <SpinLoading />
  ) : (
    <Form
      form={form}
      layout="vertical"
      onFinish={async () => {
        const isUpdate = await updateTemplate(documentTypeCuid);
        if (isUpdate) {
          onClose();
        }
      }}
      onValuesChange={async (
        changedValues: any,
        values: MutationDescriptionTemplateType & { types: string[]; indexers: number[]; use_indexers: boolean }
      ) => {
        if (changedValues.use_indexers) {
          if (indexers.length === 0) {
            await listIndexers(documentTypeCuid);
          }
        } else if (changedValues.use_indexers === false) {
          removeIndexersTemplate();
          form.setFieldValue("indexers", undefined);
        }

        if (changedValues.separator) {
          useDocumentTypesStore.setState((state) => ({
            payloadTemplate: {
              ...state.payloadTemplate,
              separator: changedValues.separator,
            },
          }));
        }

        if (changedValues.indexers || changedValues.types) {
          const allSelected = [...values.types, ...(values.indexers.map((item) => String(item)) || [])];
          const selected = preview.filter(
            (item) => allSelected.includes(String(item.document_type_index_id)) || allSelected.includes(item.item)
          );
          useDocumentTypesStore.setState((state) => ({
            payloadTemplate: {
              ...state.payloadTemplate,
              items: selected,
            },
          }));
        }
      }}
    >
      <Alert
        showIcon
        className="mb-4"
        type="info"
        closable
        description="A aplicação do template ocorre quando o documento não possui uma descrição definida e o tipo de documento está associado a um template."
      />
      <Form.Item
        label="Separador"
        name="separator"
        rules={[
          {
            required: payloadTemplate.items ? payloadTemplate.items.length > 1 : false,
            message: "Você possui mais de um tipo, selecione o separador.",
          },
        ]}
      >
        <Selector
          fieldMap={{
            value: "value",
            label: "label",
            dataLabel: ["label"],
            dataFilter: ["label"],
          }}
          options={[
            { label: `Underline "_" `, value: "_" },
            { label: `Hífen "-"`, value: "-" },
            { label: `Espaço " "`, value: ` ` },
          ]}
        />
      </Form.Item>

      <Form.Item label="Tipo" name="types">
        <Selector
          fieldMap={{
            value: "value",
            label: "label",
            dataLabel: ["label"],
            dataFilter: ["label"],
          }}
          options={optionsType}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item name="use_indexers" initialValue={false} label="Usar indexadores no template">
        <Segmented
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
        />
      </Form.Item>

      <Form.Item hidden={!useIndexers} name="indexers" label="Indexadores">
        <Selector
          fieldMap={{
            value: "id",
            label: "label",
            dataLabel: ["label"],
            dataFilter: ["label"],
          }}
          options={indexers}
          onDeselect={(value) => {
            deleteDescription(value, "document_type_index_id");
          }}
          mode="multiple"
          loading={loadingIndexers}
          disabled={loadingIndexers}
        />
      </Form.Item>

      <Form.Item label="Template">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  className="min-h-60"
                  size="small"
                  header={
                    <div className="grid grid-cols-12 divide-x">
                      <div className="flex items-center w-full col-span-2">Ordem</div>
                      <div className="flex w-full col-span-8 pl-3">Nome</div>
                      <div className="flex items-center justify-center w-full col-span-2">Ações</div>
                    </div>
                  }
                  bordered
                  dataSource={payloadTemplate.items}
                  renderItem={(item, index) => (
                    <Draggable key={item.order} draggableId={String(item.order)} index={index}>
                      {(provided) => (
                        <List.Item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div className="grid w-full grid-cols-12 divide-x">
                            <div className="flex items-center w-full col-span-2">
                              <div className="flex items-center gap-3">
                                <Tooltip title="Segure e raste para ordenar" trigger={["click", "hover"]}>
                                  <span>
                                    <Icon icon="tabler:menu-order" height={18} className="text-blue-500" />
                                  </span>
                                </Tooltip>
                                <span>{item.order}</span>
                              </div>
                            </div>
                            <div className="flex items-center w-full col-span-8 pl-3">
                              {returnLabel(item.item, item.document_type_index_id)}
                            </div>
                            <div className="flex items-center justify-center w-full col-span-2">
                              <Tooltip title="Excluir" trigger={["click", "hover"]}>
                                <Button
                                  className="peer"
                                  type="text"
                                  shape="circle"
                                  onClick={() => deleteDescription(item.order, "order")}
                                  icon={
                                    <Icon
                                      icon="material-symbols:delete"
                                      height={18}
                                      width={18}
                                      className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
                                    />
                                  }
                                  size={"middle"}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </List.Item>
                      )}
                    </Draggable>
                  )}
                />
                {provided.placeholder} {/* Necessário para o react-beautiful-dnd */}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form.Item>
      <Form.Item label="Preview descrição">
        <Input
          readOnly
          value={
            payloadTemplate.items
              ? payloadTemplate.items
                  .map(({ item, document_type_index_id }) => returnLabel(item, document_type_index_id))
                  .join(payloadTemplate.separator)
              : ""
          }
        />
      </Form.Item>
      <Form.Item>
        <div className="text-right">
          <Space size="small">
            <Button disabled={loadingUpdateTemplate} type="default" danger onClick={() => onClose()}>
              Cancelar
            </Button>
            <D3Can action="update" resource="customer_document_type">
              <Button loading={loadingUpdateTemplate} type="primary" htmlType="submit">
                Atualizar
              </Button>
            </D3Can>
          </Space>
        </div>
      </Form.Item>
    </Form>
  );
}

export default DescriptionTemplateDocumentTypeForm;
