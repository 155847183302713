import { Icon } from "@iconify/react";
import { Button, Form, Input, Segmented, Select, Spin, Steps, Tooltip } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import { useDocumentStore } from "./store";
import { ChildrenType, IndexesValue, ItemDocumentTypeType, PayloadDocumentType } from "./types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { DynamicFormElement } from "../indexers/DynamicFormElement";
import { useForm } from "antd/es/form/Form";
import DocumentLifeCycleManager from "@components/shared/DocumentLifeCycleManager";
import useNavigateStore from "@views/customer/spaces/work/components/navigate/store";
import StatusSelect from "@components/shared/StatusSelect";
import { ctNotification } from "@components/shared/CTNotification";
import NewUploadManager from "@components/shared/CTUploadManager";
import { useUploadContext } from "@context/CTUpload/UploadContext";
import ListView from "@components/shared/CTUploadManager/ListView";

type RegisterDigitalDocumentProps = {
  isDrawer?: boolean;
  dtfCuid?: string;
};

function RegisterDigitalDocument({ isDrawer = false, dtfCuid }: RegisterDigitalDocumentProps) {
  const {
    listIndexesDocumentType,
    simpleListDocumentTypeCustomer,
    simpleListDepartments,
    payload,
    registrationDocument,
    resetState,
    documentTypes,
    departments,
    indexes,
    finish,
  } = useDocumentStore();

  const { onClose, openDrawer } = useContext(D3DrawerContext);
  const divRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [current, setCurrent] = useState(0);

  const [formClassification] = useForm<Partial<PayloadDocumentType>>();
  const [documentTypeSelected, setDocumentTypeSelected] = useState<ItemDocumentTypeType>();

  const [formFill] = useForm<Partial<PayloadDocumentType>>();

  const [document, setDocument] = useState<ChildrenType>();

  const { onFinish, loadingFinish } = useUploadContext();

  useEffect(() => {
    (async () => {
      if (current === 0) {
        setLoading(true);
        await simpleListDocumentTypeCustomer();
        await simpleListDepartments();
        setLoading(false);
      } else if (current === 1) {
        setLoading(true);
        await listIndexesDocumentType(payload.document_type_cuid);
        setLoading(false);
      } else if (current === 2) {
        setLoading(false);
      }

      return () => {
        resetState();
      };
    })();
  }, [current, dtfCuid]);

  console.log(payload);

  useEffect(() => {
    if (documentTypes.length > 0 && dtfCuid) {
      const documentType = documentTypes.find((item) => item.cuid === dtfCuid);
      setDocumentTypeSelected(documentType);
      if (documentType?.active) {
        const datePurge =
          documentType.temporality_year > 0
            ? dayjs(payload.original_date).add(documentType.temporality_year, "year")
            : "";
        payload.expected_purge_date = datePurge;
      } else {
        ctNotification({
          title: "Atenção !",
          content: "Não é possível realizar o upload neste tipo de documento, pois ele está inativado.",
          type: "info",
        });
        onClose();
      }
      payload.document_type_cuid = dtfCuid;
      formClassification.setFieldsValue(payload);
    } else {
      formClassification.setFieldsValue(payload);
    }
  }, [documentTypes]);

  useEffect(() => {
    const div = divRef.current;
    const checkScroll = () => {
      if (div) {
        setIsScroll(div.scrollHeight > div.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(checkScroll);
    if (div) {
      resizeObserver.observe(div);
    }
    checkScroll();
    return () => {
      if (div) {
        resizeObserver.unobserve(div);
      }
    };
  }, [indexes]);

  const scrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  const steps = [
    {
      title: "Classificação",
      content: (
        <div ref={divRef} className="relative flex justify-center w-full h-full p-3 overflow-y-auto custom-scroll">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Spin />
            </div>
          ) : (
            <Form
              form={formClassification}
              className={`h-full w-[420px] px-4 ${isScroll ? "mb-40" : ""}`}
              layout="vertical"
              onFinish={(values: Partial<PayloadDocumentType>) => {
                useDocumentStore.setState({
                  payload: {
                    ...payload,
                    expected_purge_date: values.expected_purge_date
                      ? dayjs(values.expected_purge_date).format("YYYY-MM-DD")
                      : "",
                    is_permanent_storage: values.is_permanent_storage ? values.is_permanent_storage : false,
                  },
                });
                setCurrent(current + 1);
              }}
              onValuesChange={(changedValues, allValues) => {
                const key = Object.keys(changedValues)[0];
                const value = changedValues[key];
                useDocumentStore.setState((state) => ({
                  payload: {
                    ...state.payload,
                    [key]: value,
                  },
                }));
                if (changedValues.document_type_cuid) {
                  const documentType = documentTypes.find((item) => item.cuid === changedValues.document_type_cuid);
                  setDocumentTypeSelected(documentType);
                  if (documentType) {
                    const purgeDate =
                      documentType.temporality_year > 0
                        ? dayjs(payload.original_date).add(documentType.temporality_year, "year")
                        : "";
                    formClassification.setFieldValue("expected_purge_date", purgeDate);
                    formClassification.setFieldValue("is_permanent_storage", documentType.temporality_permanent_guard);
                  }
                }
                if (changedValues.original_date || changedValues.is_permanent_storage === false) {
                  if (documentTypeSelected) {
                    const purgeDate =
                      documentTypeSelected.temporality_year > 0
                        ? dayjs(changedValues.original_date).add(documentTypeSelected.temporality_year, "year")
                        : "";
                    formClassification.setFieldValue("expected_purge_date", purgeDate);
                  }
                }
                if (changedValues.original_date === "" || changedValues.is_permanent_storage) {
                  formClassification.setFieldValue("expected_purge_date", "");
                }
              }}
            >
              <Form.Item<Partial<PayloadDocumentType>>
                label="Departamento"
                name="billable_department_cuid"
                rules={[
                  {
                    required: true,
                    message: "Por favor, selecione o departamento.",
                  },
                ]}
              >
                <Select
                  listItemHeight={10}
                  allowClear
                  removeIcon={
                    <Icon
                      icon="iconamoon:close-duotone"
                      className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                    />
                  }
                  menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                  optionLabelProp="dataLabel"
                  showSearch
                  maxTagCount="responsive"
                  size="middle"
                  className="w-full truncate select-none"
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                >
                  {departments.map(({ active, abbreviation, cuid, description }) => (
                    <Select.Option
                      disabled={!active}
                      key={cuid}
                      value={cuid}
                      dataFilter={`${abbreviation} - ${description}`}
                      dataLabel={`${abbreviation} - ${description}`}
                    >
                      <div className="flex items-center justify-between ">
                        <div className="flex flex-col truncate">
                          <span className="truncate">{`${abbreviation} - ${description}`}</span>
                        </div>
                        <StatusSelect status={active} />
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item<Partial<PayloadDocumentType>>
                name="global_visibility"
                initialValue={false}
                label="Visibilidade global?"
              >
                <Segmented
                  options={[
                    { label: "Sim", value: true },
                    { label: "Não", value: false },
                  ]}
                  size="small"
                  className="select-none"
                />
              </Form.Item>
              <Form.Item<Partial<PayloadDocumentType>> name="description" label="Descrição">
                <Input />
              </Form.Item>
              <DocumentLifeCycleManager
                disabled={dtfCuid ? true : false}
                documentTypeSelected={documentTypeSelected ? documentTypeSelected : ({} as ItemDocumentTypeType)}
                documentTypes={documentTypes}
                loading={loading}
                payload={payload}
              />
            </Form>
          )}
        </div>
      ),
    },
    {
      title: "Preenchimento",
      content: (
        <div ref={divRef} className="relative flex justify-center w-full h-full p-3 overflow-y-auto custom-scroll">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Spin />
            </div>
          ) : (
            <Form
              form={formFill}
              onFinish={async (values: Partial<PayloadDocumentType>) => {
                setLoading(true);
                const indexes_values = Object.entries(values).map(([key, val]) => {
                  const [type] = Object.entries(val);
                  const { end_value, start_value, value } = type[1] as IndexesValue;
                  return {
                    end_value: end_value ? end_value.toString() : "",
                    document_type_index_id: parseInt(key),
                    start_value: start_value ? start_value.toString() : "",
                    value: value ? value.toString() : "",
                  };
                }) as IndexesValue[];

                useDocumentStore.setState({
                  payload: {
                    ...payload,
                    indexes_values: indexes_values,
                  },
                });

                const result = await registrationDocument();
                if (finish) {
                  if (result.status === "success") {
                    useNavigateStore.getState().addChildren(result.body as ChildrenType);
                    resetState();
                    formClassification.resetFields();
                    formFill.resetFields();
                    setCurrent(0);
                  } else {
                    setLoading(false);
                  }
                } else {
                  if (result.status === "success") {
                    setDocument(result.body as ChildrenType);
                    useNavigateStore.getState().addChildren(result.body as ChildrenType);
                    setCurrent(2);
                  } else {
                    setLoading(false);
                  }
                }
              }}
              className={`h-full w-[420px] px-4 ${isScroll ? "mb-40" : ""}`}
              layout="vertical"
            >
              {DynamicFormElement(indexes)}
            </Form>
          )}
        </div>
      ),
    },
    {
      title: "Upload de arquivos",
      content: (
        <div ref={divRef} className="relative flex justify-center w-full h-full p-3 overflow-y-auto custom-scroll">
          {!loading && documentTypeSelected ? (
            <NewUploadManager
              registerDocument
              billable_department_cuid={payload.billable_department_cuid}
              global_visibility={payload.global_visibility}
              fileCuid={document?.cuid ? document.cuid : documentTypeSelected.cuid}
              require_departments={false}
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <Spin />
            </div>
          )}
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div
      className={`${
        isDrawer ? " border rounded" : "mb-1 mr-1 rounded shadow-md"
      } flex flex-col h-full overflow-hidden bg-white `}
    >
      <div className="flex items-center justify-center h-12 border-b border-gray-200 min-h-12 ">
        <div className="flex items-center justify-center">
          <span className="text-xl font-semibold text-gray-600">Cadastramento de Documento Digital</span>
        </div>
      </div>

      <div className="flex items-start justify-center w-full h-full overflow-hidden grow">
        <div className="flex flex-col w-[900px] h-full overflow-hidden py-2">
          <div className="flex items-center px-2 min-h-9 h-9">
            <Steps current={current} items={items} />
          </div>
          <div className="relative flex h-full p-2 overflow-hidden grow">
            {isScroll && (
              <div className="absolute z-50 flex items-center justify-center top-1/2 right-8">
                <Tooltip title="Click ou role para ver mais indexadores" trigger={["click", "hover"]}>
                  <Button
                    type="link"
                    danger
                    onClick={scrollToBottom}
                    icon={<Icon height={18} icon="line-md:arrow-down" className="text-red-600 h-11 animate-bounce" />}
                  />
                </Tooltip>
              </div>
            )}
            {steps[current].content}
          </div>
          <div className={`flex items-center  px-2 h-11 min-h-11 ${current === 1 ? "justify-between" : "justify-end"}`}>
            {current === 1 && (
              <Button disabled={loading} type="dashed" onClick={() => setCurrent(current - 1)}>
                Anterior
              </Button>
            )}
            <div className="flex gap-2">
              {current === 0 ? (
                <Button loading={loading} type="primary" onClick={() => formClassification.submit()}>
                  Próximo
                </Button>
              ) : current === 1 ? (
                <div className="flex gap-2">
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      useDocumentStore.setState({ finish: true });
                      formFill.submit();
                    }}
                  >
                    Finalizar
                  </Button>
                  <Button loading={loading} type="primary" onClick={() => formFill.submit()}>
                    Upload / Finalizar
                  </Button>
                </div>
              ) : (
                <Button
                  type="primary"
                  loading={loading || loadingFinish}
                  onClick={async () => {
                    setLoading(true);
                    await onFinish(document?.cuid ? document.cuid : "");
                    openDrawer(<ListView />, "right", 800, "Gerenciamento de Upload");
                    resetState();
                    formClassification.resetFields();
                    formFill.resetFields();
                    setCurrent(0);
                  }}
                >
                  Finalizar
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterDigitalDocument;
