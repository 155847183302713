import { Input, Form, Button, Row, Col, Spin, Divider, Segmented } from "antd";
import { useCompanyStore } from "../../store";
import { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import cnpjValidation from "@utils/formInputValidation/cnpjValidation";
import cpfValidation from "@utils/formInputValidation/cpfValidation";
import { CreateCompanyType, UpdateCompanyType, ViewCompanyType } from "../../types";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";

function TabData() {
  const [typeDocument, setTypeDocument] = useState("CNPJ");
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm<ViewCompanyType>();

  const { createCompany, updateCompany, getCompany } = useCompanyStore();

  useEffect(() => {
    (async () => {
      const result = await getCompany();
      if (result.status === "success") {
        const viewCompany = result.body as ViewCompanyType;
        form.setFieldsValue(viewCompany);
        setTypeDocument(viewCompany.document_type);
      } else {
        showMessage(result);
      }
      setLoading(false);
    })();
  }, [getCompany, form]);

  return (
    <div className="p-2">
      <Spin spinning={loading}>
        <h2 className="ml-1 text-lg font-semibold text-gray-600">Dados da empresa</h2>
        <Divider className="mt-1 mb-6 " />
        <Form
          className="w-[600px] select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: UpdateCompanyType | CreateCompanyType) => {
            setLoading(true);
            if (form.getFieldValue("cuid") !== undefined) {
              const result = await updateCompany(payload);
              if (result.status === "success") {
                showMessage(result, "Empresa atualizada com sucesso.");
              } else {
                showMessage(result);
              }
            } else {
              const result = await createCompany(payload);
              if (result.status === "success") {
                showMessage(result, "Empresa cadastrada com sucesso.");
              } else {
                showMessage(result);
              }
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Form.Item label="Cuid" name="cuid" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="Nome fantasia" name="fantasy_name">
            <Input />
          </Form.Item>
          <Form.Item label="Razão social" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Pessoa" name="document_type">
            <Segmented
              options={[
                { label: "Física", value: "CPF" },
                { label: "Jurídica", value: "CNPJ" },
              ]}
              onChange={(value: string) => {
                setTypeDocument(value);
                form.setFieldsValue({ document_type: value });
              }}
            />
          </Form.Item>
          {typeDocument === "CPF" ? (
            <Form.Item
              label="CPF"
              name="document"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o CPF!",
                },
                () => ({
                  validator(_, value) {
                    if (!value || cpfValidation(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("CPF inválido!"));
                  },
                }),
              ]}
            >
              <PatternFormat format="###.###.###-##" customInput={Input} />
            </Form.Item>
          ) : (
            <Row gutter={10}>
              <Col>
                <Form.Item
                  label="CNPJ"
                  className="w-[294px]"
                  name="document"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o CNPJ!",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || cnpjValidation(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("CNPJ inválido!"));
                      },
                    }),
                  ]}
                >
                  <PatternFormat format="##.###.###/####-##" customInput={Input} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Inscrição estadual" className="w-[294px]" name="state_inscription">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item label="Responsável pelo contrato" name="contract_manager">
            <Input />
          </Form.Item>
          <div className="flex items-center justify-end">
            <Form.Item>
              {form.getFieldValue("cuid") !== undefined ? (
                <D3Can action="update" resource="company">
                  <Button type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              ) : (
                <D3Can action="create" resource="company">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              )}
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
}

export default TabData;
