import useAuthStore from "@provider/AuthProvider";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { useEffect } from "react";
import { useReportsStore } from "../store";
import { SearchType } from "../types";
import Selector from "@components/shared/Selector";

export enum ContextType {
  SYS = "Sistema",
  CPN = "Empresa",
  CTM = "Cliente",
  CGR = "Grupo de Cliente",
}

export const SearchReportForm = () => {
  const [form] = Form.useForm<SearchType>();
  const {
    filter,
    setFilter,
    simpleListCustomer,
    listCustomer,
    loadingCustomer,
    simpleListCustomerGroup,
    listCustomerGroup,
    loadingCustomerGroup,
    closeSearch,
    resetFilter,
  } = useReportsStore();
  const { getUserInfo } = useAuthStore();
  const userInfo = getUserInfo();

  useEffect(() => {
    (async () => {
      if (userInfo.Type !== "CTM") {
        await simpleListCustomer();
        await simpleListCustomerGroup();
      }
      form.setFieldsValue(filter);
    })();
  }, [form]);

  const listContext = Object.entries(ContextType).map(([value, label]) => ({
    value,
    label,
    active: ["CTM", "CGR"].includes(value) && userInfo.Type === "CGR",
  }));

  return (
    <>
      <Form
        className="select-none w-[700px]"
        layout="vertical"
        form={form}
        onFinish={async (values: SearchType) => {
          setFilter(values);
          closeSearch(false);
        }}
        autoComplete="off"
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item<SearchType> name="report_title" label="Nome">
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<SearchType> name="report_description" label="Descrição">
              <Input className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item<SearchType> label="Cliente" className="max-h-[58px]" name="customer_cuid">
              <Selector
                fieldMap={{
                  value: "cuid",
                  label: "fantasy_name",
                  description: "name",
                  dataLabel: ["fantasy_name"],
                  dataFilter: ["fantasy_name", "name", "id", "document"],
                }}
                options={listCustomer}
                loading={loadingCustomer}
                disabled={userInfo.Type === "CTM"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<SearchType> label="Grupo de Cliente" className="max-h-[58px]" name="customer_group_cuid">
              <Selector
                fieldMap={{
                  value: "cuid",
                  label: "description",
                  dataLabel: ["description"],
                  dataFilter: ["description"],
                }}
                options={listCustomerGroup}
                loading={loadingCustomerGroup}
                disabled={userInfo.Type === "CTM" || userInfo.Type === "CGR"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item<SearchType> label="Perfil" name="report_context">
              <Selector
                fieldMap={{
                  status: userInfo.Type !== "SYS" ? "active" : "",
                  value: "value",
                  label: "label",
                  dataLabel: ["label"],
                  dataFilter: ["label"],
                  disabled: userInfo.Type !== "SYS",
                }}
                options={listContext}
                disabled={userInfo.Type === "CTM"}
              />
            </Form.Item>
          </Col>
          <Col span={12} className="pt-[30px]">
            <Form.Item>
              <div className="text-right">
                <Space size="small">
                  <Button
                    type="default"
                    danger
                    onClick={() => {
                      closeSearch(false);
                      resetFilter();
                      form.resetFields();
                    }}
                  >
                    Limpar Filtro
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Filtrar
                  </Button>
                </Space>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
