import { useMemo, useState, useEffect, useRef, useContext } from "react";
import { Button, Tooltip, Input, Form, Segmented } from "antd";
import VirtualList from "rc-virtual-list";
import { UploadFile } from "antd/lib";
import { useUploadContext } from "@context/CTUpload/UploadContext";
import { UseUploadManagedStore } from "./store";
import { extension } from "@utils/mine";
import SpinLoading from "@components/shared/SpinLoading";
import { Icon } from "@iconify/react";
import formatBytes from "@utils/formatBytes";
import InputFiles from "./components/InputFiles";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import Selector from "../Selector";
import { useUploadContextStore } from "@context/CTUpload/store";
import ListView from "./ListView";
import { ctNotification } from "@components/shared/CTNotification";

interface CustomUploadFile extends UploadFile {
  duplicate?: boolean;
  progress?: number;
}

type NewUploadManagerProps = {
  require_departments?: boolean;
  fileCuid: string;
  registerDocument?: boolean;
  global_visibility?: boolean;
  billable_department_cuid?: string;
};

function NewUploadManager({
  require_departments = false,
  fileCuid,
  registerDocument = false,
  global_visibility = false,
  billable_department_cuid = "",
}: NewUploadManagerProps) {
  const { fileList, uploading, loadingFiles, handleUpload, deleteFile, renameFile, resetFileList } = useUploadContext();
  const {
    simpleListExtensionsSystem,
    loadingExtensions,
    extensions,
    loadingDepartments,
    simpleListDepartments,
    departments,
  } = UseUploadManagedStore();
  const { openDrawer } = useContext(D3DrawerContext);
  const divRef = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();
  const [edit, setEdit] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    (async () => {
      await simpleListExtensionsSystem();
      if (require_departments) {
        await simpleListDepartments();
      }
    })();
    if (registerDocument) {
      useUploadContextStore.setState((state) => ({
        payload: {
          ...state.payload,
          billable_department_cuid,
          global_visibility,
        },
      }));
    }
  }, [fileCuid]);

  useEffect(() => {
    const divElement = divRef.current;
    if (!divElement) return;
    setHeight(divElement.offsetHeight);
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          setHeight(entry.contentRect.height);
        }
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(divElement);
    return () => {
      resizeObserver.unobserve(divElement);
    };
  }, [divRef, fileList]);

  const renderItem = useMemo(
    () => (file: CustomUploadFile) => {
      const ext = extension(file.type ?? "não reconhecido");
      return (
        <div className="grid grid-cols-12 border-b h-11">
          <div className="flex items-center justify-center col-span-1 ">
            <Icon
              className="drop-shadow-md"
              height={24}
              icon={extensions.find((ext) => ext.key === extension(file.type ?? ""))?.icon ?? ""}
            />
          </div>
          <div className="flex items-center justify-start col-span-5 px-2 ">
            {edit === file.uid ? (
              <Input
                className="w-full"
                onChange={(e) => setFileName(e.target.value)}
                value={edit === file.uid ? fileName : file.name.replace(String(ext), "")}
              />
            ) : (
              <span className="font-medium truncate max-w-[300px] ">{file.name.replace(String(ext), "")} </span>
            )}
          </div>
          <div className="flex items-center justify-center col-span-2">
            <span className="uppercase">{ext?.replace(".", "")}</span>
          </div>
          <div className="flex items-center justify-center col-span-2">
            <span className="uppercase"> {formatBytes(file.size ?? 0)}</span>
          </div>
          <div className="flex items-center justify-center col-span-2 gap-2">
            <Tooltip title={edit === file.uid ? "Salvar" : "Editar"}>
              <Button
                type="text"
                onClick={() => {
                  if (edit === file.uid) {
                    renameFile(file.uid, fileName);
                    setEdit("");
                    setFileName("");
                  } else {
                    setEdit(file.uid);
                    setFileName(file.name.replace(String(ext), ""));
                  }
                }}
                icon={
                  edit === file.uid ? (
                    <Icon
                      height={18}
                      className="text-green-400 transition-all duration-200 ease-in hover:text-green-500 hover:scale-105 "
                      icon="uil:save"
                    />
                  ) : (
                    <Icon
                      height={18}
                      className="text-blue-400 transition-all duration-200 ease-in hover:text-blue-500 hover:scale-105 "
                      icon="eva:edit-2-fill"
                    />
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Button
                type="text"
                onClick={() => deleteFile(file.uid)}
                icon={
                  <Icon
                    height={18}
                    className="text-red-400 transition-all duration-200 ease-in hover:text-red-500 hover:scale-105 "
                    icon="material-symbols:delete"
                  />
                }
              />
            </Tooltip>
          </div>
        </div>
      );
    },
    [fileList, edit, fileName]
  );

  return (
    <div className={`flex justify-between h-full overflow-hidden bg-white rounded ${registerDocument ? "" : "shadow"}`}>
      {loadingExtensions ? (
        <SpinLoading />
      ) : (
        <div className="flex flex-col h-full overflow-hidden  gap-3 min-w-[800px] p-4">
          <InputFiles />
          <div className="flex flex-col h-full overflow-hidden border rounded shadow">
            <div className="grid items-center flex-none w-full grid-cols-3 px-2 border-b h-11">
              {require_departments ? (
                <Form
                  form={form}
                  layout="inline"
                  className="px-2"
                  onValuesChange={(changedValues: any, values: any) => {
                    const key = Object.keys(changedValues)[0];
                    const value = changedValues[key];
                    if (isNaN(Number(key))) {
                      useUploadContextStore.setState((state) => ({
                        payload: {
                          ...state.payload,
                          [key]: value,
                        },
                      }));
                    }
                  }}
                >
                  <div className="flex items-center justify-start w-full gap-4 ">
                    <Form.Item
                      initialValue={false}
                      className="p-0 m-0 "
                      name="global_visibility"
                      label="Visibilidade Global?"
                    >
                      <Segmented
                        options={[
                          { value: true, label: "Sim" },
                          { value: false, label: "Não" },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="billable_department_cuid"
                      className="p-0 m-[6px] w-96"
                      label="Departamento"
                      rules={[
                        {
                          required: require_departments,
                          message: "",
                        },
                      ]}
                    >
                      <Selector
                        fieldMap={{
                          status: "active",
                          value: "cuid",
                          label: "abbreviation",
                          description: "description",
                          dataLabel: ["abbreviation", "description"],
                          dataFilter: ["description", "abbreviation"],
                          disabled: true,
                        }}
                        options={departments}
                        loading={loadingDepartments}
                      />
                    </Form.Item>
                  </div>
                </Form>
              ) : (
                <div className="flex items-center justify-center col-span-3">
                  <span className="text-xl font-semibold text-gray-600">Gerenciador de Uploads</span>
                </div>
              )}
            </div>
            <div className="grid h-12 grid-cols-12 font-semibold text-gray-600 border-b divide-x shadow-b">
              <div className="flex items-center justify-center col-span-1 ">Icon</div>
              <div className="flex items-center justify-start col-span-5 px-2 ">Nome</div>
              <div className="flex items-center justify-center col-span-2">
                <span>Tipo</span>
              </div>
              <div className="flex items-center justify-center col-span-2">
                <span> Tamanho</span>
              </div>
              <div className="flex items-center justify-center col-span-2 gap-2">Ações</div>
            </div>
            <div className="flex-col flex-grow h-full overflow-hidden">
              {loadingFiles ? (
                <SpinLoading />
              ) : (
                <div ref={divRef} className="flex-grow h-full overflow-auto">
                  <VirtualList
                    virtual
                    data={fileList}
                    height={height}
                    itemHeight={43}
                    itemKey="uid"
                    className="flex flex-col max-h-full overflow-hidden"
                  >
                    {(item: CustomUploadFile) => renderItem(item)}
                  </VirtualList>
                </div>
              )}
            </div>
            <div className="grid flex-none w-full h-10 grid-cols-2 px-2 border-t shadow-t max-h-10">
              <div className="flex items-center justify-start"></div>
              <div className="flex items-center justify-end ">
                <Button size="small" className="max-w-max" danger type="dashed" onClick={() => resetFileList()}>
                  Apagar todos ({fileList.length})
                </Button>
              </div>
            </div>
          </div>
          {!registerDocument ? (
            <Button
              size="large"
              type="primary"
              onClick={() => {
                const errors = form.getFieldsError();
                if (require_departments && errors[1].errors.length > 0) {
                  form.submit();
                  ctNotification({
                    title: "Atenção !",
                    content: "Selecione um departamento.",
                    type: "error",
                  });
                } else {
                  handleUpload(fileCuid);
                  openDrawer(<ListView />, "right", 800, "Gerenciamento de Upload");
                }
              }}
              loading={uploading}
              disabled={!fileList.length || edit !== ""}
            >
              Enviar
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default NewUploadManager;
